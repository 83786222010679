import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, createSlots as _createSlots, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Cover = _resolveComponent("Cover")!
  const _component_Card = _resolveComponent("Card")!
  const _component_FormSelect = _resolveComponent("FormSelect")!
  const _component_FormSubmitButton = _resolveComponent("FormSubmitButton")!

  return (_openBlock(), _createElementBlock("form", {
    class: _normalizeClass(_ctx.$style.container),
    onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.apply && _ctx.apply(...args)), ["prevent"]))
  }, [
    _createElementVNode("section", {
      class: _normalizeClass(_ctx.$style.contentContainer)
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.section)
      }, [
        _createVNode(_component_Card, {
          item: _ctx.titleCard,
          class: _normalizeClass(_ctx.$style.card)
        }, {
          cover: _withCtx(() => [
            _createVNode(_component_Cover, { item: _ctx.title }, null, 8, ["item"])
          ]),
          _: 1
        }, 8, ["item", "class"])
      ], 2),
      _createElementVNode("fieldset", {
        class: _normalizeClass(_ctx.$style.section)
      }, [
        _createElementVNode("legend", {
          class: _normalizeClass(_ctx.$style.header)
        }, _toDisplayString(_ctx.$t('annotationCopying.manualCopy.source.header')), 3),
        _createVNode(_component_FormSelect, {
          modelValue: _ctx.selectedRelease,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedRelease) = $event)),
          options: _ctx.releaseOptions,
          prompt: _ctx.$t('annotationCopying.manualCopy.source.placeholder'),
          optionLabel: 'displayName',
          optionKey: (r) => r.id,
          classOverrides: { 'highlighted': _ctx.$style.highlighted, 'option': _ctx.$style.option },
          class: _normalizeClass(_ctx.$style.dropdown)
        }, _createSlots({ _: 2 }, [
          (_ctx.selectedRelease)
            ? {
                name: "countWithLabel",
                fn: _withCtx(() => [
                  _createElementVNode("span", {
                    class: _normalizeClass(['badge', _ctx.$style.badgeWithLabel])
                  }, _toDisplayString(_ctx.$t('annotationCopying.manualCopy.count', { N: _ctx.selectedRelease.count })), 3)
                ]),
                key: "0"
              }
            : undefined,
          _renderList(_ctx.releaseOptions, (option) => {
            return {
              name: `count:${option.id}`,
              fn: _withCtx(() => [
                _createElementVNode("span", {
                  class: _normalizeClass(_ctx.$style.optionBadge)
                }, _toDisplayString(option.count), 3)
              ])
            }
          })
        ]), 1032, ["modelValue", "options", "prompt", "optionKey", "classOverrides", "class"]),
        _createElementVNode("p", {
          class: _normalizeClass(_ctx.$style.additional)
        }, _toDisplayString(_ctx.$t('annotationCopying.manualCopy.source.additional')), 3)
      ], 2),
      _createElementVNode("fieldset", {
        class: _normalizeClass(_ctx.$style.section)
      }, [
        _createElementVNode("legend", {
          class: _normalizeClass(_ctx.$style.header)
        }, _toDisplayString(_ctx.$t('annotationCopying.manualCopy.destination.header')), 3),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style.destination)
        }, [
          _createElementVNode("p", null, _toDisplayString(_ctx.newestRelease.name), 1),
          _createElementVNode("p", {
            class: _normalizeClass(['badge', _ctx.$style.badgeWithLabel])
          }, _toDisplayString(_ctx.$t('annotationCopying.manualCopy.count', { N: _ctx.newestRelease.count })), 3)
        ], 2),
        _createElementVNode("p", {
          class: _normalizeClass(_ctx.$style.additional)
        }, _toDisplayString(_ctx.$t('annotationCopying.manualCopy.destination.additional')), 3)
      ], 2)
    ], 2),
    _createElementVNode("footer", {
      class: _normalizeClass(_ctx.$style.applyContainer)
    }, [
      _createVNode(_component_FormSubmitButton, {
        label: _ctx.$t('annotationCopying.manualCopy.submit'),
        disabled: !_ctx.selectedRelease,
        class: _normalizeClass(!_ctx.selectedRelease ? _ctx.$style.applyInactive : ''),
        "aria-label": !_ctx.selectedRelease ? _ctx.$t('annotationCopying.manualCopy.submitInactive') : '',
        onClick: _withModifiers(_ctx.apply, ["prevent"])
      }, null, 8, ["label", "disabled", "class", "aria-label", "onClick"])
    ], 2)
  ], 34))
}