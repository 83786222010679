
import { defineComponent, ref } from 'vue';
import BackToTopButton from 'app/components/BackToTopButton.vue';

export default defineComponent({
  name: 'InfoOverlay',
  components: {
    BackToTopButton
  },
  props: {
    header: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      default: undefined
    }
  },
  emits: [
    'close'
  ],
  setup: () => {
    const topOfBody = ref<HTMLElement | null>(null);
    const showBackToTop = ref(false);
    const focusOn = '[data-close_focus]';

    return {
      focusOn,
      showBackToTop,
      topOfBody
    };
  }
});
