
import { PostishCopyStatus, PostishCopyTitleSnapshot } from 'app/base/postish';
import FormSubmitButton from 'app/components/FormSubmitButton.vue';
import StatusBanner, { Status } from 'app/components/StatusBanner.vue';
import { getReleaseLabel } from 'app/functions/use-annotation-copying';
import { useI18n } from 'app/functions/use-i18n';
import { PropType, computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'CopyStatusBanner',
  components: {
    FormSubmitButton,
    StatusBanner
  },
  props: {
    status: {
      type: String as PropType<PostishCopyStatus>,
      required: true
    },
    source: {
      type: Object as PropType<PostishCopyTitleSnapshot>,
      required: true
    },
    target: {
      type: Object as PropType<PostishCopyTitleSnapshot>,
      required: true
    }
  },
  emits: [
    'update'
  ],
  setup: (props) => {
    const { t } = useI18n();

    const releaseLabel = getReleaseLabel(props.source, props.target);

    const mappedStatus = computed(() => {
      return props.status === 'FINISHED'
        ? 'complete'
        : props.status === 'ERROR'
          ? 'error'
          : 'inProgress' as Status;
    });

    const statusLabel = computed(() => {
      return mappedStatus.value === 'inProgress'
        ? t('annotationCopying.banner.status.inProgress')
        : mappedStatus.value === 'complete'
          ? t('annotationCopying.banner.status.complete')
          : t('annotationCopying.banner.status.error');
    });

    return {
      mappedStatus,
      statusLabel,
      releaseLabel
    };
  }
});
