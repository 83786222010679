
import { C } from 'app/base/common';
import { Breakpoint, useWindowSize } from 'app/functions/use-window-size';
import { Breadcrumb } from 'app/router/breadcrumb';
import { computed, defineComponent } from 'vue';
import { useRoute, useRouter } from 'vue-router';

export default defineComponent({
  name: 'BreadcrumbNavigation',
  props: {
    title: {
      type: String,
      required: false,
      default: undefined
    },
    breadcrumbList: {
      type: Array as () => Breadcrumb[],
      required: false,
      default: undefined
    }
  },
  setup: (props, ctx) => {
    const { windowWidth } = useWindowSize();
    const mobile = computed(() => windowWidth.value <= Breakpoint.Narrow);

    const pageTitle = computed(() => {
      if (props.title) {
        return C.encode(props.title);
      }

      return undefined;
    });

    const singleDisplay = computed(() => {
      const length = props.breadcrumbList?.length || 0;

      if (mobile.value || props.breadcrumbList?.[length - 1]?.singleDisplay) {
        return true;
      }

      return false;
    });

    const breadcrumbs = computed(() => {
      if (props.breadcrumbList) {
        if (singleDisplay.value) {
          const length = props.breadcrumbList.length;

          return [props.breadcrumbList[length - 1]] as Breadcrumb[];
        }

        return props.breadcrumbList as Breadcrumb[];
      }

      return undefined;
    });

    const router = useRouter();
    const reactiveRoute = useRoute();
    const routeMeta = computed(() => reactiveRoute.meta);
    const goBack = (index: number) => {
      if (breadcrumbs.value) {
        const fullpath = breadcrumbs.value[index].fullpath;
        const name = breadcrumbs.value[index].name;

        if (singleDisplay.value) {
          //a way to let the router know we went back
          routeMeta.value.goneBack = true;
        }

        fullpath ? router.push(fullpath) : router.push({ name: name });
      }
    };

    return {
      singleDisplay,
      pageTitle,
      breadcrumbs,
      goBack
    };
  }
});
