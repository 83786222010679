
import FormInput from 'app/components/FormInput.vue';
import Prompt from 'app/components/Prompt.vue';
import { computed, defineComponent, ref, watch } from 'vue';

export default defineComponent({
  name: 'HoldPrompt',
  components: {
    FormInput,
    Prompt
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    titleId: {
      type: String,
      required: true
    },
    emailAddress: {
      type: String,
      default: undefined
    }
  },
  emits: [
    'close',
    'delete',
    'edit'
  ],
  setup: (props, ctx) => {
    const submit = (data: unknown) => {
      ctx.emit('edit', data);
    };

    const email = ref(props.emailAddress);

    watch(() => props.show, (to) => {
      if (to) {
        email.value = props.emailAddress;
      }
    });

    const changed = computed(() => email.value !== props.emailAddress);

    return {
      changed,
      email,
      submit
    };
  }
});
