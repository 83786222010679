
import TitleCard from 'app/components/cards/TitleCard.vue';
import SeriesCard from 'app/components/cards/SeriesCard.vue';
import Pagination from 'app/components/Pagination.vue';
import { SearchState } from 'app/functions/use-search';
import { SearchType } from 'app/base/search';


export default {
  name: 'PaginatedCardList',
  components : {
    Pagination,
    TitleCard,
    SeriesCard
  },
  props: {
    state: {
      type: Object as () => SearchState<SearchType>,
      required: true
    }
  },
  emits: [
    'update:currentPage'
  ]
};
