
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TitleDetailsAnnotationsLink',
  props: {
    titleSlug: {
      type: String,
      required: true
    },
    annotationCount: {
      type: Number,
      required: true
    }
  }
});
