import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["aria-label"]
const _hoisted_2 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_TagWidget = _resolveComponent("TagWidget")!
  const _component_Popout = _resolveComponent("Popout")!

  return (_openBlock(), _createElementBlock("section", {
    class: _normalizeClass(_ctx.$style.container)
  }, [
    _createElementVNode("button", {
      ref: "tagIcon",
      "aria-label": _ctx.$t('tags.header'),
      "aria-haspopup": "dialog",
      class: _normalizeClass(_ctx.$style.indicatorButton),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showTagWidget = true))
    }, [
      _createVNode(_component_Icon, {
        name: "nav-tags",
        class: _normalizeClass(_ctx.$style.cardIcon)
      }, null, 8, ["class"]),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.badge)
      }, _toDisplayString(_ctx.tagCount), 3)
    ], 10, _hoisted_1),
    (_ctx.showTagWidget && _ctx.tagIcon)
      ? (_openBlock(), _createBlock(_component_Popout, {
          key: 0,
          labelId: "TagWidget",
          reference: _ctx.tagIcon,
          onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showTagWidget = false))
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.$style.popout)
            }, [
              _createVNode(_component_Icon, {
                name: "nav-tags",
                class: _normalizeClass(_ctx.$style.headerIcon)
              }, null, 8, ["class"]),
              _createElementVNode("h3", {
                class: _normalizeClass(_ctx.$style.headerLabel)
              }, _toDisplayString(_ctx.$t('tags.header')), 3),
              _createElementVNode("button", {
                class: _normalizeClass(_ctx.$style.dismissIcon),
                "aria-label": _ctx.$t('general.close'),
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showTagWidget = false))
              }, [
                _createVNode(_component_Icon, { name: "dismiss" })
              ], 10, _hoisted_2),
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.$style.widget)
              }, [
                _createVNode(_component_TagWidget, {
                  title: _ctx.title,
                  restrictHeight: true
                }, null, 8, ["title"])
              ], 2)
            ], 2)
          ], undefined, true),
          _: 1
        }, 8, ["reference"]))
      : _createCommentVNode("", true)
  ], 2))
}