
import { useWindowSize } from 'app/functions/use-window-size';
import { defineComponent, onBeforeUnmount, onMounted, ref, watch } from 'vue';

export default defineComponent({
    name: 'DynamicExpander',
    model: {
      prop: 'expanded',
      event: 'update:expanded'
    },
    props: {
      expanded: {
        type: Boolean,
        default: true
      },
      numElements: {
        type: Number,
        required: true
      },
      duration: {
        type: Number,
        default: 200
      },
      panelId: {
        type: String,
        required: true
      },
      headerId: {
        type: String,
        default: undefined
      }
    },
    setup: (props, { emit }) => {
      const panel = ref<HTMLElement | null>(null);
      const oldHeight = ref<Number>(0);

      const setHeight = () => {
        if (!panel.value) { return; }

        oldHeight.value =  panel.value.clientHeight; //reinitialize the height to a number other than 0
      };

      onMounted(() => {
        if (props.numElements > 0) {
          changeHeight();
        }
      });

      const clearHeight = () => {
        if (!panel.value) { return; }

        panel.value.style.height = panel.value.clientHeight + 'px';

        requestAnimationFrame(() => panel.value!.style.height = '0');
      };

      const changeHeight = () => {
        requestAnimationFrame(() => {
          if (!panel.value) { return; }

          const element = panel.value.children[0] as HTMLElement;

          panel.value.style.height = oldHeight.value + 'px';  //if height is 'auto' or not set, change it to the size it currently is
          panel.value.style.height = element.offsetHeight + 'px'; //tranition to new height

          oldHeight.value = element.offsetHeight;
        });
      };

      watch(() => props.numElements, () => {
        changeHeight();
      });

      watch(() => props.panelId, () => {
        changeHeight();
      });

      const { windowWidth } = useWindowSize();
      //if the window is resized, set panel height to auto to allow it to resize accordingly
      watch(() => windowWidth.value, () => {
        if (!panel.value) { return; }
        panel.value.style.height = 'auto';
        setHeight();
      });

      onBeforeUnmount(() => {
        if (!panel.value) { return; }

        panel.value.style.height = '0';
      });

      const update = async () => {
        changeHeight();
      };

      return {
        panel,
        changeHeight,
        clearHeight,
        update
      };
    }
});
