
import { APP } from 'app/base/app';
import { SearchDefinition, SearchRequest, SearchType } from 'app/base/search';
import BrowseGroup from 'app/components/BrowseGroup.vue';
import SeriesCard from 'app/components/cards/SeriesCard.vue';
import TitleCard from 'app/components/cards/TitleCard.vue';
import { useI18n } from 'app/functions/use-i18n';
import { useSearch } from 'app/functions/use-search';
import { Breakpoint, useWindowSize } from 'app/functions/use-window-size';
import { RouteName } from 'app/router/constants';
import { computed, defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'BrowseGroupCollection',
  components: {
    BrowseGroup,
    SeriesCard,
    TitleCard
  },
  props: {
    collectionId: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    description: {
      type: String,
      default: undefined
    },
    searchType: {
      type: String as PropType<SearchType.Title | SearchType.Series>,
      required: true
    },
    definition: {
      type: Object as PropType<SearchDefinition>,
      required: true
    }
  },
  setup: (props, ctx) => {
    const { windowWidth } = useWindowSize();

    const request = computed<SearchRequest<typeof props.searchType>>(() => {
      return {
        libraryKey: APP.library.key(),
        searchType: props.searchType,
        definition: props.definition,
        modifications: {
          page: 1,
          perPage: 6
        }
      };
    });

    const { state: searchState } = useSearch(request);

    const items = computed(() => {
      if (searchState.value?.state === 'success') {
        return windowWidth.value > Breakpoint.Tablet
          ? searchState.value.response.list.items
          : searchState.value.response.list.items.slice(0, 3);
      }

      return [];
    });

    const { t } = useI18n();

    const buttonLabel = computed(() => {
      return searchState.value?.state === 'success'
        ? t(`browse.seeAll.${props.searchType}`, { N: searchState.value.response.list.totalItems })
        : t('browse.seeAll.generic');
    });

    const navigateLink = computed(() => ({
      name: RouteName.Collection,
      params: {
        collectionId: props.collectionId
      }
    }));

    return {
      buttonLabel,
      navigateLink,
      searchState,
      items
    };
  }
});
