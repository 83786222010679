import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createVNode as _createVNode, TransitionGroup as _TransitionGroup, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = ["aria-label"]
const _hoisted_2 = ["aria-label"]
const _hoisted_3 = {
  class: "badge",
  "aria-hidden": true
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_FilterAppliedButton = _resolveComponent("FilterAppliedButton")!
  const _component_DynamicExpander = _resolveComponent("DynamicExpander")!
  const _component_FilterList = _resolveComponent("FilterList")!
  const _component_FormSubmitButton = _resolveComponent("FormSubmitButton")!

  return (_openBlock(), _createElementBlock("form", {
    class: _normalizeClass(_ctx.$style.container),
    onSubmit: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.apply && _ctx.apply(...args)), ["prevent"]))
  }, [
    _createElementVNode("section", {
      class: _normalizeClass(_ctx.$style.contentContainer)
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (category) => {
        return (_openBlock(), _createElementBlock("fieldset", {
          key: category.category,
          "aria-label": category.label,
          class: _normalizeClass(_ctx.$style.division)
        }, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.categories.length > 1 ? 'button' : 'header'), {
            id: category.category,
            type: _ctx.categories.length > 1 ? 'button' : undefined,
            class: _normalizeClass(_ctx.$style.header),
            "aria-expanded": _ctx.categories.length > 1 ? category.active : undefined,
            onClick: ($event: any) => (_ctx.toggleHeader(category))
          }, {
            default: _withCtx(() => [
              _createElementVNode("h2", {
                "aria-label": _ctx.$t('filters.ariaLabels.header', { CATEGORY: category.label, NUMBER: category.length })
              }, _toDisplayString(category.label), 9, _hoisted_2),
              _createElementVNode("span", _hoisted_3, _toDisplayString(category.length), 1),
              (_ctx.categories.length > 1)
                ? (_openBlock(), _createBlock(_component_Icon, {
                    key: 0,
                    name: "chevron-down",
                    role: "presentation",
                    class: _normalizeClass([_ctx.$style.arrow, category.active ? _ctx.$style.expanded : ''])
                  }, null, 8, ["class"]))
                : _createCommentVNode("", true)
            ], undefined, true),
            _: 2
          }, 1032, ["id", "type", "class", "aria-expanded", "onClick"])),
          _createVNode(_component_DynamicExpander, {
            numElements: _ctx.appliedByCategory[category.category].length,
            panelId: `filter-selected-panel-${category.category}`,
            headerId: category.label
          }, {
            default: _withCtx(() => [
              (_ctx.appliedByCategory[category.category].length)
                ? (_openBlock(), _createBlock(_TransitionGroup, {
                    key: 0,
                    tag: "ol",
                    role: "list",
                    "aria-label": _ctx.$t('filters.ariaLabels.appliedFilters'),
                    name: "list",
                    enterFromClass: _ctx.$style.listEnter,
                    enterActiveClass: _ctx.$style.listEnterActive,
                    leaveActiveClass: _ctx.$style.listLeaveActive,
                    leaveToClass: _ctx.$style.listLeaveTo
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.appliedByCategory[category.category], (option) => {
                        return (_openBlock(), _createElementBlock("li", {
                          key: option.id,
                          class: _normalizeClass(_ctx.$style.listItem)
                        }, [
                          _createVNode(_component_FilterAppliedButton, {
                            id: `applied-filter-${option.id}`,
                            appliedOption: option,
                            optionType: category.optionType,
                            onClicked: _ctx.updateSelected
                          }, null, 8, ["id", "appliedOption", "optionType", "onClicked"])
                        ], 2))
                      }), 128))
                    ], undefined, true),
                    _: 2
                  }, 1032, ["aria-label", "enterFromClass", "enterActiveClass", "leaveActiveClass", "leaveToClass"]))
                : _createCommentVNode("", true)
            ], undefined, true),
            _: 2
          }, 1032, ["numElements", "panelId", "headerId"]),
          (_ctx.categories.length > 1)
            ? (_openBlock(), _createBlock(_component_DynamicExpander, {
                key: 0,
                expanded: category.active,
                numElements: category.active ? category.length : 0,
                panelId: `filter-subset-panel-${category.category}`,
                headerId: category.label
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_FilterList, {
                    category: category.category,
                    optionType: category.optionType,
                    optionSort: category.sort,
                    options: _ctx.optionsByCategory[category.category],
                    applied: _ctx.appliedByCategory[category.category].map((option) => option.id),
                    onChecked: _ctx.updateSelected,
                    onLength: (val) => category.length = val
                  }, null, 8, ["category", "optionType", "optionSort", "options", "applied", "onChecked", "onLength"])
                ], undefined, true),
                _: 2
              }, 1032, ["expanded", "numElements", "panelId", "headerId"]))
            : (_openBlock(), _createBlock(_component_FilterList, {
                key: 1,
                category: category.category,
                optionType: category.optionType,
                options: _ctx.optionsByCategory[category.category],
                applied: _ctx.appliedByCategory[category.category].map((option) => option.id),
                onChecked: _ctx.updateSelected,
                onLength: (val) => category.length = val
              }, null, 8, ["category", "optionType", "options", "applied", "onChecked", "onLength"]))
        ], 10, _hoisted_1))
      }), 128))
    ], 2),
    _createElementVNode("footer", {
      class: _normalizeClass(_ctx.$style.applyContainer)
    }, [
      _createVNode(_component_FormSubmitButton, {
        label: _ctx.$t('filters.apply'),
        disabled: !_ctx.changesMade,
        class: _normalizeClass(!_ctx.changesMade ? _ctx.$style.applyInactive : ''),
        "aria-label": !_ctx.changesMade ? _ctx.$t('filters.ariaLabels.applyInactive') : '',
        onClick: _ctx.apply
      }, null, 8, ["label", "disabled", "class", "aria-label", "onClick"])
    ], 2)
  ], 34))
}