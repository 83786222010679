
import { computed, defineComponent, ref } from 'vue';
import { APP } from 'app/base/app';
import { Library } from 'app/models/library';
import { Constants } from 'app/base/constants';

export default defineComponent({
  name: 'LibraryLogo',
  props: {
    library: {
      type: Library,
      default: undefined
    }
  },
  setup: (props) => {
    const appLibrary = ref(APP.library);
    const library = computed(() => props.library || appLibrary.value);
    const logoUrl = computed(() => {
      return library.value
        ? library.value.logo.url
        : Constants.assetPath('images/app-logo-ln.svg');
    });

    const logoAlt = computed(() => {
      return library.value
        ? library.value.name
        : Constants.APP_NAME;
    });

    const logoError = ref(false);

    return {
      logoAlt,
      logoError,
      logoUrl
    };
  }
});
