
import { computed, defineComponent, onMounted, PropType, ref } from 'vue';
import { APP } from 'app/base/app';
import { HudsonSearchResponse, SearchParameters, SearchThisTitleQuery } from 'app/base/hudson';
import { Series } from 'app/models/series';
import { TitleMapper, TitleRecord } from 'app/models/title';
import { Dictionary } from 'lib/common/dictionary';
import SearchThisTitleResults from 'app/components/SearchThisTitleResults.vue';
import SharePassage, { MatchInfo } from 'app/components/SharePassage.vue';
import { mapSearchThisTitleQueryParams } from 'app/router/query-mapper';
import { useRoute, useRouter } from 'vue-router';

type SearchThisTitleSearch =
  { params: SearchParameters }
  & (
    {
      state: 'loading';
    }
    | {
      state: 'error';
    }
    | {
      state: 'loaded';
      results: HudsonSearchResponse | null;
      titles: Dictionary<TitleRecord>;
    }
  );

export default defineComponent({
  name: 'SearchThisTitleContent',
  components: {
    SearchThisTitleResults,
    SharePassage
  },
  props: {
    item: {
      type: Object as PropType<TitleRecord | Series>,
      required: true
    },
    params: {
      type: Object as PropType<SearchThisTitleQuery>,
      required: true
    },
    skipRouting: {
      type: Boolean,
      required: true
    }
  },
  emits: [
    'close'
  ],
  setup: (props, ctx) => {
    const seriesId = computed(() => {
      return props.item instanceof Series
        ? props.item.id
        : props.item.seriesId;
    });

    const titleId = computed(() => {
      return props.item instanceof Series
        ? undefined
        : props.item.slug;
    });

    const results = ref<SearchThisTitleSearch>({ state: 'loading', params: props.params as SearchParameters });

    const onSearch = async (params: SearchThisTitleQuery, skipRouting = props.skipRouting) => {
      const allParams = {
        query: params.query,
        type: params.type,
        page: params.page || 1,
        scope: params.scope,
        sort: params.sort || 'relevance',
        edition: props.item.edition,
        id: (params.scope === 'series' && seriesId.value)
          ? seriesId.value.toString()
          : titleId.value!
      };
      if (!skipRouting) {
        updateRoute(params);
      }
      results.value = {
        state: 'loading',
        params: allParams
      };
      try {
        const response = await APP.services.hudson.search(allParams);
        const titleDictionary: Dictionary<TitleRecord> = {};
        if (props.item && !(props.item instanceof Series)) {
          titleDictionary[props.item.slug] = props.item;
        }

        if (response) {
          const uniqueTitleIds = new Set(response.hits.map((hit) => hit.titleId.toString()));

          const titleRecords = await APP.services.thunder.getTitles(APP.library.key(), [...uniqueTitleIds]);
          titleRecords?.items.forEach((title) => {
            const mappedTitle = TitleMapper.mapFromThunder(title);
            if (mappedTitle) {
              titleDictionary[title.id] = mappedTitle;
            }
          });
        }
        results.value = {
          state: 'loaded',
          params: allParams,
          results: response,
          titles: titleDictionary
        };
      } catch (e) {
        console.error('[SEARCHTHISTITLE] Error fetching search results', e);
        results.value = {
          state: 'error',
          params: allParams
        };
        APP.sage.submit('error', {
          errorMessage: 'Error loading search this title results',
          errorSource: 'SearchThisTitle#onSearch',
          errorData: {
            message: e.name + ': ' + e.message
          },
          submissionContext: {
            library: APP.library
          }
        });
      }
    };

    const onPage = (page: number) => {
      if (results.value.state !== 'loaded') { return; }

      onSearch({
        ...results.value.params,
        page
      });
    };

    const route = useRoute();
    const router = useRouter();
    const updateRoute = (params: SearchThisTitleQuery) => {
      const query = mapSearchThisTitleQueryParams(params);
      router.replace({
        name: route.name || undefined,
        params: route.params,
        query
      });
    };

    const clear = () => {
      ctx.emit('close');
    };

    onMounted(() => {
      if (props.params) {
        onSearch(props.params);
      }
    });

    const shareMatch = ref<(MatchInfo & { ref: HTMLElement }) | null>(null);
    const onShare = (match: MatchInfo & { ref: HTMLElement }) => {
      shareMatch.value = match;
    };

    return {
      clear,
      onPage,
      onSearch,
      onShare,
      results,
      shareMatch
    };
  }
});
