
import FocusTrap from 'app/components/FocusTrap.vue';
import { generateUUID } from 'lib/common';
import { PropType, computed, defineComponent, onMounted, ref } from 'vue';

export default defineComponent({
  name: 'ActionToast',
  components: {
    FocusTrap
  },
  props: {
    message: {
      type: String,
      required: true
    },
    type: {
      type: String as PropType<'success' | 'error' | 'info'>,
      default: 'success'
    }
  },
  emits: [
    'close'
  ],

  setup: (props) => {
    const messageId = generateUUID();
    const animation = ref<'fadeIn' | null>(null);

    const iconName = computed(() => {
      return props.type === 'success' ? 'check'
        : props.type === 'error' ? 'dismiss'
        : 'info';
    });

    /**
     * Want to only mount the component when needed (v-if instead of v-show),
     * but can't use <Transition> with the <Teleport> and get it to work right,
     * so we need to force an animation once the component is mounted
     * Eventually this might help us: https://github.com/vuejs/core/pull/6548
     * And more info about it: https://github.com/vuejs/core/issues/5836
     */
    onMounted(() => {
      animation.value = 'fadeIn';
    });

    return {
      animation,
      iconName,
      messageId
    };
  }
});
