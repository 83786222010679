
import Cover from 'app/components/Cover.vue';
import Card, { CardMetadata } from 'app/components/cards/Card.vue';
import TitleCardOptions from 'app/components/cards/TitleCardOptions.vue';
import { useI18n } from 'app/functions/use-i18n';
import { listFormatter } from 'app/i18n/list-formatter';
import { TitleRecord } from 'app/models/title';
import { RouteName } from 'app/router/constants';
import { computed, defineComponent } from 'vue';
import { RouteLocation } from 'vue-router';

/**
 * @desc - The title-based card component
 */
export default defineComponent({
  name: 'TitleCard',
  components: {
    Card,
    Cover,
    TitleCardOptions
  },
  props: {
    /**
     * @param title - The title to display in card form
     */
    title: {
      type: Object as () => TitleRecord,
      required: true
    },
    /**
     * @param link - The location this card should direct to
     * @exmaple { name: "TitleDetails", params: { titleSlug: "1234" } }
     */
    link: {
      type: Object as () => RouteLocation | undefined,
      default: undefined
    },
    /**
     * @param actionsIconsOnly - Determines whether to only show icons for the title actions (ie/ Expiration and Download indicators)
     * @default false
     */
    actionsIconsOnly: {
      type: Boolean,
      default: false
    },
    /**
     * @param borderless - Display without a border and shadow
     * @default false
     */
    borderless: {
      type: Boolean,
      default: false
    }
  },
  setup: (props, ctx) => {
    const defaultLink = { name: RouteName.TitleDetails, params: { titleSlug: props.title.slug } };
    const computedLink = computed(() => (props.link || defaultLink));
    const { locale } = useI18n();

    const cardItem = computed(() => {
      const metadata: CardMetadata[] = [];

      const meta = props.title.lexisMetadata;
      if (meta) {
        if (meta.edition) {
          metadata.push({ label: 'title.edition', value: meta.edition });
        }
        if (meta.releaseDate) {
          metadata.push({ label: 'title.releaseDate', value: meta.releaseDate });
        }
      }

      const authors = props.title.creators?.Author || [];
      const authorNames = authors.map((author) => author.name);
      const authorsString = listFormatter(authorNames, locale);

      return {
        id: props.title.slug,
        title: props.title.title,
        subtitle: props.title.subtitle,
        authors: authorsString,
        metadata: metadata
      };
    });


    return {
      cardItem,
      computedLink
    };
  }
});
