
import Card, { CardableItem } from 'app/components/cards/Card.vue';
import { useI18n } from 'app/functions/use-i18n';
import { listFormatter } from 'app/i18n/list-formatter';
import { Creator } from 'app/models/title';
import { PropType, computed, defineComponent } from 'vue';


export default defineComponent({
  name: 'AlertTitleNotAvailable',
  components: {
    Card
},
  props: {
    id: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      default: ''
    },
    authors: {
      type: Object as PropType<Creator[]>,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  setup: (props) => {
    const { locale } = useI18n();
    const authors = props.authors || [];
    const authorNames = authors.map((author) => author.name);
    const authorsString = listFormatter(authorNames, locale);

    const cardableItem = {
      id: props.id,
      title: props.title,
      subtitle: props.subtitle,
      authors: authorsString
    } as CardableItem;

    const typeName = computed(() => 'alert.type.'+props.type);

    return {
      cardableItem,
      typeName
    };
  }
});
