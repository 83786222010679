import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }
const _hoisted_4 = {
  key: 0,
  id: "progressBar",
  ref: "progressBar",
  value: "0.01"
}
const _hoisted_5 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_ctx.state !== 'updated')
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(_ctx.$style.updateStrip)
      }, [
        _createElementVNode("label", {
          for: "progressBar",
          class: _normalizeClass(_ctx.state === 'error' && _ctx.$style.error)
        }, [
          (_ctx.state === 'error')
            ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.$t('update.error')), 1))
            : (_ctx.state === 'reloading')
              ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$t('update.reloading')), 1))
              : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t('update.updating')), 1))
        ], 2),
        (_ctx.state !== 'error')
          ? (_openBlock(), _createElementBlock("progress", _hoisted_4, null, 512))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style.actions)
        }, [
          _createElementVNode("button", {
            type: "button",
            "aria-label": "Stop Update",
            disabled: _ctx.state === 'reloading',
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.cancelRelaunch()))
          }, [
            _createVNode(_component_Icon, {
              name: "dismiss",
              class: _normalizeClass(_ctx.$style.dismissButton)
            }, null, 8, ["class"])
          ], 8, _hoisted_5)
        ], 2)
      ], 2))
    : _createCommentVNode("", true)
}