
import Cover from 'app/components/Cover.vue';
import ModalDialog from 'app/components/dialogs/ModalDialog.vue';
import { useI18n } from 'app/functions/use-i18n';
import { TitleRecord } from 'app/models/title';
import { PropType, defineComponent } from 'vue';

export default defineComponent({
  components: {
    Cover,
    ModalDialog
  },
  props: {
    title: {
      type: Object as PropType<TitleRecord>,
      required: true
    }
  },
  emits: [
    'close'
  ],
  setup: (props) => {
    const { t } = useI18n();

    const coverTitle = (`${props.title.title} ${props.title.subtitle || ''}`).trim();
    const coverLabel = t('title.cover.alt', { title: coverTitle, mediaType: props.title.mediaType });

    return {
      coverLabel
    };
  }
});
