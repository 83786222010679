import Events from 'app/events/events';
import { ComputedRef, watch, watchEffect } from 'vue';


export type ChatterboxMessage = {
  message: string;
  politeness?: 'polite' | 'assertive' | undefined;
};

export type ChatterboxIdMessage = { id: string } & ChatterboxMessage;


/**
 * Announce to the screen reader when a page is in a loading state.
 *
 * @param loading computed function of whether the page is loading or not
 * @param announceLoading true to announce 'Loading', false to announce nothing, or pass in a different string to announce
 */
export function watchLoadingState(loading: ComputedRef<boolean>, announceLoading: boolean | string) {
  watchEffect(() => {
    if (loading.value) {
      Events.dispatch('chatter:loading', { message: announceLoading});
    } else {
      Events.dispatch('chatter:loaded');
    }
  });
}


/**
 * Announce a single message to the screen reader.
 * Will allow you to announce the same message twice.
 * If polite, has a small delay before announcing,
 * to account for cases where keyboard focus is assigned.
 *
 * @param message string to be announce to the screen reader
 * @param politeness 'polite' or 'assertive' depending on how quickly the message needs announced
 */
export function announceMessage(message: string, politeness?: 'polite' | 'assertive') {
  window.setTimeout(
    () => Events.dispatch('chatter:message', { message: message, politeness: politeness }),
    politeness === 'assertive' ? 0 : 250
  );
}


/**
 * Keep track of a message that may change and need to be reannounced.
 * If a message is updated to the same message as it was before, it will
 * not be announced again.
 * Can be used again for additional messages, and each will have their own live
 * region that can be individually managed.
 *
 * @param id a string to identify each message
 * @param message ComputedRef<string> of which the value will be announced to the screen reader when it changes
 * @param immediate true to announce right away or false to start announcing after the first change. Defaults to true.
 * @param politeness 'polite' or 'assertive' depending on how quickly the message needs announced
 */
export function watchMessage(id: string, message: ComputedRef<string>, immediate = true, politeness?: 'polite' | 'assertive') {
  watch(() => message.value, () => {
    Events.dispatch('chatter:update', { id: id, message: message.value, politeness: politeness });
  }, { immediate: immediate });
}
