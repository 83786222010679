import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InfoPanel = _resolveComponent("InfoPanel")!
  const _component_Overlay = _resolveComponent("Overlay")!

  return (_openBlock(), _createBlock(_component_Overlay, {
    show: _ctx.show,
    transition: _ctx.side,
    header: _ctx.header,
    initialFocus: "[data-close_focus]",
    teleportTo: "#sidebars",
    onClose: _ctx.close
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        ref: "sidebar",
        class: _normalizeClass([_ctx.$style.sidebar, _ctx.$style[_ctx.side]])
      }, [
        _createVNode(_component_InfoPanel, {
          class: _normalizeClass(_ctx.$style.panel),
          header: _ctx.header,
          icon: _ctx.icon,
          onClose: _ctx.close
        }, {
          extra: _withCtx(() => [
            _renderSlot(_ctx.$slots, "extra")
          ]),
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "default")
          ], undefined, true),
          _: 3
        }, 8, ["class", "header", "icon", "onClose"]),
        (_ctx.resizable)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              ref: "resizer",
              class: _normalizeClass(_ctx.$style.resizer),
              onMousedown: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.resize && _ctx.resize(...args)))
            }, null, 34))
          : _createCommentVNode("", true)
      ], 2)
    ], undefined, true),
    _: 3
  }, 8, ["show", "transition", "header", "onClose"]))
}