import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TitlePriorReleaseList = _resolveComponent("TitlePriorReleaseList")!
  const _component_Sidebar = _resolveComponent("Sidebar")!

  return (_openBlock(), _createBlock(_component_Sidebar, {
    show: _ctx.show,
    header: _ctx.$t('title.priorReleases'),
    onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createElementVNode("header", {
          class: _normalizeClass(_ctx.$style.header)
        }, [
          _createElementVNode("h2", {
            class: _normalizeClass(_ctx.$style.title),
            innerHTML: _ctx.title.title
          }, null, 10, _hoisted_1),
          (_ctx.title.subtitle)
            ? (_openBlock(), _createElementBlock("p", {
                key: 0,
                class: _normalizeClass(_ctx.$style.subtitle),
                innerHTML: _ctx.title.subtitle
              }, null, 10, _hoisted_2))
            : _createCommentVNode("", true)
        ], 2),
        _createVNode(_component_TitlePriorReleaseList, { title: _ctx.title }, null, 8, ["title"])
      ])
    ], undefined, true),
    _: 1
  }, 8, ["show", "header"]))
}