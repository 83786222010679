
import { computed, defineComponent } from 'vue';
import LabeledDropdown from 'app/components/LabeledDropdown.vue';


export default defineComponent({
  components: {
    LabeledDropdown
  },
  props: {
    modelValue: {
      type: Object,
      required: true
    }
  },
  emits: [
    'update:modelValue'
  ],
  setup: (props, ctx) => {
    const selected = computed({
      get: () => props.modelValue,
      set: (val) => ctx.emit('update:modelValue', val)
    });

    return {
      selected
    };
  }
});
