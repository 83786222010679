
import { APP } from 'app/base/app';
import { Constants } from 'app/base/constants';
import EmptyState from 'app/components/EmptyState.vue';
import FilterButton from 'app/components/FilterButton.vue';
import MyBooksTabPanel from 'app/components/MyBooksTabPanel.vue';
import Page from 'app/components/Page.vue';
import Surface from 'app/components/Surface.vue';
import TabView from 'app/components/TabView.vue';
import TextFilter from 'app/components/TextFilter.vue';
import { useAppEvents } from 'app/functions/use-app-events';
import { useI18n } from 'app/functions/use-i18n';
import { usePatron } from 'app/functions/use-patron';
import { getQueryParametersFromSubjectFilters, SubjectFilters, useSubjectFilters } from 'app/functions/use-subject-filters';
import { Breakpoint, useWindowSize } from 'app/functions/use-window-size';
import { Hold } from 'app/models/hold';
import { ItemWithTitleCache } from 'app/models/item-with-title';
import { Loan } from 'app/models/loan';
import { RecentlyReadTitle } from 'app/models/recently-read-title';
import { SharedTitle } from 'app/models/shared-title';
import { Title, TitleRecord } from 'app/models/title';
import { RouteName } from 'app/router/constants';
import { computed, defineComponent, onMounted, Ref, ref, watch } from 'vue';
import { useRouter } from 'vue-router';


export default defineComponent({
  name: 'MyBooks',
  components: {
    Surface,
    TabView,
    TextFilter,
    MyBooksTabPanel,
    EmptyState,
    FilterButton,
    Page
},
  props: {
    currentTab: {
      type: String,
      default: 'history'
    },
    subjectFilters: {
      type: Object as () => SubjectFilters,
      required: true
    }
  },
  setup: (props, ctx) => {
    const { t } = useI18n();

    const { windowWidth } = useWindowSize();
    const mobile = computed(() => windowWidth.value <= Breakpoint.Narrow);

    const { loans, holds, recentlyRead, shared } = usePatron();
    const recentlyReadTitles = usePatronTitles(recentlyRead, RecentlyReadTitle.SORT_FUNCTIONS.accessed);
    const sharedTitles = usePatronTitles(shared, SharedTitle.SORT_FUNCTIONS.accessed);
    const loanTitles = usePatronTitles(loans, Loan.SORT_FUNCTIONS.expires);
    const holdTitles = usePatronTitles(holds, Hold.SORT_FUNCTIONS.created);

    const borrowed = computed(() => {
      return loanTitles.value
        ? loanTitles.value.filter((title) => !title.isSimultaneousUse && !title.isBundledChild)
        : null;
    });

    const downloaded = ref<TitleRecord[] | null>(null);
    const updateDownloaded = () => {
      downloaded.value = APP.patron.loans.all
        .filter((l) => l.isDownloaded())
        .map((l) => l.titleRecord);
    };
    onMounted(async () => {
      if (APP.shell.has('rosters')) {
        updateDownloaded();
      }
    });
    useAppEvents({
      'loan:download:complete': updateDownloaded,
      'loan:download:wipe': () => setTimeout(updateDownloaded)
    });

    const filterableTitles = computed(() => {
      return [
        ...(recentlyReadTitles.value || []),
        ...(sharedTitles.value || []),
        ...(borrowed.value || []),
        ...(holdTitles.value || []),
        ...(downloaded.value || [])
      ] as TitleRecord[];
    });

    const { availableSubjects, filteredTitleIds } =
      useSubjectFilters(filterableTitles, computed(() => props.subjectFilters));

    const subjectsSelected = (subjects: SubjectFilters) => {
      updatePath(subjects, props.currentTab);
    };

    const textFilter = ref('');
    const titlesMatchingFilter = (title: TitleRecord) => {
      return filteredTitleIds.value.has(title.slug)
        && titlesMatchingTextFilter(title);
    };
    const titlesMatchingTextFilter = (title: TitleRecord) => {
      return Title.FILTER_FUNCTIONS.filterByText(title, textFilter.value);
    };

    const tabs = computed(() => {
      const tabList = [
        {
          id: 'history',
          label: t('mybooks.tabs.history'),
          count: recentlyReadTitles.value?.filter(titlesMatchingFilter).length || 0,
          items: recentlyReadTitles.value
        },
        {
          id: 'shared',
          label: t('mybooks.tabs.shared'),
          count: sharedTitles.value?.filter(titlesMatchingFilter).length || 0,
          items: sharedTitles.value
        },
        {
          id: 'borrowed',
          label: t('mybooks.tabs.borrowed'),
          count: borrowed.value?.filter(titlesMatchingFilter).length || 0,
          items: borrowed.value
        }
      ];

      if (APP.shell.has('rosters')) {
        tabList.push({
          id: 'downloaded',
          label: t('mybooks.tabs.downloaded'),
          count: downloaded.value?.filter(titlesMatchingFilter).length || 0,
          items: downloaded.value as TitleRecord[]
        });
      }

      tabList.push({
        id: 'holds',
        label: t('mybooks.tabs.holds'),
        count: holdTitles.value?.filter(titlesMatchingFilter).length || 0,
        items: holdTitles.value
      });

      return tabList;
    });

    const tabView = ref<InstanceType<typeof TabView> | null>(null);
    const onTabChange = (tab: string) => {
      updatePath(props.subjectFilters, tab);
    };

    const router = useRouter();
    const updatePath = (filters: SubjectFilters, tab: string) => {
      const newRoute = {
        name: RouteName.MyBooks,
        query: {
          ...getQueryParametersFromSubjectFilters(filters)
        },
        hash: '#' + tab
      };

      router.replace(newRoute);
    };

    const noTitles = computed(() => {
      return (
        (recentlyRead.value && !recentlyRead.value.length)
        && (borrowed.value && !borrowed.value.length)
        && (shared.value && !shared.value.length)
        && (holds.value && !holds.value.length)
        && (downloaded.value && !downloaded.value.length)
      );
    });
    const helpLink = Constants.HELP_PATHS.GET_STARTED;

    return {
      availableSubjects,
      helpLink,
      mobile,
      noTitles,
      onTabChange,
      subjectsSelected,
      tabs,
      tabView,
      textFilter,
      titlesMatchingFilter
    };
  }
});

function usePatronTitles<T extends ItemWithTitleCache<any, any>>(
  source: Readonly<Ref<readonly T[]>>,
  sort?: (a: T, b: T) => number
): Readonly<Ref<readonly TitleRecord[]>> {
  const titles = ref<TitleRecord[]>([]);
  watch(source, async () => {
    const slugs = source.value
      .slice()
      .sort((a, b) => sort ? sort(a, b) : 0)
      .map((i) => i.titleSlug);

    titles.value = await APP.titleCache.getFreshTitles(slugs);
  }, { immediate: true });

  return titles as unknown as Readonly<Ref<readonly TitleRecord[]>>;
}
