
import { defineComponent, PropType } from 'vue';

export type Status = 'inProgress' | 'complete' | 'error';

export default defineComponent({
  name: 'StatusBanner',
  props: {
    status: {
      type: String as PropType<Status>,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    sublabel: {
      type: String,
      default: undefined
    }
  }
});
