
import ManageCopyingContent from 'app/components/ManageCopyingContent.vue';
import Sidebar from 'app/components/Sidebar.vue';
import { CopyJobsState } from 'app/functions/use-copy-jobs-state';
import { TitleRecord } from 'app/models/title';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'ManageCopyingSidebar',
  components: {
    ManageCopyingContent,
    Sidebar
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
     title: {
      type: Object as PropType<TitleRecord>,
      default: undefined
    },
    copyJobsState: {
      type: Object as PropType<CopyJobsState>,
      default: undefined
    }
  },
  emits: [
    'close',
    'submit'
  ]
});
