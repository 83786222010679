
import ManageCopyingSidebar from 'app/components/ManageCopyingSidebar.vue';
import ManualCopySidebar from 'app/components/ManualCopySidebar.vue';
import SearchThisTitleSidebar from 'app/components/SearchThisTitleSidebar.vue';
import { OverlayContext } from 'app/contexts/overlay-context';
import { OverlayNames } from 'app/keys/overlay-keys';
import { Dictionary } from 'lib/common/dictionary';
import { defineComponent, PropType, watch } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'Overlays',
  components: {
    ManageCopyingSidebar,
    ManualCopySidebar,
    SearchThisTitleSidebar
  },
  props: {
    contexts: {
      type: Object as PropType<Dictionary<OverlayContext<OverlayNames>>>,
      default: null
    }
  },
  emits: [
    'close',
    'submit'
  ],
  setup: (props) => {
    const router = useRouter();

    watch(router.currentRoute, (from, to) => {
      // when you navigate to a different page, hide any open overlays
      if (from.path !== to.path) {
        Object.values(props.contexts)
          .filter((context) => context.shouldShow.value)
          .forEach((visible) => visible.hide());
      }
    });
  }
});
