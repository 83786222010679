
import SeriesCardContextMenu from 'app/components/contextMenus/SeriesCardContextMenu.vue';
import { useSeriesSubscription } from 'app/functions/use-subscription-interaction';
import { Series } from 'app/models/series';
import { defineComponent, ref } from 'vue';
import { RouteLocation } from 'vue-router';
import TableOfContentsSidebar from '../TableOfContentsSidebar.vue';

export default defineComponent({
  name: 'SeriesCardOptions',
  components: {
    SeriesCardContextMenu,
    TableOfContentsSidebar
  },
  props: {
    series: {
      type: Object as () => Series,
      required: true
    },
    link: {
      type: Object as () => RouteLocation | undefined,
      default: undefined
    }
  },
  setup: (props, ctx) => {
    const showContextMenu = ref(false);
    const overflow = ref<HTMLElement | null>(null);
    const showTableOfContents = ref(false);

    const {
      subscribeTextLong,
      toggleSubscription
    } = useSeriesSubscription(props.series, 'series card');


    return {
      overflow,
      showContextMenu,
      showTableOfContents,
      subscribeTextLong,
      toggleSubscription
    };
  }
});
