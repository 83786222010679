
import FocusTrap from 'app/components/FocusTrap.vue';
import { useModalDialog } from 'app/functions/use-modal-dialog';
import { generateUUID } from 'lib/common';
import { computed, defineComponent, onBeforeUnmount, ref } from 'vue';

export default defineComponent({
  name: 'Overlay',
  components: {
    FocusTrap
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    transition: {
      type: String,
      default: 'none'
    },
    header: {
      type: String,
      default: undefined
    },
    describedBy: {
      type: String,
      default: undefined
    },
    initialFocus: {
      type: String,
      default: undefined
    },
    type: {
      type: String as () => 'dialog' | 'alertdialog',
      default: 'dialog'
    },
    teleportTo: {
      type: String,
      default: 'body'
    },
    contentClass: {
      type: String,
      default: undefined
    },
    escapeDeactivates: {
      type: Boolean,
      default: true
    }
  },
  emits: [
    'close'
  ],
  setup: (props, ctx) => {
    const id = `overlay-${props.header?.replace(/[^a-zA-Z0-9]/g, '-')}-${generateUUID()}`;
    const parent = ref<HTMLElement | null>(null);

    const close = () => {
      ctx.emit('close');
    };

    onBeforeUnmount(() => {
      ctx.emit('close');
    });

    useModalDialog(computed(() => props.show), id);

    return {
      close,
      id,
      parent
    };
  }
});
