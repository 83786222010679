
import { ThunderFacetItem } from 'app/base/thunder';
import BrowseGroup from 'app/components/BrowseGroup.vue';
import { useI18n } from 'app/functions/use-i18n';
import { subjectRoute } from 'app/models/subject';
import { RouteName } from 'app/router/constants';
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'BrowseGroupSubjects',
  components: {
    BrowseGroup
  },
  props: {
    subjects: {
      type: Array as () => ThunderFacetItem[],
      required: true
    }
  },
  setup: (props, ctx) => {
    const { t } = useI18n();

    const buttonLabel = computed(() => {
      return props.subjects.length
        ? t('browse.seeAll.subjects', { N: props.subjects.length })
        : t('browse.seeAll.generic');
    });

    const navigateLink = {
      name: RouteName.Subjects
    };

    return {
      buttonLabel,
      navigateLink,
      subjectRoute
    };
  }
});
