
import { AnnotationDetailsAnnotation } from 'app/components/AnnotationDetailsContent.vue';
import AnnotationDetailsContextMenu from 'app/components/contextMenus/AnnotationDetailsContextMenu.vue';
import ExportQueueContextMenu from 'app/components/contextMenus/ExportQueueContextMenu.vue';
import { watchMessage } from 'app/functions/use-chatterbox';
import { useI18n } from 'app/functions/use-i18n';
import { computed, defineComponent, PropType, ref } from 'vue';

export default defineComponent({
  name: 'AnnotationSelectionToolbar',
  components: {
    AnnotationDetailsContextMenu,
    ExportQueueContextMenu
  },
  props: {
    selected: {
      type: Array as PropType<AnnotationDetailsAnnotation[]>,
      required: true
    },
    menuType: {
      type: String,
      required: false,
      default: 'annotation-details'
    }
  },
  emits: [
    'selected:clear'
  ],
  setup: (props) => {
    const { t } = useI18n();

    const reference = ref<HTMLElement | null>(null);
    const showContextMenu = ref(false);

    const disabled = computed(() => props.selected.length === 0);

    const selectedCount = computed(() => t('annotations.selectedCount', { COUNT: props.selected.length }));
    watchMessage('numSelected', selectedCount, false);

    return {
      disabled,
      reference,
      showContextMenu,
      selectedCount
    };
  }
});
