import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DownloadIndicatorStreaming = _resolveComponent("DownloadIndicatorStreaming")!
  const _component_DownloadIndicatorDownloading = _resolveComponent("DownloadIndicatorDownloading")!
  const _component_DownloadIndicatorPaused = _resolveComponent("DownloadIndicatorPaused")!
  const _component_DownloadIndicatorQueued = _resolveComponent("DownloadIndicatorQueued")!
  const _component_DownloadIndicatorDownloaded = _resolveComponent("DownloadIndicatorDownloaded")!
  const _component_DownloadIndicatorErrored = _resolveComponent("DownloadIndicatorErrored")!

  return (_ctx.state === 'streaming')
    ? (_openBlock(), _createBlock(_component_DownloadIndicatorStreaming, {
        key: 0,
        downloadSize: _ctx.downloadSize,
        iconOnly: _ctx.iconOnly,
        downloadConfirmationRequired: _ctx.isMeteredConnection,
        "onDownload:start": _ctx.startDownload,
        "onDownload:settings": _ctx.openSettings
      }, null, 8, ["downloadSize", "iconOnly", "downloadConfirmationRequired", "onDownload:start", "onDownload:settings"]))
    : (_ctx.state === 'downloading')
      ? (_openBlock(), _createBlock(_component_DownloadIndicatorDownloading, {
          key: 1,
          progress: _ctx.progress,
          iconOnly: _ctx.iconOnly,
          cancelConfirmationRequired: _ctx.isMeteredConnection,
          "onDownload:cancel": _ctx.stopDownload,
          "onDownload:settings": _ctx.openSettings
        }, null, 8, ["progress", "iconOnly", "cancelConfirmationRequired", "onDownload:cancel", "onDownload:settings"]))
      : (_ctx.state === 'paused')
        ? (_openBlock(), _createBlock(_component_DownloadIndicatorPaused, {
            key: 2,
            iconOnly: _ctx.iconOnly,
            cancelConfirmationRequired: _ctx.isMeteredConnection,
            "onDownload:cancel": _ctx.stopDownload,
            "onDownload:settings": _ctx.openSettings
          }, null, 8, ["iconOnly", "cancelConfirmationRequired", "onDownload:cancel", "onDownload:settings"]))
        : (_ctx.state === 'queued')
          ? (_openBlock(), _createBlock(_component_DownloadIndicatorQueued, {
              key: 3,
              iconOnly: _ctx.iconOnly,
              cancelConfirmationRequired: _ctx.isMeteredConnection,
              "onDownload:cancel": _ctx.stopDownload,
              "onDownload:settings": _ctx.openSettings
            }, null, 8, ["iconOnly", "cancelConfirmationRequired", "onDownload:cancel", "onDownload:settings"]))
          : (_ctx.state === 'downloaded')
            ? (_openBlock(), _createBlock(_component_DownloadIndicatorDownloaded, {
                key: 4,
                downloadSize: _ctx.downloadSize,
                iconOnly: _ctx.iconOnly,
                deleteConfirmationRequired: !_ctx.isMeteredConnection,
                "onDownload:delete": _ctx.stopDownload,
                "onDownload:settings": _ctx.openSettings
              }, null, 8, ["downloadSize", "iconOnly", "deleteConfirmationRequired", "onDownload:delete", "onDownload:settings"]))
            : (_openBlock(), _createBlock(_component_DownloadIndicatorErrored, {
                key: 5,
                downloadSize: _ctx.downloadSize,
                iconOnly: _ctx.iconOnly,
                downloadConfirmationRequired: true,
                "onDownload:start": _ctx.startDownload,
                "onDownload:settings": _ctx.openSettings
              }, null, 8, ["downloadSize", "iconOnly", "onDownload:start", "onDownload:settings"]))
}