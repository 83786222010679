
import AnnotationCardList from 'app/components/AnnotationCardList.vue';
import FormCheckbox from 'app/components/FormCheckbox.vue';
import { SelectableAnnotation } from 'app/functions/use-display-annotation';
import { FormCheckboxState } from 'app/models/form-checkbox-state';
import { PropType, computed, defineComponent, nextTick, ref } from 'vue';

export default defineComponent({
  components: {
    AnnotationCardList,
    FormCheckbox
  },
  props: {
    titleId: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    annotations: {
      type: Array as PropType<SelectableAnnotation[]>,
      required: true
    },
    subtitle: {
      type: String,
      default: undefined
    },
    hideNote: {
      type: Boolean,
      default: false
    },
    expanded: {
      type: Boolean
    },
    selectable: {
      type: Boolean,
      required: true
    }
  },
  emits: [
    'annotations:update',
    'toggle:expansion'
  ],
  setup: (props, ctx) => {
    const allIds = computed<string[]>(() => props.annotations.map((annotation) => annotation.uuid));
    const selectionState = computed<FormCheckboxState>(() => {
      const selected = props.annotations.filter((a) => a.selected);

      return selected.length === 0
        ? 'off'
        : selected.length === props.annotations.length
          ? 'on'
          : 'partial';
    });

    const annotationPanelId = `annotation-panel-${props.titleId}`;

    const expansionButton = ref<HTMLElement | null>(null);
    const toggleExpansion = async () => {
      ctx.emit('toggle:expansion', !props.expanded);
      await nextTick();
      expansionButton.value?.scrollIntoView({ behavior: 'smooth' });
      expansionButton.value?.focus({ preventScroll: true });
    };

    const updateAnnotation = (checked: FormCheckboxState, uuid: string) => {

      ctx.emit('annotations:update', checked, [uuid]);
    };

    const updateAllAnnotations = (checked: FormCheckboxState) => {

      ctx.emit('annotations:update', checked, allIds.value);
    };

    return {
      annotationPanelId,
      expansionButton,
      selectionState,
      toggleExpansion,
      updateAnnotation,
      updateAllAnnotations
    };
  }
});
