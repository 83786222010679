import { APP } from 'app/base/app';
import { Borrower } from 'app/base/borrower';
import { C, Dictionary } from 'app/base/common';
import { Constants } from 'app/base/constants';
import { CoverItem, MediaType } from 'app/base/interfaces';
import { Freshness } from 'app/base/services/freshness';
import { SeekLocation } from 'app/controllers/open-controller';
import Events from 'app/events/events';
import i18n from 'app/i18n/i18n';
import { Hold } from 'app/models/hold';
import { TitleList } from 'app/models/list';
import { Loan } from 'app/models/loan';
import { TableOfContents } from 'app/models/toc';
import { RouteName } from 'app/router/constants';
import { mapSeekLocationToQuery } from 'app/router/query-mapper';
import router from 'app/router/router';
import { RouteLocationRaw } from 'vue-router';
import { FulfillmentFormatType } from '../base/interfaces';
import { FreshableItem } from '../base/services/freshable';
import { IdNamePair, ThunderCover, ThunderCovers, ThunderFormat, ThunderIdentifier, ThunderMediaResponse } from '../base/thunder';
import { Annotation } from './annotation';
import { Tag } from './tag';
import { WaitList } from './wait-list';

export type TitleRecord = Readonly<Title>;

export type TitleInfoForShell = {
  id:  string;
  title: string;
  creator?: string;
  cover: string;
  format: MediaType;
  duration: number;
  createTime?: number;
  accessTime?: number;
  expireTime?: number;
  downloadProgress?: number;
  readingProgress?: number;
  paths: {open: string; openbook: string | null | undefined};
  sample: boolean;
  active: boolean;
};


export type Creator = { id: number; name: string };

export class Title extends FreshableItem<ThunderMediaResponse> implements CoverItem {
  private readonly BANK_READING_HISTORY_KEY = 'reading-history';
  private _tableOfContents: string;
  private _priorReleases: TitleRecord[];

  public availabilityType: 'normal' | 'always' | 'limited' | 'unknown';
  public isSimultaneousUse: boolean;
  public duration: number;
  public seriesId: number;
  public seriesRank: number;
  public coverColor: [number, number, number];
  public formatInformation: FormatInformation[] = [];
  public deviceFormats: FulfillmentFormatType[] = [];
  public imprint: IdNamePair;
  public subjectLists: TitleList[] = [];
  public subjects: IdNamePair[];
  public description: string;
  /**
   * The title of this publication. aka: The name or the 'title of the title'
   */
  public title: string;
  public sortTitle: string;
  public coverURLForDPI: string;
  public mediaType: MediaType;
  public hasODRFormat = false;
  public hasPDF: boolean;
  public humanFormat: string;
  public publicationTime: number;
  public publicationDate: string;
  public slug: string;
  public publisher: IdNamePair;
  public isLexisPublished: boolean;
  public creators: Dictionary<(Creator)[]> = {};
  public edition: string;
  public starRating?: number;
  public starRatingCount?: number;
  public subtitle: string;
  public languages: IdNamePair[] = [];
  public circ: CircData | undefined;
  public waitList: WaitList;
  public hasAudioSynchronizedText: boolean;
  public lexisMetadata: LexisMetadata = {};
  public isBundledChild: boolean;
  public parentTitleId?: string;
  public childrenTitleIds?: string[];
  public bookToC: TableOfContents;

  public get isPDF() {
    return this.hasPDF && !this.hasODRFormat;
  }

  // STATICS
  public static SORT_FUNCTIONS = {
    title: (a: TitleRecord, b: TitleRecord) => {
      if (!a?.title) { return -1; }
      if (!b?.title) { return 1; }

      return a.title.localeCompare(b.title);
    },
    seriesRank: (a: TitleRecord, b: TitleRecord) => {
      if (!a?.seriesRank) { return -1; }
      if (!b?.seriesRank) { return 1; }

      return a.seriesRank - b.seriesRank;
    },
    descendingTitle: (a: TitleRecord, b: TitleRecord) => {
      if (!a?.title) { return -1; }
      if (!b?.title) { return 1; }

      return b.title.localeCompare(a.title);
    },
    titleShell: (a: TitleInfoForShell, b: TitleInfoForShell) => {
      if (!a?.title) { return -1; }
      if (!b?.title) { return 1; }

      return a.title.localeCompare(b.title);
    }
  };

  public static FILTER_FUNCTIONS = {
    filterByTitle: (s: TitleRecord, filterValue: string, caseSensitive = false) => {
      if (filterValue && s && s.title) {
        const normTitle = caseSensitive ? s.title : s.title.toLowerCase();
        const normFilter = caseSensitive ? filterValue : filterValue.toLowerCase();

        return normTitle.indexOf(normFilter) >= 0;
      }

      return true;
    },
    filterByText: (title: TitleRecord, query: string | undefined) => {
      if (query) {
        const toSearch = [title.title];

        if (title.subtitle) {
          toSearch.push(title.subtitle);
        }

        if (title.creators.Author) {
          const authors = title.creators.Author.map((author) => author.name);
          toSearch.push(authors.join(', '));
        }

        if (title.edition) {
          toSearch.push(title.edition);
        }

        if (title.lexisMetadata.releaseDate) {
          const validDate = title.lexisMetadata.releaseDate.replace(/am|AM|pm|PM/g, '');
          toSearch.push(i18n.t('time.date', { date: new Date(validDate) }));
        }

        const queryComparable = query.toLowerCase().replace(/\s+/g, ' ');
        const fieldComparable = toSearch.map((field) => field.toLowerCase().replace(/\s+/g, ' '));
        const fieldComparableNoSpecial = fieldComparable.map((field) => field.replace(/[^\w\s]/g, ''));

        const isMatch = [...fieldComparable, ...fieldComparableNoSpecial].some((field) => field.includes(queryComparable));

        return isMatch;
      }

      return true;
    }
  };


  constructor() {
    super('title');
  }


  public freshen(raiseNetworkError = false): Promise<boolean>  {
    this._ensureSlug();
    this._tableOfContents = null;
    this.bookToC = this.bookToC || new TableOfContents(this.slug);

    const cachedTitle = APP.titleCache.get(this.slug);

    if (cachedTitle) {
      TitleMapper.freshenFromCache(this, cachedTitle);

      if (cachedTitle.freshness.isFresh()) {
        return Promise.resolve(true);
      }
    }

    return super.freshen(raiseNetworkError);
  }


  protected freshenCall(): Promise<ThunderMediaResponse> {
    return APP.services.thunder.getTitle(APP.library.key(), this.slug);
  }


  protected freshenMap(response: ThunderMediaResponse): void {
    TitleMapper.freshenFromThunder(this, response);
  }


  public arePropertiesFresh(): boolean {
    return !!this.circ;
  }


  /**
   * Get the url of a title as part of a list on the explore tab.
   * If no list is specified, the function selects a list.
   * @param list
   */
  public path(list?: TitleList): string {
    this._ensureSlug();
    let path = '';
    if (list) {
      path = list.path();
    }

    return `${path ? path + '/' : ''}${this.segment()}`;
  }


  /**
   * Get the url segment in the form of:
   * - titleSlug
   */
  public segment(): string {
    return `title/${this.slug}`;
  }


  public setHistory(loan?: Loan, slug?: string) {
    const history = APP.bank.get(this.BANK_READING_HISTORY_KEY);
    const defaultedLoan = loan || APP.patron.loans.find({ slug: slug });

    // Only add titles on loan to reading history, not samples
    if (history && defaultedLoan) {
      const titleIndex = history.indexOf(defaultedLoan.slug);
      if (titleIndex > -1) {
        history.splice(titleIndex, 1);
      }
      // Move title to front of array
      history.unshift(defaultedLoan.slug);
      APP.bank.set(this.BANK_READING_HISTORY_KEY, history);
    } else if (history && !defaultedLoan) {
      // Remove title from history
      const titleIndex = history.indexOf(slug);
      if (titleIndex > -1) {
        history.splice(titleIndex, 1);
        APP.bank.set(this.BANK_READING_HISTORY_KEY, history);
      }
    } else if (defaultedLoan) {
      // Initialize reading history
      APP.bank.set(this.BANK_READING_HISTORY_KEY, [defaultedLoan.slug]);
    }
  }


  /**
   * Set the history and codex for the title for an active title
   */
  public activate() {
    const loan = this.loan();
    this.setHistory(loan);
    this._prepareCodex();
  }


  /**
   * Updates the history for a deactive title
   */
  public deactivate(anotherActive: boolean) {
    // History management
    const history = APP.bank.get(this.BANK_READING_HISTORY_KEY);
    if (history) {
      const titleIndex = history.indexOf(`${APP.library.websiteId}-${this.slug}`);
      if (titleIndex > -1) {
        history.splice(titleIndex, 1);

        if (history.length > 0) {
          APP.bank.set(this.BANK_READING_HISTORY_KEY, history);
        } else {
          APP.bank.set(this.BANK_READING_HISTORY_KEY, null);
        }
      }
    }
    if (!anotherActive) {
      APP.events.dispatch('title:switch', { title: null, codex: null, owner: null });
      APP.shell.transmit('title:info');
    }
  }


  public openable(): boolean {
    if (APP.network.reachable) {
      return true;
    }

    const loan = this.loan();
    if (!loan || !loan.isDownloaded()) {
      return false;
    }

    return true;
  }


  public async open(seekTo?: SeekLocation): Promise<void> {
    APP.bank.flush();

    const openRoute = {
      name: RouteName.OpenBook,
      params: {
        titleSlug: this.slug
      },
      query: {
        parent: this.lexisMetadata.parent,
        ...mapSeekLocationToQuery(seekTo)
      }
    } as RouteLocationRaw;

    if (this.isBundledChild) {
      // There's currently a bug in Thunder
      // where the bulk media endpoint
      // doesn't return format information
      // for bundled children, but the regular
      // media endpoint does. Our workaround
      // is to re-freshen the bundled child
      // as freshen uses the normal media endpoint
      // to populate the format information.
      // Otherwise, bundled children will count as
      // non-ODR, non-video, non-PDF content.
      // See API-4605.
      await this.freshen();
    }

    if (!this.loan()?.downloader.isDownloaded()
      && !this.hasODRFormat
    ) {
      const format = this.isPDF ? 'pdf' : this.mediaType;

      // If we have no supported formats, exit. This shouldn't happen.
      if (!this.hasODRFormat && !this.isPDF && this.mediaType !== 'video') {
        return;
      }

      if (this.isBundledChild && this.lexisMetadata?.parent && !this.loan()) {
        await Borrower.borrowIfPossible(this.slug, this.lexisMetadata.parent);
      }

      // We need to fetch the fulfillment URL first
      // because if we don't open the new window right away
      // we'll get blocked by pop-up blockers.
      const fulfillResult = await APP.sentry.fulfillLoan(
        this.slug,
        this.deviceFormats.find(
          (f: FulfillmentFormatType) => f.thunderId.includes(format)
        )
      );

      if (!fulfillResult?.fulfill?.href) {
        APP.events.dispatch('toast', {
          type: 'error',
          message: i18n.t(`circ.fulfill.${format}.failure`)
        });

        return;
      }

      return;
    }

    if (APP.network.reachable && this.isLexisPublished) {
      const isOutOfDate = await this.isOutOfDate();

      // A title "was" out of date if we hit the open url
      // through a share action. Since the check in /open to see if a title
      // is out of date also updates the loan stamp,
      // we need to carry that forward to do the proper cleanup and show the prompt.
      const wasOutOfDate = APP.activeTitle.isActiveTitle(this.slug)
        ? APP.activeTitle.isNewRelease
        : false;

      if (isOutOfDate || wasOutOfDate || APP.flags.get('force_new_release_prompt')) {
        this.updateDownloads();

        Events.dispatch('prompt:newrelease:show', { title: this, route: openRoute });

        return;
      }
    }
    router.push(openRoute);
  }


  public async isOutOfDate(): Promise<boolean> {
    console.log('[TITLE] Checking for new release...');

    const result = await APP.sentry.updatePossession(this.slug, this.mediaType);

    let isOutOfDate = false;
    let hasOldBuidDownloaded = false;
    const loan = this.loan();

    if (result) {
      // We need to update them to a new release if:
      //  - Their old buid was not the same as the current buid
      //  - Their current download is for the wrong buid
      isOutOfDate = result.newBUID !== result.oldBUID;
      hasOldBuidDownloaded = !!loan
        && loan.downloader.rosterIds.length > 0
        && loan.downloader.rosterIds.indexOf(`title-content-${result.newBUID}`) < 0;
    }

    const shouldUpdateToNewRelease = isOutOfDate || hasOldBuidDownloaded;

    console.log('[TITLE] Found new release?', shouldUpdateToNewRelease);

    return shouldUpdateToNewRelease;
  }


  public updateDownloads() {
    const loan = this.loan();

    APP.activeTitle.isNewRelease = false;

    if (loan && !this.isBundledChild) {
      loan.downloader.purge();
      const autoDown = APP.updateManager.autoDownloadForLoan(loan);
      if (autoDown) {
        loan.downloader.setAutoDownload(autoDown);
      }
    }
  }


  /**
   * Returns the cover URL for the title,
   * using the rostered URL if it exists.
   */
  public coverURL(): string | undefined {
    return this.loan()?.coverURL() || this.rawCoverURL();
  }


  public rawCoverURL(): string {
    return this.coverURLForDPI;
  }


  public titleWithoutOrphans() {
    return this.title.replace(/\s([^\s<]{0,15})\s*$/, '&nbsp;$1');
  }


  /**
   * @desc Retrieve the table of contents for this title. Currently this is raw HTML in string form.
   * @notes Lazy-loaded value
   */
  public async tableOfContents(): Promise<string> {
    if (!this._tableOfContents) {
      const rawTitle = await APP.services.thunder.getGenericTitle(this.slug);
      if (rawTitle) {
        this._tableOfContents = rawTitle.tableOfContents;
      }
    }

    return this._tableOfContents;
  }


  public tags(): Tag[] {
    const tags: Tag[] = [];
    C.each(
      APP.patron.tags.all,
      (tag) => {
        if (tag.isAppliedToTitle(this.slug) && tag.slug !== 'completed-titles') {
          tags.push(tag);
        }
      }
    );

    return tags;
  }


  public annotations(): Annotation[] {
    return APP.patron.annotations.getByTitle(this.slug);
  }


  public annotationsForRelease(): Annotation[] {
    if (!this.lexisMetadata) {
      return [];
    }

    const slug = (this.lexisMetadata && this.lexisMetadata.parent) ? this.lexisMetadata.parent : this.slug;

    return APP.patron.annotations.getByRelease(slug,
      this.lexisMetadata.release,
      this.lexisMetadata.releaseDate).map((a) => {
        a.releaseSlug = this.slug;

        return a;
      });
  }


  public loan(): Loan | undefined {
    const lookup = (this.lexisMetadata?.parent) ? this.lexisMetadata.parent : this.slug;

    return APP.patron.loans.find({ titleSlug: lookup });
  }


  public hold(): Hold | undefined {
    return APP.patron.holds.find({ titleSlug: this.slug });
  }


  public possession() {
    return this.loan() || this.hold();
  }


  public async getPriorReleases(): Promise<TitleRecord[] | undefined> {
    if (!this.lexisMetadata?.priorReleases?.length) {
      return undefined;
    }

    if (this.freshness?.isFresh() && this._priorReleases) {
      return this._priorReleases.slice();
    }

    const priorReleaseTitles = (await APP.titleCache
      .getFreshTitles(this.lexisMetadata.priorReleases.map((r) => r.titleId)))
      .filter((t) => !!t);

    C.each(priorReleaseTitles, (p) => {
      p.lexisMetadata.parent = this.slug;
    });

    const priorReleaseItems = priorReleaseTitles.slice()
      .sort((a, b) => {
        if (a.lexisMetadata.releaseDate && b.lexisMetadata.releaseDate) {
          return new Date(b.lexisMetadata.releaseDate).getTime() - new Date(a.lexisMetadata.releaseDate).getTime();
        }

        if (a.lexisMetadata.releaseDate) {
          return -1;
        }

        if (b.lexisMetadata.releaseDate) {
          return 1;
        }

        return 1;
      });

    return (this._priorReleases = priorReleaseItems).slice();
  }


  protected _serializeAttributes(): BankedTitle {
    let creatorsWithoutLists: Dictionary<Creator[]> | undefined;
    if (this.creators) {
      creatorsWithoutLists = {};
      for (const c in this.creators) {
        creatorsWithoutLists[c] = this.creators[c].map(({ id, name }) => ({ id, name }));
      }
    }

    return {
      slug: this.slug,
      title: this.title,
      subtitle: this.subtitle,
      sortTitle: this.sortTitle,
      creators: creatorsWithoutLists,
      mediaType: this.mediaType,
      duration: this.duration,
      coverURLForDPI: this.coverURLForDPI,
      coverColor: this.coverColor,
      hasAudioSynchronizedText: this.hasAudioSynchronizedText,
      hasODRFormat: this.hasODRFormat,
      hasPDF: this.hasPDF,
      seriesId: this.seriesId,
      seriesRank: this.seriesRank,
      publisher: this.publisher ? { id: this.publisher.id, name: this.publisher.name } : null,
      isLexisPublished: this.isLexisPublished,
      imprint: this.imprint ? { id: this.imprint.id, name: this.imprint.name } : null,
      subjects: this.subjects,
      lexisMetadata: this.lexisMetadata,
      isSimultaneousUse: this.isSimultaneousUse,
      isBundledChild: this.isBundledChild,
      parentTitleId: this.parentTitleId,
      childrenTitleIds: this.childrenTitleIds,
      bookToC: <TableOfContents>this.bookToC?.serialize(),
      circ: this.circ
    };
  }


  protected _ensureSlug() {
    if (!this.slug) {
      throw new Error('[TITLE] missing slug');
    }
  }


  protected async _prepareCodex() {
    const capsule: any = { codex: {}, info: {} };
    const loan = this.loan();
    const redeliverCodex = this._deliverCodex.bind(this, capsule, loan);
    redeliverCodex();

    try {
      await this.freshen();
    } finally {
      redeliverCodex();
    }
  }


  protected _deliverCodex(capsule, loan) {
    const codex: any = {};
    const info: any = {};

    const authors = (this.creators.Author || [])
      .map((a) => a.name)
      .join(', ');
    codex.title = this.title;
    codex.byline = authors;
    codex.description = this.description;
    codex.sample = loan ? false : true;
    codex.coverURL = C.absoluteURL(this.coverURL() || '');
    codex.coverRatio = 0.75;
    codex.coverColor = this.coverColor || [245, 245, 245];

    info.id = this.slug;
    info.title = this.title;
    info.creator = authors;
    info.format = this.mediaType;
    info.cover = codex.coverURL;
    info.sample = codex.sample;
    info.duration = loan ? this.duration : Math.min(this.duration * 0.2, 300000);

    if (JSON.stringify(codex) !== JSON.stringify(capsule.codex)) {
      capsule.codex = codex;
      APP.bank.set('codex', codex);
      APP.events.dispatch('title:switch', {
        title: this,
        codex: codex,
        owner: loan || this
      });
    }

    if (JSON.stringify(info) !== JSON.stringify(capsule.info)) {
      capsule.info = info;
      APP.shell.transmit(C.absorb(info, { name: 'title:info' }));
    }
  }
}


export class TitleMapper {
  public static mapFromCache(cachedTitle: TitleRecord): TitleRecord {
    const title = new Title();
    TitleMapper.freshenFromCache(title, cachedTitle);

    return title;
  }


  public static freshenFromCache(title: Title, cachedTitle: TitleRecord): TitleRecord {
    const existingParent = title.lexisMetadata ? title.lexisMetadata.parent : undefined;
    const existingToC = title.bookToC;
    const existingCreators = title.creators;

    for (const prop in cachedTitle) {
      title[prop] = cachedTitle[prop];
    }

    if (existingParent) {
      title.lexisMetadata.parent = existingParent;
      cachedTitle.lexisMetadata.parent = existingParent;
    }

    if (existingToC) {
      title.bookToC = existingToC;
    }

    if (existingCreators) {
      title.creators = existingCreators;
    }

    title.freshness = new Freshness(title, cachedTitle.freshness.ttl, cachedTitle.freshness.freshAt);

    return title;
  }


  public static mapFromScribe(scribeTitle: ScribeTitleAttributes): ScribeTitle {
    return {
      slug: scribeTitle.titleId,
      title: scribeTitle.title,
      authors: scribeTitle.authors,
      coverURLForDPI: scribeTitle.cover.url,
      coverColor: scribeTitle.cover.color,
      library: {
        websiteId: parseInt(scribeTitle.websiteId, 10)
      },
      format: scribeTitle.format,
      _source: 'scribe'
    };
  }


  public static mapToScribe(title: Readonly<Title>): ScribeTitle {
    const lex = (title.lexisMetadata) ? title.lexisMetadata : {};

    return {
      slug: title.slug,
      title: title.title,
      authors: (title.creators.Author || []).map((a) => a.name).join(', '),
      coverURLForDPI: title.coverURLForDPI,
      coverColor: title.coverColor,
      release: lex.release || null,
      releaseDate: lex.releaseDate || null,
      parent: lex.parent,
      library: {
        websiteId: APP.library.websiteId
      },
      format: title.mediaType,
      _source: 'scribe'
    };
  }


  public static mapFromBank(bankedTitle: BankedTitle, includeCirc = false): TitleRecord {
    const title = new Title();
    TitleMapper.freshenFromBank(title, bankedTitle, includeCirc);

    return title;
  }


  public static freshenFromBank(title: Title, bankedTitle: BankedTitle, includeCirc = false) {
    if (!bankedTitle) {
      return;
    }

    title.slug = bankedTitle.slug;
    title.title = bankedTitle.title;
    title.subtitle = bankedTitle.subtitle;
    title.sortTitle = bankedTitle.sortTitle;
    // bankedTitle.format included for back-compat
    // eslint-disable-next-line dot-notation
    title.mediaType = bankedTitle.mediaType || bankedTitle['format'];
    if (bankedTitle.creators) {
      title.creators = bankedTitle.creators;
    }
    title.duration = bankedTitle.duration;
    title.coverURLForDPI = bankedTitle.coverURLForDPI;
    title.coverColor = bankedTitle.coverColor;
    title.hasAudioSynchronizedText = bankedTitle.hasAudioSynchronizedText;
    title.hasODRFormat = bankedTitle.hasODRFormat;
    title.hasPDF = bankedTitle.hasPDF;
    title.seriesId = bankedTitle.seriesId;
    title.publisher = bankedTitle.publisher;
    title.isLexisPublished = bankedTitle.isLexisPublished;
    title.imprint = bankedTitle.imprint;
    title.subjects = bankedTitle.subjects;
    title.isSimultaneousUse = bankedTitle.isSimultaneousUse;
    title.isBundledChild = bankedTitle.isBundledChild;
    title.parentTitleId = bankedTitle.parentTitleId;
    title.childrenTitleIds = bankedTitle.childrenTitleIds;
    title.bookToC = (title.bookToC || new TableOfContents(title.slug))
      .deserialize(bankedTitle.bookToC || {});

    if (bankedTitle.lexisMetadata) {
      title.lexisMetadata = bankedTitle.lexisMetadata;
    }

    if (includeCirc && bankedTitle.circ) {
      title.circ = bankedTitle.circ;
    }

    title.freshness?.makeStale();
  }


  public static mapFromThunder(thunderTitle: ThunderMediaResponse | null): TitleRecord | null {
    if (!thunderTitle) {
      return null;
    }

    const title = new Title();
    TitleMapper.freshenFromThunder(title, thunderTitle);

    return title;
  }


  public static freshenFromThunder(title: Title, thunderTitle: ThunderMediaResponse): void {
    if (!thunderTitle) {
      return;
    }

    // Filter out unsupported formats. Used by various mapping functions below.
    thunderTitle.formats = thunderTitle.formats.filter((format) => Constants.SUPPORTED_FORMATS.includes(format.id));

    title.slug = thunderTitle.id || thunderTitle.slug;
    title.title = thunderTitle.title;
    title.subtitle = thunderTitle.subtitle;
    title.sortTitle = thunderTitle.sortTitle;

    if (thunderTitle.creators) {
      title.creators = {};
      C.each(thunderTitle.creators, (creator) => {
        title.creators[creator.role] = title.creators[creator.role] || [];
        title.creators[creator.role].push({ name: creator.name, id: creator.id });
      });

    } else if (!title.creators && thunderTitle.firstCreatorName) {
      title.creators = {
        Author: [{
          name: thunderTitle.firstCreatorName,
          id: thunderTitle.firstCreatorId
        }]
      };
    }

    title.circ = TitleMapper.mapCirc(thunderTitle);

    if (!title.circ.available) {
      title.waitList = new WaitList().deserialize(thunderTitle);
    }

    title.availabilityType = thunderTitle.availabilityType;
    title.isSimultaneousUse = title.availabilityType === 'always';

    title.publicationTime = TitleMapper.earliestPubTime(thunderTitle);
    if (title.publicationTime) {
      const units: any = C.timestampToUnits(title.publicationTime);
      units.thisYear = false;
      title.publicationDate = C.timeUnitsToHumanDate(units, (s) => Constants.text(s));
    }

    if (thunderTitle.covers) {
      const cover = TitleMapper.coverAttrsForDPI(thunderTitle.covers);
      title.coverURLForDPI = TitleMapper.findCoverURLForDPI(cover);
      title.coverColor = TitleMapper.findCoverColor(cover);
    } else {
      title.coverURLForDPI = thunderTitle.coverURLForDPI;
      title.coverColor = thunderTitle.coverColor;
    }

    if (thunderTitle.description) {
      title.description = thunderTitle.description;
    }

    if (thunderTitle.detailedSeries) {
      title.seriesId = thunderTitle.detailedSeries.seriesId;
      title.seriesRank = thunderTitle.detailedSeries.rank;
    }

    if (thunderTitle.publisher) {
      title.publisher = thunderTitle.publisher;
    }
    title.isLexisPublished = thunderTitle.publisherAccount.id === Constants.LEXIS_PUBLISHER_ACCOUNT_ID;
    title.imprint = thunderTitle.imprint;
    title.subjects = thunderTitle.subjects;
    [title.edition ] = TitleMapper.parseEdition(thunderTitle.edition);

    if (thunderTitle.languages) {
      title.languages = thunderTitle.languages;
    }

    title.starRating = thunderTitle.starRating;
    title.starRatingCount = thunderTitle.starRatingCount;

    title.bookToC = title.bookToC || new TableOfContents(title.slug);

    title.isBundledChild = thunderTitle.isBundledChild;
    title.parentTitleId = thunderTitle.bundledContentParentTitleId;
    title.childrenTitleIds = thunderTitle.bundledContentChildrenTitleIds;

    if (thunderTitle.lexisMetadata) {
      title.lexisMetadata = thunderTitle.lexisMetadata;
    } else {
      // Thunder never returns the bundle parent, so we need to save that
      // if we've ever had it.
      // Thunder only sometimes returns the bundled content,
      // so again we need to save it if we've ever had it.
      const existingParent = title.lexisMetadata ? title.lexisMetadata.parent : undefined;
      const existingPriorReleases = title.lexisMetadata ? title.lexisMetadata.priorReleases : undefined;

      title.lexisMetadata = TitleMapper.lexisMetadata(thunderTitle);
      title.lexisMetadata.priorReleases = title.lexisMetadata.priorReleases || existingPriorReleases;
      title.lexisMetadata.parent = title.lexisMetadata.parent || existingParent;
    }

    TitleMapper.mapFormats(title, thunderTitle);
  }


  private static mapCirc(thunderTitle: ThunderMediaResponse): CircData {
    // isAvailable is not always a part of thunderTitle. Seems like we're using our Sentry loan data to update title records, but those aren't a 1-1 match, causing isAvailable to be lost.
    // This causes this returned value to have available as undefined, holdable as true, and copiesAvailable to be 0, which is not accurate and causes the TitleActionButton to display Place Hold when it shouldn't.
    // This is a temporary fix to prevent that, but in the future we should actually fix the real problem here, which might include reworking the circ logic.
    const available = thunderTitle.isAvailable || thunderTitle.availabilityType === 'always' || !!(thunderTitle.availableCopies && thunderTitle.availableCopies > 0);

    return {
      owned: thunderTitle.isOwned,
      available: thunderTitle.isOwned && available,
      holdable: thunderTitle.isOwned && thunderTitle.isHoldable && !available,
      holds: thunderTitle.holdsCount,
      holdsRatio: thunderTitle.holdsRatio,
      prerelease: thunderTitle.isPreReleaseTitle,
      copiesOwned: thunderTitle.isOwned ? (thunderTitle.ownedCopies || 0) : 0,
      copiesAvailable: available ? (thunderTitle.availableCopies || 1) : 0
    };
  }


  private static mapFormats(title: Title, thunderTitle: ThunderMediaResponse) {
    if (thunderTitle.type) {
      title.mediaType = Constants.toMediaType(thunderTitle.type.id);
    }

    title.duration = TitleMapper.calculateDuration(thunderTitle.formats);

    title.formatInformation = [];

    C.each(thunderTitle.formats, (deviceAttr) => {
      title.formatInformation.push({
        name: deviceAttr.name,
        isbn: deviceAttr.isbn,
        fileSize: deviceAttr.fileSize
      });

      if (deviceAttr.id === 'ebook-pdf-adobe' || deviceAttr.id === 'ebook-pdf-open') {
        title.hasPDF = true;
      }

      if (deviceAttr.id.includes('overdrive')) {
        title.hasODRFormat = true;
      }

      if (deviceAttr.bundledContent?.length) {
        // It's possible that the values are not unique.
        // The entries likely have different "FormatID"s, but we only get the titleID
        const titleIds = deviceAttr.bundledContent.map((content) => content.titleId);
        const uniqueTitleIds = new Set(titleIds);
        title.lexisMetadata.priorReleases = [...uniqueTitleIds].map((titleId) => ({ titleId }));
      }

      const fft = Constants.idToFulfillmentFormatType(deviceAttr.id);

      if (fft) {
        title.deviceFormats.push(fft);
      }

      if (deviceAttr.hasAudioSynchronizedText) {
        title.hasAudioSynchronizedText = true;
      }
    });

    title.humanFormat = TitleMapper.humanFormat(title.mediaType, title.hasAudioSynchronizedText, title.isPDF);
  }


  private static humanFormat(mediaType: MediaType, hasAudioSynchronizedText: boolean, isPDF: boolean): string {
    if (!mediaType) {
      return null;
    }

    switch (mediaType) {
      case 'book':
        return hasAudioSynchronizedText ? Constants.text('title.format.read-along-ebook')
          : isPDF ? Constants.text('title.format.pdf')
          : Constants.text('title.format.ebook');
      case 'audiobook':
        return Constants.text('title.format.audiobook');
      case 'magazine':
        return Constants.text('title.format.magazine');
      case 'video':
        return Constants.text('title.format.video');
      default:
        return C.capitalize(mediaType);
    }
  }


  private static lexisMetadata(thunderTitle: ThunderMediaResponse): LexisMetadata {
    const [setId, volume] = TitleMapper.parseFormats(thunderTitle.formats);

    const [edition, release] = TitleMapper.parseEdition(thunderTitle.edition);

    let releaseDate: string = null;

    if (thunderTitle.publishDate) {
      let truncatedDate = thunderTitle.publishDate; // ex/ 2018-04-02T00:00:00Z
      const tTimeIndex = truncatedDate.indexOf('T');
      if (tTimeIndex >= 0) {
        // TTimes and Timezones: We need to shear the hours and mins
        // off of our publishDate. Thunder sends inconsistent values.
        // Instead, we rely only on the day, month, & year

        // Also, this is a js date, so here comes some stupid...
        // new Date("2011-09-24");
        // => Fri Sep 23 2011 - ONE DAY OFF
        // new Date("2011/09/24"); // change from "-" to "/".
        // => Sat Sep 24 2011 - CORRECT DATE.
        truncatedDate = truncatedDate.split('T')[0].replace(/-/g, '\/');
      }

      const units = C.timestampToUnits(new Date(truncatedDate).getTime());
      releaseDate = C.timeUnitsToNumericalDate(units);
    } else if (thunderTitle.publishDateText) {
      releaseDate = thunderTitle.publishDateText;
    }

    let priorReleases: { titleId: string }[];
    if (!thunderTitle.isBundledChild && thunderTitle.bundledContentTitleIds && thunderTitle.bundledContentTitleIds.length) {
      priorReleases = thunderTitle.bundledContentTitleIds.map((t) => ({ titleId: t }));;
    }

    return {
      edition: edition,
      release: release || null,
      releaseDate: releaseDate || null,
      contentReserveID: thunderTitle.reserveId,
      setId: setId,
      isVolumeInSet: !!volume,
      priorReleases: priorReleases
    };
  }


  public static parseEdition(edition: string): [string, string] {
    if (!edition) {
      return [null, null];
    }

    const split = edition.split(';');

    const editionVal = split[0];
    const releaseVal = split[1];

    return [editionVal, releaseVal];
  }


  private static parseFormats(thunderFormats: ThunderFormat[]): [string, number] {
    if (!thunderFormats || thunderFormats.length === 0) {
      return [null, null];
    }

    const identifier = thunderFormats
      .find((f: ThunderFormat) => f.id === 'ebook-overdrive')
      ?.identifiers
      ?.find((value: ThunderIdentifier) => value.type === 'PublisherCatalogNumber');

    if (identifier) {
      const split = identifier.value.split('!');
      const setIDVal = split[0];
      const volumeVal = (split[1]) ? parseInt(split[1], 10) : undefined;

      return [setIDVal, volumeVal];
    }

    return [null, null];
  }


  private static earliestPubTime(thunderTitle: ThunderMediaResponse): number {
    const dates = [thunderTitle.estimatedReleaseDate];

    C.each(thunderTitle.formats, (fmt) => {
      dates.push(fmt.onSaleDateUtc);
    });

    let cand: Date;
    C.each(dates, (utc) => {
      const date = new Date(utc);
      cand = (cand && cand > date) ? cand : date;
    });

    const t = cand ? cand.getTime() : null;

    return t && isFinite(t) ? t : null;
  }


  private static calculateDuration(formats: ThunderFormat[]): number {
    if (!formats) {
      return null;
    }

    for (let i = 0, ii = formats.length; i < ii; ++i) {
      const dur = formats[i].duration;

      if (dur) {
        const parts = dur.split(':');

        return (
          (parseFloat(parts[0]) * 60 * 60 +
            parseFloat(parts[1]) * 60 +
            parseFloat(parts[2])) *
          1000
        );
      }
    }

    return null;
  }


  private static findCoverURLForDPI(cover: ThunderCover): string {
    return cover ? cover.href : null;
  }


  private static findCoverColor(cover: ThunderCover): [number, number, number] {
    if (!cover || !cover.primaryColor) {
      return [255, 255, 255]; // default to white bg
    }

    const rgb = cover.primaryColor.rgb;

    return [rgb.red, rgb.green, rgb.blue];
  }


  private static coverAttrsForDPI(covers: ThunderCovers): ThunderCover {
    const sizes = window.devicePixelRatio > 1.5
      ? [510, 300, 150]
      : [300, 510, 150];

    while (covers && sizes.length) {
      const cover: ThunderCover = covers['cover' + sizes.shift() + 'Wide'];

      if (cover && cover.href) {
        return cover;
      }
    }

    return null;
  }
}

export interface CircData {
  owned: boolean;
  available: boolean;
  holdable: boolean;
  holds?: number;
  holdsRatio?: number;
  prerelease: boolean;
  copiesOwned: number;
  copiesAvailable: number;
}

export interface BankedTitle {
  slug: string;
  title: string;
  subtitle: string;
  sortTitle: string;
  creators?: Dictionary<{ id: number; name: string}[]>;
  mediaType: MediaType;
  duration: number;
  coverURLForDPI: string;
  coverColor: [number, number, number];
  hasAudioSynchronizedText: boolean;
  hasODRFormat: boolean;
  hasPDF: boolean;
  seriesId: number;
  seriesRank: number;
  publisher: IdNamePair;
  isLexisPublished: boolean;
  imprint: IdNamePair;
  subjects: IdNamePair[];
  lexisMetadata?: LexisMetadata;
  isSimultaneousUse: boolean;
  isBundledChild: boolean;
  parentTitleId?: string;
  childrenTitleIds?: string[];
  bookToC: TableOfContents;
  circ?: CircData;
}

export interface ScribeTitle {
  _source: string;
  slug: string;
  title: string;
  authors: string;
  coverURLForDPI: string;
  coverColor: [number, number, number];
  format: string;
  library: { websiteId: number };
  release?: string;
  releaseDate?: string;
  parent?: string;
}

export interface ScribeTitleAttributes {
  titleId: string;
  title: string;
  authors: string;
  format: string;
  cover: {
    url: string;
    color: [number, number, number];
  };
  websiteId: string;
  release?: string;
  releaseDate?: string;
}

export interface LexisMetadata {
  edition?: string;
  release?: string;
  releaseDate?: string;
  contentReserveID?: string;
  isVolumeInSet?: boolean;
  setId?: string;
  priorReleases?: { titleId: string }[];
  parent?: string;
}

export interface FormatInformation {
  name: string;
  isbn: string;
  fileSize?: number;
}
