
import { Constants } from 'app/base/constants';
import EmptyState from 'app/components/EmptyState.vue';
import { usePatron } from 'app/functions/use-patron';
import { Tag } from 'app/models/tag';
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'HomeTagList',
  components: {
    EmptyState
  },
  setup: () => {
    const helpLink = Constants.HELP_PATHS.TAGS;
    const { tags: allTags } = usePatron();

    const tags = computed(() => {
      return (allTags.value)
        .filter(Tag.FILTER_FUNCTIONS.nonSystemTags)
        .sort(Tag.SORT_FUNCTIONS.recent)
        .slice(0, 10);
    });

    return {
      helpLink,
      tags
    };
  }
});
