
import { defineComponent } from 'vue';
import { SubjectCollection } from 'app/models/subject';


export default defineComponent({
  props: {
    subjects: {
      type: Array as () => SubjectCollection[],
      required: true
    },
    label: {
      type: String,
      required: false,
      default: undefined
    },
    headerTag: {
      type: String as () => 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6',
      required: false,
      default: 'h1'
    }
  }
});
