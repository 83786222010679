
import { APP } from 'app/base/app';
import { Constants, seriesCoverSizes } from 'app/base/constants';
import { Subscription } from 'app/base/samwise';
import Cover from 'app/components/Cover.vue';
import EmptyState from 'app/components/EmptyState.vue';
import Loading from 'app/components/Loading.vue';
import Page from 'app/components/Page.vue';
import SeriesCover from 'app/components/SeriesCover.vue';
import Surface from 'app/components/Surface.vue';
import Card, { CardableItem } from 'app/components/cards/Card.vue';
import { SeriesSubscriptionWithMetadata, TitleSubscriptionWithMetadata, useSubscriptionsWithMetadata } from 'app/functions/use-subscriptions-with-metadata';
import { SubscriptionsWithMetadataSymbol } from 'app/keys/injection-keys';
import { Series } from 'app/models/series';
import { TitleRecord } from 'app/models/title';
import { computed, defineComponent, inject } from 'vue';

type SubscriptionWithBook = Subscription & {
  sortTitle: string;
  item: TitleRecord | Series;
  cardableItem: CardableItem;
};

export default defineComponent({
  name: 'MySubscriptions',
  components: {
    Surface,
    Page,
    EmptyState,
    Cover,
    SeriesCover,
    Card,
    Loading
  },
  setup: () => {
    const helpLink = Constants.HELP_PATHS.SUBSCRIPTIONS;

    const {
      subscriptionsWithMetadata,
      loading,
      fetchAllSubscriptions,
      unsubscribe
    } = inject(SubscriptionsWithMetadataSymbol, useSubscriptionsWithMetadata());

    if (APP.patron.accountId) {
      fetchAllSubscriptions();
    } else {
      APP.events.on('patron:accountId:acquired', fetchAllSubscriptions);
    }

    const subscriptionsWithBook = computed(() => {
      if (!subscriptionsWithMetadata.value) { return undefined; }

      const subsWithBook: SubscriptionWithBook[] = [];

      const titleSubscriptions = subscriptionsWithMetadata.value.filter((sub) => sub.type === 'Title') as TitleSubscriptionWithMetadata[];
      titleSubscriptions.forEach((sub) => {

        subsWithBook.push({
          ...sub,
          sortTitle: sub.title.sortTitle,
          item: sub.title,
          cardableItem: {
            id: sub.title.slug,
            title: sub.title.title,
            subtitle: sub.title.subtitle
          } as CardableItem
        });
      });

      const seriesSubscriptions = subscriptionsWithMetadata.value.filter((sub) => sub.type === 'Series') as SeriesSubscriptionWithMetadata[];
      seriesSubscriptions.forEach((sub) => {
        subsWithBook.push({
          ...sub,
          sortTitle: sub.series.name,
          item: sub.series as Series,
          cardableItem: {
            id: sub.series.id.toString(),
            title: sub.series.name
          } as CardableItem
        });
      });

      return subsWithBook.sort((a, b) => a.sortTitle.localeCompare(b.sortTitle));
    });

    return {
      helpLink,
      loading,
      seriesCoverSizes,
      subscriptionsWithBook,
      unsubscribe
    };
  }
});

