import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["aria-disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    type: "submit",
    "aria-disabled": !_ctx.enabled,
    class: _normalizeClass({
      [_ctx.$style.button]: true,
      [_ctx.$style[_ctx.size]]: true,
      [_ctx.$style.disabled]: !_ctx.enabled
    })
  }, _toDisplayString(_ctx.label), 11, _hoisted_1))
}