
import { APP } from 'app/base/app';
import { SearchDefinition, SearchSortOption, SearchType } from 'app/base/search';
import BaseListPage from 'app/components/BaseListPage.vue';
import { ListPageConfig, ListTabId, useListPage } from 'app/functions/use-list-page';
import { SubjectFilters } from 'app/functions/use-subject-filters';
import { computed, defineComponent, PropType } from 'vue';


export default defineComponent ({
  name: 'Collection',
  components : {
    BaseListPage
  },
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      default: undefined
    },
    searchType: {
      type: String as PropType<SearchType>,
      required: true
    },
    definition: {
      type: Object as PropType<SearchDefinition>,
      required: true
    },
    currentSort: {
      type: String as PropType<SearchSortOption>,
      default: undefined
    },
    appliedFilters: {
      type: Object as PropType<SubjectFilters>,
      default: undefined
    },
    titlePage: {
      type: Number,
      default: undefined
    },
    seriesPage: {
      type: Number,
      default: undefined
    }
  },
  setup: (props, ctx) => {
    const tabOptions = computed<ListTabId[]>(() => props.searchType === SearchType.Series ? ['set'] : ['title']);

    const config = computed<ListPageConfig>(() => {
      let sortOptions: SearchSortOption[] = ['title', 'publishdate'];
      if (props.definition.sort) {
        sortOptions = [...new Set([props.definition.sort, ...sortOptions])];
      }

      return {
        libraryKey: APP.library.key(),
        definition: props.definition,
        tabOptions: tabOptions.value,
        sortOptions,
        modifications: {
          currentSort: props.currentSort,
          appliedFilters: props.appliedFilters,
          titlePage: props.titlePage,
          seriesPage: props.seriesPage
        }
      };
    });

    const listPage = useListPage(config);


    return {
      ...listPage
    };
  }
});
