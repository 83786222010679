
import { useAppEvents } from 'app/functions/use-app-events';
import { ChatterboxIdMessage, ChatterboxMessage } from 'app/functions/use-chatterbox';
import { useI18n } from 'app/functions/use-i18n';
import { generateUUID } from 'lib/common';
import { DataEvent } from 'lib/gala/src/events';
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'Chatterbox',
  setup: (props, ctx) => {
    const singleMessage = ref<String | undefined>('');
    const politeness = ref('polite');
    const busy = ref(false);
    const loadingMessage = ref<String | undefined>(undefined);
    const singleMessageId = ref('');
    const modifiedMessages = ref<{ [id: string]: ChatterboxIdMessage }>({});

    const { t } = useI18n();

    const newMessage = (evt: DataEvent<ChatterboxMessage>) => {
      //want the potential for the same message to be read twice in a row
      //so generate a new id value to be used as the key to rerender the live region
      singleMessageId.value = generateUUID();

      singleMessage.value = evt.m.message;
      politeness.value = evt.m.politeness || 'polite';
    };

    const updateMessage = (evt: DataEvent<ChatterboxIdMessage>) => {
      modifiedMessages.value[evt.m.id] = evt.m;
    };

    const loading = (evt: DataEvent<{ message: boolean | string }>) => {
      loadingMessage.value = typeof evt.m.message === 'string' ? evt.m.message
        : evt.m.message ? t('general.loading')
        : undefined;

      busy.value = true; //set aria-busy to true to delay any new messages
    };

    useAppEvents({
      'chatter:message': newMessage,
      'chatter:update': updateMessage,
      'chatter:loading': loading,
      'chatter:loaded': () => busy.value = false, //set aria-busy to false to announce the new message(s)
      'chatter:clear': () => {
        modifiedMessages.value = {};
        singleMessage.value = undefined;
      }
    });

    return {
      singleMessage,
      singleMessageId,
      politeness,
      busy,
      loadingMessage,
      modifiedMessages
    };
  }
});
