
import { watchLoadingState } from 'app/functions/use-chatterbox';
import { useI18n } from 'app/functions/use-i18n';
import { Breakpoint, useWindowSize } from 'app/functions/use-window-size';
import { generateUUID } from 'lib/common';
import { computed, defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'TextFilter',
  props: {
    modelValue: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: undefined
    },
    hideLabel: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    'update:modelValue'
  ],
  setup: (props, ctx) => {
    const { t } = useI18n();
    const labelWithDefault = computed(() => props.label || t('general.textFilter.placeholder'));

    const id = generateUUID();

    const { windowWidth } = useWindowSize();
    const noLabel = computed(() => (windowWidth.value < Breakpoint.Narrow) || props.hideLabel);

    const internalInput = computed({
      get: () => props.modelValue,
      set: (val) => ctx.emit('update:modelValue', val)
    });

    const setInput = (typingEvent: Event) => {
      if (typingEvent.target) {
        const target = typingEvent.target as HTMLInputElement;
        internalInput.value = target.value.trim();
      } else {
        internalInput.value = '';
      }
    };

    // Chatterbox

    // set an arbitrary loading state while the user is typing to delay any messages until the user is done
    let typeaheadTimer = -1;
    const typing = ref(false);

    const keydown = (evt: KeyboardEvent) => {
      if (/^Key[A-Z]$/.test(evt.code) || evt.code === 'Backspace') {
        clearTimeout(typeaheadTimer);
        typing.value = true;
        typeaheadTimer = window.setTimeout(() => typing.value = false, 1000);
      }
    };

    watchLoadingState(computed(() => typing.value), false);

    return {
      id,
      internalInput,
      labelWithDefault,
      noLabel,
      keydown,
      setInput
    };
  }
});
