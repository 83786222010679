import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContextMenu = _resolveComponent("ContextMenu")!

  return (_openBlock(), _createBlock(_component_ContextMenu, {
    ref: "contextMenu",
    class: _normalizeClass(_ctx.$style.menu),
    reference: _ctx.reference,
    headerIconName: "book",
    headerLabel: _ctx.$t('title.contextMenu.header'),
    options: _ctx.menuOptions,
    onClose: _ctx.closeContextMenu
  }, null, 8, ["class", "reference", "headerLabel", "options", "onClose"]))
}