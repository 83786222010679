import { withModifiers as _withModifiers, withKeys as _withKeys, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createVNode as _createVNode, TransitionGroup as _TransitionGroup } from "vue"

const _hoisted_1 = ["id", "value", "maxlength", "placeholder", "aria-labelledby", "aria-controls", "aria-expanded"]
const _hoisted_2 = ["id"]
const _hoisted_3 = ["id", "aria-label"]
const _hoisted_4 = ["onKeydown"]
const _hoisted_5 = ["id", "onClick"]
const _hoisted_6 = ["id", "onClick"]
const _hoisted_7 = {
  class: "visually-hidden",
  "aria-live": "polite"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormError = _resolveComponent("FormError")!
  const _component_Popout = _resolveComponent("Popout")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_DynamicExpander = _resolveComponent("DynamicExpander")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      ref: "tagForm",
      class: _normalizeClass(_ctx.$style.widget),
      onKeydown: _cache[6] || (_cache[6] = _withKeys(
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)), ["esc"]))
    }, [
      _createElementVNode("input", {
        id: _ctx.ids.textInput,
        value: _ctx.textInput,
        type: "text",
        autocapitalize: "off",
        autocomplete: "off",
        autocorrect: "off",
        maxlength: _ctx.maxCharLength,
        size: "15",
        placeholder: _ctx.$t('tag.create.placeholder'),
        role: "combobox",
        "aria-labelledby": _ctx.ids.addButton,
        "aria-autocomplete": "list",
        "aria-controls": _ctx.ids.autocomplete,
        "aria-haspopup": "listbox",
        "aria-expanded": _ctx.showAutocomplete,
        class: _normalizeClass(_ctx.$style.inputField),
        onKeydown: [
          _cache[0] || (_cache[0] = _withKeys(_withModifiers(($event: any) => (_ctx.highlight(0)), ["prevent"]), ["up"])),
          _cache[1] || (_cache[1] = _withKeys(_withModifiers(($event: any) => (_ctx.highlight(0)), ["prevent"]), ["down"])),
          _cache[2] || (_cache[2] = _withKeys(
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)), ["tab"])),
          _cache[3] || (_cache[3] = _withKeys(_withModifiers(($event: any) => (_ctx.applyTag(_ctx.textInput)), ["prevent"]), ["enter"]))
        ],
        onInput: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.updateTagValue && _ctx.updateTagValue(...args)))
      }, null, 42, _hoisted_1),
      _createElementVNode("button", {
        id: _ctx.ids.addButton,
        class: _normalizeClass(_ctx.$style.applyButton),
        onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.applyTag(_ctx.textInput)))
      }, _toDisplayString(_ctx.$t('tag.create.action')), 11, _hoisted_2)
    ], 34),
    (_ctx.invalidNameError)
      ? (_openBlock(), _createBlock(_component_FormError, {
          key: 0,
          "aria-live": "polite",
          contents: _ctx.$t('tag.create.invalidName')
        }, null, 8, ["contents"]))
      : _createCommentVNode("", true),
    (_ctx.showAutocomplete && _ctx.tagForm)
      ? (_openBlock(), _createBlock(_component_Popout, {
          key: 1,
          labelId: _ctx.ids.autocomplete,
          initialFocus: `#${_ctx.ids.textInput}`,
          reference: _ctx.tagForm,
          trapFocus: false,
          onClose: _ctx.close
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              role: "listbox",
              class: _normalizeClass(_ctx.$style.dropdown),
              style: _normalizeStyle(`--width-size: ${_ctx.dropdownWidth}px`)
            }, [
              _createElementVNode("ul", {
                id: _ctx.ids.autocomplete,
                "aria-label": _ctx.$t('tag.create.autocompleteList'),
                class: _normalizeClass(_ctx.$style.dropdownList),
                onKeydown: [
                  _cache[9] || (_cache[9] = _withKeys(($event: any) => (_ctx.closeAndFocus(`#${_ctx.ids.textInput}`)), ["esc"])),
                  _cache[10] || (_cache[10] = _withKeys(_withModifiers(($event: any) => (_ctx.closeAndFocus(`#${_ctx.ids.addButton}`)), ["prevent"]), ["tab"]))
                ]
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.hints, (hint, index) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: hint,
                    onKeydown: [
                      _withKeys(_withModifiers(($event: any) => (_ctx.highlight(index-1)), ["prevent"]), ["up"]),
                      _withKeys(_withModifiers(($event: any) => (_ctx.highlight(index+1)), ["prevent"]), ["down"]),
                      _cache[7] || (_cache[7] = _withKeys(_withModifiers(($event: any) => (_ctx.closeAndFocus(`#${_ctx.ids.textInput}`)), ["prevent"]), ["left"])),
                      _cache[8] || (_cache[8] = _withKeys(_withModifiers(($event: any) => (_ctx.closeAndFocus(`#${_ctx.ids.addButton}`)), ["prevent"]), ["right"]))
                    ]
                  }, [
                    _createElementVNode("button", {
                      id: `${_ctx.ids.autocomplete}-hint-${index}`,
                      class: _normalizeClass(_ctx.$style.dropdownHint),
                      onClick: ($event: any) => (_ctx.applyTag(hint))
                    }, _toDisplayString(hint), 11, _hoisted_5)
                  ], 40, _hoisted_4))
                }), 128))
              ], 42, _hoisted_3)
            ], 6)
          ], undefined, true),
          _: 1
        }, 8, ["labelId", "initialFocus", "reference", "onClose"]))
      : _createCommentVNode("", true),
    _createVNode(_component_DynamicExpander, {
      numElements: _ctx.tagsForTitle.length,
      panelId: _ctx.ids.appliedPanel,
      headerId: _ctx.title.slug
    }, {
      default: _withCtx(() => [
        (_ctx.tagsForTitle.length)
          ? (_openBlock(), _createBlock(_TransitionGroup, {
              key: 0,
              tag: "ol",
              role: "list",
              "aria-label": _ctx.$t('tag.create.appliedList'),
              name: "list",
              enterFromClass: _ctx.$style.listEnter,
              enterActiveClass: _ctx.$style.listEnterActive,
              leaveActiveClass: _ctx.$style.listLeaveActive,
              leaveToClass: _ctx.$style.listLeaveTo,
              class: _normalizeClass({
          [_ctx.$style.appliedList]: true,
          [_ctx.$style.scrollable] : _ctx.restrictHeight
        })
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tagsForTitle, (tag, index) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: tag.slug,
                    class: _normalizeClass(_ctx.$style.appliedListItem)
                  }, [
                    _createElementVNode("button", {
                      id: `${_ctx.ids.appliedPanel}-tag-${tag.slug}`,
                      class: _normalizeClass(_ctx.$style.applied),
                      onClick: ($event: any) => (_ctx.removeTag(tag, index))
                    }, [
                      _createElementVNode("span", {
                        class: _normalizeClass(_ctx.$style.appliedText)
                      }, _toDisplayString(tag.name), 3),
                      _createVNode(_component_Icon, {
                        name: "dismiss",
                        role: "presentation",
                        class: _normalizeClass(_ctx.$style.dismiss)
                      }, null, 8, ["class"])
                    ], 10, _hoisted_6)
                  ], 2))
                }), 128))
              ], undefined, true),
              _: 1
            }, 8, ["aria-label", "enterFromClass", "enterActiveClass", "leaveActiveClass", "leaveToClass", "class"]))
          : _createCommentVNode("", true)
      ], undefined, true),
      _: 1
    }, 8, ["numElements", "panelId", "headerId"]),
    _createElementVNode("div", null, [
      _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.message), 1)
    ])
  ]))
}