
import { ComponentPublicInstance, defineComponent, onMounted, ref } from 'vue';
import FormSubmitButton from 'app/components/FormSubmitButton.vue';

export default defineComponent({
  name: 'LoginFormExternal',
  components: {
    FormSubmitButton
  },
  setup: () => {
    const button = ref<ComponentPublicInstance | null>(null);

    onMounted(() => {
      (button.value?.$el as HTMLButtonElement)?.focus();
    });

    return {
      button
    };
  }
});
