
import Popout from 'app/components/Popout.vue';
import TagWidget from 'app/components/TagWidget.vue';
import { usePatron } from 'app/functions/use-patron';
import { Tag } from 'app/models/tag';
import { TitleRecord } from 'app/models/title';
import { computed, defineComponent, PropType, ref } from 'vue';

export default defineComponent({
  name: 'TagIndicator',
  components: {
    Popout,
    TagWidget
  },
  props: {
    title: {
      type: Object as PropType<TitleRecord>,
      required: true
    }
  },
  setup: (props) => {
    const tagIcon = ref<HTMLButtonElement | null>(null);

    const showTagWidget = ref(false);

    const { tags } = usePatron();
    const tagCount = computed(() => {
      const forTitle = tags.value
        .filter(Tag.FILTER_FUNCTIONS.nonSystemTags)
        .filter((tag) => tag.isAppliedToTitle(props.title.slug));

      return forTitle.length > 99 ? '99+' : forTitle.length.toString();
    });

    return {
      showTagWidget,
      tagCount,
      tagIcon
    };
  }
});
