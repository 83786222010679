
import { seriesCoverSizes } from 'app/base/constants';
import SeriesCover from 'app/components/SeriesCover.vue';
import { Series } from 'app/models/series';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TitleDetailsSetLink',
  components: {
    SeriesCover
  },
  props: {
    series: {
      type: Series,
      required: true
    }
  },
  setup: () => {
    return {
      seriesCoverSizes
    };
  }
});
