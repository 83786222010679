
import DynamicExpander from 'app/components/DynamicExpander.vue';
import FormCheckbox from 'app/components/FormCheckbox.vue';
import HighlightColor from 'app/components/HighlightColor.vue';
import Icon from 'app/components/Icon.vue';
import RelativeDate from 'app/components/RelativeDate.vue';
import TitleActionButton from 'app/components/TitleActionButton.vue';
import AnnotationDetailsContextMenu from 'app/components/contextMenus/AnnotationDetailsContextMenu.vue';
import ExportQueueContextMenu from 'app/components/contextMenus/ExportQueueContextMenu.vue';
import { SelectableAnnotation, useDisplayAnnotation } from 'app/functions/use-display-annotation';
import { ADAllowStTOption, ContextMenuType } from 'app/keys/injection-keys';
import { FormCheckboxState } from 'app/models/form-checkbox-state';
import { PropType, Ref, computed, defineComponent, inject, nextTick, ref, toRef, watchEffect } from 'vue';

export default defineComponent({
  name: 'AnnotationCard',
  components: {
    AnnotationDetailsContextMenu,
    DynamicExpander,
    ExportQueueContextMenu,
    FormCheckbox,
    HighlightColor,
    Icon,
    RelativeDate,
    TitleActionButton
  },
  props: {
    annotation: {
      type: Object as PropType<SelectableAnnotation>,
      required: true
    },
    selectable: {
      type: Boolean,
      required: true
    },
    hideNote: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    'annotation:update'
  ],
  setup: (props, ctx) => {
    const actions = ref<HTMLElement | null>(null);
    const expansionButton = ref<HTMLButtonElement | null>(null);
    const selectButton = ref<HTMLButtonElement | null>(null);

    const contextMenuType = inject(ContextMenuType, 'annotation-details');

    const showContextMenu = ref(false);
    const showSearchOption = inject(ADAllowStTOption, computed(() => false));

    const { displayAnnotation } = useDisplayAnnotation(toRef(props, 'annotation') as Ref<SelectableAnnotation>);

    const expanded = ref(false);
    const toggleExpansion = async () => {
      expanded.value = !expanded.value;
      await nextTick();
      expansionButton.value?.scrollIntoView({ behavior: 'smooth' });
      expansionButton.value?.focus({ preventScroll: true });
    };

    watchEffect(() => {
      if (props.selectable) {
        expanded.value = false;
      }
    });

    const updateSelected = (checked: FormCheckboxState) => {
      selectButton.value?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      selectButton.value?.focus();
      ctx.emit('annotation:update', { ...props.annotation, selected: checked === 'on' }, checked);
    };

    return {
      actions,
      contextMenuType,
      displayAnnotation,
      expanded,
      expansionButton,
      selectButton,
      showContextMenu,
      showSearchOption,
      toggleExpansion,
      updateSelected
    };
  }
});

