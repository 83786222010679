
import { defineComponent, onMounted } from 'vue';
import { SearchThisTitleQuery } from 'app/base/hudson';
import { Series } from 'app/models/series';
import { TitleRecord } from 'app/models/title';
import SearchThisTitleForm from 'app/components/SearchThisTitleForm.vue';
import { injectOverlayContext } from 'app/contexts/overlay-context';

export default defineComponent({
  name: 'SearchThisTitle',
  components: {
    SearchThisTitleForm
  },
  props: {
    item: {
      type: Object as () => TitleRecord | Series,
      required: true
    },
    params: {
      type: Object as () => SearchThisTitleQuery | null,
      default: null
    }
  },
  setup: (props, ctx) => {
    const { show } = injectOverlayContext('SearchThisTitleSidebar');

    const search = (params: SearchThisTitleQuery) => {
      show({ item: props.item, params: params });
    };

    onMounted(() => {
      if (props.params) {
        show({ item: props.item, params: props.params });
      }
    });

    return {
      search
    };
  }
});
