
import AnnotationTableRow from 'app/components/AnnotationTableRow.vue';
import FormCheckbox from 'app/components/FormCheckbox.vue';
import { SelectableAnnotation } from 'app/functions/use-display-annotation';
import { FormCheckboxState } from 'app/models/form-checkbox-state';
import { computed, defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'AnnotationTable',
  components: {
    AnnotationTableRow,
    FormCheckbox
  },
  props: {
    annotations: {
      type: Array as PropType<SelectableAnnotation[]>,
      required: true
    },
    hideNote: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: true
    }
  },
  emits: [
    'annotations:update'
  ],
  setup: (props, ctx) => {
    const selectionState = computed<FormCheckboxState>(() => {
      const shownAndSelectedCount = props.annotations.filter((a) => a.selected).length;

      return shownAndSelectedCount === props.annotations.length
        ? 'on'
        : shownAndSelectedCount > 0
          ? 'partial'
          : 'off';
    });

    const updateAllAnnotations = (checked: FormCheckboxState) => {
      const copy = props.annotations.slice();
      copy.forEach((a) => a.selected = checked === 'on');

      ctx.emit('annotations:update', copy);
    };

    const updateAnnotation = (index: number, annotation: SelectableAnnotation) => {
      const copy = props.annotations.slice();
      copy[index] = annotation;

      ctx.emit('annotations:update', copy);
    };

    return {
      selectionState,
      updateAllAnnotations,
      updateAnnotation
    };
  }
});
