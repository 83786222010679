
import Popout from 'app/components/Popout.vue';
import { useAppEvents } from 'app/functions/use-app-events';
import { defineComponent, PropType, ref } from 'vue';
import { RouteLocationRaw } from 'vue-router';


/**
 * Base context menu option properties.
 */
export type BaseContextMenuOption = {
  /**
   * Label to display for the option
   */
  label: string;

  /**
   * Name of the icon to display alongside the option label.
   * Used with the Icon component.
   * If absent, no icon will be displayed.
   * @example copy-to-clipboard
   */
  iconName?: string;

  /**
   * Option is not clickable, is grayed out.
   */
  inactive?: Boolean;
};

/**
 * The context menu component button option, used to render a standard button
 * option that emits click events
 */
export type ContextMenuButtonOption = BaseContextMenuOption & {
  /**
   * The handler function for an option click.
   */
  handler: Function;

  /**
   * Optionally attaches an aria-haspopup attribute to the button
   */
  hasPopup?: boolean | 'menu' | 'listbox' | 'tree' | 'grid' | 'dialog';
};

/**
 * The context menu component link option, used to render a standard link
 * option that redirects to a specified location
 */
export type ContextMenuLinkOption = BaseContextMenuOption & {
  /**
   * The location to redirect to on option click.
   */
  location: RouteLocationRaw;
};

export type ContextMenuSlotOption = {
  slotName: string;
};

/**
 * The context menu component option, used to render the standard options of the menu
 */
export type ContextMenuOption = ContextMenuButtonOption | ContextMenuLinkOption | ContextMenuSlotOption;

/**
 * @desc - The base context menu component
 */
export default defineComponent({
  name: 'ContextMenu',
  components: {
    Popout
  },
  props: {
    /**
     * @param reference - The element to anchor this context menu to
     */
    reference: {
      type: HTMLElement,
      required: true
    },
    /**
     * @param options - The options to display in the context menu
     */
    options: {
      type: Array as PropType<ContextMenuOption[]>,
      required: true
    },
    /**
     * @param headerLabel - Label for the context menu
     */
    headerLabel: {
      type: String,
      default: undefined,
      required: false
    },
    /**
     * @param headerIconName - Identifier for the icon to display in the header
     * @example book
     * @see Icon.name property for list of valid values
     */
    headerIconName: {
      type: String,
      default: undefined,
      required: false
    }
  },
  emits: [
    'close'
  ],
  setup: (props, ctx) => {
    const popout = ref<{ update: () => void } | null>(null);

    useAppEvents({
      'router:navigate': () => {
        ctx.emit('close');
      }
    });

    return {
      popout
    };
  }
});
