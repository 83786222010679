
import { FormCheckboxState } from 'app/models/form-checkbox-state';
import { generateUUID } from 'lib/common';
import { computed, defineComponent, PropType } from 'vue';
import Icon from './Icon.vue';

export default defineComponent({
  name: 'FormCheckbox',
  components: {
    Icon
  },
  props: {
    modelValue: {
      type: String as PropType<FormCheckboxState>,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    showLabel: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    description: {
      type: String as PropType<string | undefined>,
      default: undefined
    },
    ariaLabel: {
      type: String,
      default: undefined
    }
  },
  emits: [
    'update:modelValue'
  ],
  setup: (props, ctx) => {
    const id = generateUUID();
    const descriptionId = generateUUID();

    const internalValue = computed({
      get: () => props.modelValue === 'on',
      set: (val) => ctx.emit('update:modelValue', val ? 'on' : 'off')
    });

    const ariaChecked = computed(() => {
      return props.modelValue === 'on' ? 'true'
        : props.modelValue === 'off' ? 'false'
        : 'mixed';
    });

    return {
      ariaChecked,
      descriptionId,
      id,
      internalValue
    };
  }
});
