
import { computed, defineComponent, getCurrentInstance, nextTick, ref, watch } from 'vue';
import { useForRefs } from 'app/functions/use-for-refs';
import { useI18n } from 'app/functions/use-i18n';
import { preserveFocus } from 'app/router/focus-management';

export type Tab = {
  id: string;
  label: string;
  count?: number;
};

export default defineComponent({
  name: 'TabView',
  props: {
    ariaLabel: {
      type: String,
      default: undefined
    },
    tabs: {
      type: Array as () => Tab[],
      required: true
    },
    initialTab: {
      type: String,
      default: undefined
    }
  },
  emits: [
    'tab'
  ],
  setup: (props, ctx) => {
    const { t } = useI18n();

    const { itemRefs: tabElements, setItemRef } = useForRefs(getCurrentInstance());
    const tabIndex = ref(0);
    if (props.initialTab) {
      const index = props.tabs
        .findIndex((tab: Tab) => tab.id === props.initialTab);
      if (index >= 0) {
        tabIndex.value = index;
      }
    }
    const activeTab = computed(() => props.tabs[tabIndex.value]!.id);

    const list = ref<HTMLElement | null>(null);

    const changeFocusToLeft = () => {
      tabIndex.value = tabIndex.value === 0
        ? props.tabs.length - 1
        : tabIndex.value - 1;
    };

    const changeFocusToRight = () => {
      tabIndex.value = tabIndex.value === props.tabs.length - 1
        ? 0
        : tabIndex.value + 1;
    };

    watch(tabIndex, () => {
      focus();
      scrollToHighlighted();
      ctx.emit('tab', props.tabs[tabIndex.value].id);
    });

    watch(() => props.initialTab, (newTab) => {
      if (newTab !== props.tabs[tabIndex.value].id) {
        tabIndex.value = props.tabs.findIndex((tab: Tab) => tab.id === newTab);
      }
    });

    const scrollToHighlighted = () => {
      const element = tabElements[tabIndex.value];

      if (!element || !list.value) { return; };

      const firstTabOffset = tabElements[0]?.offsetLeft || 0;

      if (
        element.offsetLeft < (list.value.scrollLeft || 0)
        || (element.offsetLeft + element.offsetWidth) > (list.value.clientWidth + list.value.scrollLeft)
      ) {
        list.value.scrollLeft = element.offsetLeft - firstTabOffset;
      }
    };

    // Public
    const focus = async () => {
      await nextTick();
      tabElements[tabIndex.value]?.focus(); //focus if route doesn't change
      preserveFocus(list.value?.children[tabIndex.value]); //to preserve focus if route does change
    };

    const tabLabel = (tab: Tab) => {
      return (typeof tab.count === 'number')
        ? t('form.tabs.tabWithCount', { n: tab.count.toString(), label: tab.label })
        : tab.label;
    };

    return {
      activeTab,
      list,
      changeFocusToLeft,
      changeFocusToRight,
      focus,
      setItemRef,
      tabIndex,
      tabLabel
    };
  }
});
