
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'FormError',
  props: {
    contents: {
      type: String,
      required: true
    }
  }
});
