
import { APP } from 'app/base/app';
import { C } from 'app/base/common';
import { defineComponent, ref, onMounted, computed, onBeforeUnmount, nextTick } from 'vue';
import { RecentlyReadTitle } from 'app/models/recently-read-title';
import { TitleRecord } from 'app/models/title';
import EmptyState from 'app/components/EmptyState.vue';
import RecentlyReadTitleCard from 'app/components/RecentlyReadTitleCard.vue';
import { Scroller } from 'app/views/core/scroller';
import { Constants } from 'app/base/constants';
import { usePatron } from 'app/functions/use-patron';

export default defineComponent({
  name: 'RecentlyRead',
  components: {
    EmptyState,
    RecentlyReadTitleCard
  },
  setup: (props, ctx) => {
    const helpLink = Constants.HELP_PATHS.GET_STARTED;
    const MAX_TITLES = 6;
    const carouselList = ref<HTMLElement | null>(null);
    const curtain = ref<HTMLElement | null>(null);

    const { recentlyRead } = usePatron();
    const recentlyReadTitleIDs = computed(() => {
      return (recentlyRead.value as RecentlyReadTitle[])
        .sort(RecentlyReadTitle.SORT_FUNCTIONS.accessed)
        .slice(0, MAX_TITLES)
        .map((t) => t.titleSlug);
    });
    const recentlyReadTitles = ref<TitleRecord[] | null>(null);

    const numberPages = ref(1);
    const currentPage = ref(1);
    const slideWidth = 304;
    let slidesPerPage = 1;

    const recalculatePaging = (evt = null) => {
      calculatePages();
      calculateCurrentPage();
    };

    const calculatePages = () => {
      if (!carouselList.value || !carouselList.value.children.length) { return; }

      const childCount = recentlyReadTitles.value?.length || 0;
      slidesPerPage = Math.max(1, Math.floor(carouselList.value.offsetWidth / slideWidth));
      numberPages.value = Math.ceil(childCount / slidesPerPage);
      const leftoverSpace = carouselList.value.offsetWidth - slidesPerPage * slideWidth;
      const extraSlides = slidesPerPage - ((childCount % slidesPerPage) || slidesPerPage);
      const leftoverSpaceWithEmptyCards = leftoverSpace + slideWidth * extraSlides;
      const lastChild = carouselList.value.children[carouselList.value.children.length - 1] as HTMLElement;
      lastChild.style.paddingRight = `calc(${leftoverSpaceWithEmptyCards}px + 48px)`;
      lastChild.style.width = slideWidth + leftoverSpaceWithEmptyCards + '8px';

      if (curtain.value) {
        curtain.value.style.width = slidesPerPage === 1 ? '2rem' : leftoverSpace + 'px';
      }
    };

    const calculateCurrentPage = () => {
      const carousel = carouselList.value;
      if (!carousel) { return; }

      const pageWidth = slidesPerPage * slideWidth;
      const normalizedPage = Math.ceil(carousel.scrollLeft / pageWidth - 0.5);
      currentPage.value = Math.max(0, Math.min(normalizedPage + 1, numberPages.value));
    };

    const staggeredPaging = C.staggerInvocation(recalculatePaging, 250);
    const staggeredCurrentPage = C.staggerInvocation(calculateCurrentPage, 100);

    const nextPage = C.staggerInvocation(() => {
      scrollToPage(currentPage.value + 1);
    }, Scroller.DEFAULT_DURATION);

    const previousPage = C.staggerInvocation(() => {
      scrollToPage(currentPage.value - 1);
    }, Scroller.DEFAULT_DURATION);

    const goToPage = (i: number) => {
      scrollToPage(i);
    };

    const onSlideFocus = (slideIndex: number) => {
      const currentPageSlideIndex = (currentPage.value - 1) * slidesPerPage;
      // No need to handle previousPage() case (slideIndex < currentPageSlideIndex)
      if (slideIndex > currentPageSlideIndex + slidesPerPage - 1) {
        scrollToPage(currentPage.value + 1);
      }
    };


    let animationCancel = { id: 0 };
    const scrollToPage = (n: number) => {
      if (!carouselList.value) { return; }

      window.cancelAnimationFrame(animationCancel.id);

      animationCancel = Scroller.smoothScroll(
        (slideWidth * slidesPerPage) * (n - 1),
        'x',
        Scroller.DEFAULT_DURATION,
        carouselList.value
      );
    };


    const resizeHandler = () => staggeredPaging();
    const orientationHandler = () => recalculatePaging();
    const scrollHandler = () => staggeredCurrentPage();

    onMounted (async () => {
      // Data
      recentlyReadTitles.value = await APP.titleCache.getFreshTitles(recentlyReadTitleIDs.value);

      // Event listeners
      window.addEventListener('resize', resizeHandler);
      window.addEventListener('orientationchange', orientationHandler);
      carouselList.value?.addEventListener('scroll', scrollHandler);

      nextTick(() => recalculatePaging(null));
    });

    onBeforeUnmount (() => {
      window.removeEventListener('resize', resizeHandler);
      window.removeEventListener('orientationchange', orientationHandler);
      carouselList.value?.removeEventListener('scroll', scrollHandler);
    });

    return {
      carouselList,
      curtain,
      helpLink,

      nextPage,
      previousPage,
      goToPage,
      onSlideFocus,

      currentPage,
      numberPages,

      recentlyReadTitles
    };
  }
});
