import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["aria-label"]
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TitleDetailsContextMenuButton = _resolveComponent("TitleDetailsContextMenuButton")!
  const _component_Cover = _resolveComponent("Cover")!
  const _component_TitleActionButton = _resolveComponent("TitleActionButton")!
  const _component_TitleDetailsAnnotationsLink = _resolveComponent("TitleDetailsAnnotationsLink")!
  const _component_CopyLink = _resolveComponent("CopyLink")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_TagWidget = _resolveComponent("TagWidget")!
  const _component_TitleDetailsSeriesLink = _resolveComponent("TitleDetailsSeriesLink")!
  const _component_TitleDetailsAccordion = _resolveComponent("TitleDetailsAccordion")!
  const _component_SearchThisTitle = _resolveComponent("SearchThisTitle")!
  const _component_TitleCard = _resolveComponent("TitleCard")!
  const _component_DetailsPageContent = _resolveComponent("DetailsPageContent")!

  return (_openBlock(), _createBlock(_component_DetailsPageContent, {
    title: _ctx.title.title,
    subtitle: _ctx.title.subtitle,
    authors: _ctx.title.creators.Author,
    metadata: _ctx.metadata
  }, {
    context: _withCtx(() => [
      _createVNode(_component_TitleDetailsContextMenuButton, {
        title: _ctx.title,
        class: _normalizeClass(_ctx.$style.contextMenu)
      }, null, 8, ["title", "class"])
    ]),
    cover: _withCtx(() => [
      _createVNode(_component_Cover, {
        item: _ctx.title,
        class: _normalizeClass(_ctx.$style.cover)
      }, null, 8, ["item", "class"])
    ]),
    pillar: _withCtx(() => [
      _createVNode(_component_TitleActionButton, {
        title: _ctx.title,
        class: _normalizeClass(_ctx.$style.action),
        display: "button"
      }, null, 8, ["title", "class"]),
      (_ctx.annotationCount > 0 && _ctx.title.mediaType !== 'audiobook')
        ? (_openBlock(), _createBlock(_component_TitleDetailsAnnotationsLink, {
            key: 0,
            titleSlug: _ctx.title.slug,
            annotationCount: _ctx.annotationCount
          }, null, 8, ["titleSlug", "annotationCount"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.interactContainer)
      }, [
        _createVNode(_component_CopyLink, {
          headerLabel: "copyLink.label",
          class: _normalizeClass([_ctx.$style.interactButton, _ctx.$style.copyLink])
        }, null, 8, ["class"]),
        _createElementVNode("button", {
          class: _normalizeClass([_ctx.$style.interactButton, _ctx.$style.subscribe]),
          "aria-label": _ctx.subscribeTextLong,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleSubscription()))
        }, [
          _createElementVNode("div", null, [
            _createVNode(_component_Icon, {
              class: _normalizeClass(_ctx.$style.bell),
              name: "alert-bell"
            }, null, 8, ["class"])
          ]),
          _createElementVNode("span", null, _toDisplayString(_ctx.subscribeTextShort), 1)
        ], 10, _hoisted_1)
      ], 2),
      _createElementVNode("div", null, [
        _createElementVNode("h2", {
          class: _normalizeClass(_ctx.$style.headerLabel)
        }, [
          _createVNode(_component_Icon, {
            name: "nav-tags",
            class: _normalizeClass(_ctx.$style.headerIcon)
          }, null, 8, ["class"]),
          _createTextVNode(" " + _toDisplayString(_ctx.$t('tags.header')), 1)
        ], 2),
        _createVNode(_component_TagWidget, { title: _ctx.title }, null, 8, ["title"])
      ]),
      (_ctx.title.seriesId)
        ? (_openBlock(), _createBlock(_component_TitleDetailsSeriesLink, {
            key: 1,
            series: _ctx.series
          }, null, 8, ["series"]))
        : _createCommentVNode("", true)
    ]),
    body: _withCtx(() => [
      _createElementVNode("section", null, [
        _createVNode(_component_TitleDetailsAccordion, {
          title: _ctx.title,
          series: _ctx.series
        }, null, 8, ["title", "series"])
      ]),
      (_ctx.title.isLexisPublished
          && _ctx.title.hasODRFormat
          && _ctx.title.mediaType === 'book'
        )
        ? (_openBlock(), _createElementBlock("section", _hoisted_2, [
            _createElementVNode("h2", {
              class: _normalizeClass(_ctx.$style.sectionHead)
            }, _toDisplayString(_ctx.$t('searchThisTitle.header.title')), 3),
            _createVNode(_component_SearchThisTitle, {
              item: _ctx.title,
              params: _ctx.searchThisTitle
            }, null, 8, ["item", "params"])
          ]))
        : _createCommentVNode("", true)
    ]),
    footer: _withCtx(() => [
      (_ctx.recommended.length)
        ? (_openBlock(), _createElementBlock("section", {
            key: 0,
            class: _normalizeClass(_ctx.$style.recommended)
          }, [
            _createElementVNode("h2", {
              class: _normalizeClass(_ctx.$style.sectionHead)
            }, _toDisplayString(_ctx.$t('title.recommended')), 3),
            _createElementVNode("ul", {
              class: _normalizeClass(_ctx.$style.cardGrid)
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.recommended, (item) => {
                return (_openBlock(), _createElementBlock("ol", {
                  key: item.slug
                }, [
                  _createVNode(_component_TitleCard, { title: item }, null, 8, ["title"])
                ]))
              }), 128))
            ], 2)
          ], 2))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["title", "subtitle", "authors", "metadata"]))
}