import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_FilterSidebar = _resolveComponent("FilterSidebar")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("button", {
      type: "button",
      class: _normalizeClass(_ctx.$style.filter),
      "aria-label": _ctx.$t('filters.ariaLabels.button', { NUMBER: _ctx.filterCount }),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showFilterSidebar = true))
    }, [
      (!_ctx.iconOnly)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: _normalizeClass(_ctx.$style.text)
          }, _toDisplayString(_ctx.$t('filters.header')), 3))
        : _createCommentVNode("", true),
      _createVNode(_component_Icon, {
        name: "refine-list",
        class: _normalizeClass(_ctx.$style.icon)
      }, null, 8, ["class"]),
      (_ctx.filterCount)
        ? (_openBlock(), _createElementBlock("span", {
            key: 1,
            class: _normalizeClass(_ctx.$style.count)
          }, _toDisplayString(_ctx.filterCount), 3))
        : _createCommentVNode("", true)
    ], 10, _hoisted_1),
    _createVNode(_component_FilterSidebar, {
      options: _ctx.options,
      categoryObjects: _ctx.categoryObjects,
      show: _ctx.showFilterSidebar,
      onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showFilterSidebar = false)),
      onApply: _ctx.applyFilters
    }, null, 8, ["options", "categoryObjects", "show", "onApply"])
  ]))
}