import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GdprForm = _resolveComponent("GdprForm")!
  const _component_ModalDialog = _resolveComponent("ModalDialog")!

  return (_openBlock(), _createBlock(_component_ModalDialog, {
    ref: "modal",
    heading: _ctx.$t('gdpr.modal.header'),
    headingClass: _ctx.$style.heading,
    dismissable: false,
    onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_GdprForm, {
        defaultSetting: _ctx.defaultSetting,
        onSubmit: _ctx.onSubmit
      }, null, 8, ["defaultSetting", "onSubmit"])
    ], undefined, true),
    _: 1
  }, 8, ["heading", "headingClass"]))
}