
import { defineComponent, onMounted, ref } from 'vue';
import { Series } from 'app/models/series';
import { Title, TitleRecord } from 'app/models/title';
import TitleCard from 'app/components/cards/TitleCard.vue';

export default defineComponent({
  name: 'TitleDetailsAccordionVolumes',
  components: {
    TitleCard
  },
  props: {
    series: {
      type: Series,
      required: true
    }
  },
  setup: (props) => {
    const volumes = ref<TitleRecord[] | null>(null);

    onMounted(async () => {
      const volumeObjects = await props.series.getAllTitleObjects();

      volumes.value = props.series.items
        .map((item) => volumeObjects[item.id])
        .filter((volume) => !!volume)
        .sort(Title.SORT_FUNCTIONS.seriesRank);
    });

    return {
      volumes
    };
  }
});
