
import { APP } from 'app/base/app';
import { C } from 'app/base/common';
import { getBestQueryFromExcerpt } from 'app/base/hudson';
import Popout from 'app/components/Popout.vue';
import ShareLink from 'app/components/ShareLink.vue';
import { defineComponent, onMounted, ref } from 'vue';

export type MatchInfo = {
  titleSlug: string;
  buid: string;
  path: string;
  excerpt: string;
};

type LinkState =
  | {
    state: 'loading';
  }
  | {
    state: 'error';
  }
  | {
    state: 'loaded';
    link: string;
  };

export default defineComponent({
  name: 'SharePassage',
  components: {
    Popout,
    ShareLink
  },
  props: {
    match: {
      type: Object as () => MatchInfo,
      required: true
    },
    reference: {
      type: HTMLElement,
      required: true
    }
  },
  emits: [
    'close'
  ],
  setup: (props, ctx) => {
    const linkState = ref<LinkState>({ state: 'loading' });

    onMounted(async () => {
      const link = await getShareLink(props.match);
      if (link) {
        linkState.value = {
          state: 'loaded',
          link
        };
      } else {
        linkState.value = {
          state: 'error'
        };
      }
    });

    return {
      linkState
    };
  }
});

async function getShareLink(match: MatchInfo): Promise<string | null> {
  const query = getBestQueryFromExcerpt(match.excerpt);
  try {
    const anchorResponse = await APP.sentry.searchAnchorByBUID(match.buid, query, match.path);
    if (!anchorResponse?.result?.token) {
      return null;
    }

    return C.parameterizeURL(
      `${APP.client.info.url}/library/${APP.library.baseKey}/open/${match.titleSlug}`,
      {
        a: anchorResponse.result.token
      }
    );
  } catch (e) {
    console.error('[SHARE-PASSAGE] Unable to construct anchor link', e);
    APP.sage.submit('error', {
      errorMessage: e.name + ': ' + e.message,
      errorSource: 'SharePassage#getShareLink',
      errorData: {
        message: e.name + ': ' + e.message
      },
      submissionContext: {
        library: APP.library
      }
    });

    return null;
  }
}
