
import { defineComponent, ref, onBeforeUnmount } from 'vue';
import RelativeDate from 'app/components/RelativeDate.vue';
import Cover from 'app/components/Cover.vue';
import { Title } from 'app/models/title';
import { APP } from 'app/base/app';

export default defineComponent({
  name: 'HomeAnnotationCard',
  components: {
    RelativeDate,
    Cover
  },
  props: {
    title: {
      type: Title,
      required: true
    }
  },
  setup: (props) => {
    const numAnnotations = ref(0);
    const timestamp = ref(0);

    const fillOut = () => {
      const notes = props.title.annotations();

      numAnnotations.value = notes?.length || 0;
      timestamp.value = notes?.[0]?.syncstamp || 0;
    };

    const handler = APP.events.on('annotation:update:all', fillOut);
    fillOut();

    onBeforeUnmount(() => {
      handler.deafen();
    });

    return {
      numAnnotations,
      timestamp
    };
  }
});
