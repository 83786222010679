
import TitleActionButton from 'app/components/TitleActionButton.vue';
import TitleCard from 'app/components/cards/TitleCard.vue';
import { TitleRecord } from 'app/models/title';
import { PropType, computed, defineComponent } from 'vue';


export default defineComponent({
  name: 'AlertNewReleaseDetails',
  components: {
    TitleActionButton,
    TitleCard
  },
  props: {
    name: {
      type: String,
      required: true
    },
    titles: {
      type: Object as PropType<TitleRecord[]>,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  setup: (props) => {
    const typeName = computed(() => 'alert.type.'+props.type);

    return {
      typeName
    };
  }
});
