import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormSelect = _resolveComponent("FormSelect")!
  const _component_FormSearch = _resolveComponent("FormSearch")!

  return (_openBlock(), _createElementBlock("form", {
    class: _normalizeClass(_ctx.$style.form),
    onSubmit: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)), ["prevent"]))
  }, [
    _createElementVNode("label", {
      id: _ctx.filtersId,
      class: _normalizeClass(_ctx.$style.label)
    }, _toDisplayString(_ctx.$t('searchThisTitle.labels.filters')), 11, _hoisted_1),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.dropdowns)
    }, [
      _createVNode(_component_FormSelect, {
        modelValue: _ctx.searchType,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchType) = $event)),
        options: _ctx.searchTypes,
        optionKey: (s) => s.id,
        optionLabel: "label",
        labelId: _ctx.filtersId
      }, null, 8, ["modelValue", "options", "optionKey", "labelId"]),
      (_ctx.isInSet)
        ? (_openBlock(), _createBlock(_component_FormSelect, {
            key: 0,
            modelValue: _ctx.searchScope,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.searchScope) = $event)),
            options: _ctx.searchScopes,
            optionKey: (s) => s.id,
            optionLabel: "label",
            labelId: _ctx.filtersId
          }, null, 8, ["modelValue", "options", "optionKey", "labelId"]))
        : (_openBlock(), _createElementBlock("p", {
            key: 1,
            class: _normalizeClass(_ctx.$style.scope)
          }, _toDisplayString(_ctx.searchScope.label), 3))
    ], 2),
    _createElementVNode("label", {
      id: _ctx.queryId,
      class: _normalizeClass(_ctx.$style.label)
    }, _toDisplayString(_ctx.$t('searchThisTitle.labels.search')), 11, _hoisted_2),
    _createVNode(_component_FormSearch, {
      modelValue: _ctx.searchTerm,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.searchTerm) = $event)),
      label: _ctx.$t('searchThisTitle.labels.query'),
      labelId: _ctx.queryId,
      placeholder: _ctx.$t(`searchThisTitle.searchPlaceholder.${_ctx.item.mediaType === 'series' ? 'series' : 'title'}`),
      class: _normalizeClass(_ctx.$style.search)
    }, null, 8, ["modelValue", "label", "labelId", "placeholder", "class"])
  ], 34))
}