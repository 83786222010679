
import ExportQueueTableRow from 'app/components/ExportQueueTableRow.vue';
import { SelectableAnnotation } from 'app/functions/use-display-annotation';
import { FormCheckboxState } from 'app/models/form-checkbox-state';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'AnnotationTable',
  components: {
    ExportQueueTableRow
  },
  props: {
    annotations: {
      type: Array as PropType<SelectableAnnotation[]>,
      required: true
    },
    hideNote: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: true
    }
  },
  emits: [
    'annotations:update'
  ],
  setup: (props, ctx) => {
    const updateAnnotation = (checked: FormCheckboxState, uuid: string) => {

      ctx.emit('annotations:update', checked, uuid);
    };

    return {
      updateAnnotation
    };
  }
});
