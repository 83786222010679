import { APP } from 'app/base/app';
import { PostishCopyAnnotationSnapshotResponse } from 'app/base/postish';
import { useAppEvents } from 'app/functions/use-app-events';
import { isRef, readonly, Ref, ref, watch } from 'vue';
import { useRoute } from 'vue-router';


export function useCopyAnnotationSnapshots(jobIdRaw: Ref<string | null> | string) {
  const jobId = isRef(jobIdRaw) ? jobIdRaw : ref(jobIdRaw);

  const copyAnnotationSnapshots = ref<PostishCopyAnnotationSnapshotResponse | null>(null);
  const loading = ref<boolean>(true);
  const error = ref<boolean>(false);

  const timer = ref<ReturnType<typeof setTimeout> | null>(null);

  const update = async () => {
    if (!jobId.value) { return; }

    try {
      loading.value = true;

      const response = await APP.services.postish.getCopyAnnotationSnapshots(jobId.value);
      if (!response) { throw new Error; }
      copyAnnotationSnapshots.value = response;

      error.value = false;
    } catch {
      error.value = true;
    } finally {
      loading.value = false;
    }
  };

  watch(jobId, update, { immediate: true });

  const poll = () => {
    return setTimeout(async () => {
      await update();

      timer.value = poll();
    }, 2000);
  };

  const stopPolling = () => {
    if (timer.value) {
      clearTimeout(timer.value);
      timer.value = null;
    }
  };

  const startPolling = () => {
    timer.value = poll();

    const route = useRoute();
    watch(route, stopPolling);
  };

  if (!APP.patron.accountId || !APP.sentry.identityToken) {
    useAppEvents({
      'patron:accountId:acquired': () => update()
    });
  }

  return {
    copyAnnotationSnapshots: readonly(copyAnnotationSnapshots),
    loading: readonly(loading),
    error: readonly(error),
    update,
    startPolling,
    stopPolling
  };

}
