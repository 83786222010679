import { APP } from 'app/base/app';
import MemoryStorage from 'app/base/storage/memory-storage';
import TypedStorage from 'app/base/storage/typed-storage';
import { TempTitleCacheSymbol } from 'app/keys/injection-keys';
import { BankedTitle, TitleMapper, TitleRecord } from 'app/models/title';
import { Ref, inject, isRef, readonly, ref, watch } from 'vue';

export function useTitles(titleIdsRaw: Ref<Set<string>> | Set<string>, saveToCache = true) {
  const tempTitleCache = inject(
    TempTitleCacheSymbol,
    new TypedStorage<BankedTitle>(new MemoryStorage())
  );

  const titleIds = isRef(titleIdsRaw) ? titleIdsRaw : ref(titleIdsRaw);
  const titles = ref<TitleRecord[] | undefined>();
  const loading = ref<boolean>(false);
  const error = ref<boolean>(false);


  const update = async () => {
    if (!titleIds.value) { return; }

    try {
      loading.value = true;

      const fetchedTitles: TitleRecord[] = [];
      const idsToFetch: string[] = [];

      titleIds.value.forEach((titleId) => {
        let cachedTitle: TitleRecord | null = null;

        const storedTitle = tempTitleCache.getItem(titleId);
        if (storedTitle) {
          cachedTitle = TitleMapper.mapFromBank(storedTitle, true);
        }

        if (!cachedTitle) {
          cachedTitle = APP.titleCache.getIfFresh(titleId) || null;
        }

        if (cachedTitle) {
          fetchedTitles.push(cachedTitle);
        } else {
          idsToFetch.push(titleId);
        }
      });

      if (idsToFetch.length > 0) {
        const thunderTitles = await APP.services.thunder.getTitles(APP.library.key(), idsToFetch);
        if (!thunderTitles) { throw new Error; }

        thunderTitles.items.forEach((title) => {
          const mappedTitle = TitleMapper.mapFromThunder(title);

          if (!mappedTitle) { throw new Error; }

          fetchedTitles.push(mappedTitle);

          const bankedTitle = mappedTitle.serialize() as BankedTitle;
          tempTitleCache.setItem(title.id, bankedTitle);
        });
      }

      titles.value = fetchedTitles;

      error.value = false;
    } catch {
      error.value = true;
    } finally {
      loading.value = false;
    }
  };

  watch(titleIds, update, { immediate: true });

  return {
    titles: readonly(titles),
    loading: readonly(loading),
    error: readonly(error)
  };

}
