import { APP } from 'app/base/app';
import { PostishCopyTitleSnapshot } from 'app/base/postish';
import { announceMessage } from 'app/functions/use-chatterbox';
import { useI18n } from 'app/functions/use-i18n';
import { getReleaseDisplayNameCopyJob } from 'app/functions/use-release-display-name';
import { RouteName } from 'app/router/constants';
import router from 'app/router/router';

const { t } = useI18n();

export function startCopy(titleId: string, releaseId: string) {
  return APP.services.postish.startCopyJob(titleId, releaseId);
};


export type CopyUpdateType = 'review' | 'dismiss' | 'retry' | 'clear';

export function updateCopyJob(jobId: string | number, updateType: CopyUpdateType) {
  const id = typeof jobId === 'number' ? jobId.toString() : jobId;

  if (updateType === 'clear') {
    announceMessage(t('annotationCopying.banner.ariaLabels.clear'));
    APP.services.postish.deleteCopyJob(id);

    return undefined;
  }

  if (updateType === 'retry') {
    announceMessage(t('annotationCopying.banner.ariaLabels.retry'));

    return APP.services.postish.retryCopyJob(id);
  }

  if (updateType === 'review') {
    router.push({
      name: RouteName.CopyReview,
      params: {
        jobId: id
      }
    });
  }

  announceMessage(t('annotationCopying.banner.ariaLabels.dismiss'));

  return APP.services.postish.reviewCopyJob(id);
}


export function getReleaseLabel(source: PostishCopyTitleSnapshot, target: PostishCopyTitleSnapshot) {

  return t('annotationCopying.releaseLabel', {
    SOURCE: getReleaseDisplayNameCopyJob(source),
    TARGET: getReleaseDisplayNameCopyJob(target)
  });
};
