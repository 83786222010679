
import { defineComponent } from 'vue';
import Prompt from './Prompt.vue';

export default defineComponent({
  name: 'NewReleasePrompt',
  components: {
    Prompt
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    titleId: {
      type: String,
      default: 'titleId'
    }
  },
  emits: [
    'open'
  ]
});
