
import { FocusTrap, createFocusTrap } from 'focus-trap';
import { VNode, defineComponent, nextTick, onBeforeUnmount, onMounted, onUpdated } from 'vue';

export default defineComponent({
  name: 'FocusTrap',
  props: {
    initialFocus: {
      type: String,
      default: undefined
    },
    allowOutsideClick: {
      type: Boolean,
      default: true
    },
    clickOutsideDeactivates: {
      type: Boolean,
      default: false
    },
    escapeDeactivates: {
      type: Boolean,
      default: true
    },
    /**
     * If the focus trap is used in a modal
     * that should be able to be closed by clicking
     * on a shield element, use this prop
     * for the id of that shield.
     */
    shieldId: {
      type: String,
      default: undefined
    }
  },
  emits: [
    'deactivate',
    'updated'
  ],
  setup: (props, ctx) => {
    let slot: VNode[];
    let trap: FocusTrap;

    /**
     * Most recent HTML being trapped
     * @note FocusTrap needs to maintain a copy of it's last known HTML
     * so that in can differienate between its parent component updates
     * and it's own updates. Otherwise, a parent update can cascade to
     * child components and break the FocusTrap functionality.
     * @see {@link https://forum.vuejs.org/t/child-component-updated-method-is-affected-by-parent-changes/23286}
     */
    let elmHTML = '';

    onMounted(async () => {
      const elm = slot[0].el as HTMLElement;
      elmHTML = elm.innerHTML;

      trap = createFocusTrap(elm,
        {
          onDeactivate: () => ctx.emit('deactivate'),
          allowOutsideClick: ({ target }) => {
            return props.allowOutsideClick
              || (!!props.shieldId
                && target instanceof HTMLElement
                && target.id === props.shieldId);
          },
          escapeDeactivates: props.escapeDeactivates,
          clickOutsideDeactivates: props.clickOutsideDeactivates,
          initialFocus: props.initialFocus
        }
      );

      await nextTick();
      trap.activate();
    });

    onUpdated(() => {
      const curElm = slot[0].el as HTMLElement;
      if (curElm.innerHTML !== elmHTML) {
        elmHTML = curElm.innerHTML;
        trap.pause();
        trap.unpause();
      }
      ctx.emit('updated');
    });


    onBeforeUnmount(() => {
      try {
        trap.deactivate();
      } catch (e) {
        // no-op
      }
    });

    return () => {
      slot = ctx.slots[Object.keys(ctx.slots)[0]]!();
      if (!slot || !slot.length || slot.length !== 1) {
        throw new Error('FocusTrap requires exactly one child.');
      }

      return slot[0];
    };
  }
});
