import { APP } from 'app/base/app';
import { C } from 'app/base/common';
import { PostishAnnotation } from 'app/base/postish';
import { Annotation, AnnotationsAttrs } from 'app/models/annotation';
import { Collation } from 'app/models/collation';
import { DervishActivity, DervishActivityEvent } from '../base/interfaces';
import { ScribeTitle, TitleMapper, TitleRecord } from './title';


interface Recording {
  operation: 'create' | 'update' | 'delete';
  annotation: RecordingDetail;
}

export type BifocalMessage = {
  title: ScribeTitle;
  message_id?: string;
  created_by?: string;
  deletedUuids?: string[];
};


/**
 * A Collation of Annotations that merges saved Annotations from Postish with
 * recorded changes from Bifocal.
 *
 * There is an assumption that Bifocal events will be sent to Postish in order.
 * Any recordings older than the newest Annotation in Postish will be
 * discarded.
 */
export class Annotations extends Collation<Annotation> {
  protected ITEM_CLASS = Annotation;
  protected ITEM_NAME = 'annotation';
  protected SERIAL_VERSION = 1;
  protected SERIAL_VERSION_MIN = 1;

  private _recordings: Recording[];
  private _syncedAnnotations: PostishAnnotation[];


  constructor() {
    super();

    this._recordings = [];
    this._syncedAnnotations = [];

    APP.events.on('msg:dervish:activity:record', (evt) => this._processDervishActivity(evt.m));
  }


  public sync(itemsAttributes: PostishAnnotation[]): Annotation[] {
    // Update the stored list of items we are sure have been synced to the
    // server. First merge all of the items in the response into the list.
    // Then, remove items that are no longer in the response.
    // Also keep track of the removed UUIDs so we can use them later to build
    // the return value.
    const { allUuids, newUuids } = this._mergeIntoSynced(itemsAttributes);
    const itemsToRemove = [];
    const removedUuids = [];
    C.each(this._syncedAnnotations, (syncedAnnotation) => {
      if (allUuids.indexOf(syncedAnnotation.uuid) < 0) {
        itemsToRemove.push(syncedAnnotation);
        removedUuids.push(syncedAnnotation.uuid);
      }
    });
    C.each(itemsToRemove, (item) => C.excise(this._syncedAnnotations, item));

    // Build a list of annotations from the derived content with the items
    // that will be removed
    const syncResult = [];
    C.each(this.all, (builtItem) => {
      if (removedUuids.indexOf(builtItem.uuid) > -1) {
        syncResult.push(builtItem);
      }
    });

    // Rebuild the derived content using the new synced annotations list, which
    // will actually remove the items.
    this._buildCollationContent();

    // Announce events and save
    C.each(this.all, (item) => {
      if (newUuids.indexOf(item.uuid) > -1) {
        this._announce('add', { item: item });
      }
    });
    C.each(syncResult, (item) => {
      this._announce('remove', { item: item });
    });
    this.save();

    return syncResult;
  }


  public subsume(itemsAttributes: PostishAnnotation[]): Annotation[] {
    // Merge into syncedAnnotations
    const { allUuids, newUuids } = this._mergeIntoSynced(itemsAttributes);

    // Rebuild the derived content
    this._buildCollationContent();

    // Build a list of annotations from the built content
    const subsumeResult = [];
    C.each(this.all, (builtItem) => {
      if (allUuids.indexOf(builtItem.uuid) > -1) {
        subsumeResult.push(builtItem);
      }
    });

    // Announce events for new items and save
    C.each(subsumeResult, (item) => {
      if (newUuids.indexOf(item.uuid) > -1) {
        this._announce('add', { item: item });
      }
    });
    this.save();

    return subsumeResult;
  }


  public make(itemAttributes: PostishAnnotation, stubbed = false): Annotation {
    this._syncedAnnotations.push(itemAttributes);
    this._buildCollationContent();

    let makeResult: Annotation;
    C.each(this.all, (annotation) => {
      if (annotation.uuid === itemAttributes.uuid) {
        makeResult = annotation;
      }
    });
    this._makingItem(makeResult, itemAttributes, stubbed);
    this._announce('add', { item: makeResult });
    this.save();

    return makeResult;
  }


  public remove(itemQuery): void {
    const annotation = this.find(itemQuery);
    if (!annotation) {
      console.warn(
        '[COLLATION] Could not remove missing',
        this.ITEM_NAME,
        itemQuery
      );
    }

    const syncedAttributes = this._findSyncedAnnotationAttributes(annotation.uuid);
    if (!syncedAttributes) {
      console.warn(
        '[COLLATION] Could not remove missing',
        this.ITEM_NAME,
        itemQuery
      );
    }

    C.excise(this._syncedAnnotations, syncedAttributes);
    this._buildCollationContent();
  }


  public removeItem(item: Annotation): void {
    const syncedAttributes = this._findSyncedAnnotationAttributes(item.uuid);
    C.excise(this._syncedAnnotations, syncedAttributes);
    item.removedFromCollation = true;
    item.removed();
    this._buildCollationContent();
    this._announce('remove', { item: item });
    this.save();
}


  public removeAll(): void {
    this._syncedAnnotations = [];
    this._buildCollationContent();
    this.save();
  }


  public serialize(): AnnotationsSerialized {
    return {
      recordings: this._recordings,
      syncedAnnotations: this._syncedAnnotations
    };
  }


  public deserialize(collationAttributes: AnnotationsSerialized) {
    if (!collationAttributes) {
      return this;
    }

    this._recordings = collationAttributes.recordings || [];
    this._syncedAnnotations = collationAttributes.syncedAnnotations;
    this._buildCollationContent();

    return this;
  }


  public filterDisplayableAnnotations(filterQuery?: unknown): Annotation[] {
    return this.filter(filterQuery)
      .filter((ann) => this._isDisplayableAnnotation(ann));
  }


  /**
   * Get all annotations, regardless of displayability, without sorting
   */
  public getSerializedByBuidOrActiveTitle(): AnnotationsAttrs[] {
    const buid = this._getBuid();

    const activeTitle = APP.activeTitle.title;
    if (!activeTitle) {
      return [];
    }

    const { lexisMetadata, slug } = activeTitle;
    const { release = null, releaseDate = null, parent = null } = lexisMetadata || {};

    const matching = this._allAnnotationsExcludingMigrations().filter((annotation) => {
      if (annotation.buid && annotation.buid === buid) {
        return true;
      }

      if (annotation.titleSlug === (parent || slug) &&
          annotation.release === release &&
          annotation.releaseDate === releaseDate) {
            return true;
      }

      return false;
    });

    return matching.map((annotation) => annotation.serialize() as AnnotationsAttrs);
  }


  public getByTitle(titleId: string): Annotation[] {
    return this.filterDisplayableAnnotations({ titleSlug: titleId })
      .sort(Annotation.SORT_FUNCTIONS.syncstamp);
  }


  public getByRelease(titleId: string, release?: string, releaseDate?: string): Annotation[] {
    // console.log(`annotations.getByRelease(${titleId}, ${release}, ${releaseDate})`);

    return this.filterDisplayableAnnotations({
      titleSlug: titleId,
      release: release || null,
      releaseDate: releaseDate || null
    })
    .sort(Annotation.SORT_FUNCTIONS.syncstamp);
  }


  public getRecent(limit?: number): Annotation[] {
    const allAnnotations = this.all
      .slice()
      .sort(Annotation.SORT_FUNCTIONS.syncstamp);

    return limit ? allAnnotations.slice(0, limit) : allAnnotations;
  }


  /**
   * @desc Retrieve (specified amount) of titles with annotations on them, sorted by newest activity
   * @param limit - Maximum number of titles to return
   */
  public getRecentByTitle(limit?: number): Promise<TitleRecord[]> {
    if (!this.all || !this.all.length) {
      return Promise.resolve([]);
    }

    const _maxIndex = limit || Infinity;
    const allAnnotations = this.filterDisplayableAnnotations();
    allAnnotations.sort(Annotation.SORT_FUNCTIONS.syncstamp);

    // - Loop through our notes
    // - Collect all of the "unique" title slugs
    // - Stop when we hit our target number of results
    const slugs: string[] = [];
    let index = 0;

    while (slugs.length < _maxIndex && index < allAnnotations.length) {
      const note = allAnnotations[index];
      if (note && note.titleSlug && slugs.indexOf(note.titleSlug) < 0) {
        slugs.push(note.titleSlug);
      }
      index++;
    }

    return APP.titleCache.getFreshTitles(slugs);
  }


  private _processDervishActivity(evt: DervishActivityEvent) {
    let eventType: string;
    let detail: DervishActivity;
    const src = (evt && evt.data) ? evt.data : null;
    if (src && src.label && src.label.match(/^highlight|audiomark/)) {
      eventType = src.label;
      detail = src.act;
    } else {
      return;
    }

    switch (eventType) {
      case 'audiomark.create':
      case 'highlight.create':
        this._record('create', detail);
        break;
      case 'audiomark.update':
      case 'highlight.update':
        // Distinguish between audiomarks being updated to have a note
        // and true updates
        const existing = this._findAnnotationByUUID(detail.uuid);
        if (existing) {
          this._record('update', detail);
        } else {
          this._record('create', detail);
        }
        break;
      case 'highlight.delete':
      case 'audiomark.delete':
        this._record('delete', detail);
        break;
      default:
        break;
    }
  }


  private _record(operation: 'create' | 'update' | 'delete', detail: DervishActivity) {
    // Transform the event detail to add a title
    const bifocalTitle = APP.shell.bifocal.title();
    const lex = bifocalTitle.lexisMetadata || {};
    const titleInfo = {
      titleSlug: lex.parent || bifocalTitle.slug,
      release: lex.release || null,
      releaseDate: lex.releaseDate || null,
      buid: this._getBuid()
    };

    const recordingDetail: RecordingDetail = C.absorb(detail, titleInfo);
    const recording: Recording = { operation: operation, annotation: recordingDetail };
    const item = this._replay(recording);
    if (item) {
      this._recordings.push(recording);
      this.save();

      // Send it to Postish
      if (operation !== 'delete') {

        const note = C.absorb(item.serialize(), { title: TitleMapper.mapToScribe(item.titleRecord) }) as BifocalMessage;
        note.title.release = titleInfo.release;
        note.title.releaseDate = titleInfo.releaseDate;
        note.message_id = detail?.message_id;
        note.created_by = detail?.created_by;
        note.deletedUuids = detail?.deletedUuids;

        if (operation === 'create') {
          APP.scribe.publish('annotations.add', note);
        } else {
          APP.scribe.publish('annotations.update', note);
        }

        APP.events.dispatch('annotation:create', recordingDetail);
      } else {
        APP.scribe.publish('annotations.remove', recordingDetail);
      }
    }
  }


  private _replay(recording: Recording): Annotation {
    if (recording.operation === 'create' || recording.operation === 'update') {
      return this._replay_upsert(recording);
    }
    if (recording.operation === 'delete') { return this._replay_delete(recording); }
    throw new Error('Invalid operation');
  }


  private _replay_upsert(recording: Recording): Annotation {
    let annotation = this._findAnnotationByUUID(recording.annotation.uuid);

    if (!annotation) {
      annotation = this._spawn(recording.annotation);
      this.all.push(annotation);
    } else {
      annotation.deserialize(recording.annotation);
    }

    return annotation;
  }


  private _replay_delete(recording: Recording): Annotation {
    const annotation = this._findAnnotationByUUID(recording.annotation.uuid);
    if (!annotation) {
      return undefined;
    }

    if (annotation.removed) { annotation.removed(); }
    C.excise(this.all, annotation);
    // Remove any other recordings for this annotation.
    const others: Recording[] = [];
    C.each(this._recordings, (other) => {
      if (other !== recording && other.annotation.uuid === annotation.uuid) {
        others.push(other);
      }
    });
    C.each(others, (other) => {
      C.excise(this._recordings, other);
    });

    return annotation;
  }


  private _replayRecordingsSince(horizon: number) {
    const replayedRecordings = [];
    C.each(this._recordings, (recording) => {
      if (recording.annotation.syncstamp > horizon) {
        this._replay(recording);
        replayedRecordings.push(recording);
      }
    });
    this._recordings = replayedRecordings;
    this.save();
  }


  private _mergeIntoSynced(itemsAttributes: PostishAnnotation[]) {
    const itemUuids: string[] = [];
    const newUuids: string[] = [];
    C.each(
      itemsAttributes,
      (itemAttributes) => {
        let item = this._findSyncedAnnotationAttributes(itemAttributes.uuid);
        if (item) {
          C.absorb(itemAttributes, item);
        } else {
          item = C.absorb(itemAttributes, {});
          newUuids.push(item.uuid);
          this._syncedAnnotations.push(item);
        }
        itemUuids.push(item.uuid);
      }
    );

    return { allUuids: itemUuids, newUuids: newUuids };
  }


  private _buildCollationContent() {
    if (!this._syncedAnnotations) {
      return;
    }

    this.all = this._syncedAnnotations.map((annotation) => this._spawn(annotation));
    let horizon = 0;
    C.each(this._syncedAnnotations, (annotation) => {
      if (annotation.syncstamp > horizon) {
        horizon = annotation.syncstamp;
      }
    });
    this._replayRecordingsSince(horizon);
  }


  private _findAnnotationByUUID(uuid: string): Annotation {
    const results = this.filter({ uuid: uuid });

    return results.length ? results[0] : null;
  }


  private _findSyncedAnnotationAttributes(uuid: string): PostishAnnotation {
    let attributes: PostishAnnotation;
    C.each(this._syncedAnnotations, (syncedAnnotation) => {
      if (syncedAnnotation.uuid === uuid) {
        attributes = syncedAnnotation;
      }
    });

    return attributes;
  }


  protected _fullAttributesToQuery(itemAttributes): any {
    return { uuid: itemAttributes.uuid };
  }


  private _getBuid(): string | null {
    const url = APP.shell.bifocal.getUrl();
    let match = null;
    if (url) {
      match = url.match(/https?:\/\/\w*-(\w*)/);
    }

    return match?.[1] || null;
  }


  private _isDisplayableAnnotation(annotation: Annotation): boolean {
    return this._filterMigrationAnnotation(annotation) && !!(annotation.note || annotation.quote);
  }


  private _filterMigrationAnnotation(annotation: Annotation): boolean {
    const filterMigration = annotation.migration !== null && !APP.flags.get('migrated_annotations');

    return !filterMigration;
  }


  private _allAnnotationsExcludingMigrations(): Annotation[] {
    return this.all.filter((ann) => this._filterMigrationAnnotation(ann));
  }
}

interface AnnotationsSerialized {
  recordings: Recording[];
  syncedAnnotations: PostishAnnotation[];
}

interface RecordingDetail extends DervishActivity {
  titleSlug: string;
  release: string;
  releaseDate: string;
}
