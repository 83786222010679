
import { Constants } from 'app/base/constants';
import AlertListEmptyState from 'app/components/AlertListEmptyState.vue';
import AlertCard from 'app/components/cards/AlertCard.vue';
import { AlertWithMetadata } from 'app/functions/use-alerts-with-metadata';
import { Breakpoint, useWindowSize } from 'app/functions/use-window-size';
import { PropType, computed, defineComponent, ref, watch } from 'vue';


export default defineComponent({
  components: {
    AlertCard,
    AlertListEmptyState
  },
  props: {
    alertsWithMetadata: {
      type: Array as PropType<AlertWithMetadata[]>,
      required: true
    }
  },
  emits: [
    'clear',
    'update',
    'view-details'
  ],
  setup: (props, ctx) => {
    const helpLink = Constants.HELP_PATHS.ALERTS;
    const { windowWidth } = useWindowSize();
    const compact = computed(() => windowWidth.value > Breakpoint.HideNav);
    const announceDeleteMessage = ref(false);

    const hasAlerts = computed(() => props.alertsWithMetadata.length > 0);

    watch(() => props.alertsWithMetadata, () => {
      ctx.emit('update');
    });

    const sortByTimestamp = (a: AlertWithMetadata, b: AlertWithMetadata) => {
      if (!a) { return -1; }
      if (!b) { return 1; }

      return b.timestamp - a.timestamp;
    };

    const sortByReviewed = (a: AlertWithMetadata, b: AlertWithMetadata) => {
      if (!a) { return -1; }
      if (!b) { return 1; }

      if (a.reviewed && !b.reviewed) { return 1; }
      if (!a.reviewed && b.reviewed) { return -1; }

      return 0;
    };
    const alertsSorted = computed(() => props.alertsWithMetadata.slice().sort(sortByTimestamp).sort(sortByReviewed));

    const clearAlert = (id: number, index: number) => {
      ctx.emit('clear', id);

      // shift keyboard focus to sibling element
      const sibling = alertsSorted.value[index + 1] || alertsSorted.value[index - 1];
      if (sibling) {
        (document.querySelector(`#alert-review-${sibling.id}`) as HTMLElement)?.focus();
      }

      // annouce deletion to screen reader and re-hide it so the message isn't accessible on it's own
      announceDeleteMessage.value = true;
      setTimeout(() => announceDeleteMessage.value = false, 300);
    };

    return {
      alertsSorted,
      helpLink,
      compact,
      hasAlerts,
      clearAlert,
      announceDeleteMessage
    };
  }
});
