
import { computed, defineComponent, Ref, ref } from 'vue';
import CopyingPrompt from 'app/components/CopyingPrompt.vue';
import NewReleasePrompt from 'app/components/NewReleasePrompt.vue';
import { useAppEvents } from 'app/functions/use-app-events';
import { usePatron } from 'app/functions/use-patron';
import { useReleaseAnnotations } from 'app/functions/use-release-annotations';
import { TitleRecord } from 'app/models/title';
import { RouteLocationRaw, useRouter } from 'vue-router';

export default defineComponent({
  name: 'NewRelease',
  components: {
    CopyingPrompt,
    NewReleasePrompt
  },
  setup: () => {
    const title = ref<TitleRecord | null>(null);
    const openRoute = ref<RouteLocationRaw | null>(null);

    const router = useRouter();
    const { annotations } = usePatron();

    const annotationsForTitle = computed(() => annotations.value.filter((annotation) => annotation.titleSlug === title.value?.slug));

    useAppEvents({
      'prompt:newrelease:show': async (evt) => {
        title.value = evt.m.title;
        openRoute.value = evt.m.route;
      }
    });

    const { mostRecentReleaseWithAnnotations, releasesLoaded } = useReleaseAnnotations(title as Ref<TitleRecord | null>, annotationsForTitle);

    const mostRecentRelease = computed(() => {
      return releasesLoaded.value
        ? mostRecentReleaseWithAnnotations.value
        : undefined;
    });

    const promptToShow = computed(() => {
      return releasesLoaded.value
        ? mostRecentRelease.value
          ? 'copy'
          : 'standard'
        : null;
    });

    const titleId = computed(() => {
      return title.value
        ? title.value.slug
        : undefined;
    });

    const openBook = () => {
      if (openRoute.value) {
        router.push(openRoute.value as RouteLocationRaw);
      }

      close();
    };

    const close = () => {
      title.value = null;
      openRoute.value = null;
    };

    return {
      close,
      openBook,
      promptToShow,
      mostRecentRelease,
      title,
      titleId
    };
  }
});
