
import { defineComponent } from 'vue';
import Overlay from './Overlay.vue';
import PromptContent from './PromptContent.vue';

export default defineComponent({
  name: 'Prompt',
  components: {
    PromptContent,
    Overlay
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    submittable: {
      type: Boolean,
      default: true
    },
    confirmLabel: {
      type: String,
      required: true
    },
    cancelLabel: {
      type: String,
      default: undefined
    },
    deleteLabel: {
      type: String,
      default: undefined
    },
    transition: {
      type: String,
      default: 'down'
    }
  },
  emits: [
    'cancel',
    'close',
    'delete',
    'submit'
  ],
  setup: (props, ctx) => {
    const submit = (data: unknown) => {
      ctx.emit('submit', data);
    };

    const cancel = () => {
      ctx.emit('cancel');
    };

    return {
      cancel,
      submit
    };
  }
});
