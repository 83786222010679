import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ManualCopyContent = _resolveComponent("ManualCopyContent")!
  const _component_Sidebar = _resolveComponent("Sidebar")!

  return (_openBlock(), _createBlock(_component_Sidebar, {
    header: _ctx.$t('annotationCopying.manualCopy.header'),
    show: _ctx.show,
    resizable: false,
    side: "right",
    onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ManualCopyContent, {
        title: _ctx.title,
        releasesWithAnnotations: _ctx.releasesWithAnnotations,
        onSubmit: _cache[0] || (_cache[0] = (val) => _ctx.$emit('submit', val))
      }, null, 8, ["title", "releasesWithAnnotations"])
    ], undefined, true),
    _: 1
  }, 8, ["header", "show"]))
}