import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["aria-labelledby"]
const _hoisted_2 = ["aria-labelledby"]
const _hoisted_3 = ["aria-label", "aria-expanded", "aria-controls"]
const _hoisted_4 = ["aria-labelledby"]
const _hoisted_5 = ["aria-labelledby"]
const _hoisted_6 = ["aria-labelledby"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormCheckbox = _resolveComponent("FormCheckbox")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_TitleActionsContextMenuButton = _resolveComponent("TitleActionsContextMenuButton")!
  const _component_ExportQueueTable = _resolveComponent("ExportQueueTable")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
      [_ctx.$style.exportQueueGroup]: true,
      [_ctx.$style.expanded]: _ctx.expanded
    })
  }, [
    _createElementVNode("div", {
      class: _normalizeClass({
        [_ctx.$style.header]: true,
        [_ctx.$style.selected]: _ctx.selectionState !== 'off'
      })
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.select),
        "aria-labelledby": _ctx.labelIds.select
      }, [
        _createVNode(_component_FormCheckbox, {
          modelValue: _ctx.selectionState,
          showLabel: false,
          label: _ctx.$t('exportQueue.selectTitle', { title: _ctx.title }),
          ariaLabel: _ctx.$t('exportQueue.selectTitle', { title: _ctx.title }),
          class: _normalizeClass(_ctx.$style.checkbox),
          "onUpdate:modelValue": _ctx.updateAllAnnotations
        }, null, 8, ["modelValue", "label", "ariaLabel", "class", "onUpdate:modelValue"])
      ], 10, _hoisted_1),
      _createElementVNode("div", {
        class: _normalizeClass([_ctx.$style.expander, _ctx.$style.centered]),
        "aria-labelledby": _ctx.labelIds.expand
      }, [
        _createElementVNode("button", {
          ref: "expansionButton",
          type: "button",
          "aria-label": _ctx.$t('exportQueue.expandTitle', { title: _ctx.title }),
          "aria-expanded": _ctx.expanded,
          "aria-controls": _ctx.annotationPanelId,
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleExpansion && _ctx.toggleExpansion(...args)))
        }, [
          _createVNode(_component_Icon, { name: "chevron-down" })
        ], 8, _hoisted_3)
      ], 10, _hoisted_2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.title),
        "aria-labelledby": _ctx.labelIds.title
      }, [
        _createElementVNode("div", null, _toDisplayString(_ctx.title), 1),
        (_ctx.subtitle)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(_ctx.$style.subtitle)
            }, _toDisplayString(_ctx.subtitle), 3))
          : _createCommentVNode("", true)
      ], 10, _hoisted_4),
      _createElementVNode("div", {
        class: _normalizeClass([_ctx.$style.count, _ctx.$style.centered]),
        "aria-labelledby": _ctx.labelIds.count
      }, _toDisplayString(_ctx.annotations.length), 11, _hoisted_5),
      _createElementVNode("div", {
        class: _normalizeClass([_ctx.$style.actions, _ctx.$style.centered]),
        "aria-labelledby": _ctx.labelIds.actions
      }, [
        _createVNode(_component_TitleActionsContextMenuButton, {
          title: _ctx.groupTitle,
          hideLabel: true,
          includeCopying: false,
          darkStyle: false
        }, null, 8, ["title"])
      ], 10, _hoisted_6)
    ], 2),
    (_ctx.expanded)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.$style.groupIndicatorContainer)
          }, [
            _createElementVNode("span", {
              class: _normalizeClass(_ctx.$style.groupIndicator)
            }, null, 2)
          ], 2),
          _createVNode(_component_ExportQueueTable, {
            id: _ctx.annotationPanelId,
            class: _normalizeClass(_ctx.$style.annotations),
            annotations: _ctx.annotations,
            hideNote: _ctx.hideNote,
            title: _ctx.title,
            "onAnnotations:update": _cache[1] || (_cache[1] = (checked, uuid) => _ctx.updateAnnotation(checked, uuid))
          }, null, 8, ["id", "class", "annotations", "hideNote", "title"])
        ], 64))
      : _createCommentVNode("", true)
  ], 2))
}