
import Popout from 'app/components/Popout.vue';
import { PropType, defineComponent, onBeforeMount, ref } from 'vue';


type PopoutDisplay = 'menu' | 'confirmation';

export default defineComponent({
  name: 'DownloadIndicatorPopout',
  components: {
    Popout
  },
  props: {
    /**
     * Reference element the popout should position itself relative to.
     */
    reference: {
      type: Object as PropType<HTMLElement | undefined>,
      required: true
    },
    /**
     * Name for the icon to display in the popout header.
     */
    headerIconName: {
      type: String,
      required: true
    },
    /**
     * Descriptive popout header.
     */
    headerLabel: {
      type: String,
      required: true
    },
    /**
     * Label to display in the menu for the action a user can take.
     */
     menuActionLabel: {
      type: String,
      required: true
    },
    /**
     * When confirmation is required, a description of the action
     * that will be taken upon confirmation.
     */
     confirmationMessage: {
      type: String,
      required: true
    },
    /**
     * When true, indicates that the user should be prompted for
     * confirmation before acting.
     */
    confirmationRequired: {
      type: Boolean,
      default: true
    }
  },
  emits: [
    'download:action',
    'download:settings',
    'popout:hide'
  ],
  setup: (props, { emit }) => {
    const indicatorButton = ref<HTMLButtonElement | undefined>();

    const display = ref<PopoutDisplay>('menu');

    const hidePopout = () => {
      emit('popout:hide');
    };

    const showMenu = () => {
      display.value = 'menu';
    };

    const showConfirmation = () => {
      display.value = 'confirmation';
    };

    const triggerAction = () => {
      emit('download:action');
      hidePopout();
    };

    const manageDownloads = () => {
      emit('download:settings');
      hidePopout();
    };

    onBeforeMount(() => {
      showMenu();
    });

    return {
      display,
      indicatorButton,
      manageDownloads,
      triggerAction,

      hidePopout,
      showConfirmation,
      showMenu
    };
  }
});
