
import { PostishCopyAnnotationSnapshot } from 'app/base/postish';
import Collapsible from 'app/components/Collapsible.vue';
import CopyAnnotationSnapshotCard from 'app/components/CopyAnnotationSnapshotCard.vue';
import CopyAnnotationSnapshotTable from 'app/components/CopyAnnotationSnapshotTable.vue';
import { Breakpoint, useWindowSize } from 'app/functions/use-window-size';
import { generateUUID } from 'lib/common';
import { PropType, computed, defineComponent, ref } from 'vue';

export default defineComponent({
  components: {
    Collapsible,
    CopyAnnotationSnapshotCard,
    CopyAnnotationSnapshotTable
  },
  props: {
    message: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    snapshots: {
      type: Array as PropType<PostishCopyAnnotationSnapshot[]>,
      required: true
    },
    hideNote: {
      type: Boolean,
      default: false
    }
  },
  setup: (props) => {
    const uuid = generateUUID();

    const { windowWidth } = useWindowSize();
    const mobile = computed(() => windowWidth.value <= Breakpoint.VeryWide);

    const expandable = computed(() => props.snapshots.length > 0);
    const expanded = ref(false);
    const toggleExpansion = () => {
      if (!expandable.value) {
        return;
      }

      expanded.value = !expanded.value;

      setTimeout(() => {
        // wait until panel is expanded/collapsed, then scroll it into view
        const expanderButton = document.getElementById(`header-${uuid}`);
        expanderButton?.scrollIntoView({ behavior: 'smooth'});
      }, 200);
    };

    return {
      expandable,
      expanded,
      mobile,
      toggleExpansion,
      uuid
    };
  }
});
