
import ContextMenu, { ContextMenuOption } from 'app/components/contextMenus/ContextMenu.vue';
import TitleActionButton from 'app/components/TitleActionButton.vue';
import { useI18n } from 'app/functions/use-i18n';
import { usePatron } from 'app/functions/use-patron';
import { getReleaseDisplayNameTitle } from 'app/functions/use-release-display-name';
import { useReleaseFilter } from 'app/functions/use-release-filter';
import { TDReleasesSymbol } from 'app/keys/injection-keys';
import { Title, TitleRecord } from 'app/models/title';
import { RouteName } from 'app/router/constants';
import { computed, ComputedRef, defineComponent, inject, PropType, ref } from 'vue';

export default defineComponent({
  name: 'TitleDetailsContextMenu',
  components: {
    ContextMenu,
    TitleActionButton
},
  props: {
    reference: {
      type: Object as PropType<HTMLElement | null>,
      required: true
    },
    subscribeText: {
      type: String,
      required: true
    },
    title: {
      type: Object as PropType<Title>,
      required: true
    }
  },
  emits: [
    'close',
    'show:cover',
    'show:manualcopy',
    'toggle:subscription'
  ],
  setup: (props, ctx) => {
    const { t } = useI18n();

    const { annotations } = usePatron();
    const annotationsForTitle = computed(() => annotations.value.filter((a) => a.titleSlug === props.title.slug));

    // Manual Copy Sidebar trigger
    const releases = inject(TDReleasesSymbol) as ComputedRef<TitleRecord[]>;
    const { getFilterObjects } = useReleaseFilter(annotationsForTitle, releases);
    const releasesWithAnnotations = computed(() => getFilterObjects(annotationsForTitle.value));
    const allowManualCopy = !!(releasesWithAnnotations.value.filter((release) => release.name !== getReleaseDisplayNameTitle(props.title)).length);

    const contextMenuOptions = computed<ContextMenuOption[]>(() => {
      const options = [
        {
          slotName: 'read'
        },
        {
          label: t('title.contextMenu.viewCover'),
          handler: viewCover,
          hasPopup: 'dialog'
        },
        {
          label: props.subscribeText,
          handler: toggleSubscription
        }
      ] as ContextMenuOption[];

      if (annotationsForTitle.value.length) {
        options.push({
          slotName: 'annotations'
        });
      }

      options.push(
        {
          label: t('pageHeader.contextMenu.copyAnnotations'),
          handler: manualCopy,
          inactive: !allowManualCopy
        }
      );

      return options;
    });

    const annotationOption = computed(() => {
      return {
        label: t('title.contextMenu.viewAnnotations'),
        count: annotationsForTitle.value.length,
        location: { name: RouteName.NoteDetails, params: { titleSlug: props.title.slug } }
      };
    });

    const manualCopy = () => {
      ctx.emit('show:manualcopy', releasesWithAnnotations);
      ctx.emit('close');
    };

    const viewCover = () => {
      ctx.emit('show:cover');
      ctx.emit('close');
    };

    const toggleSubscription = () => {
      ctx.emit('toggle:subscription');
      ctx.emit('close');
    };

    const menu = ref<InstanceType<typeof ContextMenu> | null>(null);
    const onTitleActionStart = () => {
      if (menu.value) {
        menu.value.$el.style.display = 'none';
      }
    };
    const onTitleActionFinish = () => {
      if (menu.value) {
        menu.value.$el.style.display = null;
      }
      ctx.emit('close');
    };

    return {
      annotationOption,
      contextMenuOptions,
      menu,
      onTitleActionFinish,
      onTitleActionStart
    };
  }
});
