
import { usePatron } from 'app/functions/use-patron';
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'UserMenuBorrowLimits',
  setup: (props) => {
    const { card } = usePatron();

    const remainingCheckouts = computed(() => card.value?.remainingNonSuCheckouts());

    const borrowLimits = computed(() => {
      if (card.value?.limits === undefined || remainingCheckouts.value === undefined) {
        return undefined;
      }

      return {
        remaining: remainingCheckouts.value,
        maximum: card.value.limits.loan,
        current: card.value.limits.loan - remainingCheckouts.value
      };
    });

    return {
      borrowLimits
    };
  }
});
