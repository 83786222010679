
import ExportQueueContextMenu from 'app/components/contextMenus/ExportQueueContextMenu.vue';
import DynamicExpander from 'app/components/DynamicExpander.vue';
import FormCheckbox from 'app/components/FormCheckbox.vue';
import HighlightColor from 'app/components/HighlightColor.vue';
import Icon from 'app/components/Icon.vue';
import RelativeDate from 'app/components/RelativeDate.vue';
import TitleActionButton from 'app/components/TitleActionButton.vue';
import { SelectableAnnotation, useDisplayAnnotation } from 'app/functions/use-display-annotation';
import { FormCheckboxState } from 'app/models/form-checkbox-state';
import { defineComponent, nextTick, PropType, Ref, ref, toRef } from 'vue';

export default defineComponent({
  name: 'AnnotationTableRow',
  components: {
    RelativeDate,
    HighlightColor,
    Icon,
    ExportQueueContextMenu,
    FormCheckbox,
    TitleActionButton,
    DynamicExpander
  },
  props: {
    annotation: {
      type: Object as PropType<SelectableAnnotation>,
      required: true
    },
    hideNote: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    'annotation:update'
  ],
  setup: (props, ctx) => {
    const actions = ref<HTMLElement | null>(null);
    const expansionButton = ref<HTMLElement | null>(null);

    const expanded = ref(false);
    const showContextMenu = ref(false);

    const { displayAnnotation } = useDisplayAnnotation(toRef(props, 'annotation') as Ref<SelectableAnnotation>);

    const toggleExpansion = async () => {
      expanded.value = !expanded.value;
      await nextTick();
      expansionButton.value?.scrollIntoView({ behavior: 'smooth' });
      expansionButton.value?.focus();
    };

    const updateSelected = (checked: FormCheckboxState) => {

      ctx.emit('annotation:update', checked);
    };

    return {
      actions,
      expanded,
      expansionButton,
      displayAnnotation,
      showContextMenu,
      toggleExpansion,
      updateSelected
    };
  }
});

