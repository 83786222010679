
import { seriesCoverSizes } from 'app/base/constants';
import Cover from 'app/components/Cover.vue';
import SeriesCover from 'app/components/SeriesCover.vue';
import { AlertWithMetadata } from 'app/functions/use-alerts-with-metadata';
import { useI18n } from 'app/functions/use-i18n';
import { PropType, computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'AlertCard',
  components: {
    Cover,
    SeriesCover
  },
  props: {
    alertWithMetadata: {
      type: Object as PropType<AlertWithMetadata>,
      required: true
    }
  },
  emits: [
    'view-details',
    'clear'
  ],
  setup: (props) => {
    const { t } = useI18n();
    const typeName = computed(() => t(`alert.type.${props.alertWithMetadata.type}`));
    const unreviewedLabel = computed(() => props.alertWithMetadata.reviewed ? typeName.value : t('alert.type.Unreviewed', { type: typeName.value }));

    return {
      seriesCoverSizes,
      typeName,
      unreviewedLabel
    };
  }
});
