
import { APP } from 'app/base/app';
import { channel as changeChannel } from 'app/base/debugger';
import env from 'app/base/env';
import { Dictionary } from 'lib/common/dictionary';
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import Dropdown from './Dropdown.vue';

export default defineComponent({
  name: 'ChannelIndicator',
  components: {
    Dropdown
  },
  setup: () => {
    const channel = ref(env.CHANNEL);
    const channels = ref<string[]>([ channel.value ]);

    onMounted(async () => {
      try {
        const channelResponse = (await APP.services.elrond
          .fetchAsync<Dictionary<{ name: string }>>(
            'channel/guide'
          )
        )!;

        channels.value = Object.keys(channelResponse);
      } catch (e) {
        console.error('[CHANNELINDICATOR] Could not load channel.');
      }
    });

    watch(channel, async () => {
      await changeChannel({ name: channel.value });
    });

    const channelOptions = computed(() => {
      return channels.value.map((c) => ({
        id: c,
        displayName: c
      }));
    });

    return {
      color,
      channel,
      channelOptions
    };
  }
});

export function color(channel: string) {
  if (!channel || channel === 'main') {
    return 'var(--c-primary-red)';
  }
  let hash = 0;
  for (let i = 0; i < channel.length; i++) {
    hash = channel.charCodeAt(i) + ((hash << 5) - hash);
  }

  const [h, s, l] = [Math.abs(hash % 360), 100, 70];
  const c = `hsl(${h}, ${s}%, ${l}%)`;

  return c;
}
