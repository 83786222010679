import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["aria-label", "aria-expanded", "aria-controls"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormCheckbox = _resolveComponent("FormCheckbox")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_AnnotationCardList = _resolveComponent("AnnotationCardList")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
      [_ctx.$style.exportQueueGroupMobile]: true,
      [_ctx.$style.expanded]: _ctx.expanded,
      [_ctx.$style.selectable]: _ctx.selectable
    })
  }, [
    (_ctx.selectable)
      ? (_openBlock(), _createBlock(_component_FormCheckbox, {
          key: 0,
          modelValue: _ctx.selectionState,
          showLabel: false,
          label: _ctx.$t('exportQueue.selectTitle', { title: _ctx.title }),
          ariaLabel: _ctx.$t('exportQueue.selectTitle', { title: _ctx.title }),
          class: _normalizeClass({
        [_ctx.$style.checkbox]: true,
        [_ctx.$style.selected]: _ctx.selectionState !== 'off'
      }),
          "onUpdate:modelValue": _ctx.updateAllAnnotations
        }, null, 8, ["modelValue", "label", "ariaLabel", "class", "onUpdate:modelValue"]))
      : _createCommentVNode("", true),
    _createElementVNode("button", {
      ref: "expansionButton",
      type: "button",
      "aria-label": _ctx.$t('exportQueue.expandTitle', { title: _ctx.title }),
      "aria-expanded": _ctx.expanded,
      "aria-controls": _ctx.annotationPanelId,
      class: _normalizeClass({
        [_ctx.$style.headerMobile]: true,
        [_ctx.$style.selected]: _ctx.selectionState !== 'off'
      }),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleExpansion && _ctx.toggleExpansion(...args)))
    }, [
      _createVNode(_component_Icon, {
        name: "chevron-down",
        class: _normalizeClass(_ctx.$style.expander)
      }, null, 8, ["class"]),
      _createElementVNode("div", null, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style.title)
        }, _toDisplayString(_ctx.title), 3),
        (_ctx.subtitle)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(_ctx.$style.subtitle)
            }, _toDisplayString(_ctx.subtitle), 3))
          : _createCommentVNode("", true)
      ])
    ], 10, _hoisted_1),
    (_ctx.expanded)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.$style.groupIndicatorContainer)
          }, [
            _createElementVNode("span", {
              class: _normalizeClass(_ctx.$style.groupIndicator)
            }, null, 2)
          ], 2),
          _createVNode(_component_AnnotationCardList, {
            id: _ctx.annotationPanelId,
            class: _normalizeClass(_ctx.$style.annotations),
            annotations: _ctx.annotations,
            selectable: _ctx.selectable,
            title: _ctx.title,
            hideNote: _ctx.hideNote,
            "onAnnotations:update": _cache[1] || (_cache[1] = (copy, checked, uuid) => _ctx.updateAnnotation(checked, uuid))
          }, null, 8, ["id", "class", "annotations", "selectable", "title", "hideNote"])
        ], 64))
      : _createCommentVNode("", true)
  ], 2))
}