
import { computed, defineComponent } from 'vue';
import FormInput from 'app/components/FormInput.vue';
import FormSubmitButton from 'app/components/FormSubmitButton.vue';

export default defineComponent({
  name: 'EmailRegistration',
  components: {
    FormInput,
    FormSubmitButton
  },
  props: {
    username: {
      type: String,
      default: ''
    },
    password: {
      type: String,
      default: ''
    },
    showCode: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    'username',
    'password'
  ],
  setup: (props, ctx) => {
    const email = computed({
      get: () => props.username,
      set: (val) => ctx.emit('username', val)
    });

    const code = computed({
      get: () => props.password,
      set: (val) => ctx.emit('password', val)
    });

    return {
      code,
      email
    };
  }
});
