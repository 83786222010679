import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$options.components, (component) => {
    return (_openBlock(), _createElementBlock("div", {
      key: component.name
    }, [
      (_openBlock(), _createBlock(_resolveDynamicComponent(component), _mergeProps({
        show: _ctx.contexts[component.name].shouldShow.value
      }, _ctx.contexts[component.name].componentData.value, {
        onClose: _ctx.contexts[component.name].hide,
        onSubmit: _ctx.contexts[component.name].submit
      }), null, 16, ["show", "onClose", "onSubmit"]))
    ]))
  }), 128))
}