
import { PostishCopyJobResponse } from 'app/base/postish';
import FormSubmitButton from 'app/components/FormSubmitButton.vue';
import RelativeDate from 'app/components/RelativeDate.vue';
import { getReleaseLabel } from 'app/functions/use-annotation-copying';
import { useI18n } from 'app/functions/use-i18n';
import { computed, defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'CopyJobCard',
  components: {
    FormSubmitButton,
    RelativeDate
  },
  props: {
    job: {
      type: Object as PropType<PostishCopyJobResponse>,
      required: true
    }
  },
  emits: [
    'update'
  ],
  setup: (props) => {
    const { t } = useI18n();

    const releaseLabel = getReleaseLabel(props.job.source_title, props.job.target_title);

    const numCopied = computed(() => {
      return props.job.status === 'FINISHED'
        ? t('annotationCopying.manageCopying.card.complete.numCopied', {
          SUCCESSFUL: props.job.results.successes.length,
          TOTAL: props.job.results.successes.length + props.job.results.failures.length
        })
        : undefined;
    });

    return {
      numCopied,
      releaseLabel
    };
  }
});
