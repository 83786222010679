
import { PostishCopyAnnotationSnapshot } from 'app/base/postish';
import HighlightColor from 'app/components/HighlightColor.vue';
import RelativeDate from 'app/components/RelativeDate.vue';
import { defineComponent, nextTick, PropType, ref } from 'vue';

export default defineComponent({
  components: {
    HighlightColor,
    RelativeDate
  },
  props: {
    snapshots: {
      type: Array as PropType<PostishCopyAnnotationSnapshot[]>,
      required: true
    },
    hideNote: {
      type: Boolean,
      default: false
    }
  },
  setup: () => {
    const expanded = ref<Set<number>>(new Set());
    const toggleExpanded = async (id: number) => {
      if (expanded.value.has(id)) {
        expanded.value.delete(id);
      } else {
        expanded.value.add(id);
      }

      await nextTick();
      const expansionButton = document.getElementById(`expander-${id}`);
      expansionButton?.scrollIntoView({ behavior: 'smooth' });
    };

    return {
      expanded,
      toggleExpanded
    };
  }
});
