
import { APP } from 'app/base/app';
import { CirculationError } from 'app/base/circulation-error';
import Popout from 'app/components/Popout.vue';
import { SeekLocation } from 'app/controllers/open-controller';
import { useAppEvents } from 'app/functions/use-app-events';
import { useI18n } from 'app/functions/use-i18n';
import { usePatron } from 'app/functions/use-patron';
import { Title, TitleRecord } from 'app/models/title';
import { RouteName } from 'app/router/constants';
import { mapSeekLocationToQuery } from 'app/router/query-mapper';
import { Dictionary } from 'lib/common/dictionary';
import { PropType, computed, defineComponent, ref } from 'vue';
import BorrowPrompt from './BorrowPrompt.vue';
import EditHoldPrompt from './EditHoldPrompt.vue';
import HoldPrompt from './HoldPrompt.vue';

type CircState =
  | 'openable'
  | 'borrowable'
  | 'borrowed'
  | 'holdable'
  | 'held'
  | 'unknown'
  | 'loading';

export default defineComponent({
  name: 'TitleActionButton',
  components: {
    BorrowPrompt,
    HoldPrompt,
    Popout,
    EditHoldPrompt
},
  props: {
    title: {
      type: Object as PropType<TitleRecord>,
      required: true
    },
    parent: {
      type: Title,
      default: undefined
    },
    seekTo: {
      type: Object as () => SeekLocation | undefined,
      default: undefined
    },
    label: {
      type: String,
      default: undefined
    },
    display: {
      type: String as () => 'link' | 'button',
      default: 'link'
    },
    truncate: {
      type: Boolean,
      default: false
    },
    focus: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    'action:start',
    'action:finish',
    'vfocus'
  ],
  setup: (props, ctx) => {
    const container = ref<HTMLElement | null>(null);
    const isLoading = ref(false);

    const circTitle = computed(() => props.parent || props.title);

    const action = computed(() => {
      return props.title.mediaType === 'audiobook' ? 'circ.action.listen'
        : props.title.mediaType === 'video' ? 'circ.action.watch'
        : 'circ.action.read';
    });

    const icon = computed(() => {
      return props.label ? undefined
        : props.title.mediaType === 'magazine' ? 'book'
        : props.title.isPDF ? 'pdf'
        : props.title.mediaType;
    });

    const { t } = useI18n();
    const actionLabel = computed(() => {
      if (props.label) {
        //need to handle StT label differently
        return props.label !== t('searchThisTitle.goToPassage') ? props.label
          : state.value === 'held' ? t('circ.action.editHold')
          : state.value === 'unknown' ? t('circ.state.unavailable')
          : props.label;
      }

      //regular TitleActionButton labels
      return state.value === 'openable' || state.value === 'borrowable' ? t(action.value)
        : state.value === 'holdable' ? t('circ.action.placeHold')
        : state.value === 'held' ? t('circ.action.editHold')
        : t('circ.state.unavailable');
    });

    const { dispatch } = useAppEvents({
      'loan:return': (evt) => {
        if (evt?.m?.item?.titleRecord?.slug === props.title.slug) {
          isLoading.value = true;

          window.setTimeout(async () => {
            try {
              await props.title.freshen();
            } catch (ex) {
              console.error('Unexpected error freshing title', ex);
            } finally {
              isLoading.value = false;
            }
          }, 2000);
        }
      }
    });


    /* --- CIRCULATION STATE --- */

    const { card, holds, loans } = usePatron();

    const cardInfo = computed(() => {
      if (!card.value) { throw new Error('Expected patron to have a card'); }

      const lendingPeriods =
        card.value.lendingPeriods[circTitle.value.mediaType].options
          .map(([value, unit]) => ({ value, unit }));

      const [defaultLendingPeriodValue, defaultLendingPeriodUnit]
        = card.value.lendingPeriods[props.title.mediaType].preference;

      return {
        email: card.value.emailAddress,
        loansRemaining: card.value.remainingNonSuCheckouts(),
        lendingPeriods,
        defaultLendingPeriod: {
          value: defaultLendingPeriodValue,
          unit: defaultLendingPeriodUnit
        }
      };
    });

    const loanForTitle = computed(() => {
      const lookup = props.title.lexisMetadata?.parent
        ? props.title.lexisMetadata.parent
        : props.title.slug;

      return loans.value.find((l) => l.titleSlug === lookup);
    });

    const holdForTitle = computed(() => {
      const lookup = props.title.lexisMetadata?.parent
        ? props.title.lexisMetadata.parent
        : props.title.slug;

      return holds.value.find((h) => h.titleSlug === lookup);
    });

    const holdEmail = computed(() => {
      return holdForTitle.value?.emailAddress || '';
    });

    const state = computed<CircState>(() => {
      if (isLoading.value) { return 'loading'; }
      if (loanForTitle.value) { return 'openable'; }
      if (holdForTitle.value) {
        return holdForTitle.value.available
          ? 'borrowable'
          : 'held';
      }

      if (circTitle.value.circ?.available) {
        return circTitle.value.isSimultaneousUse
          ? 'openable'
          : 'borrowable';
      }
      if (circTitle.value.circ?.holdable) { return 'holdable'; }

      return 'unknown';
    });


    /* --- CIRCULATION ACTIONS --- */

    const error = ref('');
    const errorSubs = ref({});

    const openLink = computed(() => ({
      name: RouteName.OpenBook,
      params: { titleSlug: props.title.slug },
      query: {
        parent: props.title.lexisMetadata?.parent,
        ...mapSeekLocationToQuery(props.seekTo)
      }
    }));


    const circError = (key: string, subs?: Dictionary<string | number>) => {
      error.value = key;
      errorSubs.value = subs || {};
    };

    const open = (e: PointerEvent) => {
      // Title#open wires up the prompt logic, so if we're
      // clicking the read link we want to trigger the prompt
      // first.
      // However, opening in a new tab/window should function
      // like a normal navigation.
      // This is also why the template has `:event="''"`
      // so that we can control the click behavior.
      if (props.title.openable()) {
        const modifier = e.ctrlKey || e.metaKey || e.shiftKey || e.altKey;
        if (!modifier) {
          ctx.emit('action:start');
          e.preventDefault();
          if (!props.title.loan()) {
            borrowTitle();
          } else {
            props.title.open(props.seekTo);
          }
        }
      } else {
        e.preventDefault();
        circError('circ.error.offline');
      }
    };

    const borrowTitle = async (lendingPeriod?: [number, string], onError = circError) => {
      try {
        const loan = await APP.patron.borrowTitle(circTitle.value.slug, lendingPeriod);
        loan.downloader.check({
          onChecked: () => {
            props.title.open(props.seekTo);
          }
        });
      } catch (ex) {
        if (ex instanceof CirculationError) {
          onError(ex.textKey, ex.subs);
        } else {
          console.warn('Unexpected error borrowing title', ex);
          onError('circ.error.unrecognized');
        }
      }
    };


    const showBorrowPrompt = ref(false);
    const borrow = () => {
      if (!APP.network.reachable) {
        circError('circ.error.offline');

        return;
      }

      if (cardInfo.value.loansRemaining !== null && cardInfo.value.loansRemaining <= 0) {
        circError('circ.error.at-loan-limit');

        return;
      }

      showBorrowPrompt.value = true;
    };
    const onBorrowPromptBorrow = async ({ lendingPeriod }: { lendingPeriod: { value: number; unit: string }}) => {
      card.value!.lendingPeriods[circTitle.value.mediaType].preference = [lendingPeriod.value, lendingPeriod.unit];
      APP.patron.libraryCards().save();

      showBorrowPrompt.value = false;
      ctx.emit('action:finish');

      await borrowTitle(
        [lendingPeriod.value, lendingPeriod.unit],
        (l, s) => dispatch('toast', {
          type: 'error',
          message: t(l, s)
        })
      );
    };
    const onBorrowPromptClose = () => {
      showBorrowPrompt.value = false;
      ctx.emit('action:finish');
    };

    const showHoldPrompt = ref(false);
    const placeHold = () => {
      if (!APP.network.reachable) {
        circError('circ.error.offline');

        return;
      }

      if (!card.value) { return; }

      if (!card.value.canPlaceHolds) {
        circError('circ.error.card-hold-disabled');

        return;
      }

      if (card.value.limits && card.value.limits.hold <= card.value.totalHoldCount()) {
        circError('circ.error.card-at-hold-limit');

        return;
      }

      showHoldPrompt.value = true;
    };
    const onHoldPromptHold = async (data: { email: string }) => {
      showHoldPrompt.value = false;
      ctx.emit('action:finish');
      try {
        await APP.patron.holdTitle(circTitle.value, data.email);
        dispatch('toast', { type: 'success', message: t('circ.success.placeHold') });
      } catch (ex) {
        dispatch('toast', {
          type: 'error',
          message: t(ex.textKey, ex.subs)
        });
      }
    };
    const onHoldPromptClose = () => {
      showHoldPrompt.value = false;
      ctx.emit('action:finish');
    };

    const showEditHoldPrompt = ref(false);
    const editHold = () => {
      if (!APP.network.reachable) {
        circError('circ.error.offline');

        return;
      }

      showEditHoldPrompt.value = true;
    };
    const onEditHoldPromptEdit = async (data: { email: string }) => {
      showEditHoldPrompt.value = false;
      ctx.emit('action:finish');

      try {
        await APP.patron.editHold(circTitle.value, data.email);
        dispatch('toast', { type: 'success', message: t('circ.success.editHold') });
      } catch (ex) {
        dispatch('toast', { type: 'error', message: t('circ.error.unrecognized') });
      }
    };
    const onEditHoldPromptDelete = async () => {
      showEditHoldPrompt.value = false;
      ctx.emit('action:finish');

      try {
        await APP.patron.deleteHold(circTitle.value.slug);
        dispatch('toast', { type: 'success', message: t('circ.success.deleteHold') });
      } catch (ex) {
        dispatch('toast', { type: 'error', message: t('circ.error.unrecognized') });
      }
    };
    const onEditHoldPromptClose = () => {
      showEditHoldPrompt.value = false;
      ctx.emit('action:finish');
    };

    const onFocus = () => {
      ctx.emit('vfocus');
    };

    return {
      action,
      cardInfo,
      circTitle,
      container,
      borrow,
      editHold,
      error,
      errorSubs,
      holdEmail,
      icon,
      open,
      openLink,
      placeHold,
      showBorrowPrompt,
      showEditHoldPrompt,
      showHoldPrompt,
      state,
      onBorrowPromptBorrow,
      onBorrowPromptClose,
      onFocus,
      onEditHoldPromptEdit,
      onEditHoldPromptDelete,
      onEditHoldPromptClose,
      onHoldPromptHold,
      onHoldPromptClose,
      actionLabel
    };
  }
});
