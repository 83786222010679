import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Prompt = _resolveComponent("Prompt")!

  return (_openBlock(), _createBlock(_component_Prompt, {
    show: _ctx.show,
    title: _ctx.$t('circ.newRelease.header'),
    describedBy: `newrelease-explanation-${_ctx.titleId}`,
    confirmLabel: _ctx.$t('general.continue'),
    onSubmit: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('open')))
  }, {
    default: _withCtx(() => [
      _createElementVNode("p", {
        id: `newrelease-explanation-${_ctx.titleId}`
      }, _toDisplayString(_ctx.$t('circ.newRelease.description')), 9, _hoisted_1),
      _createElementVNode("p", null, _toDisplayString(_ctx.$t('circ.newRelease.downloadMessage')), 1)
    ], undefined, true),
    _: 1
  }, 8, ["show", "title", "describedBy", "confirmLabel"]))
}