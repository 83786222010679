
import { APP } from 'app/base/app';
import { C } from 'app/base/common';
import DynamicExpander from 'app/components/DynamicExpander.vue';
import FormError from 'app/components/FormError.vue';
import Popout from 'app/components/Popout.vue';
import { useAutocomplete } from 'app/functions/use-autocomplete';
import { useI18n } from 'app/functions/use-i18n';
import { usePatron } from 'app/functions/use-patron';
import { Tag } from 'app/models/tag';
import { TitleRecord } from 'app/models/title';
import { generateUUID } from 'lib/common';
import { computed, defineComponent, nextTick, PropType, ref, watch } from 'vue';

export default defineComponent({
  name: 'TagWidget',
  components: {
    Popout,
    DynamicExpander,
    FormError
},
  props: {
    title: {
      type: Object as PropType<TitleRecord>,
      required: true
    },
    restrictHeight: {
      type: Boolean,
      default: false
    }
  },
  setup: (props) => {
    const { t } = useI18n();
    const tagForm = ref<HTMLElement | null>(null);
    const dropdownWidth = ref(tagForm.value?.offsetWidth || 0);
    const showAutocomplete = ref(false);
    const textInput = ref('');
    const maxCharLength = Tag.MAX_LENGTH;
    const invalidNameError = ref(false);
    const idBase = `tag-widget-${generateUUID()}`;
    const ids = {
      textInput: `${idBase}-input`,
      addButton: `${idBase}-button`,
      autocomplete: `${idBase}-autocomplete`,
      appliedPanel: `${idBase}-applied`
    };
    const message = ref('');

    const { tags } = usePatron();
    const userTags = computed(() => tags.value.filter(Tag.FILTER_FUNCTIONS.nonSystemTags));
    const tagsForTitle = computed(() => userTags.value.filter((tag) => tag.isAppliedToTitle(props.title.slug)).sort(Tag.SORT_FUNCTIONS.alpha));
    const unappliedTagNames = computed(() => userTags.value.filter((tag) => !tagsForTitle.value.includes(tag)).map((tag) => tag.name));

    // Quirk for suggestion keyboards where v-model only updates at the end of the word
    // Using :value/@input pair to work around that
    const updateTagValue = (typingEvent: Event) => {
      if (typingEvent.target) {
        const target = typingEvent.target as HTMLInputElement;
        textInput.value = target.value;
      } else {
        textInput.value = '';
      }
      // hide any active error message once the
      // user starts to make changes to the input
      invalidNameError.value = false;
    };

    // Autocomplete

    const { hints } = useAutocomplete(textInput, unappliedTagNames, { maxResults: 5 });

    watch(() => hints.value, async () => {
      if (hints.value.length && document.activeElement === document.getElementById(`${ids.textInput}`)) {
        // assign size of popout before it opens
        await nextTick();
        dropdownWidth.value = tagForm.value?.offsetWidth || 0;
        showAutocomplete.value = true;
      } else {
        close();
      }
    });

    const highlight = async (index: number) => {
      if (!showAutocomplete.value) { return; }

      let adjIndex = index;
      if (index < 0) { adjIndex = hints.value.length - 1; }
      if (index >= hints.value.length) { adjIndex = 0; }

      const element = document.querySelector(`#${ids.autocomplete}-hint-${adjIndex}`) as HTMLElement;

      await nextTick();
      (element)?.focus();
    };

    const close = () => {
      showAutocomplete.value = false;
    };

    const closeAndFocus = (selector: string) => {
      close();
      (document.querySelector(selector) as HTMLElement)?.focus();
    };


    // Tag actions

    const applyTag = (input: string) => {
      let tagName = input.trim();

      if (!tagName) {
        invalidNameError.value = true;

        return;
      }

      // input field should prevent more than max chars,
      // but double check here and cut it off if needed
      if (C.unicodeLength(tagName) > maxCharLength) {
        tagName = tagName.slice(0, maxCharLength);
      }

      const currentTags = APP.patron.tags;

      if (currentTags.isSystemTagName(tagName)) {
        invalidNameError.value = true;

        return;
      }

      const newOrExisting = currentTags.find({ lowercaseName: tagName.toLowerCase() }) || currentTags.stub({ name: tagName });
      newOrExisting.addToTitle(props.title.slug);

      APP.tracking.log('tag_created', {
        tag_uuid: newOrExisting.uuid,
        title_slug: props.title.slug,
        title_name: (`${props.title.title} ${props.title.subtitle || ''}`).trim(),
        reserveID: props.title.lexisMetadata?.contentReserveID,
        is_set: false
      });

      textInput.value = '';
      closeAndFocus(`#${ids.textInput}`);

      changeMessage(t('tag.create.added', { NAME: newOrExisting.name }));
    };

    const removeTag = (selectedTag: Tag, index: number) => {
      // shift keyboard focus to sibling
      const sibling = tagsForTitle.value[index + 1] || tagsForTitle.value[index - 1];
      if (sibling) {
        (document.querySelector(`#${ids.appliedPanel}-tag-${sibling.slug}`) as HTMLElement).focus();
      }

      // prevent a tag button from changing its position due to the position:absolute on leave-active
      const parent = (document.querySelector(`#${ids.appliedPanel}-tag-${selectedTag.slug}`) as HTMLElement).parentElement;
      if (parent) {
        parent.style.top = parent.offsetTop + 'px';
        parent.style.left = parent.offsetLeft + 'px';
      }

      selectedTag.removeFromTitle(props.title.slug);

      changeMessage(t('tag.create.removed', { NAME: selectedTag.name }));
    };


    // Accessibility

    const changeMessage = (msg: string) => {
      // annouce message to screen reader after small delay to account for reassigned focus
      setTimeout(() => message.value = msg, 250);
      // reset the message so it isn't accessible on it's own and allows for repeated content to be annouced
      setTimeout(() => message.value = '', 1000);
    };

    let typeaheadTimer = -1;
    watch(() => textInput.value, () => {
      clearTimeout(typeaheadTimer);

      // wait until the user pauses typing before announcing count message
      typeaheadTimer = window.setTimeout(() => {
        if (hints.value.length) {
          changeMessage(t('tag.create.autocompleteCount', { COUNT: hints.value.length }));
        }
      }, 1000);
    });

    return {
      applyTag,
      close,
      closeAndFocus,
      dropdownWidth,
      highlight,
      hints,
      ids,
      invalidNameError,
      maxCharLength,
      message,
      removeTag,
      showAutocomplete,
      tagForm,
      tagsForTitle,
      textInput,
      updateTagValue
    };
  }
});
