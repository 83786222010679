
import { defineComponent, ref } from 'vue';

// Must be used inside an Accordion component to function properly.
export default defineComponent({
    name: 'Collapsible',
    props: {
      expanded: {
        type: Boolean,
        required: true
      },
      panelId: {
        type: String,
        required: true
      },
      headerId: {
        type: String,
        required: true
      }
    },
    setup: (props, { emit }) => {
      const panel = ref<HTMLElement | null>(null);

      const clearHeight = () => {
        if (!panel.value) { return; }
        panel.value.style.height = panel.value.clientHeight + 'px';

        requestAnimationFrame(() => panel.value!.style.height = '0');
      };

      const setHeight = (height?: number) => {
        requestAnimationFrame(() => {
          if (!panel.value) { return; }

          const element = panel.value.children[0];
          panel.value.style.height = element.scrollHeight + 'px';

          setTimeout(() => panel.value!.style.height = 'auto', 200);
        });
      };

      return {
        clearHeight,
        panel,
        setHeight
      };
    }
});
