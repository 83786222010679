
import { APP } from 'app/base/app';
import { SearchSortOption } from 'app/base/search';
import BaseListPage from 'app/components/BaseListPage.vue';
import Surface from 'app/components/Surface.vue';
import { useI18n } from 'app/functions/use-i18n';
import { ListPageConfig, ListTabId, useListPage } from 'app/functions/use-list-page';
import { SubjectFilters } from 'app/functions/use-subject-filters';
import { computed, defineComponent, PropType } from 'vue';


export default defineComponent({
  name: 'Publisher',
  components: {
    Surface,
    BaseListPage
  },
  props: {
    publisherId: {
      type: String,
      required: true
    },
    currentTab: {
      type: String as PropType<ListTabId>,
      default: 'title'
    },
    currentSort: {
      type: String as PropType<SearchSortOption>,
      default: undefined
    },
    appliedFilters: {
      type: Object as PropType<SubjectFilters>,
      default: undefined
    },
    titlePage: {
      type: Number,
      default: undefined
    },
    seriesPage: {
      type: Number,
      default: undefined
    }
  },
  setup: (props, ctx) => {
    const { t } = useI18n();

    const config = computed<ListPageConfig>(() => {
      return {
        libraryKey: APP.library.key(),
        definition: {
          publisherId: props.publisherId
        },
        tabOptions: ['title', 'set'],
        sortOptions: ['title', 'publishdate'],
        modifications: {
          currentTab: props.currentTab,
          currentSort: props.currentSort,
          appliedFilters: props.appliedFilters,
          titlePage: props.titlePage,
          seriesPage: props.seriesPage
        }
      };
    });

    const listPage = useListPage(config);

    const title = computed(() => {
      return listPage.titleState.value?.state === 'success'
        ? listPage.titleState.value.response.terms.publisher?.name
        : t('general.loading');
    });

    return {
      title,
      ...listPage
    };
  }
});
