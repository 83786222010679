
import { computed, defineComponent, onMounted, ref } from 'vue';
import WelcomeView from 'app/components/WelcomeView.vue';
import { APP } from 'app/base/app';
import { Library } from 'app/models/library';
import { RouteName } from 'app/router/constants';
import { useRouter } from 'vue-router';
import { Dictionary } from 'lib/common/dictionary';
import { useI18n } from 'app/functions/use-i18n';
import env from 'app/base/env';

export default defineComponent({
  name: 'Redirect',
  components: {
    WelcomeView
  },
  props: {
    currentLibraryId: {
      type: String,
      required: true
    },
    targetLibraryId: {
      type: String,
      required: true
    },
    target: {
      type: String,
      default: undefined as string | undefined
    }
  },
  setup: (props, ctx) => {
    const targetLibrary = ref<Library | null>(null);
    const currentLibraryName = computed(() => APP.library.name);
    const targetLibraryName = computed(() => targetLibrary.value?.name || '');
    const targetUrl = computed(() => {
      return props.target || {
            name: RouteName.LibraryHome,
            params: {
              libraryId: props.targetLibraryId
            }
          };
    });

    const { t } = useI18n();
    const router = useRouter();

    const displayUrl = computed(() => {
      const resolved = router.resolve(targetUrl.value) || { href: '' };

      return env.ROOT_URI + resolved.href;
    });

    onMounted(async () => {
      targetLibrary.value = await Library.resolve({
        baseKey: props.targetLibraryId
      });

      // If the target library is invalid, we'll just take them to the home page
      // of their current library.
      if (!targetLibrary.value
        || !targetLibrary.value.isAccessible
        || !targetLibrary.value.isElrondLibrary()
      ) {
        console.log('[REDIRECT] Invalid library, going home...');

        router.replace({
          name: RouteName.LibraryHome,
          params: {
            libraryId: props.currentLibraryId
          }
        });
      } else if (targetLibrary.value.websiteId === APP.library.websiteId) {
        console.log('[REDIRECT] Target library has same website id as current library, proceeding...');
        const targetRoute = router.resolve(targetUrl.value)
          || {
            name: RouteName.LibraryHome,
            params: {} as Dictionary<string>,
            query: undefined,
            hash: undefined
          };
        targetRoute.params!.libraryId = props.currentLibraryId;

        router.replace({
          name: targetRoute.name || undefined,
          params: targetRoute.params,
          query: targetRoute.query,
          hash: targetRoute.hash
        });
      }
    });

    let backButtonLabel;
    if (APP.shell.info.type === 'Native' && APP.shell.info.platform !== 'PWA') {
      backButtonLabel = t('redirect.cancel');
    }
    const onBackClicked = () => {
      if (APP.nav.length > 0) {
        APP.nav.back();
      } else {
        router.push({
          name: RouteName.LibraryHome,
          params: {
            libraryId: props.currentLibraryId
          }
        });
      }
    };

    const signOutAndContinue = () => {
      console.log('[REDIRECT] Signing out...');
      // TODO: Migrate this to a shared logout function
      // when ELRO-979 gets done
      APP.bank.wipe();
      APP.bank.freeze();
      APP.shell.transmit('roster:wipe:all');
      APP.shell.transmit('title:info');

      // Sign the user out (waiting for a while to give the bank and rosters events time to occur)
      setTimeout(async () => {
        await APP.patron.reset(false);
        APP.events.dispatch('auth:logout');

        console.log('[REDIRECT] Sign out complete, redirecting...');

        location.href = router.resolve(targetUrl.value).href || env.ROOT_URI;
      }, 200);
    };

    return {
      backButtonLabel,
      onBackClicked,
      currentLibraryName,
      displayUrl,
      signOutAndContinue,
      targetLibrary,
      targetLibraryName
    };
  }
});
