
import { computed, defineComponent } from 'vue';
import type { Series } from 'app/models/series';
import type { TitleRecord } from 'app/models/title';
import AccordionSubjectList from 'app/components/AccordionSubjectList.vue';
import Accordion, { AccordionSection } from 'app/components/Accordion.vue';
import TableOfContents from 'app/components/TableOfContents.vue';
import TitleDetailsAccordionDetails from 'app/components/TitleDetailsAccordionDetails.vue';
import TitlePriorReleaseList from 'app/components/TitlePriorReleaseList.vue';
import TitleDetailsAccordionVolumes from 'app/components/TitleDetailsAccordionVolumes.vue';
import ShowAll from 'app/components/ShowAll.vue';

export default defineComponent({
  name: 'TitleDetailsAccordion',
  components: {
    Accordion,
    TableOfContents,
    TitleDetailsAccordionVolumes,
    TitleDetailsAccordionDetails,
    TitlePriorReleaseList,
    AccordionSubjectList,
    ShowAll
  },
  props: {
    title: {
      type: Object as () => TitleRecord,
      required: true
    },
    series: {
      type: Object as () => Series | null,
      default: null
    }
  },
  setup: (props) => {
    const sections = computed(() => {
      const sectionList: AccordionSection[] = [
        {
          id: 'toc',
          label: 'title.tableOfContents'
        },
        {
          id: 'description',
          label: 'title.description'
        },
        {
          id: 'details',
          label: 'title.detailedInformation'
        }
      ];

      if (props.title.subjects?.length) {
        sectionList.push({
          id: 'subjects',
          label: 'title.subjects'
        });
      }

      if (props.series && props.series.items.length > 1) {
        sectionList.push({
          id: 'volumes',
          label: 'series.volumeCount',
          badge: props.series.items.length
        });
      }

      if (props.title.lexisMetadata?.priorReleases?.length) {
        sectionList.push({
          id: 'releases',
          label: 'title.priorReleases'
        });
      }

      return sectionList;
    });

    return {
      sections
    };
  }
});
