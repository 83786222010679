
import { reference } from '@popperjs/core';
import { APP } from 'app/base/app';
import { ClipboardHelper } from 'app/base/clipboard';
import { C } from 'app/base/common';
import ActionToast from 'app/components/ActionToast.vue';
import ContextMenu, { ContextMenuOption } from 'app/components/contextMenus/ContextMenu.vue';
import { useAppEvents } from 'app/functions/use-app-events';
import { SelectableAnnotation } from 'app/functions/use-display-annotation';
import { useI18n } from 'app/functions/use-i18n';
import { RouteName } from 'app/router/constants';
import { Quirks } from 'lib/quirkbase/quirkbase';
import { computed, defineComponent, nextTick, PropType, ref } from 'vue';

export default defineComponent({
  name: 'ExportQueueContextMenu',
  components: {
    ContextMenu,
    ActionToast
},
  props: {
    reference: {
      type: Object as PropType<HTMLElement | null>,
      default: undefined
    },
    annotations: {
      type: Object as PropType<SelectableAnnotation[]>,
      required: true
    },
    show: {
      type: Boolean,
      required: true
    },
    exportOptions: {
      type: Boolean,
      required: false,
      default: false
    },
    showCount: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    'clear',
    'close'
  ],
  setup: (props, ctx) => {
    const { dispatch } = useAppEvents();
    const { t } = useI18n();

    const showActionToast = ref(false);
    const actionMessage = ref('');

    const annotationIds = computed(() => props.annotations.map((a) => a.uuid));

    const exportAnnotation = async (type: 'pdf' | 'csv' | 'drive') => {
      const dateInfo = C.timeUnitsToNumericalDate(C.timestampToUnits(Date.now())).replace(/\//g, '_');
      const filename  = `Annotation_Export_${dateInfo}`;
      let success = false;

      try {
        if (Quirks.ask('safari')) {
          success = !!APP.shell.openInNewViewAsync(() => APP.services.postish.getExportUrl(annotationIds.value, type, filename));
        } else {
          const url = await APP.services.postish.getExportUrl(annotationIds.value, type, filename);
          success = !!APP.shell.openInNewView(url);
        }
      } catch (ex) {
        console.error('[EXPORT] Error fetching export url', ex);
      }

      if (success) {
        dispatchToast('success', t('exportQueue.contextMenu.export.success'), true);

        APP.tracking.log('notes_exported', {
          note_ids: annotationIds.value,
          export_name: filename,
          export_type: type
        });
      } else {
        dispatchToast('error', t('exportQueue.contextMenu.export.failure'));
      }
    };

    const exportPDF = () => {
      exportAnnotation('pdf');

      ctx.emit('close');
    };

    const exportCSV = () => {
      exportAnnotation('csv');

      ctx.emit('close');
    };

    const exportDrive = () => {
      exportAnnotation('drive');

      ctx.emit('close');
    };

    const copyAnnotation = async () => {
      const toCopyString = (annotation: SelectableAnnotation) => [
        annotation.quote,
        annotation.citation,
        annotation.note
      ].filter((i) => !!i).join('\n\n');

      const copyString = props.annotations.map(toCopyString).join('\n\n\n-------------------------------\n\n\n');

      const success = APP.flags.get('force_error_toast') ? false : await ClipboardHelper.copyToClipboard(copyString);

      dispatchToast(success ? 'success' : 'error', t(`exportQueue.contextMenu.copy.${success ? 'success' : 'failure'}`), success);

      ctx.emit('close');
    };

    const removeFromQueue = async () => {
      if (showActionToast.value) { closeActionToast(); }

      dispatch('export-queue:remove', props.annotations.map((a) => a.uuid));

      dispatchToast('success', t('exportQueue.contextMenu.remove.success', { N: props.annotations.length }));

      ctx.emit('close');
    };

    const contextMenuOptions = computed<ContextMenuOption[]>(() => {
      const opts = [
        {
          label: t('exportQueue.contextMenu.export.drive'),
          iconName: 'google-drive',
          handler: exportDrive
        },
        {
          label: t('exportQueue.contextMenu.export.pdf'),
          iconName: 'file-pdf',
          handler: exportPDF
        },
        {
          label: t('exportQueue.contextMenu.export.csv'),
          iconName: 'csv',
          handler: exportCSV
        },
        {
          label: t('exportQueue.contextMenu.copy.label'),
          iconName: 'copy-to-clipboard',
          handler: copyAnnotation as (() => void) | (() => Promise<void>)
        },
        {
          label: t('exportQueue.contextMenu.annotations.label'),
          iconName: 'nav-notes',
          location: {
            name: RouteName.NoteDetails,
            params: { titleSlug: props.annotations[0]?.titleSlug }
          }
        },
        {
          label: t('exportQueue.contextMenu.remove.label'),
          iconName: 'dismiss',
          handler: removeFromQueue
        }
      ] as ContextMenuOption[];

      if (props.exportOptions) {
        opts.splice(4, 1); //remove annotation details link
      } else {
        opts.splice(0, 3); //remove export actions
      }

      return opts;
    });

    const dispatchToast = async (type: 'success' | 'error' | 'info', message: string, actionable = false) => {
      await nextTick();

      if (actionable) {
        showActionToast.value = true;
        actionMessage.value = message;
      } else {
        dispatch('toast', {
          type: type,
          message: message
        });
      }
    };

    const closeActionToast = async () => {
      ctx.emit('clear');
      showActionToast.value = false;
      actionMessage.value = '';

      await nextTick();
      props.reference?.focus();
    };

    return {
      actionMessage,
      closeActionToast,
      contextMenuOptions,
      removeFromQueue,
      showActionToast
    };
  }
});
