
import TitleCard from 'app/components/cards/TitleCard.vue';
import TitleCardCopyStatus from 'app/components/cards/TitleCardCopyStatus.vue';
import TitleCardContextMenu from 'app/components/contextMenus/TitleCardContextMenu.vue';
import CoverView from 'app/components/CoverView.vue';
import PriorReleaseSidebar from 'app/components/PriorReleaseSidebar.vue';
import TableOfContentsSidebar from 'app/components/TableOfContentsSidebar.vue';
import TagIndicator from 'app/components/TagIndicator.vue';
import { CopyJobsState } from 'app/functions/use-copy-jobs-state';
import { useTitleSubscription } from 'app/functions/use-subscription-interaction';
import { TitleRecord } from 'app/models/title';
import { RouteName } from 'app/router/constants';
import { computed, defineComponent, onMounted, PropType, ref } from 'vue';
import { RouteLocationRaw } from 'vue-router';

/**
 * @desc - The notes-based implementation of the title card component
 */
export default defineComponent({
  components: {
    PriorReleaseSidebar,
    TableOfContentsSidebar,
    TitleCard,
    TitleCardContextMenu,
    TitleCardCopyStatus,
    CoverView,
    TagIndicator
},
  props: {
    /**
     * @param title - The title to display in card form
     */
    title: {
      type: Object as PropType<TitleRecord>,
      required: true
    },
    copyJobsState: {
      type: Object as PropType<CopyJobsState>,
      default: undefined
    }
  },
  setup: (props) => {
    const overflow = ref<HTMLElement | null>(null);
    const actions = ref<HTMLElement | null>(null);

    const showContextMenu = ref(false);
    const showCoverView = ref(false);
    const showPriorReleases = ref(false);
    const showTableOfContents = ref(false);
    const iconOnly = ref<boolean>(false);

    const updateIcons = (entries: ResizeObserverEntry[]) => {
      const entry = entries[0];
      const cutoff = 600;

      iconOnly.value = entry.contentRect.width < cutoff;
    };

    const resizeObserver = new ResizeObserver(updateIcons);

    onMounted(() => {
      if (actions.value) {
        resizeObserver.observe(actions.value);
      }
    });

    const {
      subscribeTextLong,
      toggleSubscription
    } = useTitleSubscription(props.title, 'note card');

    const detailsLink = computed<RouteLocationRaw>(() => {
      const route: RouteLocationRaw = {
        name: RouteName.NoteDetails,
        params: { titleSlug: props.title.slug }
      };

      return route;
    });

    const jobsForTitle = computed(() => {
      const filteredJobs = props.copyJobsState?.status === 'loaded'
        ? props.copyJobsState.jobs.filter((job) => job.target_title.title_id === props.title.slug)
        : [];

      return {
        ...props.copyJobsState,
        jobs: filteredJobs
      };
    });

    const hideOverlay = () => {
      showCoverView.value = false;
      showPriorReleases.value = false;
      showTableOfContents.value = false;
      overflow.value?.focus();
    };

    return {
      actions,
      detailsLink,
      hideOverlay,
      iconOnly,
      jobsForTitle,
      overflow,
      showContextMenu,
      showCoverView,
      showPriorReleases,
      showTableOfContents,
      subscribeTextLong,
      toggleSubscription
    };
  }
});
