import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { "data-column": "selected" }
const _hoisted_2 = { "data-column": "expander" }
const _hoisted_3 = ["aria-label"]
const _hoisted_4 = { "data-column": "color" }
const _hoisted_5 = { "data-column": "highlight" }
const _hoisted_6 = {
  key: 0,
  "data-column": "note"
}
const _hoisted_7 = { "data-column": "citation" }
const _hoisted_8 = { "data-column": "release" }
const _hoisted_9 = { "data-column": "lastUpdated" }
const _hoisted_10 = { "data-column": "actions" }
const _hoisted_11 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormCheckbox = _resolveComponent("FormCheckbox")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_HighlightColor = _resolveComponent("HighlightColor")!
  const _component_DynamicExpander = _resolveComponent("DynamicExpander")!
  const _component_TitleActionButton = _resolveComponent("TitleActionButton")!
  const _component_RelativeDate = _resolveComponent("RelativeDate")!
  const _component_AnnotationDetailsContextMenu = _resolveComponent("AnnotationDetailsContextMenu")!

  return (_openBlock(), _createElementBlock("tr", {
    class: _normalizeClass({
      [_ctx.$style.row]: true,
      [_ctx.$style.selected]: _ctx.displayAnnotation.selected,
      [_ctx.$style.expanded]: _ctx.expanded
    })
  }, [
    _createElementVNode("td", _hoisted_1, [
      _createVNode(_component_FormCheckbox, {
        modelValue: _ctx.displayAnnotation.selected ? 'on' : 'off',
        showLabel: false,
        label: _ctx.$t('annotations.display.select'),
        ariaLabel: _ctx.$t('annotations.display.select'),
        class: _normalizeClass(_ctx.$style.checkbox),
        "onUpdate:modelValue": _ctx.updateSelected
      }, null, 8, ["modelValue", "label", "ariaLabel", "class", "onUpdate:modelValue"])
    ]),
    _createElementVNode("td", _hoisted_2, [
      _createElementVNode("button", {
        ref: "expansionButton",
        type: "button",
        class: _normalizeClass(_ctx.$style.expander),
        "aria-label": _ctx.$t('annotations.display.expandText'),
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleExpansion && _ctx.toggleExpansion(...args)))
      }, [
        _createVNode(_component_Icon, { name: "chevron-down" })
      ], 10, _hoisted_3)
    ]),
    _createElementVNode("td", _hoisted_4, [
      _createVNode(_component_HighlightColor, {
        colorGroup: _ctx.displayAnnotation.colorGroup
      }, null, 8, ["colorGroup"])
    ]),
    _createElementVNode("td", _hoisted_5, [
      _createVNode(_component_DynamicExpander, {
        panelId: `annotation-highlight-panel-${_ctx.displayAnnotation.id}`,
        headerId: `highlight-${_ctx.displayAnnotation.id}`,
        numElements: _ctx.expanded ? 2 : 1
      }, {
        default: _withCtx(() => [
          _createElementVNode("p", {
            class: _normalizeClass(_ctx.$style.text)
          }, _toDisplayString(_ctx.displayAnnotation.highlight), 3)
        ], undefined, true),
        _: 1
      }, 8, ["panelId", "headerId", "numElements"])
    ]),
    (!_ctx.hideNote)
      ? (_openBlock(), _createElementBlock("td", _hoisted_6, [
          _createVNode(_component_DynamicExpander, {
            panelId: `annotation-note-panel-${_ctx.displayAnnotation.id}`,
            headerId: `note-${_ctx.displayAnnotation.id}`,
            numElements: _ctx.expanded ? 2 : 1
          }, {
            default: _withCtx(() => [
              (_ctx.displayAnnotation.note)
                ? (_openBlock(), _createElementBlock("p", {
                    key: 0,
                    class: _normalizeClass(_ctx.$style.text)
                  }, _toDisplayString(_ctx.displayAnnotation.note), 3))
                : (_openBlock(), _createElementBlock("p", {
                    key: 1,
                    class: _normalizeClass(_ctx.$style.noNote)
                  }, _toDisplayString(_ctx.$t('annotations.display.noNote')), 3))
            ], undefined, true),
            _: 1
          }, 8, ["panelId", "headerId", "numElements"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("td", _hoisted_7, [
      _createVNode(_component_DynamicExpander, {
        panelId: `annotation-citation-panel-${_ctx.displayAnnotation.id}`,
        headerId: `citation-${_ctx.displayAnnotation.id}`,
        numElements: _ctx.expanded ? 2 : 1,
        class: _normalizeClass(_ctx.$style.citationPanel)
      }, {
        default: _withCtx(() => [
          (_ctx.displayAnnotation.releaseTitleRecord)
            ? (_openBlock(), _createBlock(_component_TitleActionButton, {
                key: 0,
                title: _ctx.displayAnnotation.releaseTitleRecord,
                parent: _ctx.displayAnnotation.parentTitleRecord,
                seekTo: {
            type: 'highlight',
            location: _ctx.displayAnnotation.id
          },
                label: _ctx.displayAnnotation.citation,
                truncate: true,
                class: _normalizeClass([_ctx.$style.text, _ctx.$style.linkText])
              }, null, 8, ["title", "parent", "seekTo", "label", "class"]))
            : (_openBlock(), _createElementBlock("p", {
                key: 1,
                class: _normalizeClass(_ctx.$style.text)
              }, _toDisplayString(_ctx.displayAnnotation.citation), 3))
        ], undefined, true),
        _: 1
      }, 8, ["panelId", "headerId", "numElements", "class"])
    ]),
    _createElementVNode("td", _hoisted_8, [
      _createElementVNode("p", null, _toDisplayString(_ctx.displayAnnotation.release), 1),
      _createElementVNode("p", null, _toDisplayString(_ctx.displayAnnotation.releaseDate), 1)
    ]),
    _createElementVNode("td", _hoisted_9, [
      _createVNode(_component_RelativeDate, {
        timestamp: _ctx.displayAnnotation.lastUpdated
      }, null, 8, ["timestamp"])
    ]),
    _createElementVNode("td", _hoisted_10, [
      _createElementVNode("button", {
        ref: "actions",
        class: _normalizeClass(_ctx.$style.actions),
        "aria-label": _ctx.$t('annotations.display.actions.label'),
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showContextMenu = true))
      }, [
        _createVNode(_component_Icon, { name: "overflow" })
      ], 10, _hoisted_11),
      (_ctx.showContextMenu)
        ? (_openBlock(), _createBlock(_component_AnnotationDetailsContextMenu, {
            key: 0,
            reference: _ctx.actions,
            annotations: [_ctx.annotation],
            showCount: false,
            showSearchOption: !!_ctx.showSearchOption,
            onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showContextMenu = false))
          }, null, 8, ["reference", "annotations", "showSearchOption"]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}