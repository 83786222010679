
import DynamicExpander from 'app/components/DynamicExpander.vue';
import FilterAppliedButton from 'app/components/FilterAppliedButton.vue';
import { AppliedFilters, FilterCategoryObject, FilterOptionSort, FilterOptionType } from 'app/components/FilterButton.vue';
import FilterList from 'app/components/FilterList.vue';
import FormSubmitButton from 'app/components/FormSubmitButton.vue';
import { useI18n } from 'app/functions/use-i18n';
import { useSelectable } from 'app/functions/use-selectable';
import { FilterCategory, FilterObject } from 'app/models/filter-object';
import { PropType, computed, defineComponent, ref } from 'vue';

type CategoryPanel = {
  category: FilterCategory;
  label: string;
  optionType: FilterOptionType;
  sort: FilterOptionSort;
  active: boolean;
  length: number;
};

type CategorizedFilters = {
  [category in FilterCategory]: FilterObject[];
};

export default defineComponent({
  name: 'FilterContent',
  components: {
    FilterList,
    FilterAppliedButton,
    DynamicExpander,
    FormSubmitButton
  },
  props: {
    options: {
      type:  Array as PropType<FilterObject[]>,
      required: true
    },
    categoryObjects: {
      type: Array as PropType<FilterCategoryObject[]>,
      required: true
    }
  },
  emits: [
    'apply'
  ],
  setup: (props, ctx) => {
    const { t } = useI18n();

    const categories = ref<CategoryPanel[]>([]);
    props.categoryObjects.forEach((cat) => {
      categories.value.push({
        category: cat.id,
        label: t(`filters.categories.${cat.id}`),
        optionType: cat.optionType,
        sort: cat.sort || 'alphaAsc',
        active: props.categoryObjects.length === 1 ? true : false,
        length: 0
      });
    });

    const changesMade = ref(false);

    //list of ids of already applied options
    const selectedIds = computed(() => props.options.filter((option) => option.selected).map((applied) => applied.id));

    //list of all ids
    const allIds = computed(() => props.options.map((option) => option.id));

    const selectable = useSelectable(allIds, selectedIds.value);

    const optionsByCategory = computed(() => {
      //separate all the options by their category
      const allByCategory = {} as CategorizedFilters;
      categories.value.forEach((cat) => {
        allByCategory[cat.category] = props.options.filter((option) => option.category === cat.category);
        cat.length = allByCategory[cat.category].length;
      });

      return allByCategory;
    });

    //any changes made in children components, update accordingly
    const updateSelected = (option: FilterObject) => {
      //if a FilterAppliedButton was clicked to remove it,
      //shift the keyboard focus from that button to a sibling FilterAppliedButton
      //or if no siblings, shift the focus up to the category header button
      const selectedElement = document.getElementById(`applied-filter-${option.id}`);
      const parent = selectedElement?.parentElement;

      if (document.activeElement === selectedElement) {
        const sibling =
          parent?.nextElementSibling?.children[0]
          || parent?.previousElementSibling?.children[0]
          || document.getElementById(option.category);

        (sibling as HTMLElement)?.focus();
      }

      //to prevent a removed FilterAppliedButton from changing its position due to the position:absolute on leave-active
      if (parent) {
        parent.style.left = parent.offsetLeft + 'px';
        parent.style.top = parent.offsetTop + 'px';
      }

      selectable.toggle(option.id);
      changesMade.value = true;
    };

    const appliedByCategory = computed(() => {
      const selectedByCategory = {} as CategorizedFilters;
      categories.value.forEach((cat) => selectedByCategory[cat.category] = optionsByCategory.value[cat.category]
        .filter((sub) => selectable.isSelected(sub.id))
        .sort((a, b) => cat.sort === 'alphaDesc' ? b.name.localeCompare(a.name) : a.name.localeCompare(b.name))
      );

      return selectedByCategory;
    });

    const toggleHeader = (category: CategoryPanel) => {
      category.active = !category.active;
      category.length = optionsByCategory.value[category.category].length;
    };

    const apply = () => {
      if (!changesMade.value) { return; }
      //need to have selected ids for each category
      const filters = {} as AppliedFilters;
      categories.value.forEach((cat) => {
        const ids = [] as string[];
        appliedByCategory.value[cat.category].forEach((applied) => ids.push(applied.id));
        filters[cat.category] = ids;
      });

      ctx.emit('apply', filters);
    };

    return {
      categories,
      updateSelected,
      changesMade,
      optionsByCategory,
      appliedByCategory,
      toggleHeader,
      apply
    };
  }
});
