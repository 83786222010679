
import { APP } from 'app/base/app';
import { HighlightColorGroup } from 'app/base/constants';
import AnnotationDetailsContent, { AnnotationDetailsAnnotation } from 'app/components/AnnotationDetailsContent.vue';
import AnnotationDetailsCopyContent from 'app/components/AnnotationDetailsCopyContent.vue';
import FilterButton from 'app/components/FilterButton.vue';
import LoadingPage from 'app/components/LoadingPage.vue';
import Page from 'app/components/Page.vue';
import Surface from 'app/components/Surface.vue';
import TextFilter from 'app/components/TextFilter.vue';
import { injectOverlayContext } from 'app/contexts/overlay-context';
import { startCopy } from 'app/functions/use-annotation-copying';
import { AnnotationFilters, getQueryParametersFromAnnotationFilters, useAnnotationFilters } from 'app/functions/use-annotation-filters';
import { useCopyJobs } from 'app/functions/use-copy-jobs';
import { useCopyJobsState } from 'app/functions/use-copy-jobs-state';
import { normalizeDate } from 'app/functions/use-normalize-date';
import { usePatron } from 'app/functions/use-patron';
import { useReleaseFilter } from 'app/functions/use-release-filter';
import { Breakpoint, useWindowSize } from 'app/functions/use-window-size';
import { ADAllowStTOption, ADCopyJobState, ADReleasesWithAnnotationsSymbol } from 'app/keys/injection-keys';
import { Annotation } from 'app/models/annotation';
import { TitleMapper, TitleRecord } from 'app/models/title';
import { RouteName } from 'app/router/constants';
import NotFound from 'app/views/NotFound.vue';
import { PropType, computed, defineComponent, provide, ref, watch, watchEffect } from 'vue';
import { useRouter } from 'vue-router';

type State =
  {
    state: 'loading';
  }
  | {
    state: 'loaded';
    title: TitleRecord;
    priorReleases: TitleRecord[];
  }
  | {
    state: 'notfound';
  };

export default defineComponent({
    name: 'AnnotationDetails',
    components: {
      Page,
      Surface,
      NotFound,
      AnnotationDetailsContent,
      LoadingPage,
      TextFilter,
      FilterButton,
      AnnotationDetailsCopyContent
    },
    props: {
      titleSlug: {
        type: String,
        required: true
      },
      releaseFilters: {
        type: Array as PropType<string[]>,
        default: undefined
      },
      colorFilters: {
        type: Array as PropType<HighlightColorGroup[]>,
        default: undefined
      }
    },
    setup: (props, ctx) => {
      const router = useRouter();

      const { windowWidth } = useWindowSize();
      const mobile = computed(() => windowWidth.value <= Breakpoint.VeryWide);

      const state = ref<State>({ state: 'loading' });

      const { annotations: allAnnotations } = usePatron();

      const annotationsForTitle = computed(() => allAnnotations.value.filter((a) => a.titleSlug === props.titleSlug));

      const releases = computed<TitleRecord[]>(() => state.value.state === 'loaded'
        ? [state.value.title, ...(state.value.priorReleases || [])] as TitleRecord[]
        : []);

      const buildDetailsAnnotations = (annotations: Annotation[]) => annotations.map((a) => {
        return {
          ...a,
          shown: false,
          selected: false,
          parentTitleRecord: state.value.state === 'loaded' ? state.value.title : undefined,
          releaseTitleRecord: releases.value.find((release) => release.lexisMetadata.release === a.release && normalizeDate(release.lexisMetadata.releaseDate) === normalizeDate(a.releaseDate))
        };
      }).sort((a, b) => b.syncstamp - a.syncstamp);

      const detailsAnnotations = ref<AnnotationDetailsAnnotation[]>([]);
      watchEffect(() => detailsAnnotations.value = buildDetailsAnnotations(annotationsForTitle.value));

      const {
        textFilter,
        releaseFilter,
        colorFilter,
        filterObjects,
        filteredAnnotations,
        displayCounts
      } = useAnnotationFilters(annotationsForTitle, releases);

      watchEffect(() => releaseFilter.value = props.releaseFilters);
      watchEffect(() => colorFilter.value = props.colorFilters);

      // Reset selected values when the release or color filters change
      watch(releaseFilter, () => detailsAnnotations.value.forEach((a) => a.selected = false));
      watch(colorFilter, () => detailsAnnotations.value.forEach((a) => a.selected = false));

      /* This could maybe be more efficient by incorporating the 'shown' mapping directly into the filters,
       * but it would likely be harder to read and less reusable
       */
      const idsToShow = computed(() => new Set(filteredAnnotations.value.map((a) => a.uuid)));
      watchEffect(() => detailsAnnotations.value.forEach((a) => a.shown = idsToShow.value.has(a.uuid)));

      watch(() => props.titleSlug, async (slug) => {
        state.value = { state: 'loading' };

        try {
          // make sure annotations are up to date
          await APP.patron.syncCoordinator.remoteSync({ type: ['annotations'] });

          const title = await fetchTitle(slug);

          if (!title) {
            state.value = {
              state: 'notfound'
            };

            return;
          }

          if (title.lexisMetadata?.parent) {
            router.replace({ params: { titleSlug: title.lexisMetadata.parent } });

            return;
          }

          const priorReleases = await title.getPriorReleases();

          state.value = {
            state: 'loaded',
            title,
            priorReleases
          };
        } catch {
          state.value = {
            state: 'notfound'
          };
        }
      }, { immediate: true });


      const pageTitle = computed(() => {
        return state.value.state === 'loaded'
          ? state.value.title.title
          : undefined;
      });

      const subtitle = computed(() => {
        return state.value.state === 'loaded'
          ? state.value.title.subtitle
          : undefined;
      });

      const updatePath = (filters: AnnotationFilters) => {
        const newRoute = {
          name: RouteName.NoteDetails,
          query: {
            ...getQueryParametersFromAnnotationFilters(filters)
          }
        };

        router.replace(newRoute);
      };


      // Annotation Copying

      provide(ADReleasesWithAnnotationsSymbol, computed(() => {
        const { getFilterObjects: getReleaseObjects } = useReleaseFilter(annotationsForTitle, releases);

        return getReleaseObjects(annotationsForTitle.value);
      }));


      const { submittedData } = injectOverlayContext('ManualCopySidebar');

      watch(() => submittedData.value, async () => {
        if (state.value.state === 'loaded') {
          // kick off new copy job
          await startCopy(state.value.title.slug, submittedData.value.release);

          // If the user starts a copy job, but hasn't opened the newest release yet,
          // switch them to the newest so they don't get new release / copying prompts
          const shouldUpdate = await state.value.title.isOutOfDate();

          if (shouldUpdate) {
            state.value.title.updateDownloads();
          }
        }
      });

      const {
        copyJobs,
        loading: copyJobsLoading,
        error: copyJobsError,
        startPolling
      } = useCopyJobs(props.titleSlug);

      const {
        copyJobsState
      } = useCopyJobsState(copyJobs, copyJobsLoading, copyJobsError);

      startPolling();

      provide(ADCopyJobState, copyJobsState);

      provide(ADAllowStTOption, computed(() => {
        // Only Lexis published titles work with StT
        return state.value.state === 'loaded'
          ? state.value.title.isLexisPublished
            && state.value.title.hasODRFormat
            && state.value.title.mediaType === 'book'
          : false;
      }));

      return {
        detailsAnnotations,
        filterObjects,
        mobile,
        pageTitle,
        displayCounts,
        state,
        subtitle,
        textFilter,
        updatePath
      };
    }
});

const fetchTitle = async (slug: string) => {
  // We don't want to fetch the title through
  // the title cache, because a user could hit
  // this URL for a title they don't have notes in.
  // We wouldn't want to add that title to the cache,
  // which it would be if we did APP.titleCache.getFreshTitles().
  // Instead, we fetch from thunder if we don't have a fresh title,
  // and let the sync process add it to the cache later.
  const cachedTitle = APP.titleCache.getIfFresh(slug);
  if (cachedTitle) { return cachedTitle; }

  const thunderTitle = await APP.services.thunder.getTitle(APP.library.key(), slug);
  const title = TitleMapper.mapFromThunder(thunderTitle);

  return title;
};
