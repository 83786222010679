
import type { IdNamePair } from 'app/base/thunder';
import RouteList from 'app/components/RouteList.vue';
import { useBreadcrumbs } from 'app/functions/use-breadcrumbs';
import { RouteName } from 'app/router/constants';
import { computed, defineComponent } from 'vue';
import { RouteLocationRaw } from 'vue-router';
import BreadcrumbNavigation from './BreadcrumbNavigation.vue';

export type MetadataItem = {
  label: string;
  value: string;
  show: boolean;
};

export default defineComponent({
  name: 'DetailsPageContent',
  components: {
    BreadcrumbNavigation,
    RouteList
  },
  props: {
    metadata: {
      type: Array as () => MetadataItem[],
      default: () => []
    },
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      default: undefined
    },
    authors: {
      type: Array as () => IdNamePair[],
      default: () => []
    },
    label: {
      type: String,
      default: undefined
    }
  },
  setup: (props, ctx) => {
    const shownMetadata = computed(() =>
      props.metadata.filter((m) => m.show)
    );

    const authorLinks = computed(() => {
      return props.authors.map((author) => {
        return {
          text: author.name,
          to: {
            name: RouteName.Creator,
            params: {
              creatorId: author.id
            }
          } as RouteLocationRaw
        };
      });
    });

    const breadcrumb = useBreadcrumbs();

    return {
      authorLinks,
      shownMetadata,
      breadcrumb
    };
  }
});
