
import BackToTopButton from 'app/components/BackToTopButton.vue';
import PageHeaderBasic from 'app/components/PageHeaderBasic.vue';
import PageHeaderTitle from 'app/components/PageHeaderTitle.vue';
import { Title } from 'app/models/title';
import { computed, defineComponent, PropType, ref } from 'vue';
import { useRoute } from 'vue-router';

export default defineComponent({
  components: {
    PageHeaderBasic,
    PageHeaderTitle,
    BackToTopButton
  },
  props: {
    header: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      default: undefined
    },
    title: {
      type: Object as PropType<Title>,
      default: undefined
    },
    useBackToTop: {
      type: Boolean,
      default: false
    }
  },
  setup: (props, ctx) => {
    const pageHeader = ref<InstanceType<typeof PageHeaderTitle | typeof PageHeaderBasic> | null>(null);
    const pageHeaderEl = computed(() => pageHeader.value && pageHeader.value.$el);

    const showBackToTop = ref(false);
    // use breadcrumb as the top of the main section to focus on
    // for top level pages without breadcrumbs, use user menu button to focus on
    const route = useRoute();
    const focusOn = route.meta.top ? '[data-menu_focus]' : '[data-breadcrumb_focus]';

    return {
      pageHeader,
      pageHeaderEl,
      showBackToTop,
      focusOn
    };
  }
});
