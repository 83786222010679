
import { Breakpoint, useWindowSize } from 'app/functions/use-window-size';
import { computed, defineComponent } from 'vue';

/**
 * An empty state for when there is nothing else to show
 * @displayName EmptyState
 */
export default defineComponent({
  name: 'EmptyState',
  props: {
    /**
     * The text to display in the header of the empty state
     */
    header: {
      type: String,
      required: true
    },
    /**
     * The main content to display in the empty state
     */
    content: {
      type: String,
      required: true
    },
    /**
     * When true, compact styles and icons are used. Good for tight spaces.
     */
    isCompact: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * When true, icons and styles will be based on the dark theme
     */
    isDarkMode: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * URL to more information.
     * Note: If "link" is not defined, the help link label will not be rendered.
     */
    link: {
      type: String,
      required: false,
      default: undefined
    },
    /**
     * The label to use for the help link in the empty state
     * Note: If "link" is not defined, this label will not be rendered.
     */
    linkLabel: {
      type: String,
      required: false,
      default: undefined
    }
  },
  setup: (props, ctx) => {
    const { windowWidth } = useWindowSize();
    const isMobile = computed(() => windowWidth.value <= Breakpoint.Tablet);
    const lightnessMod = computed(() => props.isDarkMode ? 'dark' : 'light');
    const styleMod = computed(() => props.isCompact
      ? 'compact'
      : isMobile.value
        ? 'short'
        : 'wide'
    );
    const iconName = computed(() => `es-generic-${lightnessMod.value}-${styleMod.value}`);
    const iconClass = computed(() => props.isCompact
      ? 'logoImageCompact'
      : isMobile.value
        ? 'logoImageShort'
        : 'logoImageWide'
    );

    return {
      iconName,
      iconClass
    };
  }
});
