
import FormSelect, { PropertySelectFunc } from 'app/components/FormSelect.vue';
import { Breakpoint, useWindowSize } from 'app/functions/use-window-size';
import { generateUUID } from 'lib/common';
import { computed, defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'LabeledDropdown',
  components: {
    FormSelect
  },
  props: {
    modelValue: {
      type: Object,
      default: undefined
    },
    options: {
      type: Array,
      required: true
    },
    optionLabel: {
      type: [String, Function] as PropType<string | PropertySelectFunc>,
      required: true
    },
    optionKey: {
      type: [String, Function] as PropType<string | PropertySelectFunc>,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    prompt: {
      type: String,
      default: undefined
    },
    hideLabelOnSmallScreens: {
      type: Boolean,
      default: true
    }
  },
  emits: [
    'update:modelValue'
  ],
  setup: (props, ctx) => {
    const labelElementId = `formselect:label:${generateUUID()}`;

    const selected = computed({
      get: () => props.modelValue,
      set: (val) => ctx.emit('update:modelValue', val)
    });

    const { windowWidth } = useWindowSize();

    const hideLabel = computed(() => windowWidth.value < Breakpoint.Narrow);

    return {
      labelElementId,
      hideLabel,
      selected
    };
  }
});
