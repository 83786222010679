
import { LoginFormSubmission } from 'app/base/interfaces';
import FormError from 'app/components/FormError.vue';
import LibraryLogo from 'app/components/LibraryLogo.vue';
import LoginForm from 'app/components/LoginForm.vue';
import { useAppEvents } from 'app/functions/use-app-events';
import { useAuthentication } from 'app/functions/use-authentication';
import { parseQueryString } from 'lib/common';
import { computed, defineComponent, onMounted, watch } from 'vue';
import { useRoute } from 'vue-router';

type GhostAuthOption = 'od' | 'ln' | 'none';

export default defineComponent({
  name: 'Login',
  components: {
    LibraryLogo,
    LoginForm,
    FormError
  },
  props: {
    libraryId: {
      type: String,
      required: true
    },
    callback: {
      type: String,
      default: undefined
    }
  },
  setup: (props, ctx) => {
    const route = useRoute();
    const auth = useAuthentication(
      props.libraryId,
      route.query.origination as string
    );

    onMounted(() => auth.resolveLibrary());

    watch(auth.state, async (to, from) => {
      // Once we've resolved the library,
      // start fetching the login page.
      if (from.libraryState === 'resolving' && to.libraryState === 'resolved') {
        await auth.loadLoginPage(to.library);

        const ghostQuery = route.query.ghost as string;
        const ghost: GhostAuthOption = ghostQuery === 'od' || ghostQuery === 'ln'
          ? ghostQuery
          : 'none';

        if (ghost !== 'none') {
          authenticateGhost(ghost);

          return;
        }

        if (props.callback === 'callback') {
          const continuation = route.query.continuation as string;
          console.log('[AUTH] Completing external auth...');
          authenticateExternal(continuation);
        }
      }
    });

    const authenticate = async (data: LoginFormSubmission) => {
      const authResult = await auth.authenticate(data);

      if (authResult.state === 'success') {
        auth.completeAuthentication(authResult.response);
      }
    };

    const authenticateExternal = async (continuation: string) => {
      const authResult = await auth.authenticateExternal(continuation);

      if (authResult.state === 'success') {
        auth.completeAuthentication(authResult.response);
      }
    };

    const authenticateGhost = (ghostLogin: 'od' | 'ln') => {
      if (auth.state.value.libraryState === 'resolved'
        && auth.state.value.loginPage.state === 'loaded'
      ) {
        const loginPage = auth.state.value.loginPage.loginPage;
        if (ghostLogin === 'od' && loginPage.ghost) {
          authenticate({
            type: 'External',
            ilsName: loginPage.ghost.ilsName
          });
        } else if (ghostLogin === 'ln' && loginPage.ghostLexisNexis) {
          authenticate({
            type: 'External',
            ilsName: loginPage.ghostLexisNexis.ilsName
          });
        }
      }
    };

    useAppEvents({
      'msg:ui:oauth:callback': async ({ m: { url } }) => {
        if (auth.state.value.libraryState !== 'resolved') { return; }

        if (auth.state.value.loginPage.state !== 'loaded') {
          await auth.loadLoginPage(auth.state.value.library);
        }

        const params = parseQueryString(url.replace(/^[^\?]*/, ''));
        authenticateExternal(params.continuation);
      },
      'msg:ui:oauth:canceled': ({ m }) => {
        auth.cancelAuthentication();
      }
    });

    const cancelAuth = () => auth.cancelAuthentication();

    const library = computed(() => {
      if (auth.state.value.libraryState === 'resolved') {
        return auth.state.value.library;
      }

      return undefined;
    });

    return {
      authenticate,
      cancelAuth,
      library,
      state: auth.state
    };
  }
});
