
import { computed, defineComponent } from 'vue';
import { Constants } from 'app/base/constants';

export default defineComponent({
  name: 'WelcomeFooter',
  setup: (_, ctx) => {
    const currentYear = computed(() => (new Date()).getFullYear());

    const privacyPolicyLink = Constants.PRIVACY_POLICY_PATH;
    const termsLink = Constants.TERMS_AND_COND_PATH;

    return {
      currentYear,
      privacyPolicyLink,
      termsLink
    };
  }
});
