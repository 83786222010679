
import { Constants } from 'app/base/constants';
import EmptyState from 'app/components/EmptyState.vue';
import Page from 'app/components/Page.vue';
import Surface from 'app/components/Surface.vue';
import TextFilter from 'app/components/TextFilter.vue';
import { usePatron } from 'app/functions/use-patron';
import { useTextFilter } from 'app/functions/use-text-filter';
import { Tag } from 'app/models/tag';
import { RouteName } from 'app/router/constants';
import { computed, defineComponent } from 'vue';
import { RouteLocationRaw } from 'vue-router';

type DisplayTag = {
  id: string;
  name: string;
  count: number;
  route: RouteLocationRaw;
};

export default defineComponent({
  name: 'Tags',
  components: {
    EmptyState,
    Page,
    Surface,
    TextFilter
  },
  setup: () => {
    const helpLink = Constants.HELP_PATHS.TAGS;

    const { tags } = usePatron();
    const userTags = computed(() => tags.value.filter(Tag.FILTER_FUNCTIONS.nonSystemTags));

    const displayTags = computed<DisplayTag[]>(() => {
      const sorted = userTags.value.slice().sort(Tag.SORT_FUNCTIONS.alpha);
      const mapped = sorted.map((tag) => {
        return {
          id: tag.uuid,
          name: tag.name,
          count: tag.all?.length || 0,
          route: {
            name: RouteName.TagDetails,
            params: {
              tagSlug: tag.slug
            }
          }
        };
      });

      return mapped;
    });
    const hasTags = computed(() => displayTags.value.length > 0);

    const filterTags = (tag: DisplayTag, input: string) => {
      const tagNameLower = tag.name.toLocaleLowerCase();
      const inputLower = input.toLocaleLowerCase();

      return tagNameLower.includes(inputLower);
    };
    const { textFilter, filteredItems: filteredTags } = useTextFilter(displayTags, filterTags);

    return {
      helpLink,
      textFilter,
      hasTags,
      filteredTags
    };
  }
});
