
import { SearchThisTitleQuery } from 'app/base/hudson';
import SearchThisTitleContent from 'app/components/SearchThisTitleContent.vue';
import Sidebar from 'app/components/Sidebar.vue';
import { Series } from 'app/models/series';
import { TitleRecord } from 'app/models/title';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'SearchThisTitleSidebar',
  components: {
    SearchThisTitleContent,
    Sidebar
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    item: {
      type: Object as PropType<TitleRecord | Series>,
      default: undefined
    },
    params: {
      type: Object as PropType<SearchThisTitleQuery>,
      default: null
    },
    skipRouting: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    'close',
    'submit' // not used but needs to be listed for Overlays.vue
  ]
});
