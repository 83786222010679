
import { defineComponent, ref, watch } from 'vue';
import WelcomeView from 'app/components/WelcomeView.vue';
import { RouteName } from 'app/router/constants';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'app/functions/use-i18n';

export default defineComponent({
  components: {
    WelcomeView
  },
  setup: (_, ctx) => {
    const backButtonLabel = ref('');
    const route = useRoute();
    const router = useRouter();
    const { t } = useI18n();
    watch(route, (to) => {
      backButtonLabel.value = to.name === RouteName.Welcome
        ? undefined
        : t('welcome.header.back');
    }, { immediate: true, flush: 'post' });
    const back = () => {
      router.push({
        name: RouteName.Welcome
      });
    };

    return {
      back,
      backButtonLabel
    };
  }
});
