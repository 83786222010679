
import Icon from 'app/components/Icon.vue';
import { useForRefs } from 'app/functions/use-for-refs';
import { preserveFocus } from 'app/router/focus-management';
import { generateUUID } from 'lib/common/uuid';
import { computed, defineComponent, getCurrentInstance, nextTick, ref, watch } from 'vue';

export type DropdownOption = {
  id: string;
  displayName: string;
  count?: number;
  ariaLabel?: string;
};

export type TransitionClasses = {
  enterFrom: string;
  enterActive: string;
  leaveActive: string;
  leaveTo: string;
};

export default defineComponent({
  name: 'Dropdown',
  components: { Icon },
  props: {
    modelValue: {
      type: String,
      default: undefined
    },
    /**
     * A list of dropdown options.
     */
    options: {
      type: Array as () => DropdownOption[],
      required: true
    },
    /**
     * Shown when no option is selected.
     */
    placeholder: {
      type: String,
      default: undefined
    },
    /**
     * The dropdown's labeling element id.
     * This is usually a prompt, and is
     * combined with the currently selected option
     * to form the dropdown toggle's label.
     * If missing, the placeholder value will be used.
     */
    labelId: {
      type: String,
      default: undefined
    },
    /**
     * CSS class for the dropdown toggle button.
     */
    buttonClass: {
      type: String,
      default: ''
    },
    /**
     * CSS class for the dropdown toggle chevron.
     */
    iconClass: {
      type: String,
      default: ''
    },
    /**
     * CSS class for the dropdown list container.
     */
    listClass: {
      type: String,
      default: ''
    },
    /**
     * CSS class for an option in the dropdown list.
     */
    optionClass: {
      type: String,
      default: ''
    },
    /**
     * CSS class for the currently selected option in the dropdown list.
     */
    selectedClass: {
      type: String,
      default: ''
    },
    /**
     * CSS class for the currently highlighted option in the dropdown list.
     */
    highlightedClass: {
      type: String,
      default: ''
    },
    /**
     * CSS classes for the dropdown's show/hide transition.
     * @default fade in/out.
     */
    transition: {
      type: Object as () => TransitionClasses,
      default: () => ({
        enterFrom: 'fade-enter-from',
        enterActive: 'fade-enter-active',
        leaveActive: 'fade-leave-active',
        leaveTo: 'fade-leave-to'
      })
    }
  },
  emits: [
    'update:modelValue'
  ],
  setup: (props, ctx) => {
    const uuid = generateUUID();

    const buttonId = `dropdown-button-${uuid}`;
    const buttonRef = ref<HTMLButtonElement | null>(null);
    const fallbackLabelId = `dropdown-label-${uuid}`;
    const listId = `dropdown-list-${uuid}`;
    const listRef = ref<HTMLUListElement | null>(null);
    const { itemRefs, setItemRef } = useForRefs(getCurrentInstance());
    const optionElementId = (id: string) => `dropdown-option-${id}-${uuid}`;

    const optionFor = (id: string) => {
      return props.options.find((o) => o.id === id)!;
    };

    const buttonText = computed(() => {
      return props.modelValue
        ? optionFor(props.modelValue).displayName
        : (props.placeholder || '');
    });


    const activeDescendant = computed(() => {
      return open.value && highlighted.value
          ? optionElementId(highlighted.value)
          : undefined ;
    });


    // Toggling display

    const open = ref(false);

    const show = async () => {
      open.value = true;
      currentIndex.value = selectedIndex.value;
      await nextTick();
      listRef.value?.focus();
      scrollToHighlighted();
    };

    const close = () => {
      open.value = false;
    };

    const closeAndFocus = async () => {
      close();
      await nextTick();
      buttonRef.value?.focus();
    };

    const commit = () => {
      selectOption(highlighted.value);
      close();
    };

    const commitAndFocus = async () => {
      commit();
      await nextTick();
      buttonRef.value?.focus(); //focus for if the route doesn't change
      preserveFocus(buttonRef.value); //to preserve focus if route does change
    };

    const toggle = () => {
      if (open.value) {
        commitAndFocus();
      } else {
        show();
      }
    };

    const blur = (evt: FocusEvent) => {
      if (buttonRef.value && evt.relatedTarget === buttonRef.value) {
        return;
      }

      if (open.value === false) {
        return;
      }

      commit();
    };


    // Selection

    const selectedIndex = computed(() => {
      const index = props.options.findIndex((option) => {
        return option.id === props.modelValue;
      });

      return index >= 0 ? index : 0;
    });

    const currentIndex = ref(selectedIndex.value);

    const highlighted = computed(() => props.options[currentIndex.value]?.id);

    const highlightUp = () => {
      highlightDirection(-1);
    };

    const highlightDown = () => {
      highlightDirection(1);
    };

    const click = (id: string) => {
      highlightOption(id);
      commitAndFocus();
    };

    const highlightOption = async (id: string) => {
      currentIndex.value = props.options.findIndex((option) => option.id === id);
      scrollToHighlighted();
    };

    const selectOption = async (id: string) => {
      if (highlighted.value !== id) {
        highlightOption(id);
      }

      if (id !== props.modelValue) {
        ctx.emit('update:modelValue', id);
      }
    };

    const highlightDirection = (direction: 1 | -1) => {
      const newIndex = currentIndex.value + direction;
      const loopedIndex = newIndex === props.options.length ? 0
        : newIndex < 0 ? props.options.length - 1
        : newIndex;

      highlightOption(props.options[loopedIndex].id);
    };

    const scrollToHighlighted = () => {
      const element: HTMLElement & { scrollIntoViewIfNeeded?: () => void }
        = itemRefs[currentIndex.value];

      if (!element || !listRef.value) { return; }

      // Only scroll the element if it's not fully visible.
      if (
        element.offsetTop < (listRef.value.scrollTop || 0)
        || (element.offsetTop + element.offsetHeight) > (listRef.value.clientHeight + listRef.value.scrollTop)
      ) {
        listRef.value.scrollTop = element.offsetTop;
      }
    };


    // Typeahead

    let typeaheadTimer = -1;
    const typeaheadQuery = ref('');
    watch(typeaheadQuery, (to) => {
      if (!to) { return; }

      const match = props.options.find((option) => {
        return option.displayName.toLowerCase().startsWith(to);
      });

      if (match) {
        highlightOption(match.id);
      }
    });


    // Keypresses

    const keydown = (evt: KeyboardEvent) => {
      if (!props.options.length) { return; }

      if (/^Key[A-Z]$/.test(evt.code)) {
        clearTimeout(typeaheadTimer);
        typeaheadQuery.value += evt.key.toLowerCase();
        typeaheadTimer = window.setTimeout(() => typeaheadQuery.value = '', 1000);
      } else if (evt.code === 'End') {
        highlightOption(props.options[props.options.length - 1].id);
      } else if (evt.code === 'Home') {
        highlightOption(props.options[0].id);
      } else if (evt.code === 'Space') {
        evt.preventDefault();
        commitAndFocus();
      }
    };

    return {
      activeDescendant,
      blur,
      buttonId,
      buttonRef,
      buttonText,
      click,
      closeAndFocus,
      commitAndFocus,
      fallbackLabelId,
      highlighted,
      highlightDown,
      highlightUp,
      keydown,
      listId,
      listRef,
      open,
      optionElementId,
      optionFor,
      selectOption,
      setItemRef,
      show,
      toggle
    };
  }
});
