import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["href", "innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      _ctx.$style.emptyState,
      _ctx.isCompact ? _ctx.$style.compact : '',
      _ctx.isDarkMode ? _ctx.$style.darkMode : ''
    ])
  }, [
    _createVNode(_component_Icon, {
      name: _ctx.iconName,
      class: _normalizeClass(_ctx.$style[_ctx.iconClass])
    }, null, 8, ["name", "class"]),
    _createElementVNode("header", {
      "aria-live": "polite",
      class: _normalizeClass(_ctx.$style.header)
    }, _toDisplayString(_ctx.header), 3),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.content)
    }, _toDisplayString(_ctx.content), 3),
    (_ctx.link)
      ? (_openBlock(), _createElementBlock("a", {
          key: 0,
          class: _normalizeClass([_ctx.$style.link, 'focus-outline']),
          target: "_blank",
          href: _ctx.link,
          innerHTML: _ctx.linkLabel
        }, null, 10, _hoisted_1))
      : _createCommentVNode("", true)
  ], 2))
}