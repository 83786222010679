
import { Instance, Placement } from '@popperjs/core/lib';
import flip from '@popperjs/core/lib/modifiers/flip';
import preventOverflow from '@popperjs/core/lib/modifiers/preventOverflow';
import { createPopper, defaultModifiers } from '@popperjs/core/lib/popper-lite';
import FocusTrap from 'app/components/FocusTrap.vue';
import { PropType, defineComponent, nextTick, onBeforeUnmount, onMounted, onUpdated, ref, watch } from 'vue';

export default defineComponent({
  name: 'Popout',
  components: {
    FocusTrap
  },
  props: {
    reference: {
      type: HTMLElement,
      required: false, // Allows using a Vue template ref that won't have value until mount
      default: undefined as HTMLElement | undefined
    },
    labelId: {
      type: String,
      required: true
    },
    descriptionId: {
      type: String,
      required: false,
      default: undefined as string | undefined
    },
    initialFocus: {
      type: String,
      required: false,
      default: undefined as string | undefined
    },
    theme: {
      type: String as () => 'light' | 'dark',
      default: 'dark'
    },
    placement: {
      type: String as PropType<Placement>,
      default: 'bottom-start' as Placement
    },
    trapFocus: {
      type: Boolean,
      default: true
    }
  },
  emits: [
    'close'
  ],
  setup: (props, ctx) => {
    const root = ref<HTMLElement | null>(null);
    const content = ref<HTMLElement | null>(null);
    const headerLabel = ref<string | undefined>(undefined);

    let popper: Instance | null = null;

    const appendPopout = () => {
      if (!root.value || !props.reference || !content.value) { return; }

      // may want another prop to determine this
      if (props.trapFocus) {
        document.body.appendChild(root.value);
      } else {
        props.reference.appendChild(root.value);
      }


      // using aria-labelledby and labelId does not correctly label the popout,
      // so instead we are making an aria-label from either the element tied to labelId,
      // or the text/aria-label tied to the reference element that initiated the popout
      watch(() => props.labelId, async () => {
        await nextTick();
        const labelElement = document.getElementById(props.labelId);
        headerLabel.value = labelElement?.ariaLabel || labelElement?.textContent || props.reference.textContent || props.reference.ariaLabel || undefined;
      }, { immediate: true });


      preventOverflow.options = {
        altAxis: true,
        padding: 16
      };
      popper = createPopper(props.reference, content.value, {
        placement: props.placement,
        modifiers: [ ...defaultModifiers, flip, preventOverflow ]
      });
    };

    const removePopout = () => {
      if (root.value?.parentNode === document.body) {
        document.body.removeChild(root.value);
      }
      popper?.destroy();
    };

    const closeDialog = () => {
      ctx.emit('close');
    };

    const update = () => {
      popper?.update();
    };

    onMounted (async () => {
      appendPopout();
    });

    onBeforeUnmount(() => {
      removePopout();
    });

    onUpdated(() => {
      popper?.update();
    });

    return {
      root,
      content,
      closeDialog,
      update,
      headerLabel
    };
  }
});
