
import { computed, defineComponent, ref } from 'vue';
import { generateUUID } from 'lib/common';
import FormError from 'app/components/FormError.vue';
import { useI18n } from 'app/functions/use-i18n';

export default defineComponent({
  name: 'FormInput',
  components: {
    FormError
  },
  props: {
    modelValue: {
      type: String,
      required: true
    },
    type: {
      type: String as () => 'username' | 'password' | 'text' | 'email',
      required: true
    },
    label: {
      type: String,
      required: true
    },
    autofocus: {
      type: Boolean,
      required: false,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    describedBy: {
      type: String,
      default: undefined
    },
    error: {
      type: String,
      default: undefined
    },
    formName: {
      type: String,
      default: undefined
    }
  },
  emits: [
    'update:modelValue'
  ],
  setup: (props, ctx) => {
    const showPassword = ref(false);
    const validationError = ref('');
    const shownError = computed(() => props.error || validationError.value);

    const fieldType = computed(() => {
      if (showPassword.value) {
        return 'text';
      }

      switch (props.type) {
        case 'password':
          return 'password';

        case 'email':
          return 'email';

        case 'username':
        case 'text':
        default:
          return 'text';
      }
    });

    const autocomplete = computed(() => {
      switch (props.type) {
        case 'username':
          return 'username';

        case 'password':
          return 'current-password';

        case 'email':
          return 'email';

        case 'text':
        default:
          return 'off';
      }
    });

    const input = ref<HTMLInputElement | null>(null);

    const focused = ref(false);
    const active = computed(() => focused.value || !!props.modelValue);

    const id = generateUUID();

    const internalInput = computed({
      get: () => props.modelValue,
      set: (val) => ctx.emit('update:modelValue', val)
    });

    const { t } = useI18n();
    const onInvalid = () => {
      validationError.value = fieldType.value === 'email'
        ? t('form.error.invalidEmail')
        : t('form.error.missing', { name: props.label });
    };

    // Public
    const focus = () => input.value?.focus();

    return {
      active,
      autocomplete,
      fieldType,
      focus,
      focused,
      id,
      input,
      internalInput,
      onInvalid,
      shownError,
      showPassword
    };
  }
});
