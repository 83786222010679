import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["aria-disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_ExportQueueContextMenu = _resolveComponent("ExportQueueContextMenu")!
  const _component_AnnotationDetailsContextMenu = _resolveComponent("AnnotationDetailsContextMenu")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.selectionToolbar)
  }, [
    _createElementVNode("span", {
      class: _normalizeClass(_ctx.$style.selectedCount)
    }, _toDisplayString(_ctx.selectedCount), 3),
    _createElementVNode("button", {
      ref: "reference",
      class: _normalizeClass({
        [_ctx.$style.menuButton]: true,
        [_ctx.$style.disabled]: _ctx.disabled
      }),
      type: "button",
      "aria-disabled": _ctx.disabled,
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.disabled ? undefined : _ctx.showContextMenu = true))
    }, [
      _createTextVNode(_toDisplayString(_ctx.$t('annotations.contextMenu.button')) + " ", 1),
      _createVNode(_component_Icon, {
        class: _normalizeClass(_ctx.$style.menuButtonIcon),
        name: "chevron-down"
      }, null, 8, ["class"])
    ], 10, _hoisted_1),
    (_ctx.menuType === 'export-queue')
      ? (_openBlock(), _createBlock(_component_ExportQueueContextMenu, {
          key: 0,
          show: _ctx.showContextMenu,
          reference: _ctx.reference,
          annotations: _ctx.selected,
          exportOptions: true,
          showCount: true,
          onClear: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('selected:clear'))),
          onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showContextMenu = false))
        }, null, 8, ["show", "reference", "annotations"]))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_ctx.showContextMenu)
            ? (_openBlock(), _createBlock(_component_AnnotationDetailsContextMenu, {
                key: 0,
                reference: _ctx.reference,
                annotations: _ctx.selected,
                onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showContextMenu = false)),
                onCopied: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('selected:clear'))),
                onExported: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('selected:clear')))
              }, null, 8, ["reference", "annotations"]))
            : _createCommentVNode("", true)
        ], 64))
  ], 2))
}