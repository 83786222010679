import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NewReleasePrompt = _resolveComponent("NewReleasePrompt")!
  const _component_CopyingPrompt = _resolveComponent("CopyingPrompt")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_NewReleasePrompt, {
      show: _ctx.promptToShow === 'standard',
      titleId: _ctx.titleId,
      onOpen: _ctx.openBook
    }, null, 8, ["show", "titleId", "onOpen"]),
    _createVNode(_component_CopyingPrompt, {
      show: _ctx.promptToShow === 'copy',
      targetTitle: _ctx.title,
      sourceTitle: _ctx.mostRecentRelease ? _ctx.mostRecentRelease.release : undefined,
      sourceAnnotations: _ctx.mostRecentRelease ? _ctx.mostRecentRelease.annotations : undefined,
      onOpen: _ctx.openBook,
      onClose: _ctx.close
    }, null, 8, ["show", "targetTitle", "sourceTitle", "sourceAnnotations", "onOpen", "onClose"])
  ], 64))
}