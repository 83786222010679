
import DownloadIndicatorPopout from 'app/components/DownloadIndicatorPopout.vue';
import { defineComponent, ref } from 'vue';


export default defineComponent({
  name: 'DownloadIndicatorQueued',
  components: {
    DownloadIndicatorPopout
  },
  props: {
    /**
     * When true, only the icon will be rendered. No status label.
     */
    iconOnly: {
      type: Boolean,
      default: false
    },
    /**
     * When true, indicates that the user should be prompted for
     * confirmation before cancelling a download.
     */
    cancelConfirmationRequired: {
      type: Boolean,
      default: true
    }
  },
  emits: [
    'download:cancel',
    'download:settings'
  ],
  setup: (props, { emit }) => {
    const indicatorButton = ref<HTMLButtonElement | undefined>();

    const showPopout = ref(false);

    const cancelDownload = () => {
      emit('download:cancel');
    };

    const openSettings = () => {
      emit('download:settings');
    };

    return {
      cancelDownload,
      indicatorButton,
      openSettings,
      showPopout
    };
  }
});
