
import BreadcrumbNavigation from 'app/components/BreadcrumbNavigation.vue';
import TitleActionsContextMenuButton from 'app/components/contextMenus/TitleActionsContextMenuButton.vue';
import Cover from 'app/components/Cover.vue';
import { useBreadcrumbs } from 'app/functions/use-breadcrumbs';
import { Breakpoint, useWindowSize } from 'app/functions/use-window-size';
import { Title } from 'app/models/title';
import { RouteName } from 'app/router/constants';
import { computed, defineComponent, PropType } from 'vue';

export default defineComponent({
  components: {
    BreadcrumbNavigation,
    Cover,
    TitleActionsContextMenuButton
  },
  props: {
    header: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      default: undefined
    },
    title: {
      type: Object as PropType<Title>,
      required: true
    }
  },
  setup: (props, ctx) => {
    const { windowWidth } = useWindowSize();
    const isMobile = computed(() => windowWidth.value <= Breakpoint.VeryWide);

    const breadcrumbs = useBreadcrumbs();

    const metadata = computed(() => props.title.lexisMetadata);

    const detailsPage = computed(() => {
      return {
        name: RouteName.TitleDetails,
        params: { titleSlug: props.title.slug }
      };
    });

    return {
      breadcrumbs,
      detailsPage,
      isMobile,
      metadata
    };
  }
});
