import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ManageCopyingContent = _resolveComponent("ManageCopyingContent")!
  const _component_Sidebar = _resolveComponent("Sidebar")!

  return (_openBlock(), _createBlock(_component_Sidebar, {
    header: _ctx.$t('annotationCopying.manageCopying.header'),
    show: _ctx.show,
    resizable: false,
    side: "right",
    onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
  }, {
    default: _withCtx(() => [
      (_ctx.title && _ctx.copyJobsState)
        ? (_openBlock(), _createBlock(_component_ManageCopyingContent, {
            key: 0,
            title: _ctx.title,
            state: _ctx.copyJobsState
          }, null, 8, ["title", "state"]))
        : _createCommentVNode("", true)
    ], undefined, true),
    _: 1
  }, 8, ["header", "show"]))
}