
import { MatchedTitle, getBestQueryFromExcerpt } from 'app/base/hudson';
import { MatchInfo } from 'app/components/SharePassage.vue';
import TitleActionButton from 'app/components/TitleActionButton.vue';
import { useForRefs } from 'app/functions/use-for-refs';
import { defineComponent, getCurrentInstance } from 'vue';

export default defineComponent({
  name: 'SearchThisTitleResult',
  components: {
    TitleActionButton
  },
  props: {
    result: {
      type: Object as () => MatchedTitle,
      required: true
    },
    title: {
      type: Object,
      default: undefined
    }
  },
  emits: [
    'close',
    'share'
  ],
  setup: (props, ctx) => {
    const { itemRefs: shareButtons, setItemRef } = useForRefs(getCurrentInstance());
    const share = (index: number) => {
      const shareMatch: MatchInfo & { ref: HTMLElement } = {
        titleSlug: props.result.titleId.toString(),
        buid: props.result.buid,
        path: props.result.path,
        excerpt: props.result.matches[index],
        ref: shareButtons[index]
      };
      ctx.emit('share', shareMatch);
    };

    return {
      getBestQueryFromExcerpt,
      share,
      setItemRef
    };
  }
});
