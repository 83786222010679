import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CopyContent = _resolveComponent("CopyContent")!
  const _component_Prompt = _resolveComponent("Prompt")!
  const _component_InProgressContent = _resolveComponent("InProgressContent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Prompt, {
      show: _ctx.promptToShow === 'copy',
      title: _ctx.$t(`annotationCopying.prompt.copy.header`),
      describedBy: _ctx.copyPromptId,
      confirmLabel: _ctx.$t(`annotationCopying.prompt.copy.continue`),
      cancelLabel: _ctx.$t(`annotationCopying.prompt.copy.cancel`),
      transition: _ctx.transition,
      onSubmit: _ctx.copy,
      onCancel: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('open')))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_CopyContent, {
          id: _ctx.copyPromptId,
          targetTitle: _ctx.targetTitle,
          sourceTitle: _ctx.sourceTitle,
          sourceAnnotations: _ctx.sourceAnnotations
        }, null, 8, ["id", "targetTitle", "sourceTitle", "sourceAnnotations"])
      ], undefined, true),
      _: 1
    }, 8, ["show", "title", "describedBy", "confirmLabel", "cancelLabel", "transition", "onSubmit"]),
    _createVNode(_component_Prompt, {
      show: _ctx.promptToShow === 'inProgress',
      title: _ctx.$t(`annotationCopying.prompt.inProgress.header`),
      describedBy: _ctx.inProgressPromptId,
      confirmLabel: _ctx.$t(`annotationCopying.prompt.inProgress.continue`),
      cancelLabel: _ctx.$t(`annotationCopying.prompt.inProgress.cancel`),
      transition: _ctx.transition,
      onSubmit: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('open'))),
      onCancel: _ctx.goToAnnotations
    }, {
      default: _withCtx(() => [
        _createVNode(_component_InProgressContent, { id: _ctx.inProgressPromptId }, null, 8, ["id"])
      ], undefined, true),
      _: 1
    }, 8, ["show", "title", "describedBy", "confirmLabel", "cancelLabel", "transition", "onCancel"])
  ], 64))
}