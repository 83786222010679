
import TitleActionsContextMenu from 'app/components/contextMenus/TitleActionsContextMenu.vue';
import CoverView from 'app/components/CoverView.vue';
import TableOfContentsSidebar from 'app/components/TableOfContentsSidebar.vue';
import { injectOverlayContext } from 'app/contexts/overlay-context';
import { CopyJobsState } from 'app/functions/use-copy-jobs-state';
import { useTitleSubscription } from 'app/functions/use-subscription-interaction';
import { ADCopyJobState } from 'app/keys/injection-keys';
import { FilterObject } from 'app/models/filter-object';
import { TitleRecord } from 'app/models/title';
import { ComputedRef, defineComponent, inject, PropType, ref } from 'vue';

export default defineComponent({
  components: {
    CoverView,
    TitleActionsContextMenu,
    TableOfContentsSidebar
  },
  props: {
    title: {
      type: Object as PropType<TitleRecord>,
      required: true
    },
    hideLabel: {
      type: Boolean,
      required: false,
      default: false
    },
    includeCopying: {
      type: Boolean,
      required: false,
      default: true
    },
    darkStyle: {
      type: Boolean,
      default: true
    }
  },
  setup: (props) => {
    const showContextMenu = ref<boolean>(false);
    const showCoverView = ref<boolean>(false);
    const showTableOfContents = ref<boolean>(false);
    const { show: showManualCopySidebar } = injectOverlayContext('ManualCopySidebar');
    const { show: showManageCopyingSidebar } = injectOverlayContext('ManageCopyingSidebar');
    const copyJobsState = inject(ADCopyJobState, undefined) as ComputedRef<CopyJobsState> | undefined;

    const contextMenuButton = ref<HTMLElement | null>(null);

    const {
      subscribeTextLong,
      toggleSubscription
    } = useTitleSubscription(props.title, 'annotation details / export queue page');

    const closeCoverView = () => {
      showCoverView.value = false;
      contextMenuButton.value?.focus();
    };

    const closeTableOfContents = () => {
      showTableOfContents.value = false;
      contextMenuButton.value?.focus();
    };

    const showManualCopy = (releases: FilterObject[]) => {
      showManualCopySidebar({ title: props.title, releasesWithAnnotations: releases }, contextMenuButton.value);
    };

    const showManageCopying = () => {
      showManageCopyingSidebar({ title: props.title, copyJobsState }, contextMenuButton.value);
    };

    return {
      closeCoverView,
      closeTableOfContents,
      contextMenuButton,
      showContextMenu,
      showCoverView,
      showTableOfContents,
      subscribeTextLong,
      showManualCopy,
      showManageCopying,
      toggleSubscription
    };
  }
});
