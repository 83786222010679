
import { C } from 'app/base/common';
import Cover from 'app/components/Cover.vue';
import { Series } from 'app/models/series';
import { computed, defineComponent, onMounted, ref } from 'vue';

export default defineComponent({
  name: 'SeriesCover',
  components: {
    Cover
  },
  props: {
    series: {
      type: Series,
      required: true
    },
    width: {
      type: Number,
      default: 8.625
    }
  },
  setup: (props) => {
    // Global css classes defined under 'styles/core/common.less'
    const TINT_PROPERTY = 'tint-background-color';
    const DARK_TINT = 'dark';
    const LIGHT_TINT = 'light';
    const TINT_THRESHOLD_YIQ = 190;

    const coverContainer = ref<HTMLElement | null>(null);

    /**
     * Determines if the cover is 'light' or 'dark'
     */
    const tint = computed(() => {
      if (!props.series.coverColor) {
        return LIGHT_TINT;
      }
      const rgb = C.hexToRGB(props.series.coverColor);
      if (!rgb) {
        return LIGHT_TINT;
      }

      return C.colorYIQ(rgb) >= TINT_THRESHOLD_YIQ ? LIGHT_TINT : DARK_TINT;
    });

    onMounted(() => {
      if (!coverContainer.value?.children) { return; }
      const tintValue = tint.value;

      Array.from(coverContainer.value.children).forEach((child) => {
        C.DataClass.set(child as HTMLElement, TINT_PROPERTY, tintValue);
      });
    });

    return {
      coverContainer
    };
  }
});
