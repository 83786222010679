
import { Constants } from 'app/base/constants';
import EmptyState from 'app/components/EmptyState.vue';
import { Breakpoint, useWindowSize } from 'app/functions/use-window-size';
import { computed, defineComponent } from 'vue';

export default defineComponent({
  components: {
    EmptyState
  },
  setup: () => {
    const helpLink = Constants.HELP_PATHS.ALERTS;
    const { windowWidth } = useWindowSize();
    const compact = computed(() => windowWidth.value > Breakpoint.HideNav);

    return {
      helpLink,
      compact
    };
  }
});
