
import { computed, defineComponent, PropType } from 'vue';
import Dropdown from 'app/components/Dropdown.vue';

export type PropertySelectFunc = (obj: unknown) => string;

export default defineComponent({
  name: 'FormSelect',
  components: {
    Dropdown
  },
  props: {
    modelValue: {
      type: Object,
      default: undefined
    },
    options: {
      type: Array,
      required: true
    },
    optionLabel: {
      type: [String, Function] as PropType<string | PropertySelectFunc>,
      required: true
    },
    optionKey: {
      type: [String, Function] as PropType<string | PropertySelectFunc>,
      required: true
    },
    labelId: {
      type: String,
      default: undefined
    },
    prompt: {
      type: String,
      default: undefined
    },
    /**
     * override the classes that FormSelect provides to Dropdown
     * pass as dictionary with key = name of FormSelect class you want to override,
     * and value = your new class
     * Ex: { 'highlighted': $style.highlighted }
     */

    classOverrides: {
      type: Object as PropType<Record<string, string>>,
      default: () => ({})
    }
  },
  emits: [
    'update:modelValue'
  ],
  setup: (props, ctx) => {
    const getId = computed<PropertySelectFunc>(() => typeof props.optionKey === 'string'
      ? (obj: any) => obj[props.optionKey as string]
      : props.optionKey);
    const getDisplayName = computed<PropertySelectFunc>(() => typeof props.optionLabel === 'string'
      ? (obj: any) => obj[props.optionLabel as string]
      : props.optionLabel);

    const selectedInternal = computed({
      get: () => props.modelValue ? getId.value(props.modelValue) : undefined,
      set: (v) => ctx.emit('update:modelValue', props.options.find((option) => getId.value(option) === v))
    });

    const transformedOptions = computed(() => {
      return props.options.map((option: any) => {
        return {
          id: getId.value(option),
          displayName: getDisplayName.value(option),
          count: option.count,
          ariaLabel: option.ariaLabel
        };
      });
    });

    return {
      selectedInternal,
      transformedOptions
    };
  }
});
