/**
 * Elrond Translations
 * Keys are built around a 'page.section.context' structure
 * - hierarchical structure makes for easier readability
 * - Lower liklihood of duplicate keys since it's very context based and CSS module-like
 * - discourages reusing/combining keys that are used in a different context.
 *   Just because the words are the same in English in both cases doesn't mean they're the same in other languages
 * - Adjusting language doesn't affect keys
 * - reduces some cognitive load when developing. Follow the pattern.
 */
import bisacCodes from 'res/text/en-bisac-codes';
import subjects from 'res/text/en-subjects';

const messages = {
  annotations: {
    label: 'Annotations',
    header: 'My annotations',
    filter: 'Filters',
    color: {
      RL: 'Pink',
      RS: 'Red',
      OL: 'Peach',
      OS: 'Orange',
      YL: 'Pear',
      YS: 'Yellow',
      GL: 'Mint',
      GS: 'Green',
      TL: 'Aqua',
      TS: 'Turquoise',
      BL: 'Sky',
      BS: 'Blue',
      VL: 'Lavender',
      VS: 'Purple'
    },
    contextMenu: {
      button: 'Actions',
      copy: 'Copy to clipboard',
      export: 'Add to export queue',
      findHighlight: 'Find in latest release'
    },
    selectedCount: '{COUNT, number} selected',
    showingCount: 'Showing {COUNT, number} of {TOTAL, number}',
    sortBy: 'Sort by',
    sortOptions: {
      recent: 'Newest',
      oldest: 'Oldest',
      alpha: 'A - Z',
      reverseAlpha: 'Z - A'
    },
    emptystate: {
      header: 'No annotations yet',
      content: 'As you make annotations in books, you\u2019ll see them here. You can click books listed here to see the notes and highlights you\u2019ve made in them.',
      link: 'Learn how to make annotations.'
    },
    display: {
      actions: {
        label: 'Actions',
        copy: {
          label: 'Copy annotation',
          success: 'Copy successful!',
          failure: 'Copy unsuccessful. Please try again.'
        },
        addToExportQueue: {
          label: 'Add to export queue',
          success: '{ N, plural, one {Added to export queue} other {Added # annotations to export queue} }'
        }
      },
      header: 'Annotations for {title}',
      property: {
        select: 'Selection',
        color: 'Color',
        expander: 'Expand',
        highlight: 'Highlight',
        note: 'Note',
        citation: 'Citation',
        lastUpdated: 'Updated',
        release: 'Release'
      },
      empty: 'No annotations',
      expandText: 'Expand text',
      noNote: 'No note',
      selectAll: 'Select all annotations',
      select: 'Select this annotation'
    },
    textFilter: 'Search your annotations',
    select: {
      label: 'Select',
      toggle: 'Toggle selection mode',
      on: 'Selection mode on',
      off: 'Selection mode off'
    }
  },
  annotationCopying: {
    prompt: {
      copy: {
        header: 'New release',
        intro: 'There\u2019s a new release of { TITLE }.',
        question: 'Do you want to copy your { NUMBER, plural, one { # annotation } other { # annotations } } from <strong>{ SOURCE }</strong> to <strong>{ TARGET }</strong>? If not, you can do it from the annotation details page at any time.',
        additional: 'Annotations are only copied if there\u2019s matching highlighted text in the new release.',
        continue: 'Copy annotations',
        cancel: 'Not now, open latest release'
      },
      inProgress: {
        header: 'Copying...',
        explanation: 'Sometimes this can take a while. You can see the status of the copying process on the annotation details page.',
        continue: 'Open latest release',
        cancel: 'Annotation details'
      }
    },
    manualCopy: {
      header: 'Copy annotations to latest release',
      source: {
        header: 'Copy from:',
        placeholder: 'Select a source release',
        option: 'Release { RELEASE }. { N, plural, one { # annotation } other { # annotations } }',
        additional: 'Only releases with annotations are shown.'
      },
      destination: {
        header: 'Copy to:',
        additional: 'Annotations can only be copied to the latest release.'
      },
      count: '{ N, plural, one { # annotation } other { # annotations } }',
      submit: 'Copy annotations',
      submitInactive: 'Can\u2019t copy annotations. Select a release to copy from.'
    },
    banner: {
      header: 'Unreviewed copy requests',
      status: {
        inProgress: 'Copying annotations',
        complete: 'Copying complete',
        error: 'Error copying annotations. Please try again.'
      },
      actions: {
        review: 'Review',
        dismiss: 'Dismiss',
        retry: 'Retry',
        clear: 'Clear'
      },
      ariaLabels: {
        dismiss: 'Selected copy request dismissed',
        clear: 'Selected copy request cleared',
        retry: 'Trying selected copy request again'
      },
      empty: 'No copy requests to show'
    },
    manageCopying: {
      header: 'Manage annotation copying',
      emptyState: {
        header: 'No annotations copied yet',
        content: 'In-progress and completed copying requests will be listed here.',
        link: 'Learn more about copying annotations.'
      },
      listHeader: 'Copy request history',
      card: {
        inProgress: 'Copying in progress',
        error: 'Error copying annotations. Please try again.',
        complete: {
          unreviewed: 'New - request complete',
          copied: 'Copied',
          numCopied: '{ SUCCESSFUL } of { TOTAL }',
          finished: 'Finished'
        }
      }
    },
    releaseLabel: 'Release { SOURCE } → { TARGET }',
    titleCard: {
      copying: 'Copying',
      copied: 'Copied'
    }
  },
  breadcrumbs: {
    label: 'Breadcrumbs',
    home: 'Home',
    back: 'Back',
    browse: 'Browse library',
    subjects: 'Subjects',
    notes: 'My annotations',
    tags: 'My tags'
  },
  browse: {
    collection: '{ NAME } collection',
    description: 'Collection description',
    seeAll: {
      generic: 'See all',
      title: 'See { N, plural, one { # title } other { all # titles } }',
      series: 'See { N, plural, one { # set } other { all # sets } }',
      subjects: 'See { N, plural, one { # subject } other { all # subjects } }'
    },
    subjects: 'Subjects',
    subjectWithCount: '{ NAME }. { N, plural, one { # title } other { # titles } }',
    fullCollection: 'View full collection'
  },
  copyLink: {
    copied: 'Copied!',
    label: 'Copy link'
  },
  circ: {
    action: {
      applyHoldEdit: 'Apply',
      borrow: 'Borrow',
      checkOutBeforeAccessingPassage: 'Borrow this title to see the shared passage',
      deleteHold: 'Remove hold',
      editHold: 'Edit hold',
      listen: 'Listen',
      placeHold: 'Place hold',
      read: 'Read',
      watch: 'Watch'
    },
    explanation: {
      borrow: 'There are a limited number of copies of this title, so you need to borrow it.',
      editHold: 'Change the email address we\u2019ll send hold notifications to for this title.',
      hold: 'Enter your email address to be notified when this title is ready for you to borrow.'
    },
    error: {
      'header': 'Error',

      'auth': 'Something went wrong. To fix this problem, sign out and sign in again.',

      'offline': 'You\u2019re offline. Try again when you have an internet connection.',
      'timeout': 'There\u2019s been a problem. Please try again later.',
      'unrecognized': 'There\u2019s been a problem. Please try again later.',

      'at-loan-limit': 'You\u2019ve reached your loans limit. You\u2019ll need to return another title before you can borrow this one.',
      'card-at-loan-limit': 'You\u2019ve reached your loans limit. You\u2019ll need to return another title before you can borrow this one.',
      'card-at-hold-limit': 'You\u2019ve reached your holds limit. Wait for another hold to become available, then try again. If you want to place this title on hold now, you\u2019ll need to cancel an existing hold.',
      'card-hold-disabled': 'You are not allowed to place a hold on this title.',
      'card-at-churn-limit': 'You borrowed and returned too many titles in a short time. Please try again later.',
      'library-auth-message': 'From your organization: \u2019{MESSAGE}\u2019 Contact your organization for more information about their message.',
      'no-longer-holdable': 'Your organization doesn\u2019t have this book anymore. If you want to borrow it, let your admin know.',
      'no-copies-available': 'This title isn\u2019t available to borrow anymore. You can place a hold instead.'
    },
    fulfill: {
      video: {
        success: 'Opening video…',
        failure: 'Error opening video. Please try again later.'
      },
      pdf: {
        success: 'Opening PDF…',
        failure: 'Error opening PDF. Please try again later.'
      }
    },
    success: {
      placeHold: 'Hold successfully placed. You will be notified when this title is available.',
      deleteHold: 'You\u2019ve successfully removed your hold.',
      editHold: 'You\u2019ve successfully updated your email address.'
    },
    lendingPeriod: {
      dropdownLabel: 'Borrow for',
      option: '{ unit, select, days {{ value, plural, one {{value, number} day} other {{value, number} days} }} other {{ value, number }} }'
    },
    limit: {
      loansRemaining: 'You can borrow {n} more limited-copy { n, plural, one {title} other {titles} } right now.'
    },
    newRelease: {
      header: 'New release',
      description: 'You\u2019re opening a new release of this book. You can access prior releases (including any annotations you made) from the title\u2019s details page or your "My annotations" section.',
      downloadMessage: 'Previous downloads of this book have been removed from your device. Download it again to access this version offline.'
    },
    state: {
      unavailable: 'Unavailable'
    }
  },
  copyReview: {
    header: 'Copied annotations',
    warning: 'Shorter highlights may not have copied correctly. If you have any highlights with three words or fewer, make sure to double-check them below.',
    empty: {
      header: 'No annotations',
      content: 'This run did not attempt to copy any annotations. Try copying again, and make sure the source release includes the annotations you wish to copy.'
    },
    failures: {
      count: '{ COUNT } failed',
      description: 'Annotations not copied due to changes or missing text.'
    },
    successes: {
      count: '{ COUNT } successful',
      description: 'Annotations were copied if the text and location of your highlights matched the prior release.'
    }
  },
  error: {
    route: {
      header: 'Sorry, we can\u2019t find that page',
      invalid: 'Make sure the URL is correct, then try again.',
      legacyRedirect: {
        header: 'Sorry, we can\u2019t find that page',
        instructions: '<a>Learn more</a> about the LexisNexis Digital Library, or go to the <b>home page</b> to get started.'
      }
    }
  },
  exportQueue: {
    contextMenu: {
      annotations: {
        label: 'Annotation details'
      },
      copy: {
        label: 'Copy to clipboard',
        success: 'Copy successful',
        failure: 'Copy unsuccessful. Please try again.'
      },
      remove: {
        label: 'Remove from queue',
        afterExport: 'Remove { N, plural, one { annotation } other { # annotations } }',
        success: '{ N, plural, one { Annotation } other { # annotations } } removed'
      },
      export: {
        csv: 'CSV export',
        pdf: 'PDF export',
        drive: 'Google Drive export',
        success: 'Export successful',
        failure: 'Export unsuccessful. Please try again.'
      }
    },
    emptyState: {
      header: 'No annotations queued yet',
      content: 'As you add your annotations to the queue, you\u2019ll see them here. You can export some or all of the annotations in this list to export (CSV, PDF, Google Drive) or copy them.',
      link: 'Learn more about exporting annotations.'
    },
    expandTitle: 'Annotations for { title }',
    groupsHeader: {
      actions: 'Actions',
      count: 'Annotations',
      expand: 'Toggle title expansion',
      select: 'Toggle selection on all annotations for title',
      title: 'Title'
    },
    header: 'Export queue',
    selectAll: 'Toggle selection on all annotations in queue',
    selectTitle: 'Toggle selection on all annotations for { title }'
  },
  ntc: {
    header: 'Videos & more',
    access: 'Access',
    expireTime: 'Access expires on',
    prompt: {
      header: 'You\u2019re leaving the LexisNexis Digital Library',
      explanation: 'This content will open in a new tab.'
    },
    emptystate: {
      header: 'No content available',
      content: 'Your LexisNexis Digital Library doesn\u2019t offer this type of content right now.',
      link: 'Learn about the <b>Videos & more</b> section.'
    },
    error: {
      header: 'Error',
      message: 'Something went wrong. Please try again later.'
    },
    unavailable: 'Only available on desktops.'
  },
  filters: {
    header: 'Filters',
    apply: 'Apply',
    clear: 'Clear all',
    noResults: 'No filters found',
    ariaLabels: {
      button: 'Modify filters. { NUMBER, plural, one { # filter } other { # filters } } applied',
      header: '{ CATEGORY } category. { NUMBER, plural, one { # filter } other { # filters } }',
      appliedFilters: 'Applied filters. Select to remove a filter',
      filterList: 'Filters',
      applyInactive: 'Cannot apply filters. No changes made',
      nameWithCount: '{ NAME }. { N, plural, one { # result } other { # results } }'
    },
    categories: {
      Jurisdiction: 'Jurisdiction',
      PracticeArea: 'Practice area',
      Classification: 'Content type',
      Subject: 'Other subjects',
      Color: 'Color',
      Release: 'Release'
    }
  },
  gdpr: {
    modal: {
      header: 'Cookie settings',
      confirm: 'Confirm cookie settings',
      intro: 'The LexisNexis Digital Library uses cookies and similar technologies to improve your experience, monitor our performance, and understand overall usage trends for LexisNexis Digital Library services (including LexisNexis Digital Library websites and apps). We use this information to create a better experience for all users. Please review the types of cookies we use below.',
      requiredCookies: {
        header: 'Required cookies',
        explanation: 'The LexisNexis Digital Library uses cookies and local storage for core functionality. You can clear and delete them at any time by signing out.'
      },
      researchCookies: {
        header: 'Research and analytics cookies',
        explanation: 'These cookies help us understand user behavior within our services. For example, they let us know which features and sections are most popular. This information helps us design a better experience for all users. We don\u2019t share this information.'
      },
      learnMoreAboutCookies: 'To learn more about cookies, please see our <a>cookie policy</a>.',
      learnMoreAboutPrivacy: 'To learn more about how we use and protect your data, please see our <a>privacy policy</a>.'
    }
  },
  general: {
    cancel: 'Cancel',
    close: 'Close',
    continue: 'Continue',
    dismiss: 'Dismiss',
    error: 'Something went wrong.',
    loading: 'Loading',
    needHelp: 'New here? Check out our user guide to learn more.',
    subjectCategories: {
      Jurisdiction: 'Jurisdiction',
      PracticeArea: 'Practice area',
      Classification: 'Content type',
      Subject: 'Other subjects'
    },
    textFilter: {
      placeholder: 'Find'
    },
    backToTop: 'Back to top'
  },
  header: {
    menu: 'Menu',
    account: {
      about: 'About',
      back: 'Back',
      close: 'Close',
      downloadSettings: {
        label: 'Download settings',
        autoDownloads: {
          explanation: 'All books will download for offline use when you first open them.',
          label: 'Automatic downloads'
        },
        wifiDownloads: {
          explanation: 'Save data on a mobile network connection, with the option to download titles manually.',
          label: 'Download only on Wi-Fi'
        }
      },
      help: 'Help',
      info: {
        about: {
          copyrightNotice: 'Important notice about copyright materials',
          releaseNotes: 'View release notes',
          header: 'About',
          overdriveLogo: 'OverDrive logo',
          lexisNexisLogo: 'LexisNexis logo'
        },
        account: {
          header: 'Account',
          id: 'ID'
        },
        client: {
          build: 'Build',
          date: 'Date',
          header: 'Client',
          spec: 'Spec',
          url: 'URL',
          version: 'Version'
        },
        server: {
          flavor: 'Flavor',
          header: 'Server',
          platform: 'Platform',
          spec: 'Spec',
          type: 'Shell type',
          version: 'Version'
        }
      },
      label: 'My account',
      borrowingLimits: {
        borrowed: 'Borrowed: {BORROWED, number} of {TOTAL, number}',
        heading: 'Borrowing limits',
        loading: 'Loading',
        remaining: 'Remaining: {REMAINING, number} of {TOTAL, number}'
      },
      sendDiagnosticsLog: {
        label: 'Send diagnostics log',
        message: 'Sent diagnostics log'
      },
      sendFeedback: 'Send feedback',
      signOut: 'Sign out'
    }
  },
  form: {
    email: 'Email address',
    error: {
      invalidEmail: 'Must be a valid email address.',
      missing: '{name} is required.'
    },
    pagination: {
      currentPage: 'Page {page}',
      goToPage: 'Go to page {page}',
      label: 'Pagination',
      nextPage: 'Next page',
      previousPage: 'Previous page'
    },
    search: 'Search',
    tabs: {
      tabWithCount: '{label}, {n} total'
    }
  },
  mysubscriptions: {
    header: 'My subscriptions',
    emptystate: {
      header: 'No subscriptions yet',
      content: 'As you subscribe to titles and sets, you\u2019ll see them here. Use this page to view and manage your active subscriptions.',
      link: 'Learn how to add subscriptions.'
    }
  },
  subscription: {
    subscribe: {
      short: 'Subscribe',
      long: 'Subscribe to alerts',
      success: 'Subscription added',
      failure: 'Subscription not added'
    },
    unsubscribe: {
      short: 'Unsubscribe',
      long: 'Unsubscribe to alerts',
      success: 'Subscription removed',
      failure: 'Subscription not removed'
    }
  },
  alert: {
    label: 'View alerts',
    labelUnreviewed: 'View unreviewed alerts',
    header: 'Alerts',
    details: {
      seriesRemoved: 'This set has been removed from your digital library. Your subscription for this set has been removed.',
      seriesUpdated: '{ series } has been updated. New volumes are listed below.',
      titleRemoved: 'This title has been removed from your digital library. Your subscription for this title has been removed.'
    },
    emptystate: {
      header: 'No alerts yet',
      content: 'If you\u2019re subscribed to titles or sets, you\u2019ll see alerts here when those get updated or removed.',
      link: 'Learn more about subscriptions.'
    },
    error: {
      header: 'Error',
      offline: 'It looks like you\u2019re offline. Check your connection and try again.',
      general: 'Something went wrong. Please try again later.'
    },
    manage: 'Manage subscriptions',
    clear: 'Delete alert',
    clearAll: 'Delete all',
    cleared: 'Previous alert was deleted',
    type: {
      TitleNewRelease: 'New release',
      TitleNotAvailable: 'Title no longer available',
      SeriesNewRelease: 'Set has new releases',
      SeriesNotAvailable: 'Set no longer available',
      Unreviewed: 'Unreviewed { type }'
    },
    review: 'Review alert',
    reviewDetails: 'Reviewing { type } alert'
  },
  redirect: {
    header: 'You\u2019re following a link to a different library',
    description: 'You\u2019re currently logged into { CURRENT_LIBRARY }, but this link goes to { TARGET_LIBRARY }.',
    instructions: 'To view the link, you\u2019ll have to log out of { CURRENT_LIBRARY } and into { TARGET_LIBRARY }.',
    cancel: 'Cancel',
    continue: 'Log out and continue'
  },
  search: {
    placeholder: 'Search by title, author, practice area, etc.',
    inputLabel: 'Search',
    searchButton: 'Search',
    cancelButton: 'Cancel search',
    clearButton: 'Clear input',
    deleteButton: 'Delete {query}',
    triggerButton: 'Open search',
    recentHeader: 'Recent searches'
  },
  list: {
    numberResults: '{ NUMBER, plural, one { # result } other { # results } } found',
    noMatches: 'No matches',
    error: 'Something went wrong.',
    sort: {
      'default': 'Default',
      'relevance': 'Relevance',
      'newlyadded': 'Date added',
      'mostpopular': 'Popularity',
      'mostpopular-site': 'Popularity',
      'lowcirculating': 'Low circulating',
      'releasedate': 'Release date',
      'author': 'Creator',
      'title': 'Title',
      'listorder': 'Default',
      'random': 'Random',
      'seriesname': 'Title',
      'publishdate': 'Release date'
    },
    pageChange: 'Page { page }',
    tabs: {
      title: 'All titles',
      set: 'Sets'
    },
    searchAll: 'Full collection'
  },
  title: {
    downloadIndicator: {
      confirmButton: 'Yes',
      cancelButton: 'No',
      cancelConfirmMsg: 'Are you sure you want to cancel?',
      cancelDownloadButton: 'Cancel download',
      configButton: 'Manage downloads',
      deleteButton: 'Remove download ({ SIZE, number }MB)',
      deleteConfirmMsg: 'Are you sure you want to delete?',
      downloadConfirmMsg: 'You are not on a Wi-Fi connection, are you sure?',
      startDownloadButton: 'Download title ({ SIZE, number }MB)',
      status: {
        downloaded: 'Downloaded ({ SIZE, number }MB)',
        downloadedNoSize: 'Downloaded',
        downloading: 'Downloading',
        errored: 'Errored',
        paused: 'Paused',
        queued: 'Download queued',
        streaming: 'Streaming'
      }
    },
    error: {
      unowned: 'Your organization doesn\u2019t have this book anymore. If you want to borrow it, let your admin know.'
    },
    expirationIndicator: {
      label: 'Loan details',
      expiredStatus: 'Expired',
      renewButton: 'Renew',
      returnButton: 'Return',
      expiresOn: {
        date: 'You\u2019ve borrowed this title. It expires { date, date, ::MMddyyyy }.',
        time: 'You\u2019ve borrowed this title. It expires { relativeTime }.'
      },
      returnableMsg: 'You can return this title early if you\u2019re done with it.',
      renewableMsg: 'You can renew this title early if you need more time.',
      renewableReturnableMsg: 'You can return this title early if you\u2019re done with it, or renew it if you need more time.'
    },
    seriesLink: {
      header: 'Set',
      error: 'Could not load series information.',
      label: '{name} set',
      volumeLabel: 'Volumes'
    },
    metadata: {
      description: 'Title metadata'
    },
    author: 'Author',
    creator: '{ n, plural, one {Creator} other {Creators} }',
    copiesAvailable: {
      label: 'Available copies',
      message: '{ available } of { total }'
    },
    cover: {
      alt: '{ title } { mediaType, select, audiobook {audiobook} video {video} series {set} other {book} } cover',
      label: 'Cover'
    },
    contextMenu: {
      header: 'Title actions',
      viewCover: 'View cover',
      viewToC: 'View table of contents',
      viewDetailsPage: 'View details',
      viewPriorReleases: 'View prior releases',
      viewAnnotations: 'View annotations'
    },
    description: 'Description',
    detailedInformation: 'Details',
    edition: 'Edition',
    errorLoadingVolumes: 'Could not load volumes',
    expansion: {
      collapse: 'Collapse',
      expand: 'Expand'
    },
    duration: 'Duration',
    fileSize: 'File size',
    fileSizeInKB: '{ n } KB',
    isbn: 'ISBN',
    lastOpened: 'Last opened',
    release: 'Release',
    releaseDate: 'Release date',
    notesAction: 'View annotations',
    publisher: 'Publisher',
    priorReleases: 'Prior releases',
    recommended: 'Recommended titles',
    showAll: 'Show all',
    subjects: 'Subjects',
    tableOfContents: 'Table of contents',
    tocEmpty: 'No table of contents available for this title'
  },
  libraryCode: {
    error: {
      empty: 'Library code is required',
      generic: 'Something went wrong. Please try again later.',
      inaccessible: 'This library isn\u2019t available right now. Try again later, or contact the library for more help.',
      invalid: 'Sorry, we couldn\u2019t find that library. Try again, or contact the library for more help.',
      invalidType: 'Sorry, that collection isn\u2019t available in the LexisNexis Digital Library. Contact your library for more help.'
    },
    label: 'Library code',
    prompt: 'Enter your organization\u2019s library code',
    submit: 'Continue'
  },
  login: {
    copyright: '© {year} OverDrive, Inc. All Rights Reserved',
    loading: 'Loading',
    privacyPolicy: 'Privacy policy',
    termsAndConditions: 'Terms & conditions',

    email: {
      cancel: 'Cancel',
      code: 'Verification code',
      codeInstructions: 'Check your inbox, we sent you a verification code.',
      continue: 'Continue',
      emailAddress: 'Email address'
    },

    error: {
      custom: 'Something went wrong: { message }',
      generic: 'Something went wrong. Please try again later.',

      CaptchaRequired: 'Complete the captcha before trying again.',
      InvalidCredentials: 'One or more fields are wrong. Check your sign-in information and try again.',
      NoAuth: 'There was a problem signing you in. This sometimes happens when user or network requirements aren\u2019t met. If you need help, please contact your digital library administrator.',
      RegistrationIneligible: 'Your email may not be eligible for this product. Talk to your admin for help.',
      RegistrationInvalidCode: 'Error verifying your email address. Please try again.',
      Unsupported: 'That type of login isn\u2019t supported. Talk to your admin for help.',
      Unknown: 'Something went wrong. Please try again.',

      ExcessiveFines: 'There\u2019s been a problem with your account. Talk to your admin for help.',
      OverdueItems: 'There\u2019s been a problem with your account. Talk to your admin for help.',
      ExpiredCard: 'There\u2019s been a problem with your account. Talk to your admin for help.',
      LostCard: 'There\u2019s been a problem with your account. Talk to your admin for help.',
      StolenCard: 'There\u2019s been a problem with your account. Talk to your admin for help.',
      PrivilegesRevoked: 'There\u2019s been a problem with your account. Talk to your admin for help.',
      InvalidAccount: 'We can\u2019t find that account. Check your sign-in information and try again. For more help, talk to your admin.',
      RecordDeleted: 'We can\u2019t find that account. Check your sign-in information and try again. For more help, talk to your admin.',

      noAuth: 'There was a problem signing you in. This sometimes happens when user or network requirements aren\u2019t met. If you need help, please contact your digital library administrator.'
    },

    form: {
      username: {
        Barcode: 'Barcode',
        BlackboardLogin: 'Blackboard Login',
        CanvasLogin: 'Canvas Login',
        CardKey: 'Card Key',
        CardNumber: 'Card Number',
        EmailAddress: 'Email Address',
        EmployeeID: 'Employee ID',
        FirstAndLastName: 'First and Last Name',
        GuestLogin: 'Guest Login',
        IDNumber: 'ID Number',
        LastName: 'Last Name',
        library: 'Card number',
        LibraryCard: 'Library Card',
        LibraryCardOrUsername: 'Library Card or Username',
        Login: 'Login',
        LunchNumber: 'Lunch Number',
        NLBMyLibraryID: 'NLB MYLIBRARY ID',
        Name: 'Name',
        NetworkID: 'Network ID',
        Password: 'Password',
        PIN: 'PIN',
        SchoologyLogin: 'Schoology Login',
        StudentID: 'Student ID',
        StudentOrStaffID: 'Student/Staff ID',
        UniversityID: 'University ID',
        UserID: 'User ID',
        UserLogin: 'User Login',
        Username: 'Username',
        VerificationCode: 'Verification code'
      },
      password: {
        Birthdate: 'Birthdate',
        BirthdateMMDDYY: 'Birthdate (MMDDYY)',
        BirthdateMMDDYYYY: 'Birthdate (MMDDYYYY)',
        CampaignCode: 'Campaign Code',
        EmailAddress: 'Email Address',
        FirstName: 'First Name',
        GraduationYear: 'Graduation Year',
        IDNumber: 'ID Number',
        LastName: 'Last Name',
        LibraryCard: 'Library Card',
        LunchNumber: 'Lunch Number',
        Name: 'Name',
        Password: 'Password',
        PhoneLast4Digits: 'Phone (Last 4 Digits)',
        PhoneNumber: 'Phone Number',
        PhoneNumberOrPassword: 'Phone Number or Password',
        PIN: 'PIN',
        PinKey: 'PinKey',
        PINOrPassword: 'PIN/Password',
        StudentID: 'Student ID',
        StudentOrStaffID: 'Student/Staff ID',
        ZIPCode: 'ZIP Code'
      },

      complete: 'Auth complete, redirecting...',
      disclaimer: 'By clicking <strong>SIGN IN</strong>, you accept the <a>terms and conditions</a> of this site.',
      header: 'Sign in',
      help: 'Get help with your login credentials',
      prompt: 'Select your library...',
      signInAsGuest: 'Sign in as guest',
      signInWithEmail: 'Sign in with email',
      submit: 'Sign in',
      submitting: 'Submitting credentials...'
    }
  },
  mybooks: {
    header: 'My books',
    subjectFilter: 'Filters',
    textFilter: 'Search for a title',
    tabs: {
      ariaLabel: 'My books',
      borrowed: 'Borrowed',
      downloaded: 'Downloaded',
      history: 'History',
      holds: 'Holds',
      shared: 'Shared'
    }
  },
  nav: {
    link: {
      home: 'Home',
      browse: 'Browse library',
      mybooks: 'My books',
      notes: 'My annotations',
      export: 'Export queue',
      tags: 'My tags',
      ntc: 'Videos & more'
    },
    pagination: {
      previous: 'Previous',
      next: 'Next'
    }
  },
  pageHeader: {
    contextMenu: {
      ariaLabel: 'Open context menu',
      viewCover: 'View cover',
      viewDetails: 'View details',
      viewToc: 'View table of contents',
      copyAnnotations: 'Copy annotations to latest release',
      manageCopying: 'Manage annotation copying'
    },
    metadata: {
      edition: 'Edition',
      release: 'Release'
    }
  },
  home: {
    annotations: {
      count: '{ n, plural, one { # annotation } other { # annotations } } created',
      header: 'My annotations',
      viewAll: 'Go to my annotations',
      emptystate: {
        header: 'No annotations yet',
        content: 'As you make annotations in books, you\u2019ll see them here. You can click books listed here to see the notes and highlights you\u2019ve made in them.',
        link: 'Learn how to make annotations.'
      }
    },
    tags: {
      header: 'My tags',
      viewAll: 'Go to my tags'
    },
    recentlyRead: {
      header: 'Recently read',
      viewAll: 'View all',
      emptystate: {
        header: 'You haven\u2019t read any books yet',
        content: 'As you open books, they\u2019ll appear here. To find a book, use the search bar or go to the Browse library section',
        link: 'Learn how to get started with the Digital Library.'
      }
    }
  },
  searchThisTitle: {
    header: {
      title: 'Search this title',
      series: 'Search this set'
    },
    goToPassage: 'Go to passage',
    noMatches: 'No matches',
    resultDescription: {
      series: '{ sections, plural, one { # section } other { # sections } } contain matching { type, select, keyword { keyword } other { phrase } } in { volumes, plural, one { # volume of } other { # volumes of } } <a>{title}</a>.',
      title: '{ sections, plural, one { # section } other { # sections } } contain matching { type, select, keyword { keyword } other { phrase } } in <a>{title}{ subtitle, select, __MISSING_SUBTITLE {} other {: {subtitle}} }</a>.'
    },
    scope: {
      series: 'All volumes',
      title: 'This volume'
    },
    labels: {
      filters: 'Search filters',
      search: 'Phrase or keyword',
      query: 'Search query'
    },
    searchPlaceholder: {
      title:  'Search this title',
      series: 'Search this set'
    },
    share: 'Share',
    shareDescription: 'Share a link to this passage',
    sort: {
      relevance: 'Relevancy',
      chapter: 'Chapter'
    },
    type: {
      phrase: 'Phrase',
      keyword: 'Keyword'
    },
    unknownChapter: 'Unknown chapter'
  },
  series: {
    creator: 'Creator',
    contextMenu: {
      header: 'Series options',
      viewToC: 'View table of contents',
      viewDetailsPage: 'View details'
    },
    edition: 'Edition',
    label: 'Set',
    metadata: {
      description: 'Series metadata'
    },
    volume: 'Volume',
    volumes: 'Volumes',
    volumeCount: 'Volumes in set',
    volumeList: {
      label: 'Volume list',
      allVolumes: 'All volumes',
      volumesWithAnnotations: 'Volumes with annotations',
      downloadedVolumes: 'Downloaded volumes',
      textFilter: 'Search volumes'
    },
    view: 'View set'
  },
  signedOut: {
    action: 'Sign in again',
    header: 'You\u2019re signed out',
    message: 'Your session for { LIBRARY } expired.'
  },
  subjectIdOrBisacCode: {
    ...subjects,
    ...bisacCodes
  },
  subjects: {
    categories: {
      all: 'All'
    },
    emptystate: {
      header: 'No results',
      contentAll: 'No subjects found. Try a different keyword.',
      contentCategory: 'No subjects found in this category.'
    },
    error: 'Error loading subjects. Please try again.',
    header: 'Subjects',
    sort: {
      alphabeticalAsc: 'A-Z',
      alphabeticalDesc: 'Z-A',
      label: 'Sort by'
    },
    textFilter: 'Search for a subject'
  },
  survey: {
    header: 'Share your feedback',
    description: 'Let us know how we can improve the app.',
    callToAction: 'Take survey'
  },
  tag: {
    actions: {
      header: 'Tag actions',
      delete: {
        header: 'Delete tag',
        warning: 'Are you sure you want to delete this tag?',
        confirm: 'Delete',
        cancel: 'Cancel',
        success: 'Tag deleted'
      },
      rename: {
        header: 'Rename tag',
        placeholder: 'New tag name…',
        invalid: 'Invalid name',
        confirm: 'Save',
        cancel: 'Cancel'
      }
    },
    error: {
      missing: 'This tag does not exist.'
    },
    empty: {
      header: 'No titles yet',
      content: 'You don\u2019t have any titles with this tag'
    },
    filters: {
      text: {
        placeholder: 'Search for a title'
      },
      empty: {
        header: 'No titles found',
        content: 'You don\u2019t have any titles matching this term. Try using another term to find your titles.'
      }
    },
    showingCount: 'Showing {COUNT, number} of {TOTAL, number} titles',
    create: {
      placeholder: 'Add new tag',
      action: 'Add tag',
      added: '{NAME} was added to title',
      invalidName: 'Invalid name',
      autocompleteCount: '{ COUNT, plural, one { # suggestion } other { # suggestions } } ',
      autocompleteList: 'Suggestions',
      appliedList: 'Applied tags',
      removed: '{NAME} was removed from title'
    }
  },
  tags: {
    header: 'My tags',
    empty: {
      header: 'No tags yet',
      content: 'You can create tags to organize titles however you like. Tags can be anything, like case numbers, subjects, or even emoji 👍.',
      link: 'Learn how to create tags.'
    },
    filters: {
      text: {
        placeholder: 'Search for a tag'
      },
      empty: {
        header: 'No tags found',
        content: 'You don\u2019t have any tags matching this term. Try using another term to find your tags.'
      }
    }
  },
  time: {
    relative: '{ relativeTime }',
    date: '{ date, date, ::MMddyyyy}',
    today: 'Today'
  },
  switch: {
    on: 'On',
    off: 'Off'
  },
  update: {
    error: 'There was a problem updating the app. Reload the app and try again.',
    updating: 'Updating',
    reloading: 'Reloading',
    cancel: 'Stop update'
  },
  welcome: {
    header: {
      back: 'Go back',
      help: 'Help'
    }
  }
};

export default messages as typeof messages;
