
import { defineComponent, ref, watch } from 'vue';
import FormInput from './FormInput.vue';
import Prompt from './Prompt.vue';

export default defineComponent({
  name: 'HoldPrompt',
  components: {
    FormInput,
    Prompt
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    titleId: {
      type: String,
      required: true
    },
    emailAddress: {
      type: String,
      default: undefined
    }
  },
  emits: [
    'close',
    'hold'
  ],
  setup: (props, ctx) => {
    const submit = (data: unknown) => {
      ctx.emit('hold', data);
    };

    const email = ref(props.emailAddress);

    watch(() => props.show, (to) => {
      if (to) {
        email.value = props.emailAddress;
      }
    });

    return {
      email,
      submit
    };
  }
});
