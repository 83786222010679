
import { defineComponent } from 'vue';
import { APP } from 'app/base/app';
import { Constants } from 'app/base/constants';

export default defineComponent({
  name: 'SurveyCallout',
  emits: [
    'close'
  ],
  setup: (_, ctx) => {
    const link = Constants.SURVEY_LINK;

    const close = () => {
      setSurveyFlag(Constants.SURVEY_CLOSED_RESPITE_DAYS);
      ctx.emit('close');
    };

    const clickedSurvey = () => {
      setSurveyFlag(Constants.SURVEY_TAKEN_RESPITE_DAYS);
      ctx.emit('close');
    };

    const setSurveyFlag = (numberOfDays: number) => {
      const date = new Date();
      date.setDate(numberOfDays + date.getDate());

      APP.bank.set('survey:ask-again', date.toISOString());
    };

    return {
      clickedSurvey,
      close,
      link
    };
  }
});
