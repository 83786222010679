
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'FormSwitch',
  props: {
    modelValue: {
      type: Boolean,
      required: true
    },
    ariaLabel: {
      type: String,
      required: true
    }
  },
  emits: [
    'update:modelValue'
  ],
  setup: (props, ctx) => {
    const checked = computed({
      get: () => props.modelValue,
      set: (v) => ctx.emit('update:modelValue', v)
    });

    return {
      checked
    };
  }
});
