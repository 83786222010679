import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_TitleActionButton = _resolveComponent("TitleActionButton")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.title)
      ? (_openBlock(), _createBlock(_component_router_link, {
          key: 0,
          to: {
        name: 'TitleDetails',
        params: {
          titleSlug: _ctx.title.slug
        }
      },
          class: _normalizeClass(_ctx.$style.volume),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", {
              innerHTML: _ctx.title.subtitle || _ctx.title.title
            }, null, 8, _hoisted_1)
          ], undefined, true),
          _: 1
        }, 8, ["to", "class"]))
      : _createCommentVNode("", true),
    _createElementVNode("h2", {
      class: _normalizeClass(_ctx.$style.title)
    }, _toDisplayString(_ctx.result.chapterTitle || _ctx.$t('searchThisTitle.unknownChapter')), 3),
    _createElementVNode("ol", {
      class: _normalizeClass(_ctx.$style.matches)
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.result.matches, (match, index) => {
        return (_openBlock(), _createElementBlock("li", { key: index }, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.$style.match)
          }, [
            _createElementVNode("p", {
              class: _normalizeClass(_ctx.$style.excerpt),
              innerHTML: match
            }, null, 10, _hoisted_2),
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.$style.actions)
            }, [
              _createVNode(_component_TitleActionButton, {
                title: _ctx.title,
                seekTo: {
                type: 'query',
                location: _ctx.getBestQueryFromExcerpt(match)
              },
                label: _ctx.$t('searchThisTitle.goToPassage'),
                class: _normalizeClass(_ctx.$style.goto)
              }, null, 8, ["title", "seekTo", "label", "class"]),
              _createElementVNode("button", {
                ref_for: true,
                ref: _ctx.setItemRef,
                class: _normalizeClass(_ctx.$style.action),
                onClick: ($event: any) => (_ctx.share(index))
              }, _toDisplayString(_ctx.$t('searchThisTitle.share')), 11, _hoisted_3)
            ], 2)
          ], 2)
        ]))
      }), 128))
    ], 2)
  ]))
}