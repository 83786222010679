
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BrowseGroup',
  props: {
    name: {
      type: String,
      required: true
    },
    description: {
      type: String,
      default: undefined
    },
    buttonLabel: {
      type: String,
      required: true
    },
    navigateLink: {
      type: Object,
      required: true
    }
  }
});
