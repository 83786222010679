import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilterContent = _resolveComponent("FilterContent")!
  const _component_Sidebar = _resolveComponent("Sidebar")!

  return (_openBlock(), _createBlock(_component_Sidebar, {
    header: _ctx.$t('filters.header'),
    icon: "refine-list",
    show: _ctx.show,
    resizable: false,
    side: "right",
    onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('close')))
  }, _createSlots({
    default: _withCtx(() => [
      _createVNode(_component_FilterContent, {
        options: _ctx.options,
        categoryObjects: _ctx.categoryObjects,
        onApply: _cache[1] || (_cache[1] = (val) => _ctx.$emit('apply', val))
      }, null, 8, ["options", "categoryObjects"])
    ], undefined, true),
    _: 2
  }, [
    (_ctx.applied.length)
      ? {
          name: "extra",
          fn: _withCtx(() => [
            _createElementVNode("button", {
              class: _normalizeClass(_ctx.$style.clear),
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.clearFilters && _ctx.clearFilters(...args)))
            }, _toDisplayString(_ctx.$t('filters.clear')), 3)
          ]),
          key: "0"
        }
      : undefined
  ]), 1032, ["header", "show"]))
}