
import Collapsible from 'app/components/Collapsible.vue';
import InfoPanel from 'app/components/InfoPanel.vue';
import Overlay from 'app/components/Overlay.vue';
import { useAppEvents } from 'app/functions/use-app-events';
import { Breakpoint, useWindowSize } from 'app/functions/use-window-size';
import { computed, defineComponent, ref } from 'vue';

export type AccordionSection = {
  id: string;
  label: string;
  badge?: number;
};

export default defineComponent({
  name: 'Accordion',
  components: {
    Collapsible,
    InfoPanel,
    Overlay
  },
  props: {
    sections: {
      type: Array as () => AccordionSection[],
      required: true
    }
  },
  setup: (props) => {
    const { windowWidth } = useWindowSize();
    const mobile = computed(() => {
      return windowWidth.value <= Breakpoint.Narrow;
    });

    const sectionHeaderId = (id: string) => `accordion-header-${id}`;
    const sectionPanelId = (id: string) => `accordion-panel-${id}`;

    const activeSection = ref<AccordionSection | null>(null);

    const toggle = (id: string) => {
      activeSection.value = isActive(id)
        ? null
        : (props.sections.find((s) => s.id === id) || null);
    };

    const isActive = (id: string) => {
      return activeSection.value?.id === id;
    };

    useAppEvents({
      'prompt:show': () => {
        if (mobile.value) {
          activeSection.value = null;
        }
      }
    });

    return {
      activeSection,
      isActive,
      mobile,
      sectionHeaderId,
      sectionPanelId,
      toggle
    };
  }
});
