import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_TitleCardCopyStatus = _resolveComponent("TitleCardCopyStatus")!
  const _component_TagIndicator = _resolveComponent("TagIndicator")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_TitleCardContextMenu = _resolveComponent("TitleCardContextMenu")!
  const _component_CoverView = _resolveComponent("CoverView")!
  const _component_PriorReleaseSidebar = _resolveComponent("PriorReleaseSidebar")!
  const _component_TableOfContentsSidebar = _resolveComponent("TableOfContentsSidebar")!
  const _component_TitleCard = _resolveComponent("TitleCard")!

  return (_openBlock(), _createBlock(_component_TitleCard, {
    title: _ctx.title,
    link: _ctx.detailsLink
  }, {
    actions: _withCtx(() => [
      _createElementVNode("div", {
        ref: "actions",
        class: _normalizeClass(_ctx.$style.actions)
      }, [
        _createVNode(_component_router_link, {
          to: _ctx.detailsLink,
          class: _normalizeClass(_ctx.$style.detailsLink)
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('title.notesAction')), 1)
          ], undefined, true),
          _: 1
        }, 8, ["to", "class"]),
        (_ctx.jobsForTitle.jobs.length)
          ? (_openBlock(), _createBlock(_component_TitleCardCopyStatus, {
              key: 0,
              title: _ctx.title,
              copyJobsState: _ctx.jobsForTitle,
              actionsIconsOnly: _ctx.iconOnly
            }, null, 8, ["title", "copyJobsState", "actionsIconsOnly"]))
          : _createCommentVNode("", true),
        _createVNode(_component_TagIndicator, {
          title: _ctx.title,
          class: _normalizeClass(_ctx.$style.tags)
        }, null, 8, ["title", "class"]),
        _createElementVNode("button", {
          ref: "overflow",
          class: _normalizeClass(_ctx.$style.overflow),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showContextMenu = true))
        }, [
          _createVNode(_component_Icon, { name: "overflow" })
        ], 2),
        (_ctx.showContextMenu)
          ? (_openBlock(), _createBlock(_component_TitleCardContextMenu, {
              key: 1,
              reference: _ctx.overflow,
              title: _ctx.title,
              subscribeText: _ctx.subscribeTextLong,
              onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showContextMenu = false)),
              "onShow:coverview": _cache[2] || (_cache[2] = ($event: any) => (_ctx.showCoverView = true)),
              "onShow:priorreleases": _cache[3] || (_cache[3] = ($event: any) => (_ctx.showPriorReleases = true)),
              "onShow:tableofcontents": _cache[4] || (_cache[4] = ($event: any) => (_ctx.showTableOfContents = true)),
              "onToggle:subscription": _ctx.toggleSubscription
            }, null, 8, ["reference", "title", "subscribeText", "onToggle:subscription"]))
          : _createCommentVNode("", true),
        (_ctx.showCoverView)
          ? (_openBlock(), _createBlock(_component_CoverView, {
              key: 2,
              title: _ctx.title,
              onClose: _ctx.hideOverlay
            }, null, 8, ["title", "onClose"]))
          : _createCommentVNode("", true),
        _createVNode(_component_PriorReleaseSidebar, {
          show: _ctx.showPriorReleases,
          title: _ctx.title,
          onClose: _ctx.hideOverlay
        }, null, 8, ["show", "title", "onClose"]),
        _createVNode(_component_TableOfContentsSidebar, {
          show: _ctx.showTableOfContents,
          item: _ctx.title,
          onClose: _ctx.hideOverlay
        }, null, 8, ["show", "item", "onClose"])
      ], 2)
    ]),
    _: 1
  }, 8, ["title", "link"]))
}