import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["href", "onClick"]
const _hoisted_5 = { class: "visually-hidden" }
const _hoisted_6 = {
  key: 0,
  class: "series-badge"
}
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = ["aria-label"]
const _hoisted_11 = { class: "visually-hidden" }
const _hoisted_12 = ["aria-label"]
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = ["innerHTML"]
const _hoisted_15 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_FocusTrap = _resolveComponent("FocusTrap")!

  return (_openBlock(), _createElementBlock("article", {
    class: _normalizeClass({
      [_ctx.$style.card]: true,
      [_ctx.$style.borderless]: _ctx.borderless,
      [_ctx.$style.compact]: _ctx.compact
    })
  }, [
    _createElementVNode("div", {
      class: _normalizeClass([_ctx.$style.info, _ctx.showInfoFocus ? _ctx.$style.focused : ''])
    }, [
      (_ctx.link)
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 0,
            "aria-hidden": true,
            to: _ctx.link,
            class: _normalizeClass(_ctx.$style.cover),
            tabindex: "-1"
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "cover")
            ], undefined, true),
            _: 3
          }, 8, ["to", "class"]))
        : (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(_ctx.$style.cover)
          }, [
            _renderSlot(_ctx.$slots, "cover")
          ], 2)),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.meta)
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style.titlingContainer)
        }, [
          (_ctx.link)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(_ctx.$style.titling)
              }, [
                _createElementVNode("h2", {
                  ref: "titleName",
                  class: _normalizeClass([_ctx.$style.title, _ctx.$style.clamped2]),
                  innerHTML: _ctx.item.title
                }, null, 10, _hoisted_1),
                (_ctx.item.subtitle)
                  ? (_openBlock(), _createElementBlock("p", {
                      key: 0,
                      ref: "subtitle",
                      class: _normalizeClass([_ctx.$style.secondary, _ctx.$style.clamped]),
                      innerHTML: _ctx.item.subtitle
                    }, null, 10, _hoisted_2))
                  : _createCommentVNode("", true),
                (_ctx.item.authors)
                  ? (_openBlock(), _createElementBlock("p", {
                      key: 1,
                      ref: "authors",
                      class: _normalizeClass([_ctx.$style.secondary, _ctx.$style.clamped]),
                      innerHTML: _ctx.item.authors
                    }, null, 10, _hoisted_3))
                  : _createCommentVNode("", true),
                _createVNode(_component_router_link, {
                  to: _ctx.link,
                  custom: ""
                }, {
                  default: _withCtx(({ href, navigate }) => [
                    _createElementVNode("a", {
                      href: href,
                      class: _normalizeClass(_ctx.$style.link),
                      onClick: navigate,
                      onFocus: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showInfoFocus = true)),
                      onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showInfoFocus = false))
                    }, [
                      _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('title.contextMenu.viewDetailsPage')), 1)
                    ], 42, _hoisted_4)
                  ]),
                  _: 1
                }, 8, ["to"])
              ], 2))
            : (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass(_ctx.$style.titling)
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass(_ctx.$style.titleGroup)
                }, [
                  (_ctx.showSeriesBadge)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.$t('series.label')), 1))
                    : _createCommentVNode("", true),
                  _createElementVNode("h2", {
                    ref: "titleName",
                    class: _normalizeClass([_ctx.$style.title, _ctx.$style.clamped2]),
                    innerHTML: _ctx.item.title
                  }, null, 10, _hoisted_7)
                ], 2),
                (_ctx.item.subtitle)
                  ? (_openBlock(), _createElementBlock("p", {
                      key: 0,
                      ref: "subtitle",
                      class: _normalizeClass([_ctx.$style.secondary, _ctx.$style.clamped]),
                      innerHTML: _ctx.item.subtitle
                    }, null, 10, _hoisted_8))
                  : _createCommentVNode("", true),
                (_ctx.item.authors)
                  ? (_openBlock(), _createElementBlock("p", {
                      key: 1,
                      ref: "authors",
                      class: _normalizeClass([_ctx.$style.secondary, _ctx.$style.clamped]),
                      innerHTML: _ctx.item.authors
                    }, null, 10, _hoisted_9))
                  : _createCommentVNode("", true)
              ], 2)),
          (_ctx.showExpansionButton)
            ? (_openBlock(), _createElementBlock("div", {
                key: 2,
                class: _normalizeClass(_ctx.$style.expansionButtonContainer),
                "aria-hidden": "true"
              }, [
                _createElementVNode("button", {
                  type: "button",
                  class: _normalizeClass(_ctx.$style.expansionButton),
                  "aria-label": _ctx.$t('title.expansion.expand'),
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showExpansion = true))
                }, [
                  _createVNode(_component_Icon, { name: "truncation-expander" })
                ], 10, _hoisted_10)
              ], 2))
            : _createCommentVNode("", true)
        ], 2),
        (_ctx.item.metadata)
          ? (_openBlock(), _createElementBlock("table", {
              key: 0,
              class: _normalizeClass(_ctx.$style.metadata)
            }, [
              _createElementVNode("caption", _hoisted_11, _toDisplayString(_ctx.$t('title.metadata.description')), 1),
              _createElementVNode("tbody", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.item.metadata, (meta) => {
                  return (_openBlock(), _createElementBlock("tr", {
                    key: meta.label
                  }, [
                    _createElementVNode("th", {
                      class: _normalizeClass(_ctx.$style.metadataLabel),
                      scope: "row"
                    }, _toDisplayString(_ctx.$t(meta.label)), 3),
                    _createElementVNode("td", null, _toDisplayString(meta.value), 1)
                  ]))
                }), 128))
              ])
            ], 2))
          : _createCommentVNode("", true)
      ], 2)
    ], 2),
    (_ctx.showActions)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(_ctx.$style.actions)
        }, [
          _renderSlot(_ctx.$slots, "actions")
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.showExpansion)
      ? (_openBlock(), _createBlock(_component_FocusTrap, {
          key: 1,
          onDeactivate: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showExpansion = false))
        }, {
          default: _withCtx(() => [
            (_ctx.showExpansion)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass(_ctx.$style.expansion)
                }, [
                  _createElementVNode("button", {
                    type: "button",
                    class: _normalizeClass(_ctx.$style.expansionDismiss),
                    "aria-label": _ctx.$t('title.expansion.collapse'),
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showExpansion = false))
                  }, [
                    _createVNode(_component_Icon, { name: "dismiss" })
                  ], 10, _hoisted_12),
                  _createElementVNode("h3", {
                    class: _normalizeClass(_ctx.$style.title),
                    innerHTML: _ctx.item.title
                  }, null, 10, _hoisted_13),
                  (_ctx.item.subtitle)
                    ? (_openBlock(), _createElementBlock("p", {
                        key: 0,
                        class: _normalizeClass(_ctx.$style.secondary),
                        innerHTML: _ctx.item.subtitle
                      }, null, 10, _hoisted_14))
                    : _createCommentVNode("", true),
                  (_ctx.item.authors)
                    ? (_openBlock(), _createElementBlock("p", {
                        key: 1,
                        class: _normalizeClass(_ctx.$style.secondary),
                        innerHTML: _ctx.item.authors
                      }, null, 10, _hoisted_15))
                    : _createCommentVNode("", true)
                ], 2))
              : _createCommentVNode("", true)
          ], undefined, true),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 2))
}