
import CopyStatusBanner from 'app/components/CopyStatusBanner.vue';
import DynamicExpander from 'app/components/DynamicExpander.vue';
import { CopyUpdateType, updateCopyJob } from 'app/functions/use-annotation-copying';
import { CopyJobsState } from 'app/functions/use-copy-jobs-state';
import { ADCopyJobState } from 'app/keys/injection-keys';
import { ComputedRef, computed, defineComponent, inject, ref } from 'vue';

export default defineComponent({
  name: 'AnnotationDetailsCopyContent',
  components: {
    CopyStatusBanner,
    DynamicExpander
  },
  props: {
    titleSlug: {
      type: String,
      required: true
    }
  },
  emits: [
  ],
  setup: () => {
    const header = ref<HTMLElement | null>(null);

    const copyState = inject(ADCopyJobState) as ComputedRef<CopyJobsState>;

    const filteredJobs = computed(() => {
      return copyState.value.status === 'loaded'
        ? copyState.value.jobs.filter((job) => !job.reviewed)
        : [];
    });

    // Use as :key for DynamicExpander.
    // Needed for small screens, where the number of banners doesnt change but the size of a banner may.
    // If number changes, reload the component so panel height readjusts.
    const numInProgress = computed(() => filteredJobs.value.map((job) => job.status === 'IN_PROGRESS').length);

    const update = (id: number, updateType: CopyUpdateType) => {
      // Shift focus to visually hidden header
      header.value?.focus();

      updateCopyJob(id, updateType);
    };

    return {
      copyState,
      filteredJobs,
      header,
      numInProgress,
      update
    };
  }
});
