
import Popout from 'app/components/Popout.vue';
import RelativeDate from 'app/components/RelativeDate.vue';
import { useI18n } from 'app/functions/use-i18n';
import { relativeTimeFormatter, toRelativeTimeOptions } from 'app/i18n/relative-time-formatter';
import { Loan } from 'app/models/loan';
import { computed, defineComponent, ref } from 'vue';

/**
 * An indicator (and label) that displays the amount of time until this
 * loan is expired. It also allows for early returns and renewals, when available
 * @displayName LoanExpirationIndicator
 */
export default defineComponent({
  name: 'LoanExpirationIndicator',
  components: {
    Popout,
    RelativeDate
  },
  props: {
    /**
     * The loan to display expiration information for
     */
    loan: Loan,
    /**
     * When true, only the icon will be rendered. No date label.
     */
    iconOnly: {
      type: Boolean,
      default: false
    }
  },
  setup: (props, ctx) => {
    const iconBtn = ref<HTMLButtonElement | null>(null);

    const isPopped = ref(false);

    const isExpired = computed(() => props.loan?.isExpired() || false);
    const isRenewable = computed(() => props.loan?.isRenewable() || false);
    const isReturnable = computed(() => props.loan?.isReturnable() || false);

    const { locale } = useI18n();
    const timestamp = computed(() => props.loan?.expireTime || 0);
    const relativeTime = computed(() => {
      const opts = toRelativeTimeOptions(timestamp.value);

      if (opts) {
        opts.numeric = 'always'; //always to show 'in 1 day' instead of 'tomorrow'

        return relativeTimeFormatter(opts, locale);
      }

      return undefined;
    });

    const date = computed(() => new Date(timestamp.value));


    const renewLoan = () => {
      props.loan?.renewLoan();
      isPopped.value = false;
    };

    const returnLoan = () => {
      props.loan?.returnLoan();
      isPopped.value = false;
    };

    return {
      iconBtn,
      date,
      relativeTime,
      timestamp,

      isExpired,
      isPopped,
      isRenewable,
      isReturnable,

      renewLoan,
      returnLoan
    };
  }
});
