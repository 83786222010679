
import { computed, defineComponent, ref, watch } from 'vue';
import FormCaptcha from 'app/components/FormCaptcha.vue';
import LoginFormExternal from 'app/components/LoginFormExternal.vue';
import LoginFormLocal from 'app/components/LoginFormLocal.vue';
import FormSelect from 'app/components/FormSelect.vue';
import type { AuthLoginPage, LoginForm, LoginFormSubmission } from 'app/base/interfaces';
import { AuthenticationState } from 'app/functions/use-authentication';
import { AuthError } from 'app/base/auth-definitions';
import FormError from 'app/components/FormError.vue';
import EmailRegistration from 'app/components/EmailRegistration.vue';

export default defineComponent({
  name: 'LoginForm',
  components: {
    FormCaptcha,
    LoginFormExternal,
    LoginFormLocal,
    FormSelect,
    FormError,
    EmailRegistration
  },
  props: {
    page: {
      type: Object as () => AuthLoginPage,
      required: true
    },
    auth: {
      type: Object as () => AuthenticationState,
      required: true
    }
  },
  emits: [
    'cancel',
    'submit'
  ],
  setup: (props, ctx) => {
    const dropdownForms = computed(() =>
      props.page.forms
        .slice()
        .filter((f) => f.type === 'Local' || f.type === 'External')
        .sort((a, b) => a.sortName.localeCompare(b.sortName))
    );

    const guestForm = computed(() => {
      if (props.page.guestSession) {
        return {
          type: 'Guest' as const,
          ilsName: props.page.guestSession.ilsName
        };
      }

      return undefined;
    });

    const emailForm = computed(() =>
      props.page.forms.find((f) => f.type === 'EmailRegistration')
    );

    const selected = ref<LoginForm | null>(null);

    const clearSelection = () => {
      selected.value = dropdownForms.value.length === 1
        ? dropdownForms.value[0]
        : null;

      ctx.emit('cancel');
    };

    clearSelection();

    const emailSelected = computed(() => selected.value?.type === 'EmailRegistration');

    const formData = ref<LoginFormSubmission | null>(null);
    watch(selected, (to, from) => {
      if (!to) {
        formData.value = null;

        return;
      }

      if (to.ilsName !== formData.value?.ilsName) {
        formData.value = {
          type: to.type as LoginFormSubmission['type'],
          ilsName: to.ilsName,
          username: null,
          password: null
        };
      }
    }, { immediate: true });
    const username = computed({
      get: () => formData.value?.username || '',
      set: (v) => formData.value!.username = v
    });
    const password = computed({
      get: () => formData.value?.password || '',
      set: (v) => formData.value!.password = v
    });

    const captchaRequired = computed(() => {
      return props.auth.state === 'error'
        && props.auth.code === AuthError.CaptchaRequired;
    });

    const submit = (data: LoginFormSubmission | null | undefined) => {
      if (!data) { return; }

      try {
        if (captchaRequired.value && window.grecaptcha) {
          data.captcha = window.grecaptcha.getResponse();
        }
      } catch (err) {
        console.error('[CAPTCHA] Could not get captcha response', err);
      }

      ctx.emit('submit', { ...data });
    };

    const submitting = computed(() => {
      return props.auth.state === 'submitting';
    });

    const errorMessage = computed(() => {
      if (props.auth.state === 'error') {
        return props.auth.message;
      }

      if (props.auth.state === 'code' && props.auth.error) {
        return props.auth.error.message;
      }

      return undefined;
    });

    return {
      captchaRequired,
      clearSelection,
      dropdownForms,
      errorMessage,
      formData,
      emailForm,
      emailSelected,
      guestForm,
      selected,
      submit,
      submitting,
      username,
      password
    };
  }
});
