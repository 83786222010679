
import { defineComponent } from 'vue';
import { Constants } from 'app/base/constants';

export default defineComponent({
  name: 'WelcomeHeader',
  props: {
    backButtonLabel: {
      type: String,
      default: undefined
    }
  },
  emits: [
    'back'
  ],
  setup: () => {
    return {
      helpLink: Constants.HELP_PATHS.HOME
    };
  }
});
