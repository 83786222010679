
import { computed, defineComponent, onMounted, ref } from 'vue';
import { LoginFormField } from 'app/base/interfaces';
import FormInput from 'app/components/FormInput.vue';
import FormSubmitButton from 'app/components/FormSubmitButton.vue';

export default defineComponent({
  name: 'LoginFormLocal',
  components: {
    FormInput,
    FormSubmitButton
  },
  props: {
    username: {
      type: String,
      required: false,
      default: ''
    },
    usernameConfig: {
      type: Object as () => LoginFormField,
      required: true
    },
    password: {
      type: String,
      required: false,
      default: ''
    },
    passwordConfig: {
      type: Object as () => LoginFormField,
      required: true
    },
    loginHintUrl: {
      type: String,
      default: undefined
    }
  },
  emits: [
    'username',
    'password',
    'submit'
  ],
  setup: (props, ctx) => {
    const usernameInput = computed({
      get: () => props.username,
      set: (val) => ctx.emit('username', val)
    });
    const passwordInput = computed({
      get: () => props.password,
      set: (val) => ctx.emit('password', val)
    });

    const showPassword = ref(false);

    const submit = () => {
      ctx.emit('submit',
        {
          username: usernameInput.value,
          password: passwordInput.value
        }
      );
    };

    const initialInput = ref<{ focus: () => void } | null>(null);

    onMounted(() => {
      initialInput.value?.focus();
    });

    return {
      initialInput,
      passwordInput,
      showPassword,
      submit,
      usernameInput
    };
  }
});
