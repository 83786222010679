
import Cover from 'app/components/Cover.vue';
import RelativeDate from 'app/components/RelativeDate.vue';
import TitleActionButton from 'app/components/TitleActionButton.vue';
import { usePatron } from 'app/functions/use-patron';
import { Title } from 'app/models/title';
import { RouteName } from 'app/router/constants';
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'RecentlyReadTitleCard',
  components: {
    RelativeDate,
    TitleActionButton,
    Cover
  },
  props: {
    title: {
      type: Title,
      required: true
    }
  },
  emits: [
    'vfocus'
  ],
  setup: (props, ctx) => {
    const { recentlyRead } = usePatron();
    const readTag = computed(() => recentlyRead.value.find((t) => t.titleSlug === props.title.slug));
    const timestamp = computed(() => readTag?.value.lastAccessTime);

    const titleLink = computed(() => ({ name: RouteName.TitleDetails, params: { titleSlug: props.title.slug } }));

    const fullTitle = (`${props.title.title} ${props.title.subtitle || ''}`).trim();


    const onFocus = () => {
      ctx.emit('vfocus');
    };

    return {
      fullTitle,
      onFocus,
      timestamp,
      titleLink
    };
  }
});
