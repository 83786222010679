import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, renderSlot as _renderSlot, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = ["aria-label"]
const _hoisted_3 = ["disabled", "aria-haspopup", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Popout = _resolveComponent("Popout")!

  return (_openBlock(), _createBlock(_component_Popout, {
    ref: "popout",
    class: _normalizeClass(_ctx.$style.shield),
    reference: _ctx.reference,
    labelId: "contextMenu",
    onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.container)
      }, [
        _createElementVNode("section", null, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.$style.headerContainer)
          }, [
            (_ctx.headerLabel)
              ? (_openBlock(), _createElementBlock("header", {
                  key: 0,
                  class: _normalizeClass(_ctx.$style.header)
                }, [
                  (_ctx.headerIconName)
                    ? (_openBlock(), _createBlock(_component_Icon, {
                        key: 0,
                        name: _ctx.headerIconName
                      }, null, 8, ["name"]))
                    : _createCommentVNode("", true),
                  _createElementVNode("h1", {
                    id: "contextMenu",
                    class: _normalizeClass(_ctx.$style.menuHeading)
                  }, _toDisplayString(_ctx.$t(_ctx.headerLabel)), 3)
                ], 2))
              : (_openBlock(), _createElementBlock("span", _hoisted_1)),
            _createElementVNode("button", {
              class: _normalizeClass(`${_ctx.$style.dismiss}`),
              "aria-label": _ctx.$t('general.close'),
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
            }, [
              _createVNode(_component_Icon, { name: "dismiss" })
            ], 10, _hoisted_2)
          ], 2),
          _createElementVNode("ul", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
              return (_openBlock(), _createElementBlock("li", {
                key: index,
                class: _normalizeClass(_ctx.$style.option)
              }, [
                ('slotName' in option)
                  ? _renderSlot(_ctx.$slots, option.slotName, { key: 0 })
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      (option.iconName)
                        ? (_openBlock(), _createBlock(_component_Icon, {
                            key: 0,
                            name: option.iconName
                          }, null, 8, ["name"]))
                        : _createCommentVNode("", true),
                      ('handler' in option)
                        ? (_openBlock(), _createElementBlock("button", {
                            key: 1,
                            class: _normalizeClass({
                  [_ctx.$style.inactive]: option.inactive
                }),
                            disabled: option.inactive,
                            "aria-haspopup": option.hasPopup,
                            onClick: option.handler
                          }, _toDisplayString(_ctx.$t(option.label)), 11, _hoisted_3))
                        : (_openBlock(), _createBlock(_component_router_link, {
                            key: 2,
                            to: option.location
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t(option.label)), 1)
                            ], undefined, true),
                            _: 2
                          }, 1032, ["to"]))
                    ], 64))
              ], 2))
            }), 128))
          ])
        ]),
        _renderSlot(_ctx.$slots, "additional")
      ], 2)
    ], undefined, true),
    _: 3
  }, 8, ["class", "reference"]))
}