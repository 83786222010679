
import { defineComponent } from 'vue';
import Page from 'app/components/Page.vue';

export default defineComponent({
  name: 'LoadingPage',
  components: {
    Page
  },
  props: {
    includeToolbar: {
      type: Boolean,
      default: true
    }
  }
});
