
import { defineComponent, ref, onBeforeUnmount } from 'vue';
import EmptyState from 'app/components/EmptyState.vue';
import HomeAnnotationCard from 'app/components/HomeAnnotationCard.vue';
import { APP } from 'app/base/app';
import { TitleRecord } from 'app/models/title';
import { Constants } from 'app/base/constants';

export default defineComponent({
  name: 'HomeAnnotationList',
  components: {
    EmptyState,
    HomeAnnotationCard
  },
  setup: () => {
    const helpLink = Constants.HELP_PATHS.ANNOTATIONS;
    const titlesWithAnnotations = ref<TitleRecord[]>([]);

    const fillOut = async () => {
      titlesWithAnnotations.value = (await APP.patron.annotations
        .getRecentByTitle(6))
        .filter((t) => t.mediaType !== 'audiobook');
    };
    const handler = APP.events.on('annotation:update:all', fillOut);
    fillOut();

    onBeforeUnmount(() => {
      handler.deafen();
    });

    return {
      helpLink,
      titlesWithAnnotations
    };
  }
});
