
import { defineComponent, nextTick, onMounted, ref } from 'vue';

export default defineComponent({
  name: 'ShowAll',
  setup: (_, ctx) => {
    const container = ref<HTMLElement | null>(null);
    const fullHeight = ref(false);

    const maxHeight =
      20 // lines
      * 1.5 // line-height
      * 16; // font-size

    onMounted(async () => {
      if (!container.value) { return; }

      await nextTick();
      if (container.value.scrollHeight <= maxHeight) {
        fullHeight.value = true;
      }
    });

    const showAll = () => fullHeight.value = true;

    return {
      container,
      fullHeight,
      showAll
    };
  }
});
