import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PromptContent = _resolveComponent("PromptContent")!
  const _component_Overlay = _resolveComponent("Overlay")!

  return (_openBlock(), _createBlock(_component_Overlay, {
    show: _ctx.show,
    transition: _ctx.transition,
    type: "alertdialog",
    teleportTo: "#prompts",
    header: _ctx.title,
    contentClass: _ctx.$style.prompt,
    onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.notch)
      }, null, 2),
      _createVNode(_component_PromptContent, {
        title: _ctx.title,
        confirmLabel: _ctx.confirmLabel,
        submittable: _ctx.submittable,
        cancelLabel: _ctx.cancelLabel,
        deleteLabel: _ctx.deleteLabel,
        onSubmit: _ctx.submit,
        onDelete: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('delete'))),
        onCancel: _ctx.cancel
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ], undefined, true),
        _: 3
      }, 8, ["title", "confirmLabel", "submittable", "cancelLabel", "deleteLabel", "onSubmit", "onCancel"])
    ], undefined, true),
    _: 3
  }, 8, ["show", "transition", "header", "contentClass"]))
}