
import { defineComponent, ref, computed } from 'vue';
import BrowseGroupCollection from 'app/components/BrowseGroupCollection.vue';
import BrowseGroupSubjects from 'app/components/BrowseGroupSubjects.vue';
import Page from 'app/components/Page.vue';
import Surface from 'app/components/Surface.vue';
import { APP } from 'app/base/app';
import { RouteName } from 'app/router/constants';
import { ThunderFacetItem, ThunderView } from 'app/base/thunder';
import { useLibrary } from 'app/functions/use-library';
import { SearchType, toSearchDefinition } from 'app/base/search';

type BrowseSearchType = 'collection' | 'subject';

type State<T extends BrowseSearchType> =
  {
    state: 'loading';
  }
  | {
    state: 'loaded';
    results: T extends 'collection' ? ThunderView : ThunderFacetItem[];
  }
  | {
    state: 'notfound';
  };

export default defineComponent({
  name: 'Browse',
  components: {
    BrowseGroupCollection,
    BrowseGroupSubjects,
    Page,
    Surface
  },
  setup: (props, ctx) => {
    const library = useLibrary();

    // Collection Definitions

    const collectionState = ref<State<'collection'>>({ state: 'loading' });

    const getCollections = async () => {
      try {
        const collections = await APP.services.thunder.getPages(APP.library.key(), true);

        if (!collections) {
          collectionState.value = {
            state: 'notfound'
          };

          return;
        }

        collectionState.value = {
          state: 'loaded',
          results: collections.items.find((item) => item.isDefault)!.views.find((view) => view.isDefault)!
        };
      } catch {
        collectionState.value = {
          state: 'notfound'
        };
      }
    };

    getCollections();

    const showableCollections = computed(() => {
      if (collectionState.value.state === 'loaded') {
        const collectionOrder = collectionState.value.results.collections.reduce((obj, collection) => {
          obj[collection.id] = collection.order;

          return obj;
        }, {} as Record<string, number>);

        return collectionState.value.results.collectionDefinitions.map((definition) => {
          return {
            id: definition.id,
            name: definition.name,
            description: definition.description,
            searchType: definition.itemType === 'Series' ? SearchType.Series : SearchType.Title,
            definition: toSearchDefinition(definition)
          };
        }).sort((a, b) => collectionOrder[a.id] - collectionOrder[b.id]);
      }

      return [];
    });

    const fullCollectionNavigateLink = {
      name: RouteName.Search
    };

    // Subjects

    const subjectState = ref<State<'subject'>>({ state: 'loading' });

    const getSubjects = async () => {
      try {
        const subjects = await APP.services.thunder.getSubjects(APP.library.key());

        if (!subjects) {
          subjectState.value = {
            state: 'notfound'
          };

          return;
        }

        subjectState.value = {
          state: 'loaded',
          results: subjects
        };
      } catch {
        subjectState.value = {
          state: 'notfound'
        };
      }
    };

    getSubjects();

    const showableSubjects = computed(() => {
      return subjectState.value.state === 'loaded'
        ? subjectState.value.results
        : [];
    });

    const loading = computed(() => {
      return collectionState.value.state === 'loading' || subjectState.value?.state === 'loading';
    });

    return {
      fullCollectionNavigateLink,
      library,
      loading,
      showableCollections,
      showableSubjects
    };
  }
});
