
import { defineComponent } from 'vue';
import { Title } from 'app/models/title';
import Sidebar from 'app/components/Sidebar.vue';
import TitlePriorReleaseList from 'app/components/TitlePriorReleaseList.vue';

export default defineComponent({
  name: 'PriorReleaseSidebar',
  components: {
    Sidebar,
    TitlePriorReleaseList
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    title: {
      type: Title,
      required: true
    }
  },
  emits: [
    'close'
  ]
});
