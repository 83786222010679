import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormInput = _resolveComponent("FormInput")!
  const _component_Prompt = _resolveComponent("Prompt")!

  return (_openBlock(), _createBlock(_component_Prompt, {
    show: _ctx.show,
    title: _ctx.$t('circ.action.placeHold'),
    describedBy: `hold-explanation-${_ctx.titleId}`,
    confirmLabel: _ctx.$t('circ.action.placeHold'),
    cancelLabel: _ctx.$t('general.cancel'),
    onSubmit: _ctx.submit,
    onCancel: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close'))),
    onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('close')))
  }, {
    default: _withCtx(() => [
      _createElementVNode("p", {
        id: `hold-explanation-${_ctx.titleId}`
      }, _toDisplayString(_ctx.$t('circ.explanation.hold')), 9, _hoisted_1),
      _createVNode(_component_FormInput, {
        modelValue: _ctx.email,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
        type: "email",
        required: true,
        label: _ctx.$t('form.email'),
        formName: "email",
        class: _normalizeClass(_ctx.$style.email)
      }, null, 8, ["modelValue", "label", "class"])
    ], undefined, true),
    _: 1
  }, 8, ["show", "title", "describedBy", "confirmLabel", "cancelLabel", "onSubmit"]))
}