
import { computed, defineComponent } from 'vue';
import { HudsonSearchResponse, SearchParameters, SearchThisTitleQuery } from 'app/base/hudson';
import { MediaType } from 'app/base/interfaces';
import { Dictionary } from 'lib/common/dictionary';
import Pagination from 'app/components/Pagination.vue';
import SearchThisTitleResultList from 'app/components/SearchThisTitleResultList.vue';
import SearchThisTitleForm from 'app/components/SearchThisTitleForm.vue';
import { RouteName } from 'app/router/constants';
import { TitleRecord } from 'app/models/title';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'SearchThisTitleResults',
  components: {
    Pagination,
    SearchThisTitleForm,
    SearchThisTitleResultList
  },
  props: {
    item: {
      type: Object as () => {
        title: string;
        subtitle?: string;
        mediaType: MediaType | 'series';
        seriesId?: number;
        path: () => string;
      },
      required: true
    },
    results: {
      type: Object as () => HudsonSearchResponse | null,
      default: null
    },
    titles: {
      type: Object as () => Dictionary<TitleRecord>,
      default: () => ({})
    },
    error: {
      type: Boolean,
      default: false
    },
    request: {
      type: Object as () => SearchParameters,
      required: true
    }
  },
  emits: [
    'close',
    'page',
    'search',
    'share'
  ],
  setup: (props, ctx) => {
    const onSearch = (params: SearchThisTitleQuery) => {
      ctx.emit('search', {
        scope: params.scope,
        query: params.query,
        type: params.type
      });
    };

    const onPage = (newPage: number) => {
      ctx.emit('page', newPage);
    };

    const router = useRouter();
    const path = computed(() => {
      const name = props.request.scope === 'series'
        ? RouteName.SetDetails
        : RouteName.TitleDetails;
      const params: Dictionary<string> =  props.request.scope === 'series'
        ? { seriesId: props.request.id }
        : { titleSlug: props.request.id };

      return router.resolve({
        name,
        params
      }).href;
    });

    return {
      onPage,
      onSearch,
      path
    };
  }
});
