
import ContextMenu, { ContextMenuOption } from 'app/components/contextMenus/ContextMenu.vue';
import TitleActionButton from 'app/components/TitleActionButton.vue';
import { useI18n } from 'app/functions/use-i18n';
import { getReleaseDisplayNameTitle } from 'app/functions/use-release-display-name';
import { ADReleasesWithAnnotationsSymbol } from 'app/keys/injection-keys';
import { FilterObject } from 'app/models/filter-object';
import { TitleRecord } from 'app/models/title';
import { RouteName } from 'app/router/constants';
import { computed, ComputedRef, defineComponent, inject, PropType, ref } from 'vue';

export default defineComponent({
  components: {
    ContextMenu,
    TitleActionButton
},
  props: {
    reference: {
      type: Object as PropType<HTMLElement | null>,
      required: true
    },
    title: {
      type: Object as PropType<TitleRecord>,
      required: true
    },
    includeCopying: {
      type: Boolean,
      required: true
    },
    subscribeText: {
      type: String,
      required: true
    }
  },
  emits: [
    'close',
    'show:cover',
    'show:tableofcontents',
    'show:manualcopy',
    'show:managecopying',
    'toggle:subscription'
  ],
  setup: (props, ctx) => {
    const { t } = useI18n();

    const releasesWithAnnotations = inject(ADReleasesWithAnnotationsSymbol, undefined) as ComputedRef<FilterObject[]> | undefined;
    const allowManualCopy = !!(releasesWithAnnotations?.value.filter((release) => release.name !== getReleaseDisplayNameTitle(props.title)).length);

    const contextMenuOptions = computed<ContextMenuOption[]>(() => {
      const opts = [
        {
          slotName: 'read'
        },
        {
          label: t('pageHeader.contextMenu.viewDetails'),
          location: {
            name: RouteName.TitleDetails,
            params: { titleSlug: props.title.slug }
          }
        },
        {
          label: t('pageHeader.contextMenu.viewToc'),
          handler: viewToc
        },
        {
          label: t('pageHeader.contextMenu.viewCover'),
          handler: viewCover,
          hasPopup: 'dialog'
        },
        {
          label: props.subscribeText,
          handler: toggleSubscription
        }
      ] as ContextMenuOption[];
      if (props.includeCopying) {
        opts.push({
          label: t('pageHeader.contextMenu.copyAnnotations'),
          handler: manualCopy,
          inactive: !allowManualCopy
        },
        {
          label: t('pageHeader.contextMenu.manageCopying'),
          handler: manageCopying
        });
      }

      return opts;
    });

    const manageCopying = () => {
      ctx.emit('show:managecopying');
      ctx.emit('close');
    };

    const manualCopy = () => {
      ctx.emit('show:manualcopy', releasesWithAnnotations);
      ctx.emit('close');
    };

    const viewToc = () => {
      ctx.emit('show:tableofcontents');
      ctx.emit('close');
    };

    const viewCover = () => {
      ctx.emit('show:cover');
      ctx.emit('close');
    };

    const toggleSubscription = () => {
      ctx.emit('toggle:subscription');
      ctx.emit('close');
    };

    const menu = ref<InstanceType<typeof ContextMenu> | null>(null);
    const onTitleActionStart = () => {
      if (menu.value) {
        menu.value.$el.style.display = 'none';
      }
    };
    const onTitleActionFinish = () => {
      if (menu.value) {
        menu.value.$el.style.display = null;
      }
      ctx.emit('close');
    };

    return {
      contextMenuOptions,
      menu,
      onTitleActionFinish,
      onTitleActionStart
    };
  }
});
