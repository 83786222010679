
import { defineComponent, PropType } from 'vue';
import ManualCopyContent from 'app/components/ManualCopyContent.vue';
import Sidebar from 'app/components/Sidebar.vue';
import { TitleRecord } from 'app/models/title';
import { FilterObject } from 'app/models/filter-object';

export default defineComponent({
  name: 'ManualCopySidebar',
  components: {
    ManualCopyContent,
    Sidebar
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
     title: {
      type: Object as PropType<TitleRecord>,
      default: undefined
    },
    releasesWithAnnotations: {
      type: Array as PropType<FilterObject[]>,
      default: () => []
    }
  },
  emits: [
    'close',
    'submit'
  ]
});
