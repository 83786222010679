
// DEVELOPER NOTE: When testing this functionality, you can use the following command
// in the console to kick off an update: APP.events.dispatch('app:update:simulate')
import { defineComponent, ref } from 'vue';
import { APP } from '../../app/base/app';

/**
 * Notification that a new version of the application is availble.
 */
export default defineComponent({
  name: 'UpdateStrip',
  emits: [
    'cancel',
    'show'
  ],
  setup: (props, ctx) => {
    const label = ref<HTMLLabelElement | null>(null);
    const progressBar = ref<HTMLProgressElement | null>(null);
    const isUpdateReady = ref<boolean>(false);
    const state = ref<'updated' | 'available' | 'updating' | 'error' | 'reloading'>('updated');

    const RELAUNCH_DELAY_MS = 3000;
    let timer = -1;

    const cancelRelaunch = () => {
      console.log('[UPDATE-STRIP] Update reload cancelled');
      clearTimeout(timer);
      state.value = 'updated';
      ctx.emit('cancel');
    };

    /**
     * Set the progress components value
     * @note Because FF does not support "-webkit-progress-value" pseudo-element, we
     * need to simulate the animation.
     * @ref https://developer.mozilla.org/en-US/docs/Web/CSS/::-webkit-progress-value
     * @ref https://stackoverflow.com/questions/44588284/animating-progress-element-value
     */
    const setProgress = (val: number) => {
      if (!progressBar.value) {
        return;
      }

      const currentVal = progressBar.value.value || 0;
      const step = val * 16 / 500;

      // we're not animating backwards (css will cover non-FF browsers)
      if (val <= currentVal) {
        progressBar.value.value = val;

        return;
      }

      const animate = (current: number) => {
        if (!progressBar.value) { return; }

        const newVal = current + step;
        progressBar.value.value = newVal;

        newVal < val && requestAnimationFrame(() => {
          animate(newVal);
        });
      };

      animate(currentVal);
    };


    APP.events.on('app:update:available', () => {
      console.log('[UPDATE-STRIP] Update available');
      state.value = 'available';
      ctx.emit('show');
      setProgress(0.5);
    });

    APP.events.on('app:update:ready', () => {
      console.log('[UPDATE-STRIP] Update ready');

      // If the user cancelled, no need to continue
      if (state.value === 'updated') {
        return;
      }

      isUpdateReady.value = true;
      setProgress(1);

      console.log('[UPDATE-STRIP] Scheduling reload');
      timer = window.setTimeout(() => {
        console.log('[UPDATE-STRIP] Reloading updated application');
        state.value = 'reloading';
        setProgress(1);
        APP.reload();
      }, RELAUNCH_DELAY_MS);
    });

    APP.events.on('app:update:failed', () => {
      // Nothing to worry about if not 'actively' updating
      if (state.value === 'updated') {
        return;
      }

      console.error('[UPDATE-STRIP] Update failed');
      state.value = 'error';

      setProgress(0);
    });

    return {
      isUpdateReady,
      label,
      progressBar,
      state,
      cancelRelaunch
    };
  }
});

