import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, TransitionGroup as _TransitionGroup, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["aria-hidden"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AlertCard = _resolveComponent("AlertCard")!
  const _component_AlertListEmptyState = _resolveComponent("AlertListEmptyState")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.content)
  }, [
    (_ctx.alertsSorted.length > 0)
      ? (_openBlock(), _createBlock(_TransitionGroup, {
          key: 0,
          tag: "ol",
          role: "list",
          "aria-label": _ctx.$t('alert.header'),
          name: "list",
          enterFromClass: _ctx.$style.listEnter,
          enterActiveClass: _ctx.$style.listEnterActive,
          leaveActiveClass: _ctx.$style.listLeaveActive,
          leaveToClass: _ctx.$style.listLeaveTo,
          class: _normalizeClass(_ctx.$style.list)
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.alertsSorted, (alert, index) => {
              return (_openBlock(), _createElementBlock("li", {
                key: alert.id,
                class: _normalizeClass(_ctx.$style.listItem)
              }, [
                _createVNode(_component_AlertCard, {
                  alertWithMetadata: alert,
                  onViewDetails: (alert) => _ctx.$emit('view-details', alert),
                  onClear: ($event: any) => (_ctx.clearAlert(alert.id, index))
                }, null, 8, ["alertWithMetadata", "onViewDetails", "onClear"])
              ], 2))
            }), 128))
          ], undefined, true),
          _: 1
        }, 8, ["aria-label", "enterFromClass", "enterActiveClass", "leaveActiveClass", "leaveToClass", "class"]))
      : (_openBlock(), _createBlock(_component_AlertListEmptyState, { key: 1 })),
    _createElementVNode("div", {
      "aria-live": "polite",
      "aria-hidden": !_ctx.announceDeleteMessage,
      class: "visually-hidden"
    }, _toDisplayString(_ctx.$t('alert.cleared')), 9, _hoisted_1)
  ], 2))
}