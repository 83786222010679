
import { Constants } from 'app/base/constants';
import { CoverItem } from 'app/base/interfaces';
import { computed, defineComponent, onBeforeUnmount, onMounted, ref } from 'vue';

export default defineComponent({
  name: 'Cover',
  props: {
    item: {
      type: Object as () => CoverItem,
      required: true
    },
    lazy: {
      type: Boolean,
      default: true
    },
    /**
     * Determines whether to enclose the cover in a border or not
     * @default false
     */
    bordered: {
      type: Boolean,
      default: false
    }
  },
  setup: (props) => {
    const img = ref<HTMLImageElement | null>(null);
    const fallback = Constants.assetPath('images/cover-404-book.png');
    const coverURL = computed(() => {
      return props.item.coverURL() || fallback;
    });
    const src = ref(props.lazy ? '' : coverURL.value);
    const coverColor = computed(() => {
      if (!props.item.coverColor) {
        return '';
      }

      return typeof props.item.coverColor === 'string'
        ? props.item.coverColor
        : `rgb(${props.item.coverColor})`;
    });

    const error = () => {
      if (src.value) {
        src.value = fallback;
      }
    };

    if (props.lazy) {
      if ('loading' in HTMLImageElement.prototype) {
        src.value = coverURL.value;
      } else if ('IntersectionObserver' in window) {
        const observer = new IntersectionObserver((entries, o) => {
          entries.forEach((e) => {
            if (e.isIntersecting) {
              src.value = coverURL.value;
              o.disconnect();
            }
          });
        });

        onMounted(() => {
          if (img.value) {
            observer.observe(img.value);
          }
        });

        onBeforeUnmount(() => {
          observer.disconnect();
        });
      } else {
        src.value = coverURL.value;
      }
    }

    return {
      coverColor,
      error,
      img,
      src
    };
  }
});
