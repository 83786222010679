
import { APP } from 'app/base/app';
import EmptyState from 'app/components/EmptyState.vue';
import LoadingPage from 'app/components/LoadingPage.vue';
import Page from 'app/components/Page.vue';
import SortSelect from 'app/components/SortSelect.vue';
import SubjectsList from 'app/components/SubjectsList.vue';
import Surface from 'app/components/Surface.vue';
import TabView from 'app/components/TabView.vue';
import TextFilter from 'app/components/TextFilter.vue';
import { useI18n } from 'app/functions/use-i18n';
import { fromTitleList, SubjectCategory, SubjectCollection } from 'app/models/subject';
import { computed, defineComponent, onMounted, ref } from 'vue';


type LoadingState = {
  state: 'loading';
} | {
  state: 'ready';
  data:  SubjectCollection[];
} | {
  state: 'failed';
};

type SubjectsListOptions = {
  id: SubjectCategory;
  label: string;
  items: SubjectCollection[];
};

type Tab = {
  id: 'all' | SubjectCategory;
  label: string;
  lists: SubjectsListOptions[];
  count: number;
};

export default defineComponent({
  name: 'Subjects',
  components: {
    EmptyState,
    SortSelect,
    Surface,
    SubjectsList,
    TabView,
    TextFilter,
    Page,
    LoadingPage
},
  props: {

  },
  setup: (props, ctx) => {
    const textFilter = ref('');

    const { t } = useI18n();
    const sortOptions = [
      {
        label: t('subjects.sort.alphabeticalAsc'),
        sort: (a: SubjectCollection, b: SubjectCollection) => {
          if (!a.name) { return -1; }
          if (!b.name) { return 1; }

          return a.name.localeCompare(b.name);
        }
      },
      {
        label: t('subjects.sort.alphabeticalDesc'),
        sort: (a: SubjectCollection, b: SubjectCollection) => {
          if (!a.name) { return -1; }
          if (!b.name) { return 1; }

          return b.name.localeCompare(a.name);
        }
      }
    ];
    const selectedSort = ref(sortOptions[0]);

    const loadingState = ref<LoadingState>({
      state: 'loading'
    });

    const subjects = computed<SubjectCollection[]>(() => {
      const raw = loadingState.value.state === 'ready' ? loadingState.value.data as SubjectCollection[] : [];
      const filtered = raw.filter((sub) => textFilter.value.length > 0
        ? sub.name.toLowerCase().includes(textFilter.value.toLowerCase())
        : true
      );
      const sorted = filtered.sort(selectedSort.value.sort);

      return sorted;
    });

    const subjectsByCategory = computed(() => {
      return subjects.value.reduce(
        (categorized, subject) => {
          categorized[subject.category].push(subject);

          return categorized;
        },
        {
          Jurisdiction: [],
          PracticeArea: [],
          Classification: [],
          Subject: []
        } as { [category in SubjectCategory]: SubjectCollection[] }
      );
    });

    const subjectsListOptions = computed(() => {
      const entries = Object.entries(subjectsByCategory.value) as [SubjectCategory, SubjectCollection[]][];

      return entries.reduce(
        (lists, [category, items]) => {
          lists[category] = {
            id: category,
            label: `general.subjectCategories.${category}`,
            items
          };

          return lists;
        },
        {} as { [category in SubjectCategory]: SubjectsListOptions }
      );
    });

    const tabs = computed<Tab[]>(() => {
      const { Jurisdiction, PracticeArea, Classification, Subject: subject } = subjectsListOptions.value;
      const all = [ Jurisdiction, PracticeArea, Classification, subject ];

      const options: Tab[] = [
        {
          id: 'all',
          label: t('subjects.categories.all'),
          lists: all.filter((listOptions) => listOptions.items.length > 0),
          count: all.reduce((sum, listOptions) => sum + listOptions.items.length, 0)
        }
      ];

      all.forEach((list) => {
        options.push({
          id: list.id,
          label: t(list.label),
          lists: [list],
          count: list.items.length
        });
      });

      return options;
    });

    onMounted(async () => {
      loadingState.value = {
        state: 'loading'
      };

      try {
        await APP.library.freshen();
        await APP.library.catalog.freshen();

        const titleLists = APP.library.catalog.getSubjects(true);
        const mappedSubjects = titleLists.map(fromTitleList);

        loadingState.value = {
          state: 'ready',
          data: mappedSubjects
        };
      } catch {
        loadingState.value = {
          state: 'failed'
        };
      }
    });

    return {
      loadingState,
      selectedSort,
      sortOptions,
      tabs,
      textFilter
    };
  }
});
