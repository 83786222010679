import { APP } from 'app/base/app';
import { PostishCopyJobResponse, PostishCopyStatus } from 'app/base/postish';
import { useAppEvents } from 'app/functions/use-app-events';
import { DeepReadonly, Ref, readonly, ref, watch } from 'vue';
import { RouteLocation, useRouter } from 'vue-router';

export function useCopyJobs(titleId?: string) {
  const copyJobs = ref<PostishCopyJobResponse[] | null>(null);
  const loading = ref<boolean>(true);
  const error = ref<boolean>(false);

  const timer = ref<ReturnType<typeof setTimeout> | null>(null);

  const update = async () => {
    try {
      loading.value = true;

      const response = await APP.services.postish.getAllCopyJobs();

      copyJobs.value = sortJobs(filterJobs(response));

      error.value = false;
    } catch {
      error.value = true;
    } finally {
      loading.value = false;
    }
  };

  update();

  const poll = () => {
    return setTimeout(async () => {
      await update();

      timer.value = poll();
    }, 2000);
  };

  const stopPolling = () => {
    if (timer.value) {
      clearTimeout(timer.value);
      timer.value = null;
    }
  };

  const startPolling = () => {
    timer.value = poll();

    const router = useRouter();
    watch(router.currentRoute, (from: RouteLocation, to: RouteLocation) => {
      // stop the recursion when you leave the page, but not when the query params change
      if (from.path !== to.path) {
        stopPolling();
      }
    });
  };

  if (!APP.patron.accountId || !APP.sentry.identityToken) {
    useAppEvents({
      'patron:accountId:acquired': () => update()
    });
  }

  const filterJobs = (jobs: PostishCopyJobResponse[] | null) => {
    if (!jobs) { return null; }

    if (titleId) {
      return jobs.filter((job) => job.target_title.title_id === titleId);
    }

    return jobs;
  };

  const sortJobs = (jobs: PostishCopyJobResponse[] | null) => {
    if (!jobs) { return null; }

    const statusPriority: { [key in PostishCopyStatus | 'FINISHED_REVIEWED']: number } = {
      ERROR: 0,
      QUEUED: 1,
      IN_PROGRESS: 1,
      FINISHED: 2,
      FINISHED_REVIEWED: 3
    };

    return jobs.slice().sort((a, b) => {
      const aStatus = a.status === 'FINISHED' && a.reviewed ? 'FINISHED_REVIEWED' : a.status;
      const bStatus = b.status === 'FINISHED' && b.reviewed ? 'FINISHED_REVIEWED' : b.status;

      if (statusPriority[aStatus] < statusPriority[bStatus]) { return -1; }
      if (statusPriority[aStatus] > statusPriority[bStatus]) { return 1; }

      return Date.parse(b.created_at) - Date.parse(a.created_at);
    });
  };

  return {
    copyJobs: readonly(copyJobs) as DeepReadonly<Ref<PostishCopyJobResponse[]>>,
    loading: readonly(loading),
    error: readonly(error),
    update,
    startPolling,
    stopPolling
  };

}
