import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilterButton = _resolveComponent("FilterButton")!
  const _component_SortSelect = _resolveComponent("SortSelect")!
  const _component_NotesTitleCard = _resolveComponent("NotesTitleCard")!
  const _component_EmptyState = _resolveComponent("EmptyState")!
  const _component_Page = _resolveComponent("Page")!
  const _component_Surface = _resolveComponent("Surface")!

  return (_openBlock(), _createBlock(_component_Surface, null, {
    default: _withCtx(() => [
      _createVNode(_component_Page, {
        header: _ctx.$t('annotations.header'),
        useBackToTop: true
      }, {
        toolbar: _withCtx(() => [
          _createVNode(_component_FilterButton, {
            options: _ctx.availableSubjects,
            filterType: "subject",
            iconOnly: _ctx.mobile,
            onInput: _ctx.updatePath
          }, null, 8, ["options", "iconOnly", "onInput"]),
          _createVNode(_component_SortSelect, {
            modelValue: _ctx.selectedSort,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedSort) = $event)),
            options: _ctx.sortOptions,
            optionKey: "id",
            optionLabel: "label",
            class: _normalizeClass(_ctx.$style.sort)
          }, null, 8, ["modelValue", "options", "class"])
        ]),
        default: _withCtx(() => [
          (_ctx.sortedTitles.length)
            ? (_openBlock(), _createElementBlock("ul", {
                key: 0,
                class: _normalizeClass(_ctx.$style.cardList)
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortedTitles, (title) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: title.slug
                  }, [
                    _createVNode(_component_NotesTitleCard, {
                      title: title,
                      copyJobsState: _ctx.copyJobsState
                    }, null, 8, ["title", "copyJobsState"])
                  ]))
                }), 128))
              ], 2))
            : (_openBlock(), _createBlock(_component_EmptyState, {
                key: 1,
                class: _normalizeClass(_ctx.$style.emptyState),
                link: _ctx.helpLink,
                header: _ctx.$t('annotations.emptystate.header'),
                content: _ctx.$t('annotations.emptystate.content'),
                linkLabel: _ctx.$t('annotations.emptystate.link')
              }, null, 8, ["class", "link", "header", "content", "linkLabel"]))
        ]),
        _: 1
      }, 8, ["header"])
    ], undefined, true),
    _: 1
  }))
}