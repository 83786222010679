import { APP } from 'app/base/app';
import { C } from 'app/base/common';
import { Constants } from 'app/base/constants';
import { Downloader } from 'app/base/updates/downloader';
import { SentryLoan, SentryReadingData } from '../base/sentry';
import { BankedPossession, Possession } from './possession';

export class Loan extends Possession {
  public downloader: Downloader;
  public fulfillmentFormatTypes: any[];
  public returnableTime: number;
  public renewableTime: number;
  public lastAccessTime: number;
  public lockedFormatType: any;
  public isAssigned: boolean;
  public fulfillmentFormatTypeLockId: string;
  public readingProgress: number;

  public static SORT_FUNCTIONS = {
    ...Possession.SORT_FUNCTIONS,
    downloaded: (a: Loan, b: Loan) => {
      if (!a || !a.downloader || !a.downloader.downloadedTime) {
        return -1;
      }

      if (!b || !b.downloader || !b.downloader.downloadedTime) {
        return 1;
      }

      return b.downloader.downloadedTime - a.downloader.downloadedTime;
    }
  };


  constructor() {
    super();
    this.type = 'loan';
    this.downloader = new Downloader(this);
    this.fulfillmentFormatTypes = [];
  }


  public coverURL(): string | undefined {
    if (APP.shell.has('rosters')) {
      this.coverURL = () => {
        const title = APP.titleCache.get(this.titleSlug);
        const url = title.rawCoverURL();

        return url ? '/covers' + url.replace(/^https?:\/\/[^\/]+/, '') : undefined;
      };
    } else {
      this.coverURL = () => {
        const title = APP.titleCache.get(this.titleSlug);

        return title.rawCoverURL();
      };
    }

    return this.coverURL();
  }


  public isReturnable() {
    const now = C.epochMilliseconds();

    return this.returnableTime > 0 && this.returnableTime < now
      && !this.isAssigned;
  }


  public isRenewable() {
    const now = C.epochMilliseconds();

    return this.renewableTime > 0 && this.renewableTime < now
      && this.titleRecord.circ.copiesOwned > 0 && !this.titleRecord.circ.holds
      && !this.isAssigned;
  }


  public isDownloaded(): boolean {
    if (this.downloader && this.downloader.downloadPercent >= 1) {
      return true;
    }

    return false;
  }


  /**
   * True if this loan is expired, otherwise false
   */
  public isExpired() {
    return this.expireTime <  C.epochMilliseconds();
  }


  /**
   * Returns this loan to the library
   */
  public async returnLoan(): Promise<void> {
    await APP.sentry.returnLoan(this.titleSlug);
    APP.patron.loans.removeItem(this);
    APP.events.dispatch('loan:return', { item: this });
  }


  /**
   * Renews this loan
   */
  public async renewLoan(): Promise<void> {
    await APP.patron.renewLoan(this.titleSlug);
  }


  // The thunderId argument is optional. If supplied, will check whether this
  // loan can be fulfilled for the given format type identified by this
  // thunderId. If not supplied, will check whether this loan can be
  // fulfilled to any external format type. NB: isFulfillable will be true
  // even when the loan is already fulfilled.
  //
  public isFulfillable(thunderId?: string): boolean {
    if (typeof thunderId === 'undefined') {
      return this.fulfillmentFormatTypes.length > 0;
    }
    for (let i = 0, ii = this.fulfillmentFormatTypes.length; i < ii; ++i) {
      if (this.fulfillmentFormatTypes[i].thunderId === thunderId) {
        return true;
      }
    }

    return false;
  }


  // Again, the thunderId argument is optional.
  //
  public isFulfilled(thunderId?) {
    if (typeof thunderId === 'undefined') {
      return this.fulfillmentFormatTypeLockId ? true : false;
    }

    return this.fulfillmentFormatTypeLockId === thunderId;
  }


  public removed() {
    if (this.downloader) {
      this.downloader.wipe();
    }
  }


  public async updateLoanFromSentry() {
    if (APP.network.reachable) {
      const loanReadingData = await APP.sentry.fetchReadingData(APP.patron.currentCard(), this.titleRecord.mediaType, this.titleRecord.slug);
      this._updateReadingData(loanReadingData);
    }
  }


  private _updateReadingData(readingData: SentryReadingData | null) {
    if (readingData) {
      this.lastAccessTime = Math.max((readingData.timestamps?.accessed * 1000 || 0), this.lastAccessTime);
      this.readingProgress = readingData.position?.percentageOfBook ? readingData.position.percentageOfBook : this.readingProgress;
    }
  }


  protected _transformRemoteAttributes(attrs: SentryLoan) {
    const out = super._transformRemoteAttributes(attrs);
    out.lastAccessTime = null; // TODO: If the API ever stores/sends this, we should grab that
    out.createTime = this._utcStringToTime(C.excise(attrs, 'checkoutDate'));
    out.expireTime = this._utcStringToTime(C.excise(attrs, 'expireDate'));
    out.renewableTime = this._utcStringToTime(C.excise(attrs, 'renewableOn'));
    out.returnableTime = attrs.isReturnable ? C.epochMilliseconds() : null;
    out.isAssigned = attrs.isAssigned;
    out.fulfillmentFormatTypes = [];
    C.each(attrs.otherFormats, (fmtAttrs) => {
      const fft = Constants.idToFulfillmentFormatType(fmtAttrs.id);
      if (fft) {
        out.fulfillmentFormatTypes.push(fft);
      }
    });
    if (attrs.isFormatLockedIn) {
      if (attrs.otherFormats && attrs.otherFormats.length) {
        out.fulfillmentFormatTypeLockId = attrs.otherFormats[0].id;
      }
    }

    return out;
  }


  protected _deserializeAttributes(attrs) {
    this.lastAccessTime = C.excise(attrs, 'lastAccessTime');
    this.renewableTime = C.excise(attrs, 'renewableTime');
    this.returnableTime = C.excise(attrs, 'returnableTime');

    this.fulfillmentFormatTypes = C.excise(attrs, 'fulfillmentFormatTypes') || [];
    this.fulfillmentFormatTypeLockId = C.excise(attrs, 'fulfillmentFormatTypeLockId');
    if (attrs.downloader) {
      this.downloader.deserialize(C.excise(attrs, 'downloader'));
    }

    super._deserializeAttributes(attrs);

    return;
  }


  protected serializeInternal(): BankedLoan {
    const out = <BankedLoan>super.serializeInternal();
    out.downloader = this.downloader.serialize();
    out.lastAccessTime = this.lastAccessTime;
    out.renewableTime = this.renewableTime;
    out.returnableTime = this.returnableTime;
    out.isAssigned = this.isAssigned;
    out.fulfillmentFormatTypes = this.fulfillmentFormatTypes;
    out.fulfillmentFormatTypeLockId = this.fulfillmentFormatTypeLockId;

    return out;
  }
}

interface BankedLoan extends BankedPossession {
  downloader: any;
  lastAccessTime: number;
  renewableTime: number;
  returnableTime: number;
  fulfillmentFormatTypes: any[];
  fulfillmentFormatTypeLockId: string;
}
