
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    query: {
      type: String,
      required: false,
      default: ''
    }
  },
  emits: [
    'click'
  ]
});
