
import { defineComponent } from 'vue';
import HomeAnnotationList from 'app/components/HomeAnnotationList.vue';
import HomeTagList from 'app/components/HomeTagList.vue';
import RecentlyRead from 'app/components/RecentlyRead.vue';
import Surface from 'app/components/Surface.vue';

export default defineComponent({
  name: 'Home',
  components: {
    HomeAnnotationList,
    HomeTagList,
    RecentlyRead,
    Surface
  }
});
