
import { defineComponent, ref } from 'vue';
import { generateUUID } from 'lib/common/uuid';
import FormInput from 'app/components/FormInput.vue';
import FormSubmitButton from 'app/components/FormSubmitButton.vue';
import { resolve } from 'app/base/library-resolver';
import { Constants } from 'app/base/constants';

export type LibraryCodeEntry = {
  ghost?: string;
  code: string;
};

export default defineComponent({
  components: {
    FormInput,
    FormSubmitButton
  },
  emits: [
    'valid'
  ],
  setup: (props, ctx) => {
    const input = ref<HTMLElement | null>(null);

    const promptId = generateUUID();

    const libraryCode = ref('');
    const codeError = ref<string | null>(null);

    const inputFocus = ref(false);
    const logoPath = Constants.assetPath('images/app-logo-ln.svg');

    const onSubmit = async (event: Event) => {
      codeError.value = null;

      /* Valid code examples:
      * od:rll
      * ln:lexisdl
      * mockingbird
      * legal-aid
      */
      const codeRegex = /^(?:(?<ghost>od|ln):)?(?<code>\w+(-\w+)*)$/;
      const matches = libraryCode.value.toLowerCase().match(codeRegex);
      const { ghost, code } = matches?.groups || {};

      const resolveResult = await resolve(code);

      if (resolveResult.state === 'error') {
        codeError.value = `libraryCode.error.${resolveResult.error}`;
      } else {
        ctx.emit('valid', { ghost, code} as LibraryCodeEntry);
      }

      if (codeError.value) {
        input.value?.focus();
      }
    };

    return {
      codeError,
      inputFocus,
      libraryCode,
      logoPath,
      onSubmit,
      promptId
    };
  }
});
