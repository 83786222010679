
import { ClipboardHelper } from 'app/base/clipboard';
import { computed, defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'ShareLink',
  props: {
    headerLabel: {
      type: String,
      required: true
    },
    link: {
      type: String,
      default: undefined
    },
    centerOnSmallerScreens: {
      type: Boolean,
      default: false
    }
  },
  setup: (props) => {
    const linkToCopy = computed(() => props.link || getLinkFromLocation());
    const getLinkFromLocation = () => location.href.replace('nautilus-', '');

    const copied = ref(false);
    const copy = async () => {
      // We can't use the computed `linkToCopy` here,
      // as the URL could get modified in other ways,
      // and it seems we can't just watch `window.location`.
      const success = await ClipboardHelper.copyToClipboard(props.link || getLinkFromLocation());
      if (success) {
        copied.value = true;
        window.setTimeout(() => copied.value = false, 3000);
      }
    };

    return {
      copied,
      copy,
      linkToCopy
    };
  }
});
