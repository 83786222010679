
import { ClipboardHelper } from 'app/base/clipboard';
import { ArgType, commands } from 'app/base/debugger';
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'DebugConsole',
  setup: () => {
    const command = ref('');
    const output = ref('');

    const submit = async () => {
      const [commandName, ...argInputs] = command.value.split(' ');
      const commandConfig = commands[commandName];
      const args = argInputs.reduce((config, argInput) => {
        const [name, val] = argInput.split('=');

        const matchingArg = commandConfig.args.find((a) => name === a.name);
        if (!matchingArg) { return config; }

        switch(matchingArg.type) {
          case 'string':
            config[name] = val;
            break;
          case 'string[]':
            config[name] = val.split(',');
            break;
          case 'number':
            config[name] = parseInt(val, 10);
            break;
          case 'number[]':
            config[name] = val.split(',').map((v) => parseInt(v, 10));
            break;
          case 'boolean':
            config[name] = val !== 'false';
            break;
          default:
            throw new Error(`Bad command argument for ${name}`);
        }

        return config;
      }, {} as { [key: string]: ArgType });

      output.value = await commandConfig.func(args);
    };

    const copied = ref(false);
    const copy = async () => {
      if (output.value) {
        ClipboardHelper.copyToClipboard(output.value);
        copied.value = true;
        setTimeout(() => copied.value = false, 1500);
      }
    };

    return {
      command,
      copied,
      copy,
      output,
      submit
    };
  }
});
