
import { IdNamePair } from 'app/base/thunder';
import { subjectRoute } from 'app/models/subject';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AccordionSubjectList',
  props: {
    subjects: {
      type: Array as () => IdNamePair[],
      required: true
    }
  },
  setup: () => {
    return {
      subjectLink: subjectRoute
    };
  }
});
