
import { FilterOptionSort, FilterOptionType } from 'app/components/FilterButton.vue';
import FormCheckbox from 'app/components/FormCheckbox.vue';
import TextFilter from 'app/components/TextFilter.vue';
import { FilterCategory, FilterObject } from 'app/models/filter-object';
import { PropType, computed, defineComponent, ref, watch } from 'vue';

export default defineComponent({
  name: 'FilterList',
  components: {
    TextFilter,
    FormCheckbox
  },
  props: {
    category: {
      type: String as PropType<FilterCategory>,
      required: true
    },
    optionType: {
      type: String as PropType<FilterOptionType>,
      default: 'text'
    },
    optionSort: {
      type: String as PropType<FilterOptionSort>,
      default: 'alphaAsc'
    },
    options: {
      type: Array as PropType<FilterObject[]>,
      required: true
    },
    applied: {
      type: Array as PropType<string[]>,
      required: true
    }
  },
  emits: [
    'checked',
    'length'
  ],
  setup: (props, ctx) => {
    const textFilter = ref('');

    const filteredOptions = computed(() => {
      return props.options.filter((sub) => textFilter.value.length > 0
        ? sub.name.toLowerCase().includes(textFilter.value.toLowerCase())
        : true
      ).sort((a, b) => props.optionSort === 'alphaDesc' ? b.name.localeCompare(a.name) : a.name.localeCompare(b.name));
    });

    const setFocus = (position: number) => {
      const optionList = document.querySelector(`[data-filter-category='${props.category}']`)?.querySelectorAll('input[type="checkbox"]');

      if (optionList) {
        (optionList[position] as HTMLElement).focus();
      }
    };

    const selected = (optionId: string) => {
      return props.applied.includes(optionId);
    };

    watch(() => filteredOptions.value.length, () => {
      ctx.emit('length', filteredOptions.value.length);
    });

    return {
      textFilter,
      filteredOptions,
      setFocus,
      selected
    };
  }
});
