
import FormError from 'app/components/FormError.vue';
import Popout from 'app/components/Popout.vue';
import { useAppEvents } from 'app/functions/use-app-events';
import { useI18n } from 'app/functions/use-i18n';
import { Tag } from 'app/models/tag';
import { Tags } from 'app/models/tags';
import { RouteName } from 'app/router/constants';
import { computed, defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'TagActions',
  components: {
    FormError,
    Popout
  },
  props: {
    tag: {
      type: Tag,
      required: true
    }
  },
  setup: (props) => {
    const { dispatch } = useAppEvents();
    const { t } = useI18n();
    const myTagsLink = { name: RouteName.Tags };
    const showTagActions = ref<boolean>(false);
    const state = ref<'menu' | 'renaming' | 'deleting'>('menu');
    const tagActions = ref<HTMLElement | null>(null);
    const tagInput = ref<HTMLInputElement | null>(null);
    const tagRenameVal = ref(props.tag.name);
    const isInvalidRename = ref(false);
    const idBase = `tag-${props.tag.slug}`;
    const ids = {
      menu: `${idBase}-actions`,
      rename: `${idBase}-rename`,
      textInput: `${idBase}-input`,
      delete: `${idBase}-delete`
    };

    const labelId = computed(() => state.value === 'menu'
      ? ids.menu
      : state.value === 'renaming'
      ? ids.rename
      : ids.delete);

    // Quirk for suggestion keyboards where v-model only updates at the end of the word
    // Using :value/@input pair to work around that
    const updateRenameValue = (renameEvent: Event) => {
      if (renameEvent.target) {
        const target = renameEvent.target as HTMLInputElement;
        tagRenameVal.value = target.value;
      } else {
        tagRenameVal.value = '';
      }

      const trimmedVal = tagRenameVal.value.trim();
      if (!Tags.SAME_NAME(trimmedVal, props.tag.name) && !Tag.isValid(trimmedVal)) {
        isInvalidRename.value = true;
      } else {
        isInvalidRename.value = false;
      }
    };

    const router = useRouter();
    const onRenameSubmit = () => {

      if (isInvalidRename.value) {
        return;
      }

      const trimmedVal = tagRenameVal.value.trim();

      if (props.tag.name === trimmedVal) {
        onClose();

        return;
      }

      props.tag.rename(trimmedVal);
      router.replace({ name: RouteName.TagDetails, params: {
        tagSlug: props.tag.slug
      }});
      onClose();
    };

    const onDeleteClick = () => {
      props.tag.delete();
      dispatch('toast', { type: 'success', message: t('tag.actions.delete.success') });
      router.push({ name: RouteName.Tags });
    };

    const onClose = () => {
      showTagActions.value = false;
      tagRenameVal.value = props.tag.name;
      isInvalidRename.value = false;
      state.value = 'menu';
    };

    return {
      state,
      tagRenameVal,
      isInvalidRename,
      showTagActions,
      myTagsLink,
      labelId,
      ids,
      Tag,

      tagActions,
      tagInput,

      onDeleteClick,
      onClose,
      onRenameSubmit,
      updateRenameValue
    };
  }
});
