
import { Downloader } from 'app/base/updates/downloader';
import DownloadIndicatorDownloaded from 'app/components/DownloadIndicatorDownloaded.vue';
import DownloadIndicatorDownloading from 'app/components/DownloadIndicatorDownloading.vue';
import DownloadIndicatorErrored from 'app/components/DownloadIndicatorErrored.vue';
import DownloadIndicatorPaused from 'app/components/DownloadIndicatorPaused.vue';
import DownloadIndicatorQueued from 'app/components/DownloadIndicatorQueued.vue';
import DownloadIndicatorStreaming from 'app/components/DownloadIndicatorStreaming.vue';
import { useAppEvents } from 'app/functions/use-app-events';
import { useDownloadInfo } from 'app/functions/use-download-info';
import { computed, defineComponent } from 'vue';


type DownloadState = 'streaming' | 'downloading' | 'paused' | 'queued' | 'downloaded' | 'errored';

/**
 * An indicator (and label) that displays the download status of a loan
 * It also allows for some simple download actions (cancel, clear, etc.)
 * @displayName DownloadIndicator
 */
export default defineComponent({
  name: 'DownloadIndicator',
  components: {
    DownloadIndicatorDownloaded,
    DownloadIndicatorDownloading,
    DownloadIndicatorErrored,
    DownloadIndicatorPaused,
    DownloadIndicatorQueued,
    DownloadIndicatorStreaming
  },
  props: {
    /**
     * The downloader to display information for.
     */
    downloader: {
      type: Downloader,
      required: true
    },
    /**
     * When true, only the icon will be rendered. No status label.
     */
    iconOnly: {
      type: Boolean,
      default: false
    }
  },
  setup: (props) => {
    // NOTE: We limit functionality when the download is just starting up
    const MIN_DOWNLOAD_THRESHOLD = 0.005;

    const {
      downloadSize,
      isErrored,
      isMeteredConnection,
      isPaused,
      isSetToDownload,
      progress
    } = useDownloadInfo(props.downloader);

    const state = computed<DownloadState>(() => {
      if (isErrored.value) {
        return 'errored';
      }

      if (progress.value >= 1) {
        return 'downloaded';
      }

      if (isSetToDownload.value) {
        if (isPaused.value) {
          return 'paused';
        }

        if (progress.value <= MIN_DOWNLOAD_THRESHOLD) {
          return 'queued';
        }

        return 'downloading';
      }

      return 'streaming';
    });

    const startDownload = () => {
      if (isMeteredConnection.value) {
        // eslint-disable-next-line vue/no-mutating-props
        props.downloader.overrideCellular = true;
      }
      props.downloader.setAutoDownload(true);
    };

    const stopDownload = () => {
      props.downloader.setAutoDownload(false);
    };

    const { dispatch } = useAppEvents();
    const openSettings = () => {
      dispatch('open:download:settings');
    };

    return {
      downloadSize,
      isMeteredConnection,
      progress,
      state,

      startDownload,
      stopDownload,
      openSettings
    };
  }
});
