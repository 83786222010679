
import { defineComponent, ref } from 'vue';
import FormSubmitButton from './FormSubmitButton.vue';
import Icon from './Icon.vue';

export default defineComponent({
  name: 'PromptContent',
  components: {
    FormSubmitButton,
    Icon
},
  props: {
    title: {
      type: String,
      required: true
    },
    submittable: {
      type: Boolean,
      default: true
    },
    confirmLabel: {
      type: String,
      required: true
    },
    cancelLabel: {
      type: String,
      default: undefined
    },
    deleteLabel: {
      type: String,
      default: undefined
    }
  },
  emits: [
    'cancel',
    'delete',
    'submit'
  ],
  setup: (props, { emit }) => {
    const form = ref<HTMLFormElement | null>(null);

    const submit = () => {
      if (!form.value) { return; }

      if (!props.submittable) { return; }

      const data = new FormData(form.value);
      emit('submit', Object.fromEntries(data.entries()));
    };

    return {
      form,
      submit
    };
  }
});
