
import { APP } from 'app/base/app';
import env from 'app/base/env';
import AlertMenu from 'app/components/AlertMenu.vue';
import { color } from 'app/components/ChannelIndicator.vue';
import LibraryLogo from 'app/components/LibraryLogo.vue';
import MobileSearch from 'app/components/MobileSearch.vue';
import Overlay from 'app/components/Overlay.vue';
import SearchBar from 'app/components/SearchBar.vue';
import UpdateStrip from 'app/components/UpdateStrip.vue';
import UserMenu from 'app/components/UserMenu.vue';
import { useAppEvents } from 'app/functions/use-app-events';
import { Breakpoint, useWindowSize } from 'app/functions/use-window-size';
import { computed, defineComponent, nextTick, ref } from 'vue';

export default defineComponent({
  name: 'HeaderBar',
  components: {
    LibraryLogo,
    Overlay,
    SearchBar,
    MobileSearch,
    UpdateStrip,
    UserMenu,
    AlertMenu
},
  emits: [
    'show-nav'
  ],
  setup: () => {
    const { windowWidth } = useWindowSize();
    const isUpdating = ref(false);
    const mobile = computed(() => windowWidth.value <= Breakpoint.HideNav);
    const showUserMenu = ref(false);
    const showAlerts = ref(false);
    const query = ref('');
    const menu = ref<InstanceType<typeof UserMenu> | null>(null);

    const showChannel = APP.flags.get('debug');
    const channelColor = color(env.CHANNEL);

    useAppEvents({
      'open:download:settings': async () => {
        showUserMenu.value = true;
        await nextTick();
        menu.value?.changeMenu('download');
      }
    });

    return {
      channelColor,
      isUpdating,
      menu,
      mobile,
      query,
      showChannel,
      showUserMenu,
      showAlerts
    };
  }
});
