import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContextMenu = _resolveComponent("ContextMenu")!
  const _component_ActionToast = _resolveComponent("ActionToast")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.show && _ctx.reference)
      ? (_openBlock(), _createBlock(_component_ContextMenu, _mergeProps({ key: 0 }, _ctx.$attrs, {
          reference: _ctx.reference,
          options: _ctx.contextMenuOptions,
          headerLabel: _ctx.showCount ? _ctx.$t('annotations.selectedCount', { COUNT: _ctx.annotations.length }) : undefined,
          onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
        }), null, 16, ["reference", "options", "headerLabel"]))
      : _createCommentVNode("", true),
    (_ctx.showActionToast)
      ? (_openBlock(), _createBlock(_component_ActionToast, {
          key: 1,
          message: _ctx.actionMessage,
          type: "success",
          onClose: _ctx.closeActionToast
        }, {
          action: _withCtx(() => [
            _createElementVNode("button", {
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.removeFromQueue && _ctx.removeFromQueue(...args)))
            }, _toDisplayString(_ctx.$t('exportQueue.contextMenu.remove.afterExport', { N: _ctx.annotations.length })), 1)
          ]),
          _: 1
        }, 8, ["message", "onClose"]))
      : _createCommentVNode("", true)
  ], 64))
}