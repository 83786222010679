
import BreadcrumbNavigation from 'app/components/BreadcrumbNavigation.vue';
import { useBreadcrumbs } from 'app/functions/use-breadcrumbs';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    BreadcrumbNavigation
  },
  props: {
    header: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      default: undefined
    }
  },
  setup: (props, ctx) => {
    const breadcrumbs = useBreadcrumbs();

    return {
      breadcrumbs
    };
  }
});
