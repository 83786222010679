
import { computed, defineComponent, ref, watch, reactive, watchEffect } from 'vue';
import { RouteName } from 'app/router/constants';
import LibraryLogo from 'app/components/LibraryLogo.vue';
import SurveyCallout from 'app/components/SurveyCallout.vue';
import { useRoute } from 'vue-router';
import { usePatron } from 'app/functions/use-patron';
import { injectNtcContext } from 'app/contexts/ntc-context';

type NavLink = {
  route: RouteName;
  label: string;
  icon: string;
  badge?: string;
};

type NavTargets = {
  home: NavLink;
  browse: NavLink;
  mybooks: NavLink;
  notes: NavLink;
  export: NavLink;
  tags: NavLink;
  ntc?: NavLink;
};

export default defineComponent({
  name: 'NavigationSidebar',
  components: {
    LibraryLogo,
    SurveyCallout
  },
  props: {
    showSurvey: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    'close-survey'
  ],
  setup: (props, ctx) => {
    const { exportQueue } = usePatron();
    const exportQueueLength = computed(() => {
      return exportQueue.value.length > 99
        ? '99+'
        : exportQueue.value.length === 0
          ? ''
          : exportQueue.value.length.toString();
    });

    const targets: NavTargets = reactive({
      home: {
        route: RouteName.Home,
        label: 'nav.link.home',
        icon: 'nav-home'
      },
      browse: {
        route: RouteName.Browse,
        label: 'nav.link.browse',
        icon: 'nav-browse'
      },
      mybooks: {
        route: RouteName.MyBooks,
        label: 'nav.link.mybooks',
        icon: 'nav-mybooks'
      },
      notes: {
        route: RouteName.Notes,
        label: 'nav.link.notes',
        icon: 'nav-notes'
      },
      export: {
        route: RouteName.Export,
        label: 'nav.link.export',
        icon: 'nav-export',
        badge: exportQueueLength
      },
      tags: {
        route: RouteName.Tags,
        label: 'nav.link.tags',
        icon: 'nav-tags'
      }
    });

    const { ntcAvailable } = injectNtcContext();

    watchEffect(() => {
      if (ntcAvailable.value) {
        targets.ntc = {
          route: RouteName.Ntc,
          label: 'nav.link.ntc',
          icon: 'nav-ntc'
        };
      }
    });

    const route = useRoute();
    const active = ref(route.meta?.timeline);
    watch(route, (to) => active.value = to?.meta?.timeline);

    return {
      active,
      exportQueueLength,
      targets
    };
  }
});
