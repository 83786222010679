
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'FormSubmitButton',
  props: {
    label: {
      type: String,
      required: true
    },
    enabled: {
      type: Boolean,
      default: true
    },
    size: {
      type: String as () => 'large' | 'small',
      default: 'large'
    }
  }
});
