
import UserMenuAbout from 'app/components/UserMenuAbout.vue';
import UserMenuDownload from 'app/components/UserMenuDownload.vue';
import UserMenuMain from 'app/components/UserMenuMain.vue';
import { defineComponent, nextTick, onBeforeUnmount, onMounted, ref } from 'vue';

type Menu = 'main' | 'download' | 'about';

export default defineComponent({
  name: 'UserMenu',
  components: {
    UserMenuMain,
    UserMenuDownload,
    UserMenuAbout
  },
  emits: [
    'close'
  ],
  setup: (_, ctx) => {
    const currentMenu = ref<Menu>('main');
    const changeMenu = (menu: Menu) => currentMenu.value = menu;
    const contents = ref<HTMLElement | null>(null);
    const setHeight = async () => {
      if (!contents.value) { return; }

      await nextTick();
      const menuElement = contents.value.children[currentMenu.value === 'main' ? 0 : 1];
      contents.value.style.height = menuElement.scrollHeight + 'px';
    };
    onMounted(() => requestAnimationFrame(setHeight));
    onBeforeUnmount(() => {
      if (!contents.value) { return; }

      contents.value.style.height = '0';
    });

    return {
      changeMenu,
      contents,
      currentMenu,
      setHeight
    };
  }
});
