
import CachedStorage from 'app/base/storage/cached-storage';
import TypedStorage from 'app/base/storage/typed-storage';
import Chatterbox from 'app/components/Chatterbox.vue';
import { TempSeriesCacheSymbol, TempTitleCacheSymbol } from 'app/keys/injection-keys';
import { BankedSeries } from 'app/models/series';
import { BankedTitle } from 'app/models/title';
import { defineComponent, provide } from 'vue';

export default defineComponent({
  components: {
    Chatterbox
  },
  setup: () => {
    // Title objects have circular references, so we need to store them as BankedTitle objects
    provide(TempTitleCacheSymbol, new TypedStorage<BankedTitle>(
      new CachedStorage({
        maxItems: 50
      })
    ));

    provide(TempSeriesCacheSymbol, new TypedStorage<BankedSeries>(
      new CachedStorage({
        maxItems: 50
      })
    ));
  }
});
