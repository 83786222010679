
import { Constants } from 'app/base/constants';
import { computed, defineComponent } from 'vue';
import { useI18n } from 'app/functions/use-i18n';

export default defineComponent({
  name: 'NotFound',
  props: {
    headerText: {
      type: String,
      default: undefined
    },
    messageText: {
      type: String,
      default: undefined
    },
    linkText: {
      type: String,
      default: undefined
    },
    linkAnchorHref: {
      type: String,
      default: undefined
    }
  },
  setup: (props, ctx) => {
    const { t } = useI18n();
    const header = computed(() => props.headerText || t('error.route.header'));
    const message = computed(() => props.messageText || t('error.route.invalid'));
    const link = computed(() => props.linkText || t('general.needHelp'));
    const anchor = computed(() => props.linkAnchorHref || Constants.HELP_PATHS.GET_STARTED);

    return {
      header,
      message,
      link,
      anchor
    };
  }
});

