
import { seriesCoverSizes } from 'app/base/constants';
import AlertNewReleaseDetails from 'app/components/AlertNewReleaseDetails.vue';
import AlertNotAvailableDetails from 'app/components/AlertNotAvailableDetails.vue';
import Cover from 'app/components/Cover.vue';
import SeriesCover from 'app/components/SeriesCover.vue';
import { AlertWithMetadata } from 'app/functions/use-alerts-with-metadata';
import { Series } from 'app/models/series';
import { TitleRecord } from 'app/models/title';
import { PropType, defineComponent } from 'vue';


export default defineComponent({
  components: {
    AlertNewReleaseDetails,
    AlertNotAvailableDetails,
    Cover,
    SeriesCover
},
  props: {
    alert: {
      type: Object as PropType<AlertWithMetadata>,
      required: true
    }
  },
  emits: [
    'close'
  ],
  setup: () => {

    return {
      seriesCoverSizes
    };
  }
});
