import { APP } from 'app/base/app';
import { NtcProviderResponse } from 'app/base/ntc';
import { usePatron } from 'app/functions/use-patron';
import Quirks from 'lib/gala/src/quirks';
import { computed, ComputedRef, inject, InjectionKey, provide, readonly, Ref, ref, watch } from 'vue';

interface NtcContext {
  getNtcContent: () => Promise<void>;
  ntcAvailable: ComputedRef<boolean>;
  ntcState: ComputedRef<State>;
  isProviderUnavailable: (id: string) => boolean;
  shouldShowPrompt: Ref<boolean>;
  providerId: Ref<string | null>;
  ntcError: Ref<string>;
  show: (id: string) => void;
  hide: () => void;
  submit: () => void;
};

type State =
  {
    state: 'loading';
  }
  | {
    state: 'loaded';
    providers: NtcProviderResponse[];
  }
  | {
    state: 'notfound';
  };


export const NtcContextSymbol: InjectionKey<NtcContext> = Symbol('ntc');

const buildNtcContext = () => {
  const { card } = usePatron();
  const libraryKey = computed(() => card.value?.library.key());
  const isSessionUser = computed(() => card.value?.isSessionUser);

  const state = ref<State>({ state: 'loading' });

  const getNtcContent = async () => {
    try {
      if (isSessionUser.value === undefined || libraryKey.value === undefined) {
        // Keep the state as loading until we have a value for isSessionUser and libraryKey
        return;
      }

      if (isSessionUser.value) {
        // Guest/Session auths don't work with the NTC authentication right,
        // so we are disabling NTC for them
        state.value = {
          state: 'notfound'
        };

        return;
      }

      const providers = await APP.services.ntc.getNtcSubscriptions(libraryKey.value);

      if (!providers) {
        state.value = {
          state: 'notfound'
        };

        return;
      }

      state.value = {
        state: 'loaded',
        providers
      };
    } catch {
      state.value = {
        state: 'notfound'
      };
    }
  };

  watch(isSessionUser, getNtcContent, { immediate: true });

  const ntcAvailable = computed(() => {
    return state.value.state === 'loaded' && state.value.providers.length > 0;
  });

  const ntcState = computed<State>(() => {
    return state.value;
  });

  const isProviderUnavailable = (id: string) => {
    // The Depostition video with provider id of 'lexis-nexis'
    // is hosted by Kanopy and would require the Kanopy app to watch on mobile.
    // Rather than having our users deal with that workflow,
    // we will just prevent access to the video on mobile devices.

    return id === 'lexis-nexis' && Quirks.ask('mobile');
  };

  const shouldShowPrompt = ref(false);
  const providerId = ref(null);
  const ntcError = ref('');
  const ntcCode = ref(null);

  const show = async (id: string) => {
    providerId.value = id;

    //grab code from Sentry early and instead of after clicking ACCESS to avoid popup blockers
    ntcCode.value = await APP.sentry.fetchNtcCode(APP.patron.currentCard());

    if (ntcCode.value) {
      shouldShowPrompt.value = true;
    } else {
      ntcError.value = providerId.value;
    }
  };

  const hide = () => {
    shouldShowPrompt.value = false;
    ntcCode.value = null;
  };

  const submit = () => {
    const code = ntcCode.value.code;

    hide();

    //redirect to ntc-website
    //since we already have the ntc code from Sentry, this open-in-a-new-tab action is a direct user interaction
    //see: https://stackoverflow.com/a/2587692
    APP.services.ntc.goToNtcWebsite(libraryKey.value!, providerId.value, code);
  };

  const context = {
    getNtcContent,
    ntcAvailable,
    ntcState,
    isProviderUnavailable,
    shouldShowPrompt: readonly(shouldShowPrompt),
    providerId: readonly(providerId),
    ntcError,
    show,
    hide,
    submit
  };

  return context;
};

export const provideNtcContext = () => {
  const context = buildNtcContext();

  provide(NtcContextSymbol, context);

  return context;
};

export const injectNtcContext = () => {
  const context: NtcContext = inject(NtcContextSymbol);

  // If we try to inject without a provided context, print an error but create a non-provided context for use in-place.
  if (!context) {
    console.error('Ntc context not provided');

    return buildNtcContext();
  }

  return context;
};
