
import { SearchThisTitleQuery } from 'app/base/hudson';
import CopyLink from 'app/components/CopyLink.vue';
import Cover from 'app/components/Cover.vue';
import DetailsPageContent, { MetadataItem } from 'app/components/DetailsPageContent.vue';
import SearchThisTitle from 'app/components/SearchThisTitle.vue';
import TagWidget from 'app/components/TagWidget.vue';
import TitleActionButton from 'app/components/TitleActionButton.vue';
import TitleDetailsAccordion from 'app/components/TitleDetailsAccordion.vue';
import TitleDetailsAnnotationsLink from 'app/components/TitleDetailsAnnotationsLink.vue';
import TitleDetailsSeriesLink from 'app/components/TitleDetailsSeriesLink.vue';
import TitleCard from 'app/components/cards/TitleCard.vue';
import TitleDetailsContextMenuButton from 'app/components/contextMenus/TitleDetailsContextMenuButton.vue';
import { useI18n } from 'app/functions/use-i18n';
import { useTitleSubscription } from 'app/functions/use-subscription-interaction';
import type { Series } from 'app/models/series';
import type { TitleRecord } from 'app/models/title';
import { title } from 'app/views/core/cards/title-card.module.less';
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'TitleDetailsContent',
  components: {
    CopyLink,
    TitleActionButton,
    TitleCard,
    TitleDetailsAccordion,
    TitleDetailsAnnotationsLink,
    TitleDetailsContextMenuButton,
    TitleDetailsSeriesLink,
    DetailsPageContent,
    Cover,
    SearchThisTitle,
    TagWidget
  },
  props: {
    title: {
      type: Object as () => TitleRecord,
      required: true
    },
    series: {
      type: Object as () => Series | null,
      default: null
    },
    annotationCount: {
      type: Number,
      default: 0
    },
    onLoan: {
      type: Boolean,
      default: false
    },
    recommended: {
      type: Array as () => TitleRecord[],
      default: () => []
    },
    searchThisTitle: {
      type: Object as () => SearchThisTitleQuery | null,
      default: null
    }
  },
  setup: (props, ctx) => {
    const { t } = useI18n();

    const {
      subscribeTextShort,
      subscribeTextLong,
      toggleSubscription
    } = useTitleSubscription(props.title, 'title details page');

    const metadata = computed(() => {
      const meta = props.title.lexisMetadata;
      if (!meta) {
        return undefined;
      }

      const metadataList: MetadataItem[] = (['edition', 'release', 'releaseDate'] as const)
        .filter((m) => !!meta[m])
        .map((m) => {
          return {
            label: `title.${m}`,
            value: meta[m]!,
            show: true
          };
        });

      if (props.title.circ) {
        metadataList.push({
          label: 'title.copiesAvailable.label',
          value: t(
            'title.copiesAvailable.message',
            {
              available: props.title.circ.copiesAvailable,
              total: props.title.circ.copiesOwned
            }
          ) as string,
          show: !props.onLoan
            && !props.title.isSimultaneousUse
            && props.title.circ?.copiesOwned > 0
        });
      }

      return metadataList;
    });

    return {
      metadata,
      subscribeTextShort,
      subscribeTextLong,
      toggleSubscription
    };
  }
});
