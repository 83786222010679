import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SharePassage = _resolveComponent("SharePassage")!
  const _component_SearchThisTitleResults = _resolveComponent("SearchThisTitleResults")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.shareMatch)
      ? (_openBlock(), _createBlock(_component_SharePassage, {
          key: 0,
          reference: _ctx.shareMatch.ref,
          match: _ctx.shareMatch,
          onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.shareMatch = null))
        }, null, 8, ["reference", "match"]))
      : _createCommentVNode("", true),
    _createVNode(_component_SearchThisTitleResults, _mergeProps(_ctx.$attrs, {
      item: _ctx.item,
      request: _ctx.results.params,
      results: _ctx.results.state === 'loaded' ? _ctx.results.results : null,
      titles: _ctx.results.state === 'loaded' ? _ctx.results.titles : {},
      error: _ctx.results.state === 'error',
      onSearch: _ctx.onSearch,
      onPage: _ctx.onPage,
      onShare: _ctx.onShare,
      onClose: _ctx.clear
    }), null, 16, ["item", "request", "results", "titles", "error", "onSearch", "onPage", "onShare", "onClose"])
  ], 64))
}