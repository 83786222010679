import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.container)
  }, [
    _createElementVNode("h2", {
      class: _normalizeClass([_ctx.$style.header, _ctx.centerOnSmallerScreens ? _ctx.$style.centerOnSmallerScreens : ''])
    }, [
      _createVNode(_component_Icon, { name: "copy-link-to-clipboard" }),
      (!_ctx.copied)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.$t(_ctx.headerLabel)), 1))
        : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$t('copyLink.copied')), 1))
    ], 2),
    _createElementVNode("button", {
      class: _normalizeClass([_ctx.$style.link, _ctx.copied ? _ctx.$style.copied : '']),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.copy && _ctx.copy(...args)))
    }, _toDisplayString(_ctx.linkToCopy), 3)
  ], 2))
}