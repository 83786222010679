import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "visually-hidden" }
const _hoisted_2 = ["aria-busy", "aria-live"]
const _hoisted_3 = ["aria-live", "aria-busy"]
const _hoisted_4 = {
  key: 0,
  "aria-live": "assertive"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modifiedMessages, (message) => {
      return (_openBlock(), _createElementBlock("div", {
        key: message.id,
        "aria-busy": _ctx.busy,
        "aria-live": message.politeness || 'polite'
      }, _toDisplayString(message.message), 9, _hoisted_2))
    }), 128)),
    (_openBlock(), _createElementBlock("div", {
      key: _ctx.singleMessageId,
      "aria-live": _ctx.politeness,
      "aria-busy": _ctx.busy
    }, _toDisplayString(_ctx.singleMessage), 9, _hoisted_3)),
    (_ctx.busy)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.loadingMessage), 1))
      : _createCommentVNode("", true)
  ]))
}