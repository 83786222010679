
import { computed, defineComponent, watch } from 'vue';
import EmptyState from 'app/components/EmptyState.vue';
import NtcTitleCard from 'app/components/cards/NtcTitleCard.vue';
import Page from 'app/components/Page.vue';
import Surface from 'app/components/Surface.vue';
import { Constants } from 'app/base/constants';
import { injectNtcContext } from 'app/contexts/ntc-context';
import { usePatron } from 'app/functions/use-patron';
import { Loan } from 'app/models/loan';
import { useRoute } from 'vue-router';

export type NtcProvider = {
  id: string;
  name: string;
  description: string;
  backgroundColor: number[];
  fontColor: number[];
  icon: string;
  loan?: Loan;
  unavailable: boolean;
};

export default defineComponent({
  name: 'Ntc',
  components: {
    EmptyState,
    NtcTitleCard,
    Page,
    Surface
  },
  setup: (props, ctx) => {
    const { getNtcContent, ntcState, isProviderUnavailable, ntcError, show: showPrompt } = injectNtcContext();
    const { loans } = usePatron();

    const route = useRoute();
    watch(route, () => {
      getNtcContent(); //ensure ntc is up to date when visiting the page
    }, { immediate: true, flush: 'post' });

    const providers = computed(() => {
      return ntcState.value.state === 'loaded'
        ? ntcState.value.providers
          .filter((provider) => provider.subscription.status === 'active')
          .map((provider) => {
            return {
              id: provider.slug,
              name: provider.name,
              description: provider.description,
              backgroundColor: provider.colors.brand,
              fontColor: provider.colors.background,
              icon: provider.icon.image.url,
              loan: loans.value.find((loan) => loan.titleRecord.lexisMetadata.contentReserveID === provider.reserveId),
              unavailable: isProviderUnavailable(provider.slug)
            };
          })
        : [];
    });

    const state = computed(() => {
      return ntcState.value.state;
    });

    const helpLink = Constants.HELP_PATHS.NTC;

    return {
      helpLink,
      ntcError,
      providers,
      showPrompt,
      state
    };
  }
});
