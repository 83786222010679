
import { APP } from 'app/base/app';
import { Constants } from 'app/base/constants';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'UserMenuAbout',
  setup: () => {
    const privacyPolicyLink = Constants.PRIVACY_POLICY_PATH;
    const termsLink = Constants.TERMS_AND_COND_PATH;
    const copyrightLink = Constants.COPYRIGHT_PATH;
    const releaseNoteLink = Constants.RELEASENOTE_PATH;
    const { name, ...serverInfo } = APP.shell.info;
    const info = {
      account: { id: APP.patron.accountId },
      client: APP.client.info,
      server: serverInfo
    };

    return {
      copyrightLink,
      info,
      privacyPolicyLink,
      releaseNoteLink,
      termsLink
    };
  }
});
