
import { computed, defineComponent, ref, watchEffect } from 'vue';
import { Library } from 'app/models/library';
import WelcomeView from 'app/components/WelcomeView.vue';
import { RouteName } from '../router/constants';
import { useRouter } from 'vue-router';
import env from 'app/base/env';

export default defineComponent({
  components: {
    WelcomeView
  },
  props: {
    libraryId: {
      type: String,
      required: true
    },
    target: {
      type: String,
      required: false,
      default: ''
    }
  },
  setup: (props, ctx) => {
    const library = ref<Library | null>(null);
    const libraryName = computed(() => library.value?.name || '');

    const router = useRouter();
    const targetLocation = computed(() => {
      return props.target
        || router.resolve({ name: RouteName.LibraryHome, params: { libraryId: props.libraryId } }).href
        || env.ROOT_URI;
    });

    const goToTarget = () => {
      window.location.replace(targetLocation.value);
    };

    watchEffect(async () => {
      if (props.libraryId) {
        library.value = await Library.resolve({
          baseKey: props.libraryId
        });
      }
    });

    return {
      goToTarget,
      libraryName
    };
  }
});
