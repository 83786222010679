import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Popout = _resolveComponent("Popout")!

  return (_openBlock(), _createBlock(_component_Popout, {
    labelId: "download-indicator-popup-label",
    reference: _ctx.reference,
    placement: "bottom-end",
    onClose: _ctx.hidePopout
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.popout)
      }, [
        _createVNode(_component_Icon, {
          name: _ctx.headerIconName,
          class: _normalizeClass(_ctx.$style.headerIcon),
          "aria-hidden": "true"
        }, null, 8, ["name", "class"]),
        _createElementVNode("h1", {
          id: "download-indicator-popup-label",
          class: _normalizeClass(_ctx.$style.headerLabel)
        }, _toDisplayString(_ctx.headerLabel), 3),
        _createElementVNode("button", {
          class: _normalizeClass(_ctx.$style.dismissIcon),
          "aria-label": _ctx.$t('general.close'),
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.hidePopout && _ctx.hidePopout(...args)))
        }, [
          _createVNode(_component_Icon, { name: "dismiss" })
        ], 10, _hoisted_1),
        (_ctx.display === 'menu')
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(_ctx.$style.menu)
            }, [
              _createElementVNode("button", {
                class: _normalizeClass(_ctx.$style.action),
                onClick: _cache[1] || (_cache[1] = () => _ctx.confirmationRequired ? _ctx.showConfirmation() : _ctx.triggerAction())
              }, _toDisplayString(_ctx.menuActionLabel), 3),
              _createElementVNode("button", {
                class: _normalizeClass(_ctx.$style.action),
                onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.manageDownloads && _ctx.manageDownloads(...args)))
              }, _toDisplayString(_ctx.$t('title.downloadIndicator.configButton')), 3)
            ], 2))
          : (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass(_ctx.$style.confirmation)
            }, [
              _createElementVNode("span", null, _toDisplayString(_ctx.confirmationMessage), 1),
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.$style.confirmationActions)
              }, [
                _createElementVNode("button", {
                  class: _normalizeClass(_ctx.$style.action),
                  onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.triggerAction && _ctx.triggerAction(...args)))
                }, _toDisplayString(_ctx.$t('title.downloadIndicator.confirmButton')), 3),
                _createElementVNode("button", {
                  class: _normalizeClass(_ctx.$style.action),
                  onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.showMenu && _ctx.showMenu(...args)))
                }, _toDisplayString(_ctx.$t('title.downloadIndicator.cancelButton')), 3)
              ], 2)
            ], 2))
      ], 2)
    ], undefined, true),
    _: 1
  }, 8, ["reference", "onClose"]))
}