
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'InProgressContent',
  props: {
    id: {
      type: String,
      required: true
    }
  },
  setup: () => {
    return {};
  }
});
