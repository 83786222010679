
import { APP } from 'app/base/app';
import { C } from 'app/base/common';
import { usePatron } from 'app/functions/use-patron';
import { RouteName } from 'app/router/constants';
import router from 'app/router/router';
import { generateUUID } from 'lib/common/uuid';
import { computed, defineComponent, ref, watch, watchEffect } from 'vue';

enum PopupState {
  Hidden,
  Shown,
  Active
};

type Cell = {
  id: string;
  active: boolean;
  onSpace?: (event: KeyboardEvent) => void;
  onEnter?: (event: KeyboardEvent) => void;
};

type Row = {
  id: string;
  active: boolean;
  query: string;
  buttons: {
    text: Cell;
    delete: Cell;
  };
};

export default defineComponent({
  props: {
    modelValue: {
      type: String,
      required: true
    },
    mobile: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: [
    'search',
    'update:modelValue'
  ],
  setup: (props, ctx) => {
    const { searchHistory } = usePatron();
    const recentSearches = computed(() => searchHistory.value.slice(0, 5));

    const popupState = ref<PopupState>(PopupState.Hidden);
    const popupShown = computed(() => recentSearches.value.length > 0 && (props.mobile || popupState.value >= PopupState.Shown));
    const inPopup = computed(() => popupShown.value && popupState.value >= PopupState.Active);

    const combobox = ref<HTMLElement | null>(null);
    const searchContainer = ref<HTMLElement | null>(null);

    const input = ref<HTMLInputElement | null>(null);
    const inputQuery = computed({
      get: () => props.modelValue,
      set: (val) => ctx.emit('update:modelValue', val)
    });
    const showClear = computed(() => inputQuery.value.length > 0);

    const popup = ref<HTMLElement | null>(null);
    const popupId = generateUUID();

    const clearButton = ref<HTMLElement | null>(null);

    const _focusRowIndex = ref(0);
    const focusRowIndex = computed({
      get: () => _focusRowIndex.value,
      set: (value) => {
        if (value < 0) {
          _focusRowIndex.value = 0;
        } else if (value >= recentSearches.value.length) {
          _focusRowIndex.value = Math.max(0, recentSearches.value.length - 1);
        } else {
          _focusRowIndex.value = value;
        }
      }
    });


    const focusCol = ref<keyof Row['buttons']>('text');

    const rows = computed<Row[]>(() => recentSearches.value.map((query, i) => {
      return {
        id: `${popupId}-${i}`,
        active: inPopup.value && focusRowIndex.value === i,
        query,
        buttons: {
          text: {
            id: `${popupId}-${i}-text`,
            active: inPopup.value && focusRowIndex.value === i && focusCol.value === 'text'
          },
          delete: {
            id: `${popupId}-${i}-delete`,
            active: inPopup.value && focusRowIndex.value === i && focusCol.value === 'delete',
            onSpace: (event: KeyboardEvent) => {
              event.preventDefault();
              removeOption(query);
            },
            onEnter: (event: KeyboardEvent) => {
              event.preventDefault();
              removeOption(query);
            }
          }
        }
      };
    }));
    watchEffect(() => {
      if (rows.value.length <= 0) {
        popupState.value = PopupState.Hidden;
      }
    });

    const activeCell = computed(() => rows.value[focusRowIndex.value]?.buttons[focusCol.value]);
    const activeId = computed(() => inPopup.value && activeCell.value?.id);

    const savedQuery = ref('');
    watch(inPopup, () => {
      if (inPopup.value) {
        savedQuery.value = inputQuery.value;
        inputQuery.value = rows.value[focusRowIndex.value].query;
      } else {
        inputQuery.value = savedQuery.value;
      }
    });
    watch(focusRowIndex, () => {
      if (inPopup.value) {
        inputQuery.value = rows.value[focusRowIndex.value].query;
      }
    });

    // Event handlers

    const onFocus = () => {
      popupState.value = PopupState.Shown;
    };

    const onBlur = (event: FocusEvent) => {
      if (![searchContainer.value, popup.value].some((el) => el && el.contains(event.relatedTarget as Node))) {
        popupState.value = PopupState.Hidden;
      }
    };

    const onPopupFocus = () => input.value?.focus();

    // Key handlers

    const onSpace = (event: KeyboardEvent) => {
      if (inPopup.value) {
        activeCell.value?.onSpace?.(event);
      }
    };

    const onEnter = (event: KeyboardEvent) => {
      if (inPopup.value && activeCell.value.onEnter) {
        activeCell.value.onEnter(event);
      }
    };

    const onEsc = () => {
      popupState.value = PopupState.Hidden;
    };

    const onUp = (event: KeyboardEvent) => {
      if (inPopup.value) {
        if (focusRowIndex.value === 0) {
          popupState.value = PopupState.Shown;
        } else {
          focusRowIndex.value--;
        }
        event.preventDefault();
      }
    };

    const onDown = (event: KeyboardEvent) => {
      if (inPopup.value) {
        focusRowIndex.value++;
        event.preventDefault();
      } else {
        popupState.value = PopupState.Active;
        focusRowIndex.value = 0;
        focusCol.value = 'text';
      }
    };

    const onLeft = (event: KeyboardEvent) => {
      if (inPopup.value) {
        focusCol.value = 'text';
        event.preventDefault();
      }
    };

    const onRight = (event: KeyboardEvent) => {
      if (inPopup.value) {
        focusCol.value = 'delete';
        event.preventDefault();
      }
    };

    // Actions

    const submit = () => {
      const trimmedQuery = inputQuery.value.trim();
      if (trimmedQuery.length) {
        APP.patron.searchHistory.add(trimmedQuery);

        savedQuery.value = trimmedQuery;
        popupState.value = PopupState.Hidden;

        const newRoute = {
          name: RouteName.Search,
          query: {
            query: C.encode(trimmedQuery)
          }
        };
        router.push(newRoute);

        ctx.emit('search');
      } else {
        input.value?.focus();
      }
    };

    const clearInput = () => {
      inputQuery.value = '';
      input.value?.focus();
    };

    const removeOption = (query: string) => {
      APP.patron.searchHistory.remove(query);
      input.value?.focus();
    };

    return {
      activeId,
      clearButton,
      clearInput,
      combobox,
      inPopup,
      input,
      inputQuery,
      onBlur,
      onDown,
      onEnter,
      onEsc,
      onFocus,
      onLeft,
      onRight,
      onPopupFocus,
      onSpace,
      onUp,
      popup,
      popupId,
      popupShown,
      removeOption,
      rows,
      searchContainer,
      showClear,
      submit
    };
  }
});
