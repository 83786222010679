import { useColorFilter } from 'app/functions/use-color-filter';
import { useReleaseFilter } from 'app/functions/use-release-filter';
import { useTextFilter } from 'app/functions/use-text-filter';
import { Annotation, AnnotationCategory } from 'app/models/annotation';
import { TitleRecord } from 'app/models/title';
import { Ref, computed } from 'vue';

export type AnnotationFilters = {
  [category in AnnotationCategory]: string[] | undefined;
};

export function useAnnotationFilters(annotations: Readonly<Ref<Annotation[]>>, releases: Readonly<Ref<TitleRecord[]>>) {
  const {
    releaseFilter,
    filteredItems: releaseFilteredAnnotations,
    getFilterObjects: releaseGetFilterObjects
  } = useReleaseFilter(annotations, releases);

  const {
    colorFilter,
    filteredItems: releaseAndColorFilteredAnnotations,
    getFilterObjects: colorGetFilterObjects
  } = useColorFilter(releaseFilteredAnnotations);

  const {
    textFilter,
    filteredItems: filteredAnnotations
  } = useTextFilter(releaseAndColorFilteredAnnotations, (a, text) => Annotation.FILTER_FUNCTIONS.filterByText(a, text));

  const filterObjects = computed(() => [
    ...releaseGetFilterObjects(releaseAndColorFilteredAnnotations.value as any),
    ...colorGetFilterObjects(releaseAndColorFilteredAnnotations.value)
  ]);

  const displayCounts = computed(() => {
    return {
      showing: filteredAnnotations.value.length || 0,
      total: releaseAndColorFilteredAnnotations.value.length || 0
    };
  });

  return {
    textFilter,
    releaseFilter,
    colorFilter,

    filteredAnnotations,
    filterObjects,
    displayCounts
  };
};

export const getQueryParametersFromAnnotationFilters = (filters: AnnotationFilters | undefined) => {
  if (!filters) { return {}; }

  return {
    color: filters.Color?.join(',') || undefined,
    release: filters.Release?.join(',') || undefined
  };
};
