
import { useAppEvents } from 'app/functions/use-app-events';
import { announceMessage } from 'app/functions/use-chatterbox';
import { computed, defineComponent, nextTick, ref } from 'vue';

export default defineComponent({
  name: 'Toast',
  setup: () => {
    const message = ref('');
    const type = ref<'success' | 'error' | 'info'>('success');
    const show = ref(false);
    const animate = ref(true);
    const iconName = computed(() => {
      return type.value === 'success' ? 'check'
        : type.value === 'error' ? 'dismiss'
        : 'info';
    });

    let hideTimeout = -1;

    useAppEvents({
      toast: async ({ m }) => {
        show.value = false;
        animate.value = false;
        clearTimeout(hideTimeout);

        await nextTick();

        message.value = m.message;
        type.value = m.type;
        show.value = true;
        animate.value = true;

        announceMessage(message.value);

        hideTimeout = window.setTimeout(
          () => show.value = false,
          5000
        );
      }
    });

    return {
      animate,
      iconName,
      message,
      show,
      type
    };
  }
});
