import { APP } from 'app/base/app';
import { C, Dictionary } from 'app/base/common';
import env from 'app/base/env';
import { Alert } from 'app/base/samwise';
import { depthLimitedStringify } from 'lib/common';
import flags from 'res/data/feature_flags.json';

export type ArgType =
  string |
  string[] |
  number |
  number[] |
  boolean;

type ArgTypeString<T extends ArgType> =
  T extends string ? 'string' :
  T extends string[] ? 'string[]' :
  T extends number ? 'number' :
  T extends number[] ? 'number[]' :
  T extends boolean ? 'boolean' :
  never;

type Arg = {
  name: string;
  type: ArgTypeString<ArgType>;
  description?: string;
  example?: string;
};

type CommandFunc = (args: { [name: string]: ArgType }) => string | Promise<string>;

type Command = {
  description: string;
  func: CommandFunc;
  args: Arg[];
};

export const commands: Dictionary<Command> = {
  bank: {
    description: 'Display data stored in the bank',
    args: [
      {
        name: 'entry',
        type: 'string'
      },
      {
        name: 'depth',
        type: 'number'
      }
    ],
    func: getBank
  },
  card: {
    description: 'Display the current card',
    args: [],
    func: getCard
  },
  channel: {
    description: 'List all channels, or flip to a channel',
    args: [
      {
        name: 'name',
        type: 'string'
      }
    ],
    func: channel
  },
  chip: {
    description: 'Display the current chip',
    args: [],
    func: getChip
  },
  crash: {
    description: 'Simulate an unrecoverable error',
    args: [
      {
        name: 'message',
        type: 'string'
      }
    ],
    func: crash
  },
  diagnostics: {
    description: 'Open shell diagnostics',
    args: [],
    func: openDiagnostics
  },
  downloads: {
    description: 'View download settings',
    args: [],
    func: getDownloadSettings
  },
  error: {
    description: 'Simulate an error',
    args: [
      {
        name: 'message',
        type: 'string'
      }
    ],
    func: error
  },
  flag: {
    description: 'List all feature flags, or change the value of a flag',
    args: [
      {
        name: 'clear',
        type: 'boolean'
      },
      ...Object.keys(flags).map((f) => ({
        name: f,
        type: 'boolean'
      })) as Arg[]
    ],
    func: changeFlag
  },
  genAlert: {
    description: 'Generate samwise alerts',
    args: [
      {
        name: 'count',
        type: 'number'
      },
      {
        name: 'type',
        type: 'string'
      },
      {
        name: 'seriesId',
        type: 'number'
      },
      {
        name: 'titleId',
        type: 'number[]'
      }
    ],
    func: genAlert
  },
  list: {
    description: 'List all available commands',
    args: [],
    func: list
  },
  network: {
    description: 'Display or change network status',
    args: [
      {
        name: 'setting',
        type: 'string'
      }
    ],
    func: network
  },
  relaunch: {
    description: 'Relaunch the app',
    args: [],
    func: relaunch
  },
  reset: {
    description: 'Reset the app',
    args: [],
    func: reset
  },
  setversion: {
    description: 'Override the current version in the app.',
    args: [
      {
        name: 'version',
        type: 'string'
      }
    ],
    func: setVersion
  },
  traits: {
    description: 'List platform traits',
    args: [],
    func: traits
  },
  update: {
    description: 'Simulate or force an app update',
    args: [
      {
        name: 'force',
        type: 'boolean'
      }
    ],
    func: update
  },
  updateSubscription: {
    description: 'Set lastChecked date on new or existing subscription',
    args: [
      {
        name: 'titleId',
        type: 'number'
      },
      {
        name: 'seriesId',
        type: 'number'
      },
      {
        name: 'date',
        type: 'string'
      }
    ],
    func: updateSubscription
  },
  version: {
    description: 'Display version info',
    args: [],
    func: displayVersion
  }
};

function getBank(args: { entry?: string; depth?: number }): string {
  const entryArg = args.entry;
  const depthArg = args.depth || 8;

  const stored = APP.bank.getData();

  return entryArg ? depthLimitedStringify(stored[entryArg], depthArg) : depthLimitedStringify(stored, depthArg);
}


function getCard(): string {
  const card = APP.patron.currentCard();

  return JSON.stringify({
    cardId: card.cardId,
    puid: card.puid,
    advantageKey: card.advantageKey,
    canPlaceHolds: card.canPlaceHolds,
    emailAddress: card.emailAddress
  }, null, 2);
}


function changeFlag(args: Partial<{ [flag in keyof typeof flags]: boolean} & { clear: boolean }>): string {
  if (Object.keys(args).length === 0) {
    return getFlags();
  }

  const { clear, ...flagArgs } = args;

  if (clear) {
    APP.flags.clear();

    return 'Flags cleared';
  }

  const pairs = Object.entries(flagArgs) as [keyof typeof flags, boolean][];

  pairs.forEach(([name, value]) => {
    if (!value) {
      return `Missing new value for flag \`${name}\``;
    }

    if (!(name in flags)) {
      return `Unsupported flag \`${name}\``;
    }
  });

  pairs.forEach(([name, value]) => {
    APP.flags.override(name, value);
  });

  return 'Flags updated';
}

type ChannelDefinition = {
  name: string;
  version: string;
  info: unknown;
};

export async function channel(args: { name?: string }): Promise<string> {
  let output = '';
  if (!args.name) {
    try {
      // List available channels
      const response = (await APP.services.elrond.fetchAsync<Dictionary<ChannelDefinition>>('channel/guide'))!;
      const channels = [
        `Current channel: ${env.CHANNEL}`,
        'Available channels:'
      ];
      C.each(response, (k, v) => {
        channels.push(`${k} --> ${v.version}`);
      });

      output = channels.join('\n');
    } catch (ex) {
      output = 'Error fetching channels: ' + (ex.status || ex.message);
    }
  } else {
    // Update current channel
    try {
      await APP.services.elrond.fetchAsync({
        url: 'channel/select',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ channel: args.name }),
        credentials: true,
        textResponse: true
      });
      setTimeout(() => forceUpdate(), 2000);

      output = 'Channel updated, relaunching app...';
    } catch (ex) {
      output = 'Error setting channel: ' + ex.status;
    }
  }

  return output;
}


function crash(args: { message?: string }): string {
  setTimeout(() => {
    APP.bank.set('crash', args.message || 'Crash! :(');
    APP.reload();
  }, 500);

  return 'Simulating crash...';
}


function displayVersion(): string {
  return [
    'Client: ',
    JSON.stringify(APP.client.info, null, 2),
    'SHA: ' + env.BUILD_SHA,
    'Built: ' + env.BUILD_TIMESTAMP,
    'Shell: ',
    JSON.stringify(APP.shell.info, null, 2)
  ].join('\n');
}


function error(args: { message?: string }): string {
  setTimeout(() => { throw new Error(args.message); }, 500);

  return 'Simulating error...';
}


async function genAlert(args: {
  count?: number;
  type?: string;
  seriesId?: number;
  titleId?: number[];
}): Promise<string> {

  const alertCount = args.count || 1;
  const typeArg = args.type as Alert['type'];

  const results = [];

  for (let i = 0; i < alertCount; i++) {
    if (typeArg === 'TitleNewRelease') {
      if (!args.titleId || args.titleId.length === 0) {
        return `${typeArg} alert requires titleId`;
      }

      const title = await APP.services.thunder.getTitle(APP.library.key(), args.titleId[0].toString());
      if (!title) {
        return `Title ${args.titleId} was not found in thunder`;
      }

      const res = await APP.services.samwise.addTitleNewReleaseAlert(args.titleId[0]);
      results.push(res);
    } else if (typeArg === 'TitleNotAvailable') {
      if (!args.titleId || args.titleId.length === 0) {
        return `${typeArg} alert requires titleId`;
      }

      const title = await APP.services.thunder.getTitle(APP.library.key(), args.titleId[0].toString());
      if (!title) {
        return `Title ${args.titleId} was not found in thunder`;
      }

      const res = await APP.services.samwise.addTitleNotAvailableAlert(args.titleId[0]);
      results.push(res);
    } else if (typeArg === 'SeriesNewRelease') {
      if (!args.seriesId) {
        return `${typeArg} alert requires seriesId`;
      }

      if (!args.titleId || args.titleId.length === 0) {
        return `${typeArg} alert requires titleId`;
      }

      const series = await APP.services.thunder.getSeries(APP.library.key(), args.seriesId);
      if (!series) {
        return `Series ${args.seriesId} was not found in thunder`;
      }
      const titleIds = series.items.map((item) => item.id);
      if (!args.titleId.every((titleId) => titleIds.includes(titleId.toString()))) {
        const exampleEnd = titleIds.length > 5 ? 5 : titleIds.length;

        return `Some/all titleIds: [${args.titleId}] are not part of series: ${args.seriesId}. Valid titleIds are [${titleIds.slice(0, exampleEnd)}]`;
      }
      const res = await APP.services.samwise.addSeriesNewReleaseAlert(args.seriesId, args.titleId);
      results.push(res);
    } else if (typeArg === 'SeriesNotAvailable') {
      if (!args.seriesId) {
        return `${typeArg} alert requires seriesId`;
      }

      const series = await APP.services.thunder.getSeries(APP.library.key(), args.seriesId);
      if (!series) {
        return `Series ${args.seriesId} was not found in thunder`;
      }
      const res = await APP.services.samwise.addSeriesNotAvailableAlert(args.seriesId);
      results.push(res);
    } else {
      return `Unrecognized or missing alert type: ${typeArg}`;
    }
  }

  return JSON.stringify(results, null, 2);
}


function getChip(): string {
  return APP.sentry.chip;
}


function getDownloadSettings(): string {
  return JSON.stringify({
    autoDownload: APP.updateManager.autoDownloadRule?.key,
    wifiOnly: APP.updateManager.downloadQueueRule === 'wifi'
  }, null, 2);
}


function getFlags(): string {
  return Object.keys(flags)
    .map((flag) => `${flag}: ${APP.flags.get(flag as keyof typeof flags)}`)
    .join('\n');
}


function list(): string {
  return Object.entries(commands).map(([name, config]) => {
    const { description, args } = config;
    const out = args.length > 0
      ? { name, description, args }
      : { name, description };

    return JSON.stringify(out, null, 2);
  }).join('\n');
}


function network(args: { setting?: string }): string {
  if (!args) {
    return JSON.stringify({
      reachable: APP.network.reachable,
      metered: APP.network.metered,
      connection: APP.network.connection
    }, null, 2);
  }

  if (args.setting === 'off') {
    APP.network.reachable = false;

    return 'You\'re now offline.';
  }

  if (args.setting === 'cellular') {
    APP.network.connection = 'cellular';
    APP.network.metered = true;

    return 'You\'re now on a cellular connection.';
  }

  if (args.setting === 'on') {
    APP.network.check();

    return 'Turning network on.\n(This won\'t work if your device is offline.)';
  }

  return 'Unrecognized network setting. Please enter `off`, `cellular`, or `on`.';
}


function openDiagnostics(): string {
  APP.shell.transmit('diagnostics:show');

  return 'Opening diagnostics panel...';
}


function relaunch(): string {
  APP.reload();

  return 'Reloading app...';
}


function reset(): string {
  APP.semaphore.set('client', 'unloading');
  APP.nav.goRoot('reset');

  return '';
}


function setVersion(args: { version?: string }): string {
  if (!args.version) {
    return 'Missing argument. Version must be in the form of number.number.number';
  }

  if (!args.version.match(/\d+\.\d+\.\d+/)) {
    return 'Invalid argument. Version must be in the form of number.number.number';
  }

  APP.client.info.version.assign(args.version);

  return `Client version updated to: ${args.version}`;
}


function traits(): string {
  return APP.shell.traits
    ? JSON.stringify(APP.shell.traits, null, 2)
    : 'No traits found';
}


function update(args: { force?: boolean }): string {
  if (args.force) {
    forceUpdate();
  } else {
    APP.events.dispatch('app:update:simulate');
  }

  return 'Starting update...';
}


async function updateSubscription(args: { titleId?: number; seriesId?: number; date?: string }): Promise<string> {
  if (!args.titleId && !args.seriesId) {
    return 'titleId or seriesId required';
  }

  if (!args.date || isNaN(C.toTimestamp(args.date))) {
    return 'date required (mm/dd/yyyy)';
  }

  try {
    const subs = await APP.services.samwise.fetchAllSubscriptions();
    let existing = null;

    if (args.titleId) {
      const title = await APP.services.thunder.getTitle(APP.library.key(), args.titleId.toString());
      if (!title) {
        return `Title ${args.titleId} was not found in thunder`;
      }
      existing = subs?.find((s) => s.type === 'Title' && s.titleId === args.titleId);

      if (!existing) {
        existing = await APP.services.samwise.subscribeToTitle(args.titleId);
      }
    } else if (args.seriesId) {
      const series = await APP.services.thunder.getSeries(APP.library.key(), args.seriesId);
      if (!series) {
        return `Series ${args.seriesId} was not found in thunder`;
      }
      existing = subs?.find((s) => s.type === 'Series' && s.seriesId === args.seriesId);

      if (!existing) {
        existing = await APP.services.samwise.subscribeToSeries(args.seriesId);
      }
    }

    if (existing) {
      const result = await APP.services.samwise.updateSubscription(existing.id, C.toTimestamp(args.date));

      return JSON.stringify(result, null, 2);
    }

    return 'Subscription not found or not successfully created, try again.';
  } catch (err) {
    return `Error: ${err}. Try again.`;
  }
}


function forceUpdate() {
  const appRoster = APP.updateManager.rosters['elrond-js'];
  if (appRoster) {
    appRoster.wipe();
  }
  APP.reload();
}
