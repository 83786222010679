
import { defineComponent } from 'vue';
import { TitleRecord } from 'app/models/title';
import TitleCard from 'app/components/cards/TitleCard.vue';

export default defineComponent({
  name: 'MyBooksTabPanel',
  components: {
    TitleCard
  },
  props: {
    titles: {
      type: Array as () => TitleRecord[] | null,
      default: null
    },
    filter: {
      type: Function,
      required: true
    }
  }
});
