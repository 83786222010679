
import { AppliedFilters, FilterCategoryObject } from 'app/components/FilterButton.vue';
import FilterContent from 'app/components/FilterContent.vue';
import Sidebar from 'app/components/Sidebar.vue';
import { FilterObject } from 'app/models/filter-object';
import { computed, defineComponent, PropType } from 'vue';


export default defineComponent({
  name: 'FilterSidebar',
  components: {
    Sidebar,
    FilterContent
  },
  props: {
    options: {
      type:  Array as PropType<FilterObject[]>,
      required: true
    },
    categoryObjects: {
      type: Array as PropType<FilterCategoryObject[]>,
      required: true
    },
    show: {
      type: Boolean,
      required: true
    }
  },
  emits: [
    'apply',
    'close'
  ],
  setup: (props, ctx) => {
    const applied = computed(() => props.options.filter((option) => option.selected));

    const clearFilters = () => {
      const cleared = {} as AppliedFilters;
      props.categoryObjects.forEach((cat) => cleared[cat.id] = undefined);

      ctx.emit('apply', cleared);
    };

    return {
      applied,
      clearFilters
    };
  }
});
