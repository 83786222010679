
import { ClipboardHelper } from 'app/base/clipboard';
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'CopyLink',
  props: {
    headerLabel: {
      type: String,
      required: true
    },
    link: {
      type: String,
      default: undefined
    }
  },
  setup: (props) => {
    const getLinkFromLocation = () => location.href.replace('nautilus-', '');

    const copied = ref(false);
    const copy = async () => {
      const success = await ClipboardHelper.copyToClipboard(props.link || getLinkFromLocation());
      if (success) {
        copied.value = true;
        window.setTimeout(() => copied.value = false, 3000);
      }
    };

    return {
      copied,
      copy
    };
  }
});
