import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormSubmitButton = _resolveComponent("FormSubmitButton")!
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.background)
  }, [
    _createElementVNode("form", {
      ref: "form",
      class: _normalizeClass(_ctx.$style.container),
      onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)), ["prevent"]))
    }, [
      _createElementVNode("h2", {
        class: _normalizeClass(_ctx.$style.heading)
      }, _toDisplayString(_ctx.title), 3),
      _renderSlot(_ctx.$slots, "default"),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.actions)
      }, [
        _createVNode(_component_FormSubmitButton, {
          "data-prompt_default_focus": "",
          label: _ctx.confirmLabel,
          enabled: _ctx.submittable,
          size: "small"
        }, null, 8, ["label", "enabled"]),
        (_ctx.cancelLabel)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: _normalizeClass(_ctx.$style.cancel),
              type: "button",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('cancel')))
            }, _toDisplayString(_ctx.cancelLabel), 3))
          : _createCommentVNode("", true),
        (_ctx.deleteLabel)
          ? (_openBlock(), _createElementBlock("button", {
              key: 1,
              class: _normalizeClass(_ctx.$style.delete),
              type: "button",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('delete')))
            }, [
              _createVNode(_component_Icon, { name: "trashcan" }),
              _createTextVNode(" " + _toDisplayString(_ctx.deleteLabel), 1)
            ], 2))
          : _createCommentVNode("", true)
      ], 2)
    ], 34)
  ], 2))
}