
import { Title } from 'app/models/title';
import { RouteName } from 'app/router/constants';
import { MS_SECOND, secondsToHumanDuration } from 'lib/common/time';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TitleDetailsAccordionDetails',
  props: {
    title: {
      type: Title,
      required: true
    }
  },
  setup: (props) => {
    const publisherLink = (publisherId: string) => {
      return {
        name: RouteName.Publisher,
        params: {
          publisherId
        }
      };
    };

    const durationString = secondsToHumanDuration(props.title.duration / MS_SECOND, {unitCount: 1, maxUnit: 'hours'});

    return {
      durationString,
      publisherLink
    };
  }
});
