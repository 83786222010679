
import { defineComponent } from 'vue';
import Surface from 'app/components/Surface.vue';
import WelcomeHeader from 'app/components/WelcomeHeader.vue';
import WelcomeFooter from 'app/components/WelcomeFooter.vue';

export default defineComponent({
  name: 'WelcomeView',
  components: {
    Surface,
    WelcomeHeader,
    WelcomeFooter
  },
  props: {
    backButtonLabel: {
      type: String,
      default: undefined
    }
  },
  emits: [
    'back'
  ]
});
