
import CopyContent from 'app/components/CopyContent.vue';
import InProgressContent from 'app/components/InProgressContent.vue';
import { startCopy } from 'app/functions/use-annotation-copying';
import { Annotation } from 'app/models/annotation';
import { TitleRecord } from 'app/models/title';
import { RouteName } from 'app/router/constants';
import { generateUUID } from 'lib/common';
import { defineComponent, PropType, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import Prompt from './Prompt.vue';

export default defineComponent({
  name: 'CopyingPrompt',
  components: {
    CopyContent,
    InProgressContent,
    Prompt
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    targetTitle: {
      type: Object as PropType<TitleRecord>,
      default: undefined
    },
    sourceTitle: {
      type: Object as PropType<TitleRecord>,
      default: undefined
    },
    sourceAnnotations: {
      type: Array as PropType<Annotation[]>,
      default: () => []
    }
  },
  emits: [
    'close',
    'open'
  ],
  setup: (props, ctx) => {
    const router = useRouter();

    const promptToShow = ref<'copy' | 'inProgress' | null> (null);
    const transition = ref<'down' | 'fade'> ('down');

    const copyPromptId = ref(`copy-explanation-${generateUUID()}`);
    const inProgressPromptId = ref(`inProgress-explanation-${generateUUID()}`);

    watch(() => props.show, () => {
      if (props.show) {
        promptToShow.value = 'copy';
        transition.value = 'down';
      } else {
        promptToShow.value = null;
        transition.value = 'down';
      }
    });

    const copy = async () => {
      await startCopy(props.targetTitle!.slug, props.sourceTitle!.slug);

      promptToShow.value = 'inProgress';
      transition.value = 'fade';
    };

    const goToAnnotations = () => {
      ctx.emit('close');

      router.push({
        name: RouteName.NoteDetails,
        params: {
          titleSlug: props.targetTitle!.slug
        }
      });
    };

    return {
      copy,
      copyPromptId,
      goToAnnotations,
      inProgressPromptId,
      promptToShow,
      transition
    };
  }
});
