
import { Constants } from 'app/base/constants';
import FormCheckbox from 'app/components/FormCheckbox.vue';
import FormSubmitButton from 'app/components/FormSubmitButton.vue';
import { computed, defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'GdprForm',
  components: {
    FormSubmitButton,
    FormCheckbox
},
  props: {
    defaultSetting: {
      type: Boolean,
      required: true
    }
  },
  emits: [
    'submit'
  ],
  setup: (props, ctx) => {
    const setting = ref(props.defaultSetting);

    const checkbox = computed({
      get: () => setting.value ? 'on' : 'off',
      set: (val: string) => {
        setting.value = val === 'on';
      }
    });

    const onSubmit = () => {
      ctx.emit('submit', setting.value);
    };

    return {
      checkbox,
      cookiePolicyPath: Constants.COOKIE_POLICY_PATH,
      privacyPolicyPath: Constants.PRIVACY_POLICY_PATH,
      onSubmit,
      setting
    };
  }
});
