
import { APP } from 'app/base/app';
import UserMenuBorrowLimits from 'app/components/UserMenuBorrowLimits.vue';
import { injectPrivacySettingsContext } from 'app/contexts/privacy-settings-context';
import { useAppEvents } from 'app/functions/use-app-events';
import { useI18n } from 'app/functions/use-i18n';
import { RouteName } from 'app/router/constants';
import { computed, defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import ChannelIndicator from './ChannelIndicator.vue';


export default defineComponent({
  name: 'UserMenuMain',
  components: {
    ChannelIndicator,
    UserMenuBorrowLimits
  },
  emits: [
    'close',
    'menu'
  ],
  setup: (_, ctx) => {
    const { t } = useI18n();
    const { dispatch } = useAppEvents();

    const canDownload = APP.shell.has('rosters');
    const showDebug = APP.flags.get('debug');
    const toggleDebugConsole = () => {
      dispatch('show:debug');
      ctx.emit('close');
    };
    const router = useRouter();
    const signOut = () => router.push({ name: RouteName.SignOut });

    const { showSettings } = injectPrivacySettingsContext();

    const showGdprModal = () => {
      showSettings();
      ctx.emit('close');
    };

    const hasDiagnosticsCapability = computed(() => APP.shell.has('diagnostics:log'));
    const triggerDiagnosticsLog = () => {
      APP.shell.transmit('diagnostics:log:email');
      dispatch('toast', { type: 'success', message: t('header.account.sendDiagnosticsLog.message') });
      ctx.emit('close');
    };

    return {
      canDownload,
      hasDiagnosticsCapability,
      toggleDebugConsole,
      showDebug,
      showGdprModal,
      signOut,
      triggerDiagnosticsLog
    };
  }
});
