
import { defineComponent, ref, computed } from 'vue';
import SearchBar from 'app/components/SearchBar.vue';
import MobileSearchTrigger from 'app/components/MobileSearchTrigger.vue';
import Overlay from './Overlay.vue';

export default defineComponent({
  components: {
    SearchBar,
    MobileSearchTrigger,
    Overlay
},
  props: {
    modelValue: {
      type: String,
      required: true
    }
  },
  emits: [
    'update:modelValue'
  ],
  setup: (props, ctx) => {
    const active = ref(false);
    const searchQuery = computed({
      get: () => props.modelValue,
      set: (val) => ctx.emit('update:modelValue', val)
    });

    return { active, searchQuery };
  }
});
