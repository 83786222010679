
import { APP } from 'app/base/app';
import { seriesCoverSizes } from 'app/base/constants';
import { SearchThisTitleQuery } from 'app/base/hudson';
import CopyLink from 'app/components/CopyLink.vue';
import DetailsPageContent from 'app/components/DetailsPageContent.vue';
import SearchThisTitle from 'app/components/SearchThisTitle.vue';
import SeriesCover from 'app/components/SeriesCover.vue';
import SeriesDetailsAccordion from 'app/components/SeriesDetailsAccordion.vue';
import TabView from 'app/components/TabView.vue';
import TextFilter from 'app/components/TextFilter.vue';
import NotesTitleCard from 'app/components/cards/NotesTitleCard.vue';
import TitleCard from 'app/components/cards/TitleCard.vue';
import { useI18n } from 'app/functions/use-i18n';
import { useSeriesSubscription } from 'app/functions/use-subscription-interaction';
import { Series } from 'app/models/series';
import { Title, TitleRecord } from 'app/models/title';
import { SeriesDetailsVolume } from 'app/views/SeriesDetails.vue';
import { Dictionary } from 'lib/common/dictionary';
import { computed, defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'SeriesDetailsContent',
  components: {
    DetailsPageContent,
    SeriesCover,
    CopyLink,
    SeriesDetailsAccordion,
    TabView,
    TitleCard,
    NotesTitleCard,
    TextFilter,
    SearchThisTitle
  },
  props: {
    series: {
      type: Series,
      required: true
    },
    volumes: {
      type: Object as () => Dictionary<SeriesDetailsVolume>,
      default: () => ({})
    },
    searchThisTitle: {
      type: Object as () => SearchThisTitleQuery | null,
      default: null
    }
  },
  setup: (props, ctx) => {
    const { t } = useI18n();

    const {
      subscribeTextShort,
      subscribeTextLong,
      toggleSubscription
    } = useSeriesSubscription(props.series, 'series details page');

    const metadata = computed(() => {
      return [
        {
          label: 'series.volumeCount',
          value: Object.keys(props.volumes || {}).length
            || props.series.items?.length.toString()
            || '-',
          show: true
        },
        {
          label: 'series.edition',
          value: props.series.edition,
          show: !!props.series.edition
        }
      ];
    });

    const volumeDictionary = computed(() => {
      return Object.values(props.volumes)
        .reduce(
          (dict, next) => {
            dict[next.title.slug] = next.title;

            return dict;
          },
          {} as Dictionary<TitleRecord>
        );
    });

    const legacySetIdForHudson = computed(() => {
      return props.volumes[props.series.firstTitleSlug]?.title.lexisMetadata?.setId;
    });


    const textFilter = ref('');

    const allVolumes = computed(() => {
      return Object.values(props.volumes)
        .sort((v1, v2) => Title.SORT_FUNCTIONS.seriesRank(v1.title, v2.title));
    });

    const volumesWithAnnotations = computed(() => {
      return allVolumes.value
        .filter((v) => v.hasAnnotations);
    });

    const downloadedVolumes = computed(() => {
      return allVolumes.value
        .filter((v) => v.isDownloaded);
    });

    const volumeMatchesFilter = (volume: SeriesDetailsVolume) => {
      return Title.FILTER_FUNCTIONS.filterByText(volume.title, textFilter.value);
    };

    const volumeTabs = computed(() => {
      const tabs = [
        {
          id: 'all',
          label: t('series.volumeList.allVolumes'),
          count: allVolumes.value.filter(volumeMatchesFilter).length
        },
        {
          id: 'annotated',
          label: t('series.volumeList.volumesWithAnnotations'),
          count: volumesWithAnnotations.value.filter(volumeMatchesFilter).length
        }
      ];

      if (APP.shell.has('rosters')) {
        tabs.push({
          id: 'downloaded',
          label: t('series.volumeList.downloadedVolumes'),
          count: downloadedVolumes.value.filter(volumeMatchesFilter).length
        });
      }

      return tabs;
    });

    return {
      downloadedVolumes,
      allVolumes,
      legacySetIdForHudson,
      metadata,
      seriesCoverSizes,
      subscribeTextLong,
      subscribeTextShort,
      textFilter,
      toggleSubscription,
      volumeDictionary,
      volumeTabs,
      volumesWithAnnotations,
      volumeMatchesFilter
    };
  }
});
