import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, withKeys as _withKeys, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["aria-labelledby"]
const _hoisted_2 = ["id"]
const _hoisted_3 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("dialog", {
    ref: "dialog",
    class: _normalizeClass({
      [_ctx.$style.dialog]: true,
      [_ctx.$style.closing]: _ctx.closing
    }),
    style: _normalizeStyle(_ctx.animationStyles),
    "aria-labelledby": _ctx.headingId,
    onCancel: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.closeIfAllowed && _ctx.closeIfAllowed(...args))),
    onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.closeIfAllowed && _ctx.closeIfAllowed(...args))),
    onKeydown: _cache[4] || (_cache[4] = _withKeys(
//@ts-ignore
(...args) => (_ctx.closeIfAllowed && _ctx.closeIfAllowed(...args)), ["esc"]))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.contentWrapper),
      onClick: _cache[1] || (_cache[1] = (e) => e.stopPropagation())
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.headerBar)
      }, [
        _createElementVNode("h2", {
          id: _ctx.headingId,
          class: _normalizeClass([_ctx.$style.heading, _ctx.headingClass])
        }, _toDisplayString(_ctx.heading), 11, _hoisted_2),
        (_ctx.dismissable)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: _normalizeClass(_ctx.$style.dismiss),
              "aria-label": _ctx.$t('general.close'),
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)))
            }, [
              _createVNode(_component_Icon, { name: "dismiss" })
            ], 10, _hoisted_3))
          : _createCommentVNode("", true)
      ], 2),
      _renderSlot(_ctx.$slots, "default")
    ], 2)
  ], 46, _hoisted_1))
}