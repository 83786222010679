import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchThisTitleContent = _resolveComponent("SearchThisTitleContent")!
  const _component_Sidebar = _resolveComponent("Sidebar")!

  return (_openBlock(), _createBlock(_component_Sidebar, {
    show: _ctx.show,
    header: _ctx.$t(`searchThisTitle.header.${(_ctx.item && _ctx.item.mediaType === 'series') ? 'series' : 'title'}`),
    onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_SearchThisTitleContent, {
        item: (_ctx.item as TitleRecord | Series),
        params: _ctx.params,
        skipRouting: _ctx.skipRouting,
        onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
      }, null, 8, ["item", "params", "skipRouting"])
    ], undefined, true),
    _: 1
  }, 8, ["show", "header"]))
}