
import { defineComponent, ref } from 'vue';
import InfoPanel from 'app/components/InfoPanel.vue';
import Overlay from 'app/components/Overlay.vue';

export default defineComponent({
  name: 'Sidebar',
  components: {
    InfoPanel,
    Overlay
  },
  props: {
    header: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      default: undefined
    },
    show: {
      type: Boolean,
      required: true
    },
    side: {
      type: String as () => 'left' | 'right',
      default: 'right'
    },
    resizable: {
      type: Boolean,
      default: true
    }
  },
  emits: [
    'close'
  ],
  setup: (props, ctx) => {
    const close = () => {
      ctx.emit('close');
    };

    const sidebar = ref<HTMLElement | null>(null);
    const resizer = ref<HTMLElement | null>(null);

    const resize = (event: MouseEvent) => {
      if (!resizer.value) { return; }
      if (!sidebar.value) { return; }
      if (event.button !== 0) { return; }
      const handler = (e: MouseEvent) => {
        e.preventDefault();
        const width = props.side === 'right'
          ? window.innerWidth - e.x
          : e.x;
        sidebar.value!.style.width = `${Math.max(300, width)}px`;
      };
      document.addEventListener('mousemove', handler);
      document.addEventListener('mouseup', () => {
        document.removeEventListener('mousemove', handler);
      }, { once: true });
    };

    return {
      close,
      resize,
      resizer,
      sidebar
    };
  }
});
