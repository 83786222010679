import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["aria-label"]
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_DownloadIndicatorPopout = _resolveComponent("DownloadIndicatorPopout")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("button", {
      ref: "indicatorButton",
      class: _normalizeClass(_ctx.$style.indicatorButton),
      "aria-label": _ctx.$t('title.downloadIndicator.status.downloaded', { SIZE: _ctx.downloadSize}),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showPopout = !_ctx.showPopout))
    }, [
      _createVNode(_component_Icon, {
        name: "dl-downloaded",
        class: _normalizeClass([_ctx.$style.icon, 'icon-complete'])
      }, null, 8, ["class"]),
      (!_ctx.iconOnly)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$t('title.downloadIndicator.status.downloaded', { SIZE: _ctx.downloadSize})), 1))
        : _createCommentVNode("", true)
    ], 10, _hoisted_1),
    (_ctx.showPopout)
      ? (_openBlock(), _createBlock(_component_DownloadIndicatorPopout, {
          key: 0,
          reference: _ctx.indicatorButton,
          headerIconName: "dl-downloaded",
          headerLabel: _ctx.$t('title.downloadIndicator.status.downloadedNoSize'),
          menuActionLabel: _ctx.$t('title.downloadIndicator.deleteButton', { SIZE: _ctx.downloadSize }),
          confirmationMessage: _ctx.$t('title.downloadIndicator.deleteConfirmMsg'),
          confirmationRequired: _ctx.deleteConfirmationRequired,
          "onDownload:action": _ctx.deleteDownload,
          "onDownload:settings": _ctx.openSettings,
          "onPopout:hide": _cache[1] || (_cache[1] = ($event: any) => (_ctx.showPopout = false))
        }, null, 8, ["reference", "headerLabel", "menuActionLabel", "confirmationMessage", "confirmationRequired", "onDownload:action", "onDownload:settings"]))
      : _createCommentVNode("", true)
  ], 64))
}