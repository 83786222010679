import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChannelIndicator = _resolveComponent("ChannelIndicator")!
  const _component_UserMenuBorrowLimits = _resolveComponent("UserMenuBorrowLimits")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.container)
  }, [
    (_ctx.showDebug)
      ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.$style.channel)
          }, [
            _createTextVNode(" Channel "),
            _createVNode(_component_ChannelIndicator)
          ], 2),
          _createElementVNode("button", {
            class: _normalizeClass(_ctx.$style.link),
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleDebugConsole && _ctx.toggleDebugConsole(...args)))
          }, " Debug console ", 2)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_UserMenuBorrowLimits),
    _createElementVNode("section", null, [
      (_ctx.canDownload)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: _normalizeClass(_ctx.$style.link),
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('menu', 'download')))
          }, _toDisplayString(_ctx.$t('header.account.downloadSettings.label')), 3))
        : _createCommentVNode("", true),
      _createElementVNode("button", {
        class: _normalizeClass(_ctx.$style.link),
        "aria-haspopup": "dialog",
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.showGdprModal && _ctx.showGdprModal(...args)))
      }, _toDisplayString(_ctx.$t('gdpr.modal.header')), 3)
    ]),
    _createElementVNode("section", null, [
      _createElementVNode("button", {
        class: _normalizeClass(_ctx.$style.link),
        onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.signOut && _ctx.signOut(...args)))
      }, _toDisplayString(_ctx.$t('header.account.signOut')), 3)
    ]),
    _createElementVNode("section", null, [
      _createElementVNode("a", {
        href: "https://help.lexisdl.com",
        target: "_blank",
        class: _normalizeClass(_ctx.$style.link)
      }, _toDisplayString(_ctx.$t('header.account.help')), 3),
      _createElementVNode("a", {
        href: "mailto:lexisdl@overdrive.com",
        class: _normalizeClass(_ctx.$style.link)
      }, _toDisplayString(_ctx.$t('header.account.sendFeedback')), 3),
      (_ctx.hasDiagnosticsCapability)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: _normalizeClass(_ctx.$style.link),
            onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.triggerDiagnosticsLog && _ctx.triggerDiagnosticsLog(...args)))
          }, _toDisplayString(_ctx.$t('header.account.sendDiagnosticsLog.label')), 3))
        : _createCommentVNode("", true),
      _createElementVNode("button", {
        class: _normalizeClass(_ctx.$style.link),
        onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('menu', 'about')))
      }, _toDisplayString(_ctx.$t('header.account.about')), 3)
    ])
  ], 2))
}