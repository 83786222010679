
import { FilterOptionType } from 'app/components/FilterButton.vue';
import { FilterObject } from 'app/models/filter-object';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'FilterAppliedButton',
  props: {
    appliedOption: {
      type: Object as PropType<FilterObject>,
      default: undefined
    },
    optionType: {
      type: String as PropType<FilterOptionType>,
      default: 'text'
    }
  },
  emits: [
    'clicked'
  ]
});
