
import { PostishCopyAnnotationSnapshot, PostishCopyTitleSnapshot } from 'app/base/postish';
import CopyReviewSection from 'app/components/CopyReviewSection.vue';
import EmptyState from 'app/components/EmptyState.vue';
import LoadingPage from 'app/components/LoadingPage.vue';
import Page from 'app/components/Page.vue';
import Surface from 'app/components/Surface.vue';
import { getReleaseLabel } from 'app/functions/use-annotation-copying';
import { useCopyAnnotationSnapshots } from 'app/functions/use-copy-annotation-snapshots';
import { useCopyJob } from 'app/functions/use-copy-job';
import { useLibrary } from 'app/functions/use-library';
import { useTitle } from 'app/functions/use-title';
import { TitleRecord } from 'app/models/title';
import NotFound from 'app/views/NotFound.vue';
import { computed, defineComponent } from 'vue';


type PageState = {
  status: 'loaded';
  title: TitleRecord;
  release: string;
  successes: PostishCopyAnnotationSnapshot[];
  failures: PostishCopyAnnotationSnapshot[];
} | {
  status: 'loading';
} | {
  status: 'error';
};

export default defineComponent({
  name: 'CopyReview',
  components: {
    CopyReviewSection,
    EmptyState,
    LoadingPage,
    NotFound,
    Page,
    Surface
  },
  props: {
    jobId: {
      type: String,
      required: true
    }
  },
  setup: (props) => {
    const library = useLibrary();
    const hideNote = computed(() => library.value?.disableNotes || false);

    const {
      copyJob,
      loading: copyJobLoading
    } = useCopyJob(props.jobId);

    const {
      copyAnnotationSnapshots,
      loading: copyAnnotationSnapshotsLoading
    } = useCopyAnnotationSnapshots(props.jobId);

    const targetTitleId = computed(() => copyJob.value?.target_title.title_id || null);
    const {
      title: targetTitle,
      loading: targetTitleLoading
    } = useTitle(targetTitleId);

    const successes = computed<PostishCopyAnnotationSnapshot[]>(() => {
      const snapshots = copyAnnotationSnapshots.value;
      if (!snapshots) { return []; }

      const job = copyJob.value;
      if (!job) { return []; }

      const successUuids = job.results.successes.map((s) => s.copied);

      const successSnapshots = snapshots.filter((snap) => successUuids.includes(snap.uuid)) as PostishCopyAnnotationSnapshot[];

      return successSnapshots.sort((a, b) => b.syncstamp - a.syncstamp);
    });

    const failures = computed<PostishCopyAnnotationSnapshot[]>(() => {
      const snapshots = copyAnnotationSnapshots.value;
      if (!snapshots) { return []; }

      const job = copyJob.value;
      if (!job) { return []; }

      const failureUuids = job.results.failures;

      const failureSnapshots = snapshots.filter((snap) => failureUuids.includes(snap.uuid)) as PostishCopyAnnotationSnapshot[];

      return failureSnapshots.sort((a, b) => b.syncstamp - a.syncstamp);
    });

    const dataLoading = computed(() => copyJobLoading.value || copyAnnotationSnapshotsLoading.value || targetTitleLoading.value);
    const dataReady = computed(() => copyJob.value && copyAnnotationSnapshots.value && targetTitle.value);

    const state = computed<PageState>(() => {
      if (dataReady.value) {
        return {
          status: 'loaded',
          title: targetTitle.value,
          release: getReleaseLabel(copyJob.value!.source_title as PostishCopyTitleSnapshot, copyJob.value!.target_title as PostishCopyTitleSnapshot),
          successes: successes.value,
          failures: failures.value
        } as PageState;
      }

      if (dataLoading.value) {
        return {
          status: 'loading'
        };
      }

      return {
        status: 'error'
      };
    });

    return {
      hideNote,
      state
    };
  }
});
