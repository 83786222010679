import { OverlayNames, OverlaySubmittedData, overlaySymbols } from 'app/keys/overlay-keys';
import { Dictionary } from 'lib/common/dictionary';
import { ComputedRef, inject, provide, readonly, ref, Ref } from 'vue';

export interface OverlayContext<T extends OverlayNames> {
  shouldShow: Ref<boolean>; // if the overlay component should be visible or not
  componentData: Ref<unknown>; // overlay component-specific data (props) that is passed in from the trigger
  focusOnAfterClose: Ref<HTMLElement | null>; // element to focus on after overlay component is hidden
  submittedData: ComputedRef<OverlaySubmittedData<T>>; // data submitted from the overlay component to be handled somewhere else
  show: (data: unknown, focus?: HTMLElement | null) => void;
  hide: () => void;
  submit: (data: unknown) => void;
};

const buildOverlayContext = () => {
  const shouldShow = ref(false);
  const componentData = ref(null);
  const focusOnAfterClose = ref(null);
  const submittedData = ref(null);

  const show = (data: unknown, focus?: HTMLElement | null) => {
    shouldShow.value = true;
    componentData.value = data;
    focusOnAfterClose.value = focus;
  };

  const hide = () => {
    shouldShow.value = false;
    componentData.value = null;
    focusOnAfterClose.value?.focus();
  };

  const submit = (data: unknown) => {
    hide();

    submittedData.value = data;
  };

  const context = {
    shouldShow: readonly(shouldShow),
    componentData: readonly(componentData),
    focusOnAfterClose: readonly(focusOnAfterClose),
    submittedData: readonly(submittedData),
    show,
    hide,
    submit
  };

  return context;
};

const contexts = {} as Dictionary<OverlayContext<OverlayNames>>;

/**
 * Provide only in Arena.vue.
 * Makes a new context for all the symbols in overlay-keys.ts
 */
export const provideOverlayContext = () => {
  for (const key of Object.keys(overlaySymbols)) {
    const context = buildOverlayContext();

    provide(Symbol.for(key), context);

    contexts[key] = context;
  }

  return contexts;
};

/**
 * Places to inject:
 * - trigger component (to show and provide data)
 * - component that handles the submitted data
 * @param key Name of the component to show
 */
export const injectOverlayContext = (key: OverlayNames) => {
  const context: OverlayContext<typeof key> = inject(Symbol.for(key));

  // If we try to inject without a provided context, print an error but create a non-provided context for use in-place.
  if (!context) {
    console.error(key, 'context not provided');

    contexts[key] = buildOverlayContext();

    return contexts[key];
  }

  return context;
};
