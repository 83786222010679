import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchThisTitleForm = _resolveComponent("SearchThisTitleForm")!

  return (_openBlock(), _createBlock(_component_SearchThisTitleForm, {
    item: _ctx.item,
    query: _ctx.params ? _ctx.params.query : undefined,
    scope: _ctx.params ? _ctx.params.scope : undefined,
    type: _ctx.params ? _ctx.params.type : undefined,
    onSearch: _ctx.search
  }, null, 8, ["item", "query", "scope", "type", "onSearch"]))
}