
import { defineComponent, computed, ref } from 'vue';
import { Series } from 'app/models/series';
import VolumeSelect from 'app/components/VolumeSelect.vue';
import TableOfContents from 'app/components/TableOfContents.vue';
import { Title, TitleRecord } from 'app/models/title';
import { Dictionary } from 'app/base/common';

export default defineComponent({
  name: 'SeriesTableOfContents',
  components: {
    VolumeSelect,
    TableOfContents
  },
  props: {
    series: {
      type: Series,
      required: true
    },
    titleMap: {
      type: Object as () => Dictionary<TitleRecord>,
      required: true
    },
    appendDropdownToBody: {
      type: Boolean,
      default: true
    },
    showTitle: {
      type: Boolean,
      default: false
    }
  },
  setup: (props) => {
    const volumes = computed(() => {
      return props.series.items
        .map((item) => props.titleMap[item.id])
        .filter((t) => !!t)
        .sort(Title.SORT_FUNCTIONS.seriesRank);
    });
    const title = ref<TitleRecord | null>(volumes.value[0]);

    return {
      volumes,
      title
    };
  }
});
