
import { computed, defineComponent, ref } from 'vue';
import { APP } from 'app/base/app';
import FormSwitch from 'app/components/FormSwitch.vue';

export default defineComponent({
  name: 'UserMenuDownload',
  components: {
    FormSwitch
  },
  setup: () => {
    const hasCellular = APP.network.isPrecise;
    const autoDownloadRule = ref(APP.updateManager.autoDownloadRule);
    const autoDownloads = computed({
      get: () => autoDownloadRule.value?.key === 'everything',
      set: (v) => APP.updateManager.setAutoDownloadRule({ key: v ? 'everything' : 'nothing'})
    });
    const downloadQueueRule = ref(APP.updateManager.downloadQueueRule);
    const wifiDownloads = computed({
      get: () => downloadQueueRule.value === 'wifi',
      set: (v) => APP.updateManager.setDownloadQueueRule(v ? 'wifi' : 'go')
    });

    return {
      autoDownloads,
      hasCellular,
      wifiDownloads
    };
  }
});
