
import { defineComponent, PropType, ref } from 'vue';
import { NtcProvider } from 'app/views/Ntc.vue';
import Popout from 'app/components/Popout.vue';
import RelativeDate from 'app/components/RelativeDate.vue';

export default defineComponent({
  name: 'NtcTitleCard',
  components: {
    Popout,
    RelativeDate
  },
  props: {
    provider: {
      type: Object as PropType<NtcProvider>,
      required: true
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    'access',
    'clearError'
  ],
  setup: (props) => {
    const accessButton = ref<HTMLElement | null>(null);

    return {
      accessButton
    };
  }
});
