
import FocusTrap from 'app/components/FocusTrap.vue';
import { useClamped } from 'app/functions/use-clamped';
import { computed, defineComponent, ref } from 'vue';
import { RouteLocation } from 'vue-router';

export type CardMetadata = {
  label: string;
  value: string;
};

/**
 * The basic building block for a card
 */
export type CardableItem = {
  id: string;
  title: string;
  subtitle: string;
  authors: string;
  metadata: CardMetadata[];
};

/**
 * @desc - The base card component
 */
export default defineComponent({
  components: {
    FocusTrap
  },
  props: {
    /**
     * @param item - The item to display in card form
     */
    item: {
      type: Object as () => CardableItem,
      required: true
    },
    /**
     * @param link - The location this card should direct to
     * @example { name: "TitleDetails", params: { titleSlug: "1234" } }
     */
    link: {
      type: Object as () => RouteLocation,
      default: undefined
    },
    showExpander: {
      type: Boolean,
      default: true
    },
    /**
     * Display without card border and shadow
     */
    borderless: {
      type: Boolean,
      default: false
    },
    compact: {
      type: Boolean,
      default: false
    },
    showSeriesBadge: {
      type: Boolean,
      default: false
    }
  },
  setup: (props, ctx) => {
    const showInfoFocus = ref(false);

    const titleName = ref<HTMLElement | null>(null);
    const { clamped: titleClamped } = useClamped(titleName);
    const subtitle = ref<HTMLElement | null>(null);
    const { clamped: subtitleClamped } = useClamped(subtitle);
    const authors = ref<HTMLElement | null>(null);
    const { clamped: authorsClamped } = useClamped(authors);
    const showExpansionButton = computed(() => props.showExpander && (titleClamped.value || subtitleClamped.value || authorsClamped.value));
    const showExpansion = ref(false);
    const showActions = computed(() => !!ctx.slots.actions);

    return {
      showActions,
      showExpansion,
      showExpansionButton,
      showInfoFocus,
      authors,
      subtitle,
      titleName
    };
  }
});
