
import { defineComponent } from 'vue';
import Prompt from './Prompt.vue';

export default defineComponent({
  name: 'NtcPrompt',
  components: {
    Prompt
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    providerId: {
      type: String,
      default: 'provider'
    }
  },
  emits: [
    'submit',
    'close'
  ]
});
