
import CoverView from 'app/components/CoverView.vue';
import TitleDetailsContextMenu from 'app/components/contextMenus/TitleDetailsContextMenu.vue';
import { injectOverlayContext } from 'app/contexts/overlay-context';
import { useTitleSubscription } from 'app/functions/use-subscription-interaction';
import { FilterObject } from 'app/models/filter-object';
import { Title } from 'app/models/title';
import { PropType, defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'TitleDetailsContextMenuButton',
  components: {
    CoverView,
    TitleDetailsContextMenu
  },
  props: {
    title: {
      type: Object as PropType<Title>,
      required: true
    }
  },
  setup: (props, ctx) => {
    const showContextMenu = ref<boolean>(false);
    const showCoverView = ref<boolean>(false);
    const { show: showManualCopySidebar } = injectOverlayContext('ManualCopySidebar');

    const {
      subscribeTextLong,
      toggleSubscription
    } = useTitleSubscription(props.title, 'title actions menu');

    const contextMenuButton = ref<HTMLElement | null>(null);

    const showManualCopy = (releases: FilterObject[]) => {
      showManualCopySidebar({ title: props.title, releasesWithAnnotations: releases }, contextMenuButton.value);
    };

    const closeCoverView = () => {
      showCoverView.value = false;
      contextMenuButton.value?.focus();
    };

    return {
      closeCoverView,
      contextMenuButton,
      showContextMenu,
      showCoverView,
      subscribeTextLong,
      showManualCopy,
      toggleSubscription
    };
  }
});
