import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SeriesCover = _resolveComponent("SeriesCover")!
  const _component_CopyLink = _resolveComponent("CopyLink")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_SeriesDetailsAccordion = _resolveComponent("SeriesDetailsAccordion")!
  const _component_SearchThisTitle = _resolveComponent("SearchThisTitle")!
  const _component_TextFilter = _resolveComponent("TextFilter")!
  const _component_TitleCard = _resolveComponent("TitleCard")!
  const _component_NotesTitleCard = _resolveComponent("NotesTitleCard")!
  const _component_TabView = _resolveComponent("TabView")!
  const _component_DetailsPageContent = _resolveComponent("DetailsPageContent")!

  return (_openBlock(), _createBlock(_component_DetailsPageContent, {
    label: "series.label",
    title: _ctx.series.name,
    authors: _ctx.series.creators.Author,
    metadata: _ctx.metadata
  }, {
    cover: _withCtx(() => [
      _createVNode(_component_SeriesCover, {
        series: _ctx.series,
        width: _ctx.seriesCoverSizes.seriesDetails,
        class: _normalizeClass(_ctx.$style.cover)
      }, null, 8, ["series", "width", "class"])
    ]),
    pillar: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.interactContainer)
      }, [
        _createVNode(_component_CopyLink, {
          headerLabel: "copyLink.label",
          class: _normalizeClass([_ctx.$style.interactButton, _ctx.$style.copyLink])
        }, null, 8, ["class"]),
        _createElementVNode("button", {
          class: _normalizeClass([_ctx.$style.interactButton, _ctx.$style.subscribe]),
          "aria-label": _ctx.subscribeTextLong,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleSubscription()))
        }, [
          _createElementVNode("div", null, [
            _createVNode(_component_Icon, {
              class: _normalizeClass(_ctx.$style.bell),
              name: "alert-bell"
            }, null, 8, ["class"])
          ]),
          _createElementVNode("span", null, _toDisplayString(_ctx.subscribeTextShort), 1)
        ], 10, _hoisted_1)
      ], 2)
    ]),
    body: _withCtx(() => [
      _createElementVNode("section", null, [
        _createVNode(_component_SeriesDetailsAccordion, {
          series: _ctx.series,
          volumeDictionary: _ctx.volumeDictionary
        }, null, 8, ["series", "volumeDictionary"])
      ]),
      _createElementVNode("section", null, [
        _createElementVNode("h2", {
          class: _normalizeClass(_ctx.$style.sectionHead)
        }, _toDisplayString(_ctx.$t('searchThisTitle.header.series')), 3),
        _createVNode(_component_SearchThisTitle, {
          item: _ctx.series,
          params: _ctx.searchThisTitle
        }, null, 8, ["item", "params"])
      ])
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_TabView, {
        ariaLabel: "series.volumeList.label",
        tabs: _ctx.volumeTabs
      }, {
        intermediary: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.$style.volumeFilter)
          }, [
            _createVNode(_component_TextFilter, {
              modelValue: _ctx.textFilter,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.textFilter) = $event)),
              label: _ctx.$t('series.volumeList.textFilter')
            }, null, 8, ["modelValue", "label"])
          ], 2)
        ]),
        "panel-all": _withCtx(() => [
          _createElementVNode("ol", {
            class: _normalizeClass(_ctx.$style.volumeList)
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.allVolumes, (volume) => {
              return _withDirectives((_openBlock(), _createElementBlock("li", {
                key: volume.title.slug
              }, [
                _createVNode(_component_TitleCard, {
                  title: volume.title
                }, null, 8, ["title"])
              ])), [
                [_vShow, _ctx.volumeMatchesFilter(volume)]
              ])
            }), 128))
          ], 2)
        ]),
        "panel-annotated": _withCtx(() => [
          _createElementVNode("ol", {
            class: _normalizeClass(_ctx.$style.volumeList)
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.volumesWithAnnotations, (volume) => {
              return _withDirectives((_openBlock(), _createElementBlock("li", {
                key: volume.title.slug
              }, [
                _createVNode(_component_NotesTitleCard, {
                  title: volume.title
                }, null, 8, ["title"])
              ])), [
                [_vShow, _ctx.volumeMatchesFilter(volume)]
              ])
            }), 128))
          ], 2)
        ]),
        "panel-downloaded": _withCtx(() => [
          _createElementVNode("ol", {
            class: _normalizeClass(_ctx.$style.volumeList)
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.downloadedVolumes, (volume) => {
              return _withDirectives((_openBlock(), _createElementBlock("li", {
                key: volume.title.slug
              }, [
                _createVNode(_component_TitleCard, {
                  title: volume.title
                }, null, 8, ["title"])
              ])), [
                [_vShow, _ctx.volumeMatchesFilter(volume)]
              ])
            }), 128))
          ], 2)
        ]),
        _: 1
      }, 8, ["tabs"])
    ]),
    _: 1
  }, 8, ["title", "authors", "metadata"]))
}