
import { defineComponent, computed, watch } from 'vue';
import { Title, TitleRecord } from 'app/models/title';
import LabeledDropdown from 'app/components/LabeledDropdown.vue';


export default defineComponent({
  name: 'VolumeSelect',
  components: {
    LabeledDropdown
  },
  props: {
    volumes: {
      type: Array as () => TitleRecord[],
      required: true
    },
    modelValue: {
      type: Object as () => TitleRecord,
      required: true
    }
  },
  emits: [
    'update:modelValue'
  ],
  setup: (props, ctx) => {
    const options = computed(() => props.volumes.slice().sort(Title.SORT_FUNCTIONS.seriesRank));
    const selected = computed({
      get: () => props.modelValue,
      set: (val) => ctx.emit('update:modelValue', val)
    });

    watch(options, () => {
      const matchingVolume = selected.value && options.value.find((v) => v.slug === selected.value.slug);
      const defaultSelected = matchingVolume || options.value[0];
      selected.value = defaultSelected;
    }, { immediate: true, deep: true });

    return {
      options,
      selected
    };
  }
});
