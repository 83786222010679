import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withKeys as _withKeys, withCtx as _withCtx, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = { key: 4 }
const _hoisted_3 = { id: "circError" }
const _hoisted_4 = { id: "circErrorDesc" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Popout = _resolveComponent("Popout")!
  const _component_BorrowPrompt = _resolveComponent("BorrowPrompt")!
  const _component_HoldPrompt = _resolveComponent("HoldPrompt")!
  const _component_EditHoldPrompt = _resolveComponent("EditHoldPrompt")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "container",
    class: _normalizeClass([_ctx.$style.container, _ctx.$style[_ctx.display], {[_ctx.$style.addFocus]: _ctx.focus}])
  }, [
    (_ctx.state === 'openable')
      ? (_openBlock(), _createBlock(_component_router_link, {
          key: 0,
          to: _ctx.openLink,
          custom: ""
        }, {
          default: _withCtx(({ href }) => [
            _createElementVNode("a", {
              href: href,
              class: _normalizeClass(_ctx.label ? _ctx.$style.label : _ctx.$style.action),
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.open && _ctx.open(...args))),
              onKeypress: _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.open && _ctx.open(...args)), ["enter"]))
            }, [
              (_ctx.icon)
                ? (_openBlock(), _createBlock(_component_Icon, {
                    key: _ctx.state,
                    name: _ctx.icon,
                    class: _normalizeClass(_ctx.$style.icon)
                  }, null, 8, ["name", "class"]))
                : _createCommentVNode("", true),
              _createElementVNode("span", null, _toDisplayString(_ctx.actionLabel), 1)
            ], 42, _hoisted_1)
          ]),
          _: 1
        }, 8, ["to"]))
      : (_ctx.state === 'held')
        ? (_openBlock(), _createElementBlock("button", {
            key: 1,
            class: _normalizeClass(_ctx.label ? _ctx.$style.label : _ctx.$style.action),
            type: "button",
            onClick: _cache[2] || (_cache[2] = ($event: any) => {_ctx.editHold(); _ctx.$emit('action:start')}),
            onFocus: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onFocus && _ctx.onFocus(...args)))
          }, [
            (_ctx.icon)
              ? (_openBlock(), _createBlock(_component_Icon, {
                  key: _ctx.state,
                  name: _ctx.icon,
                  class: _normalizeClass(_ctx.$style.icon)
                }, null, 8, ["name", "class"]))
              : _createCommentVNode("", true),
            _createElementVNode("span", {
              class: _normalizeClass({[_ctx.$style.truncate]: _ctx.truncate})
            }, _toDisplayString(_ctx.actionLabel), 3)
          ], 34))
        : (_ctx.state === 'borrowable')
          ? (_openBlock(), _createElementBlock("button", {
              key: 2,
              class: _normalizeClass(_ctx.label ? _ctx.$style.label : _ctx.$style.action),
              type: "button",
              onClick: _cache[4] || (_cache[4] = ($event: any) => {_ctx.borrow(); _ctx.$emit('action:start')}),
              onFocus: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.onFocus && _ctx.onFocus(...args)))
            }, [
              (_ctx.icon)
                ? (_openBlock(), _createBlock(_component_Icon, {
                    key: _ctx.state,
                    name: _ctx.icon,
                    class: _normalizeClass(_ctx.$style.icon)
                  }, null, 8, ["name", "class"]))
                : _createCommentVNode("", true),
              _createElementVNode("span", {
                class: _normalizeClass({[_ctx.$style.truncate]: _ctx.truncate})
              }, _toDisplayString(_ctx.actionLabel), 3)
            ], 34))
          : (_ctx.state === 'holdable')
            ? (_openBlock(), _createElementBlock("button", {
                key: 3,
                class: _normalizeClass(_ctx.label ? _ctx.$style.label : _ctx.$style.action),
                type: "button",
                onClick: _cache[6] || (_cache[6] = ($event: any) => {_ctx.placeHold(); _ctx.$emit('action:start')}),
                onFocus: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.onFocus && _ctx.onFocus(...args)))
              }, [
                (_ctx.icon)
                  ? (_openBlock(), _createBlock(_component_Icon, {
                      key: _ctx.state,
                      name: _ctx.icon,
                      class: _normalizeClass(_ctx.$style.icon)
                    }, null, 8, ["name", "class"]))
                  : _createCommentVNode("", true),
                _createElementVNode("span", {
                  class: _normalizeClass({[_ctx.$style.truncate]: _ctx.truncate})
                }, _toDisplayString(_ctx.actionLabel), 3)
              ], 34))
            : (_ctx.state === 'loading')
              ? (_openBlock(), _createElementBlock("p", _hoisted_2, [
                  (_openBlock(), _createBlock(_component_Icon, {
                    key: _ctx.state,
                    name: "dl-queued",
                    class: _normalizeClass(_ctx.$style.icon)
                  }, null, 8, ["class"]))
                ]))
              : (_openBlock(), _createElementBlock("p", {
                  key: 5,
                  class: _normalizeClass(_ctx.label ? _ctx.$style.labelNoAction : _ctx.$style.action)
                }, [
                  (_ctx.icon)
                    ? (_openBlock(), _createBlock(_component_Icon, {
                        key: _ctx.state,
                        name: _ctx.icon,
                        class: _normalizeClass(_ctx.$style.icon)
                      }, null, 8, ["name", "class"]))
                    : _createCommentVNode("", true),
                  _createTextVNode(" " + _toDisplayString(_ctx.actionLabel), 1)
                ], 2)),
    (_ctx.error)
      ? (_openBlock(), _createBlock(_component_Popout, {
          key: 6,
          reference: _ctx.container,
          labelId: "circError",
          descriptionId: "circErrorDesc",
          onClose: _cache[9] || (_cache[9] = ($event: any) => (_ctx.error = ''))
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.$style.errorDialog)
            }, [
              _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t('circ.error.header')), 1),
              _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t(_ctx.error, _ctx.errorSubs)), 1),
              _createElementVNode("button", {
                class: "popout-dismiss",
                onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.error = ''))
              }, [
                _createVNode(_component_Icon, { name: "dismiss" })
              ])
            ], 2)
          ], undefined, true),
          _: 1
        }, 8, ["reference"]))
      : _createCommentVNode("", true),
    _createVNode(_component_BorrowPrompt, {
      show: _ctx.showBorrowPrompt,
      title: _ctx.title,
      circ: _ctx.circTitle.circ,
      loansRemaining: _ctx.cardInfo.loansRemaining,
      defaultLendingPeriod: _ctx.cardInfo.defaultLendingPeriod,
      lendingPeriods: _ctx.cardInfo.lendingPeriods,
      onBorrow: _ctx.onBorrowPromptBorrow,
      onClose: _ctx.onBorrowPromptClose
    }, null, 8, ["show", "title", "circ", "loansRemaining", "defaultLendingPeriod", "lendingPeriods", "onBorrow", "onClose"]),
    _createVNode(_component_HoldPrompt, {
      show: _ctx.showHoldPrompt,
      titleId: _ctx.title.slug,
      emailAddress: _ctx.cardInfo.email,
      onHold: _ctx.onHoldPromptHold,
      onClose: _ctx.onHoldPromptClose
    }, null, 8, ["show", "titleId", "emailAddress", "onHold", "onClose"]),
    _createVNode(_component_EditHoldPrompt, {
      show: _ctx.showEditHoldPrompt,
      titleId: _ctx.title.slug,
      emailAddress: _ctx.holdEmail,
      onEdit: _ctx.onEditHoldPromptEdit,
      onDelete: _ctx.onEditHoldPromptDelete,
      onClose: _ctx.onEditHoldPromptClose
    }, null, 8, ["show", "titleId", "emailAddress", "onEdit", "onDelete", "onClose"])
  ], 2))
}