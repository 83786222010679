
import { defineComponent, ref, watchEffect } from 'vue';

export default defineComponent({
  name: 'BackToTopButton',
  props: {
    scrollTo: {
      type: HTMLElement,
      default: undefined as HTMLElement | undefined
    },
    focusOn: {
      type: String,
      required: true
    }
  },
  emits: [
    'show'
  ],
  setup: (props, ctx) => {
    const fadeScroller = ref(false);

    const observer = new IntersectionObserver(
      (entries) => {
        const shouldShow = !entries.some((entry) => entry.intersectionRatio > 0 || entry.isIntersecting ||
          (entry.rootBounds && (entry.rootBounds.height < entry.boundingClientRect.top))
        );

        ctx.emit('show', shouldShow);

        if (shouldShow) {
          fadeScroller.value = false;
          setTimeout(() => fadeScroller.value = true, 1000);
        }
      },
      { root: null, threshold: 0 }
    );

    watchEffect(() => {
      if (props.scrollTo) {
        observer.observe(props.scrollTo);
      } else {
        observer.disconnect();
      }
    });

    const scrollIntoView = () => {
      props.scrollTo?.scrollIntoView({ behavior: 'smooth' });

      setFocus();
    };

    const setFocus = () => {
      const toFocus = document.querySelector(props.focusOn) as HTMLElement;
      toFocus?.focus({ preventScroll: true }); //preventScroll so it does not interfere with the smooth scrolling already happening
    };

    return {
      fadeScroller,
      scrollIntoView
    };
  }
});
