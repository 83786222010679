
import { computed, defineComponent, ref, watch } from 'vue';
import Surface from 'app/components/Surface.vue';
import { Series } from 'app/models/series';
import { APP } from 'app/base/app';
import Loading from 'app/components/Loading.vue';
import NotFound from 'app/views/NotFound.vue';
import { TitleRecord } from 'app/models/title';
import { Dictionary } from 'lib/common/dictionary';
import { useAppEvents } from 'app/functions/use-app-events';
import SeriesDetailsContent from 'app/components/SeriesDetailsContent.vue';
import { SearchThisTitleQuery } from 'app/base/hudson';

type State =
  | {
    state: 'loading';
  }
  | {
    state: 'notfound';
  }
  | {
    state: 'loaded';
    series: Series;
  };

export type SeriesDetailsVolume = {
  title: TitleRecord;
  hasAnnotations: boolean;
  isDownloaded: boolean;
};

export default defineComponent({
  name: 'SeriesDetails',
  components: {
    Loading,
    Surface,
    NotFound,
    SeriesDetailsContent
  },
  props: {
    seriesId: {
      type: String,
      required: true
    },
    searchThisTitle: {
      type: Object as () => SearchThisTitleQuery | null,
      default: null
    }
  },
  setup: (props, ctx) => {
    const state = ref<State>({ state: 'loading' });
    const volumes = ref<Dictionary<SeriesDetailsVolume>>({});

    const searchParameters = computed(() => {
      if (props.searchThisTitle) {
        return props.searchThisTitle;
      }

      return null;
    });

    watch(() => props.seriesId, async (to) => {
      state.value = { state: 'loading' };
      const series = await loadSeries(props.seriesId);

      // It's possible the user's navigated away
      // by the time we're done loading the title.
      // Let's check that we're still current.
      if (to !== props.seriesId) {
        return;
      }

      if (series.freshness
        && !series.freshness.isFreshening()
        && !series.freshness.isFresh()
      ) {
        state.value = {
          state: 'notfound'
        };
      } else {
        state.value = {
          state: 'loaded',
          series
        };

        const volumeDictionary = await series.getAllTitleObjects();
        volumes.value = mapVolumes(Object.values(volumeDictionary));
        updateAnnotations();
        updateDownloaded();
      }
    }, { immediate: true });

    const mapVolumes = (volumeList: TitleRecord[]) => {
      return volumeList
        .map((title) => {
          return {
            title,
            hasAnnotations: false,
            isDownloaded: false
          };
        })
        .reduce(
          (dict, next) => {
            dict[next.title.slug] = next;

            return dict;
          },
          {} as Dictionary<SeriesDetailsVolume>
        );
    };

    const updateAnnotations = () => {
      APP.patron.annotations.all
        .filter((a) => a.titleSlug in volumes.value)
        .forEach((a) => volumes.value[a.titleSlug].hasAnnotations = true);
    };

    const updateDownloaded = () => {
      APP.patron.loans.all
        .filter((l) => l.titleSlug in volumes.value)
        .forEach((l) => volumes.value[l.titleSlug].isDownloaded = l.isDownloaded());
    };

    useAppEvents({
      'annotation:update:all': ({ m }) => {
        if (state.value.state === 'loaded') {
          updateAnnotations();
        }
      },
      'loan:download:complete': ({ m }) => {
        if (state.value.state === 'loaded'
          && m.loan.titleSlug in volumes.value
        ) {
          volumes.value[m.loan.titleSlug].isDownloaded = true;
        }
      },
      'loan:download:wipe': ({ m }) => {
        if (state.value.state === 'loaded'
          && m.loan.titleSlug in volumes.value
        ) {
          volumes.value[m.loan.titleSlug].isDownloaded = false;
        }
      }
    });

    return {
      searchParameters,
      state,
      volumes
    };
  }
});

async function loadSeries(seriesId: string) {
  const series = new Series(Number(seriesId));

  await series.freshen();

  return series;
}

