// For components registered in Overlays.vue,
// add the component name here so a new context
// will be created for each in overlay-context.ts
export const overlaySymbols = {
  ManageCopyingSidebar: Symbol('ManageCopyingSidebar'),
  ManualCopySidebar: Symbol('ManualCopySidebar'),
  SearchThisTitleSidebar: Symbol('SearchThisTitleSidebar')
} as const;


// The Symbols as a Type
export type OverlayNames = keyof typeof overlaySymbols;


// The data emitted from the overlay component as a Type
export type OverlaySubmittedData<T extends OverlayNames> =
  T extends 'ManualCopySidebar' ? { release: string } :
  never;
