
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'Pagination',
  props: {
    currentPage: {
      type: Number,
      required: true
    },
    totalPages: {
      type: Number,
      required: true
    },
    maxPages: {
      type: Number,
      default: 5
    }
  },
  emits: [
    'update:currentPage'
  ],
  setup: (props, ctx) => {
    /**
     * All the pages we may want to show
     * - Always shows the first, current, and last pages
     * - Tries to evenly distribute pages around the current page as much as possible
     */
    const pages = computed(() => {
      const pagesToShow = new Set([1, props.currentPage, props.totalPages]);

      const maxPagesToShow = Math.min(props.maxPages, props.totalPages);
      let previous = props.currentPage;
      let next = props.currentPage;

      while (pagesToShow.size < maxPagesToShow) {
        previous = Math.max(1, previous - 1);
        pagesToShow.add(previous);

        if (pagesToShow.size < maxPagesToShow) {
          next = Math.min(props.totalPages, next + 1);
          pagesToShow.add(next);
        }
      }

      const sorted = [...pagesToShow].sort((a, b) => a - b);

      return sorted;
    });

    /**
     * Groups the pages into sequences of numbers so that we can show separators between sequences
     * - [1, 2, 3, 4, 6] => [[1, 2, 3, 4], [6]]
     * - [1, 4, 5, 6, 7] => [[1], [4, 5, 6, 7]]
     * - [1, 3, 4, 5, 8] => [[1], [3, 4, 5], [8]]
     * - [2, 3, 6, 7, 9] => [[2, 3], [6, 7], [9]] (not relevant to paging, but it's how the grouping works)
     */
    const groupedPages = computed(() => {
      // Split off the first value to start the first group.
      // Could do it as part of the reduce, but this saves an extra check.
      const [first, ...rest] = pages.value;

      // Iterate through all the pages we want to show, adding it to the last group
      // if it continues a sequence, or starting a new group if it doesn't.
      // Assumes that the list of pages is ordered.
      const groupedList = rest.reduce((groups, num) => {
        const finishedGroups = groups.slice(0, -1);
        const lastGroup = groups[groups.length - 1];

        const lastNum = lastGroup[lastGroup.length - 1];

        //Continue in the same group/sequence
        if (num === lastNum + 1) {
          return [...finishedGroups, [...lastGroup, num]];
        }

        //Continue in the same group/sequence filling in the one missing number
        if (num === lastNum + 2) {
          return [...finishedGroups, [...lastGroup, num - 1, num]];
        }

        //Start a new group/sequence
        return [...finishedGroups, lastGroup, [num]];
      }, [[first]] as number[][]);

      return groupedList;
    });

    const showBackButton = computed(() => props.currentPage > 1);
    const showForwardButton = computed(() => props.currentPage < props.totalPages);

    const goToPage = (page: number) => {
      const viablePage = Math.max(1, Math.min(props.totalPages, page));
      ctx.emit('update:currentPage', viablePage);
    };

    const back = () => {
      goToPage(props.currentPage - 1);
    };

    const forward = () => {
      goToPage(props.currentPage + 1);
    };

    return {
      back,
      forward,
      goToPage,
      groupedPages,
      showBackButton,
      showForwardButton
    };
  }
});
