import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  id: "share-passage-description",
  class: "visually-hidden"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ShareLink = _resolveComponent("ShareLink")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Popout = _resolveComponent("Popout")!

  return (_openBlock(), _createBlock(_component_Popout, {
    reference: _ctx.reference,
    labelId: "share-passage-description",
    onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.container)
      }, [
        _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.$t('searchThisTitle.shareDescription')), 1),
        (_ctx.linkState.state === 'loaded')
          ? (_openBlock(), _createBlock(_component_ShareLink, {
              key: 0,
              headerLabel: "searchThisTitle.share",
              link: _ctx.linkState.link
            }, null, 8, ["link"]))
          : (_openBlock(), _createBlock(_component_Icon, {
              key: 1,
              name: "spinner",
              class: _normalizeClass(_ctx.$style.loading),
              "aria-label": _ctx.$t('general.loading')
            }, null, 8, ["class", "aria-label"])),
        _createElementVNode("button", {
          class: "dark popout-dismiss",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
        }, [
          _createVNode(_component_Icon, { name: "dismiss" })
        ])
      ], 2)
    ], undefined, true),
    _: 1
  }, 8, ["reference"]))
}