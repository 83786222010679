
import { PostishCopyAnnotationSnapshot } from 'app/base/postish';
import HighlightColor from 'app/components/HighlightColor.vue';
import RelativeDate from 'app/components/RelativeDate.vue';
import { getReleaseDisplayNameAnnotationSnapshot } from 'app/functions/use-release-display-name';
import { PropType, defineComponent, nextTick, ref } from 'vue';

export default defineComponent({
  name: 'AnnotationCard',
  components: {
    HighlightColor,
    RelativeDate
  },
  props: {
    snapshot: {
      type: Object as PropType<PostishCopyAnnotationSnapshot>,
      required: true
    },
    hideNote: {
      type: Boolean,
      default: false
    }
  },
  setup: (props, ctx) => {
    const expansionButton = ref<HTMLElement | null>(null);

    const expanded = ref(false);
    const toggleExpansion = async () => {
      expanded.value = !expanded.value;

      await nextTick();
      expansionButton.value?.scrollIntoView({ behavior: 'smooth'});
      expansionButton.value?.focus({ preventScroll: true });
    };

    const releaseDisplayName = getReleaseDisplayNameAnnotationSnapshot(props.snapshot);

    return {
      expanded,
      expansionButton,
      releaseDisplayName,
      toggleExpansion
    };
  }
});

